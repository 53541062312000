<template>
  <v-main>
    <v-container fluid id="home">
      <v-row justify="start" class="mb-2 header-fixed-height pt-3">
        <v-btn icon color="primary" @click="$router.push('/sources')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <h2>{{ name }}</h2>
      </v-row>
      <v-row justify="start">
        <v-col class="py-0 px-2">
          <v-select :items="external_data" v-model="external_data_value" @change="externalData" :placeholder="$t('story.detail.choose')" outlined dense :label="$t('source.detail.external_data')"></v-select>
        </v-col>
        <v-col class="py-0 px-2" v-if="external_data_value === ''">
          <v-file-input color="primary" prepend-icon="" outlined dense v-model="file" :error-messages="fileMessage" @change="preview" :label="$t('source.detail.upload')" :show-size="file !== undefined"></v-file-input>
        </v-col>
        <v-col class="py-0 px-2">
          <v-text-field :label="$t('source.detail.name')" outlined dense v-model="name" :error-messages="nameMessage" type="text" />
        </v-col>
        <v-btn color="primary" class="ml-2" v-if="external_data_value === 's3'" @click="showConnectionSettings = true" outlined>{{ $t('source.detail.connection_settings') }}</v-btn>
        <v-col class="py-0 px-2" md="auto" sm="auto" lg="auto" xl="auto">
          <v-btn color="primary" class="ml-2" @click="showComment = true" fab small outlined><v-icon>mdi-information-variant</v-icon></v-btn>
          <div v-if="commentMessage.length > 0" style="font-size: 12px; color: #ff5252 !important; width: 150px; line-height: 12px;">{{ commentMessage[0] }}</div>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center">
        <v-btn color="primary" class="ml-2" v-if="external_data_value === 's3'" :loading="load" @click="previewS3" outlined>{{ $t('buttons.preview') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="external_data_value === 's3' && id === 0" :loading="load_update" @click="saveS3" outlined>{{ $t('buttons.save') }}</v-btn>
        <p v-if="external_data_value === 's3' && s3Message.length > 0" class="mb-0 red--text pl-4" style="align-self: center;">{{ s3Message[0] }}</p>
        <v-btn color="primary" class="ml-2" v-if="external_data_value !== 's3' && file != undefined && fileMessage.length == 0 && id == 0" :loading="load" @click="save" outlined>{{ $t('buttons.save') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="external_data_value !== 's3' && file != undefined && fileMessage.length == 0 && id != 0" :loading="load" @click="update" outlined>{{ $t('buttons.update') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="external_data_value !== 's3' && external_data_value !== 'mixed' && file === undefined && id != 0" :loading="load" @click="updateName" outlined>{{ $t('buttons.update') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="external_data_value === 's3' && id != 0" :loading="load_update" @click="updateS3" outlined>{{ $t('buttons.save') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="external_data_value === 'mixed' && id === 0" :loading="load" @click="saveMixed" outlined>{{ $t('buttons.save') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="external_data_value === 'mixed' && id != 0" :loading="load" @click="updateMixed" outlined>{{ $t('buttons.update') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="id != 0" :loading="load_download" outlined @click="download">{{ $t('buttons.download') }}</v-btn>
        <v-btn color="warning" class="ml-2" v-if="id != 0" :loading="load_delete" outlined @click="deleteSource">{{ $t('buttons.delete') }}</v-btn>
        <img v-if="external_data_value == 'weather'" style="padding-left: 10px" height="45" src="../../img/yandex_weather.png" />
        <img v-if="external_data_value == 'exchange_rate'" style="padding-left: 10px" height="40" src="../../img/bank_russia.png" />
        <v-icon v-if="errors.values.length > 0" size="40" class="pl-3" color="red">mdi-alert</v-icon>
        <p v-if="errors.values.length > 0" class="mb-0 red--text" style="align-self: center;">{{ $t('source.detail.data_errors') }}</p>
        <v-switch v-if="errors.values.length > 0" class="ml-3 my-0" style="align-self: center;" color="red" dense hide-details v-model="errorsShow"></v-switch>
        <p v-if="external_data_value === 'mixed' && mixedMessage.length > 0" class="mb-0 red--text pl-4" style="align-self: center;">{{ mixedMessage[0] }}</p>
        <p class="text-left mb-0 pl-5" v-if="rows !== 0 && rows !== '-'">{{ $t('source.detail.rows') }}<span class="font-weight-bold primary--text">{{ $numeral(rows).format('0,0') }}</span></p>
        <p class="text-left mb-0 pl-2" v-if="rows !== 0">{{ $t('source.detail.columns') }}<span class="font-weight-bold primary--text">{{ $numeral(columns.length).format('0,0') }}</span></p>
      </v-row>
      <v-row class="pt-3" v-if="((file != undefined && fileMessage.length == 0) || id != 0) && external_data_value !== 'mixed'">
        <v-col cols="12" class="px-0">
          <v-progress-circular indeterminate color="primary" v-if="columns.length === 0 && !hugeData"></v-progress-circular>
          <v-progress-circular rotate="-90" size="100" width="15" v-if="columns.length === 0 && hugeData" :value="uploadedPercent" color="primary">{{ uploadedPercent }}</v-progress-circular>
          <v-simple-table fixed-header :height="`${windowHeight - 250}px`">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" v-if="columns.length > 0">
                    <p class="table-header grey--text">#</p>
                    <p class="table-subheader white--text">#</p>
                  </th>
                  <th class="text-left" v-for="(column, key) in columns" :key="key">
                    <p class="table-header">{{ column.name }}</p>
                    <p class="table-subheader">{{ types[column.type == 'date' ? column.type + '_' + column.period : column.type] }}</p>
                  </th>
                </tr>
              </thead>
              <tbody v-if="!errorsShow">
                <tr v-for="(row, key1) in values" :key="key1">
                  <td class="grey--text text-left">{{ key1 + 1 }}</td>
                  <td class="text-left" v-for="(value, key2) in row" :key="key2">{{ value }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(row, key1) in errors.values" :key="key1">
                  <td class="grey--text">{{ errors.index[key1] + 1 }}</td>
                  <td v-for="(value, key2) in row" :key="key2">{{ value == 'empty_value' ? $t('source.detail.empty_value') :  value == 'wrong_value' ? $t('source.detail.wrong_value') : value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row class="pt-3" v-if="external_data_value === 's3' && fileMessage.length == 0">
        <v-col cols="12" class="px-0">
          <v-simple-table fixed-header :height="`${windowHeight - 250}px`">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" v-if="columns.length > 0">
                    <p class="table-header grey--text">#</p>
                    <p class="table-subheader white--text">#</p>
                  </th>
                  <th class="text-left" v-for="(column, key) in columns" :key="key">
                    <p class="table-header">{{ column.name }}</p>
                    <p class="table-subheader">{{ types[column.type == 'date' ? column.type + '_' + column.period : column.type] }}</p>
                  </th>
                </tr>
              </thead>
              <tbody v-if="!errorsShow">
                <tr v-for="(row, key1) in values" :key="key1">
                  <td class="grey--text">{{ key1 + 1 }}</td>
                  <td v-for="(value, key2) in row" :key="key2">{{ value }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(row, key1) in errors.values" :key="key1">
                  <td class="grey--text">{{ errors.index[key1] + 1 }}</td>
                  <td v-for="(value, key2) in row" :key="key2">{{ value == 'empty_value' ? $t('source.detail.empty_value') :  value == 'wrong_value' ? $t('source.detail.wrong_value') : value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row justify="start" v-if="external_data_value === 'mixed'">
        <v-col cols="3" sm="3" md="3">
          <draggable class="list-group pa-0" tag="ul" v-model="stages" :move="checkMove" v-bind="dragOptions" @start="isDragging = true" @end="isDragging = false">
            <transition-group type="transition" name="flip-list">
              <li :class="`list-group-item v-btn v-btn--block v-btn--flat v-btn--outlined theme--light v-size--default primary--text mb-2 justify-start ${value.fixed ? 'draggable' : '' }`"
               v-for="value in stages" :key="value.order" @click="chooseProcess(value.order)" @contextmenu.prevent.stop="chooseItem($event, value.order)">
                <v-icon color="primary" class="pr-2">{{ icon_types[value.type] }}</v-icon>
                <span style="text-transform: none;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{ value.name }}</span>
              </li>
            </transition-group>
          </draggable>
          <v-row class="mt-3" justify="center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="mx-2" @click="showAddSourceHandling = true" fab dark color="green">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('source.detail.add_handling') }}</span>
            </v-tooltip>
          </v-row>
          <v-row class="mt-7" justify="center">
            <v-btn depressed color="primary" block @click="showResults = true">{{ $t('source.detail.handling_result1') }}</v-btn>
          </v-row>
        </v-col>
        <v-col cols="9" sm="9" md="9">
          <v-row v-if="!showResults && stages[activeOrderIndex].order != null">
            <v-col>
              <v-text-field :label="$t('source.detail.process_name')" hide-details outlined dense v-model="stages[activeOrderIndex].name" type="text" />
            </v-col>
            <v-col>
              <v-select v-if="stages[activeOrderIndex].type === 'add_source'" hide-details :items="sources" v-model="stages[activeOrderIndex].options.source_id" outlined dense @change="calculateResults" :placeholder="$t('story.detail.choose')" :label="$t('story.detail.source')"></v-select>
              <v-select v-if="stages[activeOrderIndex].type === 'preprocessing'" hide-details :items="preprocessing_list" v-model="stages[activeOrderIndex].options.preprocess" outlined dense @change="updatePreprocess" :placeholder="$t('story.detail.choose')" :label="$t('source.detail.handling_preprocessing')"></v-select>
              <v-select v-if="stages[activeOrderIndex].type === 'merge'" hide-details :items="merge_list" v-model="stages[activeOrderIndex].options.merge_type" outlined dense @change="updatePreprocess" :placeholder="$t('story.detail.choose')" :label="$t('source.detail.merge')"></v-select>
            </v-col>
            <v-col v-if="stages[activeOrderIndex].type !== 'add_source'">
              <v-switch v-model="tableActive" hide-details class="mt-0 pt-2" :label="$t('source.detail.show_data')"></v-switch>
            </v-col>
          </v-row>
          <v-row v-if="showResults">
            <p>{{ $t('source.detail.handling_result2') }}</p>
          </v-row>
          <v-row>
            <v-col cols="12" class="px-0">
              <v-simple-table v-if="(stages[activeOrderIndex].values.length > 0 && stages[activeOrderIndex].type === 'add_source') || (stages[activeOrderIndex].type === 'preprocessing' && tableActive) || (stages[activeOrderIndex].type === 'merge' && tableActive) || showResults" fixed-header :height="`${windowHeight - 250}px`">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" v-if="stages[activeOrderIndex].columns.length > 0">
                        <p class="table-header grey--text">#</p>
                        <p class="table-subheader white--text">#</p>
                      </th>
                      <th class="text-left" style="cursor: pointer;" v-for="(column, key) in stages[activeOrderIndex].columns" :key="key" @click="active_column = column.name;showColumnInfo = true;">
                        <p class="table-header">{{ column.name }}</p>
                        <p class="table-subheader">{{ types[column.type == 'date' ? column.type + '_' + column.period : column.type] }}</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, key1) in stages[activeOrderIndex].values" :key="key1">
                      <td class="grey--text">{{ key1 + 1 }}</td>
                      <td v-for="(value, key2) in row" :key="key2">{{ value }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-if="stages[activeOrderIndex].type === 'preprocessing' && !tableActive">
                <v-row>
                  <v-col>
                    <div v-for="(value, key) in stages[activeOrderIndex].options.values" :key="key" class="mb-3 d-flex align-center">
                      <v-select hide-details v-if="['remove_column', 'rename_column'].includes(stages[activeOrderIndex].options.preprocess)" :items="columnsList" v-model="value.name" outlined dense @change="calculateResults" :placeholder="$t('story.detail.choose')" :label="$t('source.detail.column')"></v-select>
                      <v-select hide-details v-if="stages[activeOrderIndex].options.preprocess === 'replace_nan'" :items="columnsListNA" v-model="value.name" outlined dense @change="calculateResults" :placeholder="$t('story.detail.choose')" :label="$t('source.detail.column')"></v-select>
                      <v-select hide-details v-if="stages[activeOrderIndex].options.preprocess === 'replace_empty'" :items="columnsListEmpty" v-model="value.name" outlined dense @change="calculateResults" :placeholder="$t('story.detail.choose')" :label="$t('source.detail.column')"></v-select>
                      <v-select hide-details v-if="stages[activeOrderIndex].options.preprocess === 'replace_zeros'" :items="columnsListZeros" v-model="value.name" outlined dense @change="calculateResults" :placeholder="$t('story.detail.choose')" :label="$t('source.detail.column')"></v-select>
                      <v-select hide-details v-if="stages[activeOrderIndex].options.preprocess === 'change_type'" :items="columnsListDTypes" v-model="value.name" outlined dense @change="calculateResults" :placeholder="$t('story.detail.choose')" :label="$t('source.detail.column')"></v-select>
                      <v-text-field v-if="stages[activeOrderIndex].options.preprocess === 'rename_column'" class="pl-3" hide-details :label="$t('source.detail.new_name')" @input="calculateResults" outlined dense v-model="value.value" type="text" />
                      <v-select hide-details v-if="['replace_nan', 'replace_empty', 'replace_zeros'].includes(stages[activeOrderIndex].options.preprocess)" :items="impute_strategies" class="pl-3" v-model="value.value" outlined dense @change="calculateResults" :placeholder="$t('story.detail.choose')" :label="$t('source.detail.impute_type')"></v-select>
                      <v-text-field v-if="['replace_nan', 'replace_empty', 'replace_zeros'].includes(stages[activeOrderIndex].options.preprocess) && value.value == 'value'" class="pl-3" hide-details :label="$t('source.detail.value')" @input="calculateResults" outlined dense v-model="value.custom" type="text" />
                      <v-select hide-details v-if="stages[activeOrderIndex].options.preprocess === 'change_type'" :items="dtypes" class="pl-3" v-model="value.value" outlined dense @change="calculateResults" :placeholder="$t('story.detail.choose')" :label="$t('source.detail.columnd_dtype')"></v-select>
                      <v-btn icon color="pink" @click="deleteCondition(key)"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-tooltip bottom v-if="stages[activeOrderIndex].options.preprocess !== ''">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="addCondition" fab dark color="blue">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('source.detail.add_condition') }}</span>
                </v-tooltip>
              </div>
              <div v-if="stages[activeOrderIndex].type === 'merge' && !tableActive">
                <v-row>
                  <v-col>
                    <div v-for="(value, key) in stages[activeOrderIndex].options.values" :key="key" class="mb-3 d-flex align-center">
                      <v-select hide-details v-if="stages[activeOrderIndex].options.merge_type === 'left_join'" :items="sourceMergeList" v-model="value.source1" outlined dense @change="calculateResults" :placeholder="$t('story.detail.choose')" :label="$t('source.detail.source1')"></v-select>
                      <v-select hide-details v-if="stages[activeOrderIndex].options.merge_type === 'left_join'" :items="sourceMergeList" v-model="value.source2" outlined dense @change="calculateResults" :placeholder="$t('story.detail.choose')" class="pl-3" :label="$t('source.detail.source2')"></v-select>
                      <v-select hide-details v-if="stages[activeOrderIndex].options.merge_type === 'left_join'" :items="sourceMergeColumns" v-model="value.merge_key" outlined dense @change="calculateResults" :placeholder="$t('story.detail.choose')" class="pl-3" :label="$t('source.detail.merge_key')"></v-select>
                      <v-btn icon color="pink" @click="deleteCondition(key)"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-tooltip bottom v-if="stages[activeOrderIndex].options.merge_type !== ''">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="addCondition" fab dark color="blue">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('source.detail.add_condition') }}</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <vue-simple-context-menu class="context-padding" elementId="myUniqueId" :options="options" ref="vueSimpleContextMenu" @option-clicked="chooseOption"/>
    <v-dialog v-model="showComment">
      <change-comment :comment="comment" @update="setComment"></change-comment>
    </v-dialog>
    <v-dialog v-model="showConnectionSettings">
      <change-connection-settings :s3="s3" @update="setSettings"></change-connection-settings>
    </v-dialog>
    <v-dialog v-model="showAddSourceHandling">
      <add-source-handling @update="addHandling"></add-source-handling>
    </v-dialog>
    <v-dialog v-model="showColumnInfo">
      <change-column-info :desc="stages[activeOrderIndex].desc" :column="active_column"></change-column-info>
    </v-dialog>
  </v-main>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';
import { required, maxLength } from 'vuelidate/lib/validators';
import ChangeComment from '@/views/story/ChangeComment.vue';
import ChangeConnectionSettings from '@/views/story/ChangeConnectionSettings.vue';
import AddSourceHandling from '@/views/story/AddSourceHandling.vue';
import ChangeColumnInfo from '@/views/story/ChangeColumnInfo.vue';

import draggable from 'vuedraggable';

const nameFormat = name => /^[A-zА-я0-9 _-]+$/.test(name);
const notUndefined = value => value !== undefined;
const rightFormat = value => ['xls', 'xlsx', 'xlsm', 'xlsb', 'odf', 'csv', 'json'].includes(value !== undefined ? value.name.split('.').pop() : '');

export default {
  name: 'SourceDetail',
  mixins: [baseMixin],
  components: { ChangeComment, ChangeConnectionSettings, AddSourceHandling, ChangeColumnInfo, draggable },
  data() {
    return {
      id: this.$route.params.id * 1,
      name: this.$t('source.detail.new'),
      file: undefined,
      columns: [],
      values: [],
      errors: {
        values: [],
        index: []
      },
      errorsShow: false,
      load: false,
      load_update: false,
      load_download: false,
      load_delete: false,
      windowHeight: 0,
      types: {
        date_hours: this.$t('source.detail.date_hours'),
        date_days: this.$t('source.detail.date_days'),
        date_weeks: this.$t('source.detail.date_weeks'),
        date_months: this.$t('source.detail.date_months'),
        date_quarters: this.$t('source.detail.date_quarters'),
        date_years: this.$t('source.detail.date_years'),
        number: this.$t('source.detail.number'),
        category: this.$t('source.detail.category')
      },
      external_data: [
        { text: this.$t('source.detail.no'), value: '' },
        { text: this.$t('source.detail.mixed'), value: 'mixed' },
        { text: this.$t('source.detail.weather'), value: 'weather' },
        { text: this.$t('source.detail.exchange_rate'), value: 'exchange_rate' },
        { text: this.$t('source.detail.s3'), value: 's3' }
      ],
      external_data_value: '',
      showComment: false,
      comment: '',
      s3: {
        url: '',
        access_key_id: '',
        secret_access_key: '',
        bucket_name: '',
        file_name: ''
        // url: 'https://storage.yandexcloud.net',
        // access_key_id: '4xt76PIxtBqnMC-a9xRh',
        // secret_access_key: '5xpuoD7wxa1Aj95PxHkdC-1O5T8CW2QveSHfLh_e',
        // bucket_name: 'beeline-b2l-experiment-testdataset',
        // file_name: 'test.csv'
      },
      showConnectionSettings: false,
      showAddSourceHandling: false,
      showColumnInfo: false,
      stages: [
        {
          name: this.$t('source.detail.new_process'),
          order: 0,
          type: 'add_source',
          fixed: true,
          columns: [],
          values: [],
          desc: {},
          options: { source_id: null }
        }
      ],
      isDragging: false,
      delayedDragging: false,
      icon_types: {
        add_source: 'mdi-database-plus',
        preprocessing: 'mdi-cog-counterclockwise',
        merge: 'mdi-set-merge'
      },
      sources: [],
      active_order: 0,
      active_column: '',
      rows: 0,
      preprocessing_list: [
        { text: this.$t('source.detail.remove_column'), value: 'remove_column' },
        { text: this.$t('source.detail.rename_column'), value: 'rename_column' },
        { text: this.$t('source.detail.replace_nan'), value: 'replace_nan' },
        { text: this.$t('source.detail.replace_empty'), value: 'replace_empty' },
        { text: this.$t('source.detail.replace_zeros'), value: 'replace_zeros' },
        { text: this.$t('source.detail.change_type'), value: 'change_type' },
        // { text: this.$t('source.detail.create_new_column'), value: 'create_new_column' }
      ],
      merge_list: [
        { text: this.$t('source.detail.merge_left_join'), value: 'left_join' },
      ],
      impute_strategies: [
        { text: this.$t('source.detail.impute_delete'), value: 'delete' },
        { text: this.$t('source.detail.impute_median'), value: 'median' },
        { text: this.$t('source.detail.impute_average'), value: 'average' },
        { text: this.$t('source.detail.impute_most_freq'), value: 'most_freq' },
        { text: this.$t('source.detail.impute_0'), value: '0' },
        { text: this.$t('source.detail.impute_empty'), value: 'empty' },
        { text: this.$t('source.detail.impute_value'), value: 'value' },
      ],
      dtypes: ['int16', 'int32', 'int64', 'float32', 'float64', 'text', 'time', 'category'],
      showResults: false,
      tableActive: false,
      options: [
        { name: this.$t('buttons.delete'), type: 'delete' }
      ],
      uploadedPercent: 0,
      hugeData: false
    };
  },
  validations: {
    file: {
      notUndefined,
      rightFormat
    },
    name: {
      required,
      maxLength: maxLength(150),
      nameFormat
    },
    comment: {
      maxLength: maxLength(500)
    },
  },
  computed: {
    fileMessage() {
      if (this.$v.file.$error || this.getError('file') !== '') {
        if (!this.$v.file.notUndefined) {
          return [this.$t('validations.empty_file')];
        } else if (!this.$v.file.rightFormat) {
          return [this.$t('validations.wrong_file')];
        } else if (!this.$v.file.$error && this.getError('file') !== '') {
          return [this.getError('file')];
        }
      }
      return [];
    },
    nameMessage() {
      if (this.$v.name.$error || this.getError('name') !== '') {
        if (!this.$v.name.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.name.nameFormat) {
          return [this.$t('validations.username_format')];
        } else if (!this.$v.name.maxLength) {
          return [this.$t('validations.max_length[0]') + this.$v.name.$params.maxLength.max + this.$t('validations.max_length[1]')];
        } else if (!this.$v.name.$error && this.getError('name') !== '') {
          return [this.getError('name')];
        }
      }
      return [];
    },
    commentMessage() {
      if (this.$v.comment.$error || this.getError('comment') !== '') {
        if (!this.$v.comment.maxLength) {
          return [this.$t('validations.max_length[0]') + this.$v.comment.$params.maxLength.max + this.$t('validations.max_length[1]')];
        } else if (!this.$v.comment.$error && this.getError('comment') !== '') {
          return [this.getError('comment')];
        }
      }
      return [];
    },
    s3Message() {
      if (this.getError('s3') !== '') {
        return [this.getError('s3')];
      }
      return [];
    },
    mixedMessage() {
      if (this.getError('mixed') !== '') {
        return [this.getError('mixed')];
      }
      return [];
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    activeOrderIndex() {
      return this.stages.findIndex(x => x.order === this.active_order);
    },
    columnsList() {
      return this.stages.length === 0 ? [] : (this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].columns.length === 0 ? [] : this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].columns.map(x => x.name));
    },
    columnsListNA() {
      return this.stages.length === 0 ? [] : (this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].columns.length === 0 ? [] : this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].columns.map(x => { return { text: x.name + ' (' + this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].desc[x.name].count_nan + ')', value: x.name }; }));
    },
    columnsListEmpty() {
      return this.stages.length === 0 ? [] : (this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].columns.length === 0 ? [] : this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].columns.map(x => { return { text: x.name + ' (' + this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].desc[x.name].count_empty + ')', value: x.name }; }));
    },
    columnsListZeros() {
      return this.stages.length === 0 ? [] : (this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].columns.length === 0 ? [] : this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].columns.map(x => { return { text: x.name + ' (' + this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].desc[x.name].count_zero + ')', value: x.name }; }));
    },
    columnsListDTypes() {
      return this.stages.length === 0 ? [] : (this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].columns.length === 0 ? [] : this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].columns.map(x => { return { text: x.name + ' (' + this.stages[this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex - 1].desc[x.name].dtype + ')', value: x.name }; }));
    },
    sourceMergeList() {
      return this.stages.length === 0 ? [] : this.stages.slice(0, this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex).filter(x => ['add_source', 'merge'].includes(x.type)).map(x => x.name);
    },
    sourceMergeColumns() {
      return this.stages.length === 0 ? [] : this.findDuplicates(this.stages.slice(0, this.activeOrderIndex === 0 ? 0 : this.activeOrderIndex).filter(x => ['add_source', 'merge'].includes(x.type)).map(x => x.columns.map(y => y.name)).flat());
    }
  },
  created() {
    window.addEventListener('resize', this.$lodash.debounce(this.getWindowHeight, 500));
    this.getWindowHeight();
    if (this.id !== 0) {
      this.$fetch.get(`/api/sources/${this.id}`).then(data => {
        this.name = data.name;
        this.values = data.values;
        this.columns = data.columns;
        this.comment = data.comment;
        this.rows = data.data.rows;
        this.external_data_value = data.data.external_data;
        if (this.external_data_value === 's3') {
          this.s3 = data.data.s3_auth;
        }
        if (this.external_data_value === 'mixed') {
          this.getSources();
          data.data.stages.forEach((value, index) => {
            if (index > 0) {
              let options = {};
              if (value.type === 'add_source') {
                options = { source_id: value.options.source_id };
              } else if (value.type === 'preprocessing') {
                options = { preprocess: value.options.preprocess, values: value.options.values };
              } else if (value.type === 'merge') {
                options = { merge_type: value.options.merge_type, values: value.options.values };
              }
              this.stages.push({ name: value.name, order: index, type: value.type, fixed: false, columns: [], values: [], desc: {}, options });
            } else {
              this.stages[index].name = value.name;
              this.stages[index].options.source_id = value.options.source_id;
            }
          });
          this.calculateResults();
        }
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$lodash.debounce(this.getWindowHeight, 500));
  },
  methods: {
    getWindowHeight() {
      this.windowHeight = document.documentElement.clientHeight;
    },
    preview() {
      this.$v.$touch();
      this.serverErrors = {};
      if (!this.$v.$invalid) {
        let size = this.file.size / 1024 / 1024;
        if (size > 50) {
          this.uploadHugeFile();
          this.hugeData = true;
        } else {
          let formData = new FormData();
          formData.append('file', this.file);
          this.hugeData = false;
          this.$fetch
            .post('/api/source/preview', formData)
            .then(data => {
              this.columns = data.columns;
              this.values = data.values;
              this.errors = data.errors;
              this.rows = data.rows;
              this.name = data.fileName === '' ? this.$t('source.detail.new') : data.fileName;
            })
            .catch(error => {
              this.serverErrors = error;
            });
        }
      }
    },
    uploadHugeFile() {
      let size = this.file.size;
      let sliceSize = 10 * 1024 * 1024;
      let reader = new window.FileReader();
      let file = this.file;
      let vi = this;
      let index = 0;
      function uploadFile(start) {
        let nextSlice = start + sliceSize + 1;
        let blob = file.slice(start, nextSlice);
        reader.onloadend = function (event) {
          if (event.target.readyState !== FileReader.DONE) {
            return;
          }
          let formData = new FormData();
          formData.append('file', blob);
          formData.append('index', index);
          formData.append('file_name', file.name);
          formData.append('status', 'continue');
          vi.$fetch
            .post('/api/source_batch/upload', formData)
            .then(() => {
              let sizeDone = start + sliceSize;
              let percentDone = Math.floor((sizeDone / size) * 100);
              if (nextSlice < size) {
              // if (nextSlice <= 10 * 1024 * 1024 * 3) {
                // Update upload progress
                vi.uploadedPercent = percentDone;
                // More to upload, call function recursively
                uploadFile(nextSlice);
              } else {
                // Update upload progress
                vi.uploadedPercent = 100;
                formData.append('status', 'finish');
                vi.$fetch
                  .post('/api/source_batch/upload', formData)
                  .then(data => {
                    vi.columns = data.columns;
                    vi.values = data.values;
                    vi.errors = data.errors;
                    vi.rows = data.rows;
                    vi.name = data.file_name === '' ? vi.$t('source.detail.new') : data.file_name;
                  })
                  .catch(error => {
                    vi.serverErrors = error;
                  });
              }
            })
            .catch(error => {
              vi.serverErrors = error;
            });
        };
        index += 1;
        reader.readAsDataURL(blob);
      }
      uploadFile(0);
    },
    save() {
      this.$v.$touch();
      this.serverErrors = {};
      let formData = new FormData();
      if (!this.hugeData) {
        formData.append('file', this.file);
      }
      formData.append('name', this.name);
      formData.append('comment', this.comment);
      formData.append('huge_data', this.hugeData);
      formData.append('file_name', this.file.name);
      formData.append('external_data', this.external_data_value);
      if (!this.$v.$invalid) {
        this.load = true;
        this.$fetch
          .post('/api/sources', formData)
          .then(() => {
            this.$notify.success({ message: this.$t('notifications.source_uploaded') });
            this.$router.push('/sources');
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
          });
      }
    },
    update() {
      this.$v.$touch();
      this.serverErrors = {};
      let formData = new FormData();
      if (!this.hugeData) {
        formData.append('file', this.file);
      }
      formData.append('name', this.name);
      formData.append('comment', this.comment);
      formData.append('huge_data', this.hugeData);
      formData.append('file_name', this.file.name);
      formData.append('external_data', this.external_data_value);
      if (!this.$v.$invalid) {
        this.load = true;
        this.$fetch
          .put(`/api/sources/${this.id}`, formData)
          .then(() => {
            this.$notify.success({ message: this.$t('notifications.source_updated') });
            this.$router.push('/sources');
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
          });
      }
    },
    updateName() {
      this.$v.name.$touch();
      this.$v.comment.$touch();
      this.serverErrors = {};
      let formData = new FormData();
      formData.append('name', this.name);
      formData.append('comment', this.comment);
      formData.append('huge_data', this.hugeData);
      if (!this.$v.name.$invalid && !this.$v.comment.$invalid) {
        this.load = true;
        this.$fetch
          .patch(`/api/sources/${this.id}`, formData)
          .then(() => {
            this.$notify.success({ message: this.$t('notifications.source_updated') });
            this.$router.push('/sources');
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
          });
      }
    },
    deleteSource() {
      this.serverErrors = {};
      this.load_delete = true;
      this.$fetch
        .delete(`/api/sources/${this.id}`)
        .then(() => {
          this.$notify.success({ message: this.$t('notifications.source_deleted') });
          this.$router.push('/sources');
        })
        .catch(error => {
          this.load_delete = false;
          this.serverErrors = error;
        });
    },
    download() {
      this.load_download = true;
      this.$fetch
        .download(`/api/source/download/${this.id}`)
        .then(data => {
          const url = URL.createObjectURL(
            new Blob([data], {
              type: 'application/vnd.ms-excel'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'source.xlsx');
          document.body.appendChild(link);
          link.click();
          this.load_download = false;
        })
        .catch(() => {
          this.load_download = false;
        });
    },
    externalData() {
      if (this.external_data_value === 'weather') {
        this.name = this.$t('source.detail.weather');
        this.$set(this, 'columns', [
          { name: 'Город', type: 'category' },
          { name: 'Широта', type: 'number' },
          { name: 'Долгота', type: 'number' },
          { name: 'Дата', type: 'date_days' },
          { name: 'Температура', type: 'number' },
          { name: 'Влажность', type: 'number' },
          { name: 'Давление', type: 'number' },
          { name: 'Сила ветра', type: 'number' }
        ]);
        this.file = {};
        this.$set(this, 'values', [
          ['Москва', 55.755773, 37.617761, '12.08.2020', 17, 70, 745, 4.1],
          ['Москва', 55.755773, 37.617761, '13.08.2020', 16, 72, 744, 4.4],
          ['Москва', 55.755773, 37.617761, '14.08.2020', 15, 90, 740, 3.8],
          ['Москва', 55.755773, 37.617761, '15.08.2020', 19, 85, 750, 3.9],
          ['Москва', 55.755773, 37.617761, '16.08.2020', 20, 65, 749, 3.5],
          ['Москва', 55.755773, 37.617761, '17.08.2020', 21, 73, 743, 4.5],
          ['Новосибирск', 55.028739, 82.906928, '12.08.2020', 21, 80, 738, 2.7],
          ['Новосибирск', 55.028739, 82.906928, '12.08.2020', 19, 82, 740, 3.2],
          ['Новосибирск', 55.028739, 82.906928, '12.08.2020', 22, 85, 744, 2.5],
          ['Новосибирск', 55.028739, 82.906928, '12.08.2020', 20, 90, 735, 3.6],
          ['Новосибирск', 55.028739, 82.906928, '12.08.2020', 19, 75, 750, 3.9],
          ['Новосибирск', 55.028739, 82.906928, '12.08.2020', 18, 78, 748, 4.2]
        ]);
      } else if (this.external_data_value === 'exchange_rate') {
        this.name = this.$t('source.detail.exchange_rate');
        this.$set(this, 'columns', [
          { name: 'Дата', type: 'date_days' },
          { name: 'Валюта', type: 'category' },
          { name: 'Курс', type: 'number' }
        ]);
        this.file = {};
        this.$set(this, 'values', [
          ['01.08.2020', 'USD', 73.4261],
          ['02.08.2020', 'USD', 73.4261],
          ['03.08.2020', 'USD', 73.4261],
          ['04.08.2020', 'USD', 74.1586],
          ['05.08.2020', 'USD', 73.4261],
          ['06.08.2020', 'USD', 73.4261],
          ['07.08.2020', 'USD', 73.0397],
          ['08.08.2020', 'USD', 73.6376],
          ['01.08.2020', 'EUR', 87.2889],
          ['02.08.2020', 'EUR', 87.2889],
          ['03.08.2020', 'EUR', 87.2889],
          ['04.08.2020', 'EUR', 87.2253],
          ['05.08.2020', 'EUR', 86.5011],
          ['06.08.2020', 'EUR', 86.625],
          ['07.08.2020', 'EUR', 86.6178],
          ['08.08.2020', 'EUR', 87.1722]
        ]);
      } else if (this.external_data_value === 's3') {
        this.name = this.$t('source.detail.s3');
        this.$set(this, 'columns', []);
        this.file = undefined;
        this.$set(this, 'values', []);
      } else if (this.external_data_value === 'mixed') {
        // this.name = this.$t('source.detail.s3');
        this.$set(this, 'columns', []);
        this.file = undefined;
        this.$set(this, 'values', []);
        this.getSources();
      } else {
        this.name = this.$t('source.detail.new');
        this.$set(this, 'columns', []);
        this.file = undefined;
        this.$set(this, 'values', []);
      }
    },
    setComment(value) {
      this.comment = value;
    },
    setSettings(value) {
      this.$set(this, 's3', value);
    },
    previewS3() {
      this.serverErrors = {};
      this.load = true;
      this.$fetch
        .post('/api/source_s3/update', { s3: this.s3 })
        .then(data => {
          this.load = false;
          this.columns = data.columns;
          this.values = data.values;
          this.errors = data.errors;
          this.rows = data.rows;
          this.name = data.file_name === '' ? this.$t('source.detail.new') : data.file_name;
        })
        .catch(error => {
          this.load = false;
          this.serverErrors = error;
        });
    },
    saveS3() {
      this.load_update = true;
      this.serverErrors = {};
      this.$fetch
        .post('/api/source_s3/save/0', { s3: this.s3, name: this.name, comment: this.comment })
        .then(() => {
          this.load_update = false;
          this.$notify.success({ message: this.$t('notifications.source_uploaded') });
          this.$router.push('/sources');
        })
        .catch(error => {
          this.load_update = false;
          this.serverErrors = error;
        });
    },
    updateS3() {
      this.load_update = true;
      this.serverErrors = {};
      this.$fetch
        .put(`/api/source_s3/save/${this.id}`, { s3: this.s3, name: this.name, comment: this.comment })
        .then(() => {
          this.$notify.success({ message: this.$t('notifications.source_updated') });
          this.$router.push('/sources');
        })
        .catch(error => {
          this.load = false;
          this.serverErrors = error;
        });
    },
    addHandling(data) {
      let options = {};
      if (data === 'add_source') {
        options = { source_id: null };
      } else if (data === 'preprocessing') {
        options = { preprocess: '', values: [] };
      } else if (data === 'merge') {
        options = { merge_type: '', values: [] };
      }
      let order = this.stages.length;
      this.stages.push({ name: this.$t('source.detail.new_process'), order, type: data, fixed: false, columns: [], values: [], desc: {}, options });
      this.showAddSourceHandling = false;
      this.calculateResults();
      this.active_order = order;
    },
    getSources() {
      this.$fetch.get('/api/sources').then(data => {
        let sources = [];
        data.forEach(source => {
          sources.push({ text: source.name, value: source.id });
        });
        this.sources = sources;
      });
    },
    chooseProcess(order) {
      this.active_order = order;
      this.showResults = false;
    },
    updatePreprocess() {
      this.stages[this.activeOrderIndex].options.values = [];
    },
    addCondition() {
      if (this.stages[this.activeOrderIndex].type === 'preprocessing') {
        if (this.stages[this.activeOrderIndex].options.preprocess === 'remove_column') {
          this.stages[this.activeOrderIndex].options.values.push({ name: '' });
        } else if (this.stages[this.activeOrderIndex].options.preprocess === 'rename_column') {
          this.stages[this.activeOrderIndex].options.values.push({ name: '', value: '' });
        } else if (this.stages[this.activeOrderIndex].options.preprocess === 'replace_nan') {
          this.stages[this.activeOrderIndex].options.values.push({ name: '', value: '', custom: '' });
        } else if (this.stages[this.activeOrderIndex].options.preprocess === 'replace_empty') {
          this.stages[this.activeOrderIndex].options.values.push({ name: '', value: '', custom: '' });
        } else if (this.stages[this.activeOrderIndex].options.preprocess === 'replace_zeros') {
          this.stages[this.activeOrderIndex].options.values.push({ name: '', value: '', custom: '' });
        } else if (this.stages[this.activeOrderIndex].options.preprocess === 'change_type') {
          this.stages[this.activeOrderIndex].options.values.push({ name: '', value: '' });
        }
      } else if (this.stages[this.activeOrderIndex].type === 'merge') {
        if (this.stages[this.activeOrderIndex].options.merge_type === 'left_join') {
          this.stages[this.activeOrderIndex].options.values.push({ source1: '', source2: '', merge_key: '' });
        }
      }
    },
    deleteCondition(key) {
      this.stages[this.activeOrderIndex].options.values.splice(key, 1);
      this.calculateResults();
    },
    calculateResults() {
      this.$fetch.post('/api/source/result/preview', { stages: this.stages }).then(data => {
        data.forEach((stage, index) => {
          this.stages[index].values = stage.values;
          this.stages[index].columns = stage.columns;
          this.stages[index].desc = stage.desc;
        });
      });
    },
    checkMove(e) {
      const { index, futureIndex } = e.draggedContext;
      return !(this.stages[index].fixed || this.stages[futureIndex].fixed);
    },
    chooseItem(event, id) {
      this.$refs.vueSimpleContextMenu.showMenu(event, id);
    },
    chooseOption(event) {
      let index = this.stages.findIndex(x => x.order === event.item);
      if (index !== 0) {
        if (this.active_order === event.item) {
          this.active_order = 0;
        }
        this.stages.splice(index, 1);
        this.calculateResults();
      }
    },
    saveMixed() {
      this.$v.$touch();
      if (!this.$v.name.$invalid && !this.$v.comment.$invalid) {
        this.load = true;
        this.serverErrors = {};
        this.$fetch
          .post('/api/source_mixed/save/0', { stages: this.stages, name: this.name, comment: this.comment })
          .then(() => {
            this.load = false;
            this.$notify.success({ message: this.$t('notifications.source_uploaded') });
            this.$router.push('/sources');
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
          });
      }
    },
    updateMixed() {
      this.$v.$touch();
      if (!this.$v.name.$invalid && !this.$v.comment.$invalid) {
        this.load = true;
        this.serverErrors = {};
        this.$fetch
          .put(`/api/source_mixed/save/${this.id}`, { stages: this.stages, name: this.name, comment: this.comment })
          .then(() => {
            this.load = false;
            this.$notify.success({ message: this.$t('notifications.source_updated') });
            this.$router.push('/sources');
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
          });
      }
    },
    findDuplicates(arr) {
      let sortedArr = arr.slice().sort();
      let results = [];
      for (let i = 0; i < sortedArr.length - 1; i++) {
        if (sortedArr[i + 1] === sortedArr[i]) {
          results.push(sortedArr[i]);
        }
      }
      return results;
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  list-style-type: none;
}

.list-group-item {
  cursor: move;
}

.order-class {
  font-size: 0.875rem;
  height: 36px;
  line-height: 36px;
  border: thin solid transparent;
  text-align: center;
}
</style>
