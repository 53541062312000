<template>
  <v-main>
    <v-container fluid id="home">
      <v-row v-if="!$route.path.includes('anaplan')" class="mt-2">
        <v-col cols="6" class="pa-0">
          <v-row justify="start">
            <v-btn icon color="primary" @click="$router.push('/stories')">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <h2>{{ name }}</h2>
          </v-row>
        </v-col>
        <v-col cols="6" class="pa-0">
          <v-row justify="end" align="center">
            <v-icon v-if="ready" size="40" color="green">mdi-check-circle</v-icon>
            <v-icon v-else size="40" color="red">mdi-alert</v-icon>
            <span :class="ready ? 'green--text' : 'warning--text'">{{ ready ? $t('story.forecast.trained') : $t('story.forecast.not_trained') }}</span>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="success_load && $route.path.includes('anaplan')" class="mt-2">
        <h2 class="pr-2 success--text">{{ $t('integration.import_success') }}</h2>
      </v-row>
      <v-row v-if="!success_load && $route.path.includes('anaplan')" class="mt-2">
        <v-col cols="6" class="pa-0">
          <v-row justify="start" align="center">
            <img height="30" class="pr-4" src="../../img/anaplan_logo.svg" />
            <h2 class="pr-2">{{ $t('integration.import') }}</h2>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-information</v-icon>
              </template>
              <v-card-text>
                <p class="text-left mb-0">Anaplan Workspace ID: {{ workspaceID }}</p>
                <p class="text-left mb-0">Anaplan Model ID: {{ modelID }}</p>
                <p class="text-left mb-0">Anaplan Import Name: {{ importName }}</p>
                <p class="text-left mb-0">Anaplan Export Name: {{ exportName }}</p>
                <p class="text-left mb-0">{{ $t('integration.token') }} Brain2Logic: {{ token }}</p>
              </v-card-text>
            </v-tooltip>
          </v-row>
        </v-col>
        <v-col cols="6" class="pa-0">
          <v-row justify="end" align="center">
            <v-icon v-if="status == 'check'" size="50" color="green">mdi-check-circle</v-icon>
            <v-icon v-else-if="status == 'error'" size="50" color="red">mdi-alert</v-icon>
            <v-progress-circular v-else-if="status == 'progress'" :size="50" :width="7" indeterminate color="warning"></v-progress-circular>
            <span :class="{ progress: 'warning--text', check: 'green--text', error: 'red--text' }[status]">{{ $t(`integration.${status}`) + (status == 'error' ? getStatusError : '') }} </span>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="start" class="pt-3" v-if="!success_load">
        <v-btn color="primary" class="ml-2" v-if="forecastType === 'time_series'" outlined @click.stop="drawer = !drawer">{{ $t('buttons.settings') }}</v-btn>
        <v-btn color="primary" v-if="!$route.path.includes('anaplan')" class="ml-2" outlined :loading="load_update" @click="update">{{ $t('buttons.retrain') }}</v-btn>
        <v-btn color="primary" v-if="$route.path.includes('anaplan')" class="ml-2" outlined :loading="load2" @click="importData">{{ $t('buttons.import') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="forecastType === 'time_series' && !hugeData" outlined :loading="load" @click="forecast()">{{ $t('buttons.forecast') }}</v-btn>
        <v-btn color="primary" v-if="hugeData" class="ml-2" outlined :loading="load2" @click="importData">{{ $t('buttons.import') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="['regression', 'classification', 'clustering'].includes(forecastType)" outlined :loading="load" @click="download('all')">{{ $t('buttons.forecast') }}</v-btn>
        <v-menu bottom origin="center center" transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" outlined class="ml-2" icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link v-if="!$route.path.includes('anaplan')">
              <v-list-item-title style="font-size: 14px" class="text-uppercase" @click="$router.push(`/story/train/${id}`)">{{ $t('buttons.change') }}</v-list-item-title>
            </v-list-item>
            <v-list-item link v-if="id !== 0 && forecastType === 'time_series'">
              <v-list-item-title style="font-size: 14px" class="text-uppercase" @click="download('one')">{{ $t('buttons.download') }}</v-list-item-title>
            </v-list-item>
            <v-list-item link v-if="id !== 0 && forecastType === 'time_series'">
              <v-list-item-title style="font-size: 14px" class="text-uppercase" @click="download('all')">{{ $t('buttons.download_all') }}</v-list-item-title>
            </v-list-item>
            <v-list-item link v-if="id !== 0 && ['regression', 'classification', 'clustering'].includes(forecastType)">
              <v-list-item-title style="font-size: 14px" class="text-uppercase" @click="download('all', true)">{{ $t('buttons.download') }}</v-list-item-title>
            </v-list-item>
            <v-list-item link v-if="!$route.path.includes('anaplan')">
              <v-list-item-title style="font-size: 14px" class="text-uppercase" @click="deleteStory">{{ $t('buttons.delete') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
      <v-row v-if="!success_load" class="mt-5">
        <v-col cols="8" class="pt-0">
          <!-- <line-chart v-if="!checkEmptyObject(chartData) && forecastType == 'time_series' && !name.includes('погод')" ref="chart" :chart-data="chartData" :dates-type="date" :size="{ width: chart_width, height: windowHeight - (64 + 16) - 36 - 50 }"></line-chart> -->
          <line-chart v-if="!checkEmptyObject(chartData) && !feature_importance.active && forecastType == 'time_series'" ref="chart" :chart-data="chartData" :dates-type="date" :features="features.list.length > 0" :size="{ width: chart_width, height: windowHeight - (64 + 16) - 36 - 50 }"></line-chart>
          <!-- <line-chart-two-y v-if="!checkEmptyObject(chartData) && forecastType == 'time_series' && name.includes('погод')" ref="chart" :chart-data="chartData" :dates-type="date" :size="{ width: chart_width, height: windowHeight - (64 + 16) - 36 - 50 }"></line-chart-two-y> -->
          <scatter-chart v-if="!checkEmptyObject(chartData) && !feature_importance.active && ['clustering', 'regression', 'classification'].includes(forecastType)" ref="chart" :chart-data="chartData" :chart-labels="chartLabels" :unique-axis="uniqueAxis" :forecast-type="forecastType" :size="{ width: chart_width, height: windowHeight - (64 + 16) - 36 - 50 }"></scatter-chart>
          <bar-chart v-if="feature_importance.active && forecastType === 'classification'" ref="chart" :labels="feature_importance.columns" :scores="feature_importance.value in feature_importance.scores ? feature_importance.scores[feature_importance.value] : []" :size="{ width: chart_width,height: windowHeight - (64 + 16) - 36 - 50 }"></bar-chart>
        </v-col>
        <v-col cols="4" class="pt-0" :style="`overflow-y: auto;height: ${windowHeight - (64 + 16) - 36 - 50}`">
          <v-text-field class="mt-2" v-if="forecastType === 'time_series' && featureExistense && !hugeData" :label="$t('story.forecast.steps') + stepType" outlined dense :error-messages="stepsMessage" v-model="steps" type="text" />
          <v-simple-table v-if="['time_series', 'regression', 'classification'].includes(forecastType) && !checkEmptyObject(errors) && !hugeData" fixed-header width="500px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left table-header">{{ $t('story.forecast.model') }}</th>
                  <th class="text-left table-header">
                    <v-tooltip top :max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-select hide-details :items="accuracy_score_list" v-model="cleaning.accuracy_score.value" dense label=""></v-select>
                        </span>
                      </template>
                      <span>{{ $t(`story.forecast.${cleaning.accuracy_score.value}.desc`) }}</span>
                    </v-tooltip>
                  </th>
                </tr>
              </thead>
              <tbody v-if="['time_series', 'regression', 'classification'].includes(forecastType)">
                <tr v-for="(value, key) in sorted_errors" :key="key" :class="{cursor: true }">
                  <td class="text-left" @click="models.value = key; models.params = best_configs[index][key];models.cleaning = cleaning;forecastType === 'time_series' ? forecast(false) : prepareChartData(true);">
                    <v-tooltip bottom :max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ $t(`story.detail.${key}.short`) }}</span>
                      </template>
                      <span>{{ $t('story.detail.optimal_config') + best_config[key] }}</span>
                    </v-tooltip>
                  </td>
                  <td v-if="cleaning.accuracy_score.value === 'confusion_matrix'">
                    {{ `True Positive - ${value[1][1]}, False Positive - ${value[0][1]}, False Negative - ${value[1][0]}, True Negative - ${value[0][0]}`}}
                  </td>
                  <td v-else-if="forecastType === 'classification' && cleaning.accuracy_score.value !== 'cv'">
                    <!-- <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ formatPercent(value * 100) }}</span>
                      </template>
                      <span>{{ formatPercentDetail(value * 100) }}</span>
                    </v-tooltip> -->
                    {{ formatPercentDetail(value * 100) }}
                  </td>
                  <td v-else-if="forecastType === 'classification' && cleaning.accuracy_score.value === 'cv'">
                    {{ formatPercentDetail(value * 100) + ' (+- ' + formatPercentDetail(errors[index][key]['cv_std']*100) + ')' }}
                  </td>
                  <td v-else>{{ ['mape'].includes(cleaning.accuracy_score.value) ? formatPercent(value) : formatNumber(value) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table v-if="['clustering'].includes(forecastType)">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left table-header">{{ $t('story.forecast.model') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, key) in models.list" :key="key" :class="{ cursor: true }">
                  <td class="text-left" @click="models.value = value.value; models.params = {};models.cleaning = cleaning; prepareChartData(true);">
                    <v-tooltip bottom :max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <span v-bind="attrs" v-on="on">{{ model_types[value.value] }}</span> -->
                        <span v-bind="attrs" v-on="on">{{ $t(`story.detail.${value.value}.short`) }}</span>
                      </template>
                      <span>{{ $t('story.detail.optimal_config') + best_config[value.value] }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-file-input class="mt-4" v-if="(forecastType === 'time_series' && !featureExistense) || ['regression', 'clustering', 'classification'].includes(forecastType)" color="primary" outlined dense v-model="file" :error-messages="fileMessage" :label="$t('story.forecast.upload')" :show-size="file !== undefined"></v-file-input>
          <v-select v-for="(category, key) in categories" :key="key" :class="key == 0 ? 'mt-4' : ''" :items="category.list" v-model="category.value" :placeholder="$t('story.forecast.choose')" @change="getHistory" outlined dense :label="category.name"></v-select>
          <v-select v-if="forecastType === 'time_series' && features.list.length > 0" hide-details :items="features.list" v-model="features.value" @change="getHistory" outlined dense :label="$t('story.detail.features')"></v-select>
          <v-select class="pt-5" v-if="forecastType === 'regression' && columnsFeaturesRange.length > 0 && columnsFeatures.length >= 1" hide-details :items="columnsFeaturesRange" v-model="axisX.name" @change="columnFeatureUpdate('x')" outlined dense :label="$t('story.detail.axisX')"></v-select>
          <v-select v-if="forecastType === 'clustering' && columnsFeaturesRange.length > 0 && columnsFeatures.length > 1" hide-details :items="columnsFeaturesRange" v-model="axisX.name" @change="columnFeatureUpdate('x')" outlined dense :label="$t('story.detail.axisX')"></v-select>
          <v-select v-if="forecastType === 'classification' && columnsFeaturesRange.length > 0 && columnsFeatures.length > 1" hide-details :items="columnsFeaturesRange" v-model="axisX.name" @change="columnFeatureUpdate('x')" outlined dense :label="$t('story.detail.axisX')"></v-select>
          <v-select class="pt-3" v-if="['clustering', 'classification'].includes(forecastType) && columnsFeaturesRange.length > 0 && columnsFeatures.length > 1" hide-details :items="columnsFeaturesRange" v-model="axisY.name" @change="columnFeatureUpdate('y')" outlined dense :label="$t('story.detail.axisY')"></v-select>
          <v-switch hide-details v-if="forecastType === 'classification'" v-model="probability" :label="$t('story.forecast.probability')"></v-switch>
          <v-switch hide-details v-if="forecastType == 'classification'" v-model="probability_threshold.active" :label="$t('story.detail.probability_threshold')" @change="getProbability"></v-switch>
          <v-slider class="pt-8" v-if="forecastType == 'classification' && probability_threshold.active" hide-details v-model="probability_threshold.value" :label="$t('story.detail.probability_threshold_value')" @change="getProbability" min="0.01" max="0.95" step="0.01" thumb-color="green lighten-1" thumb-label="always"></v-slider>
          <v-switch hide-details v-if="!hugeData" v-model="feature_importance.active" @click="updateFeatureImportance" :label="$t('story.detail.feature_importance')"></v-switch>
          <v-select class="pt-3" v-if="feature_importance.active" hide-details @change="updateFeatureImportance" :items="feature_importance.list" v-model="feature_importance.value" outlined dense :label="$t('story.detail.feature_algorithm')"></v-select>
        </v-col>
      </v-row>
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <settings-game :settings="settings" :cleaning="cleaning" :forecast-type="forecastType" @update="gameForecast"></settings-game>
      </v-navigation-drawer>
    </v-container>
  </v-main>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';
import { required, integer } from 'vuelidate/lib/validators';
import SettingsGame from '@/views/story/SettingsGame.vue';
import LineChart from '@/components/LineChart.vue';
import BarChart from '@/components/BarChart.vue';
// import LineChartTwoY from '@/components/LineChartTwoY.vue';
import ScatterChart from '@/components/ScatterChart.vue';

const notUndefined = value => value !== undefined;
const rightFormat = value => ['xls', 'xlsx', 'xlsm', 'xlsb', 'odf', 'csv', 'json'].includes(value !== undefined ? value.name.split('.').pop() : '');

export default {
  name: 'StoryForecast',
  mixins: [baseMixin],
  // components: { SettingsGame, LineChart, ScatterChart, LineChartTwoY },
  components: { SettingsGame, LineChart, ScatterChart, BarChart },
  data() {
    return {
      id: 0,
      steps: 1,
      columns: [],
      windowHeight: 0,
      chart_width: 0,
      status: 'success',
      token: '',
      workspaceID: '',
      modelID: '',
      importName: '',
      exportName: '',
      name: '',
      ready: false,
      index: 0,
      serverErrors: {},
      chartData: {},
      categories: [],
      chartLabels: [],
      series: {
        history: {
          dates: [],
          values: [],
          values2: [],
          labels: [],
          features: []
        },
        clusters: {},
        testForecast: {},
        forecasts: {
          dates: [],
          values: []
        }
      },
      features: {
        list: [],
        value: ''
      },
      models: {
        list: [],
        value: '',
        params: {},
        cleaning: {}
      },
      errors: [],
      load: false,
      load2: false,
      success_load: false,
      load_update: false,
      load_download: false,
      load_download_all: false,
      history_included: false,
      date: '',
      best_configs: [],
      forecastType: '',
      clusters: 0,
      file: undefined,
      file_configs: undefined,
      accuracy_score_list: [
        { text: this.$t('story.forecast.rmse.name'), value: 'rmse' },
        { text: this.$t('story.forecast.mse.name'), value: 'mse' },
        { text: this.$t('story.forecast.mape.name'), value: 'mape' },
        { text: this.$t('story.forecast.mae.name'), value: 'mae' },
        { text: this.$t('story.forecast.bias.name'), value: 'bias' }
      ],
      axisX: {
        name: '',
        type: '',
      },
      axisY: {
        name: '',
        type: '',
      },
      uniqueAxis: [],
      drawer: false,
      settings: {
        classification: {},
        clustering: {},
        regression: {},
        time_series: {}
      },
      cleaning: {},
      probability: false,
      probability_threshold: {
        active: false,
        value: 0.7,
      },
      feature_importance: {
        active: false,
        list: [],
        value: '',
        scores: [],
        columns: []
      },
      hugeData: false
    };
  },
  validations: {
    file: {
      notUndefined,
      rightFormat
    },
    steps: {
      required,
      integer
    }
  },
  computed: {
    getStatusError() {
      let error;
      if (Object.prototype.hasOwnProperty.call(this.serverErrors, 'detail')) {
        error = 'Brain2Logic - ' + this.serverErrors.detail;
      } else if (Object.prototype.hasOwnProperty.call(this.serverErrors, 'anaplan')) {
        error = this.serverErrors.anaplan;
      } else if (Object.prototype.hasOwnProperty.call(this.serverErrors, 'name')) {
        error = 'Brain2Logic - ' + this.$t('validations.anaplan_name');
      }
      return error;
    },
    fileMessage() {
      if (this.$v.file.$error || this.getError('file') !== '') {
        if (this.history_included) {
          return [];
        } else if (!this.$v.file.notUndefined) {
          return [this.$t('validations.empty_file')];
        } else if (!this.$v.file.rightFormat) {
          return [this.$t('validations.wrong_file')];
        } else if (!this.$v.file.$error && this.getError('file') !== '') {
          return [this.getError('file')];
        }
      }
      return [];
    },
    sorted_errors() {
      let errors = {};
      let sortable = [];
      for (let key in this.errors[this.index]) {
        sortable.push([key, this.errors[this.index][key][this.cleaning.accuracy_score.value]]);
      }
      if (this.sorted_order === 'asc') {
        sortable.sort((a, b) => a[1] - b[1]);
      } else {
        sortable.sort((a, b) => b[1] - a[1]);
      }
      sortable.forEach(value => {
        errors[value[0]] = value[1];
      });
      return errors;
    },
    sorted_order() {
      if (['time_series', 'regression', 'clustering'].includes(this.forecastType)) {
        return 'asc';
      } else if (this.forecastType === 'classification') {
        return 'desc';
      }
      return 'asc';
    },
    best_config() {
      let configs = this.best_configs[this.index];
      let newConfig = {};
      for (let model in configs) {
        newConfig[model] = '';
        for (let param in configs[model]) {
          newConfig[model] = newConfig[model] + (newConfig[model] === '' ? '' : ', ') + this.$t(`story.detail.${model}.params.${param}[0]`) + ' ' + configs[model][param];
        }
      }
      return newConfig;
    },
    stepsMessage() {
      if (this.$v.steps.$error || this.getError('steps') !== '') {
        if (!this.$v.steps.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.steps.integer) {
          return [this.$t('validations.number')];
        } else if (!this.$v.steps.$error && this.getError('steps') !== '') {
          return [this.getError('steps')];
        }
      }
      return [];
    },
    stepType() {
      if (this.columns.length !== 0) {
        let dateType = this.columns.filter(o => o.type.includes('date'))[0]['type'];
        return this.$t(`story.forecast.step_period.${dateType}`);
      }
      return '';
    },
    featureExistense() {
      if (this.columns.length !== 0) {
        let features = this.columns.filter(o => ['numerical', 'binary', 'ordinal', 'nominal'].includes(o.type));
        if (features.length === 0) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    columnsFeatures() {
      return this.columns.filter(o => ['numerical', 'binary', 'ordinal', 'nominal'].includes(o.type));
    },
    columnsFeaturesRange() {
      return this.columns
        .filter(o => ['numerical', 'binary', 'ordinal', 'nominal'].includes(o.type))
        .map(o => {
          return { text: o.name, value: o.name };
        });
    },
    columnForecast() {
      return this.columns.filter(o => o.type === 'forecast')[0]['name'];
    },
  },
  mounted() {
    // window.addEventListener('resize', this.$lodash.debounce(this.getWindowHeight, 500));
    window.addEventListener('resize', this.getWindowHeight);
    this.getWindowHeight();
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.$lodash.debounce(this.getWindowHeight, 500));
    window.removeEventListener('resize', this.getWindowHeight);
  },
  created() {
    if (!this.$route.path.includes('anaplan')) {
      this.id = this.$route.params.id * 1;
    } else {
      this.id = false;
      this.token = this.$route.params.token;
      this.workspaceID = this.$route.params.workspaceID;
      this.modelID = this.$route.params.modelID;
      this.importName = this.$route.params.importName;
      this.exportName = this.$route.params.exportName;
      this.$fetch.setToken(this.token);
      this.status = 'progress';
    }

    this.$fetch[!this.$route.path.includes('anaplan') ? 'get' : 'post'](!this.$route.path.includes('anaplan') ? `/api/stories/${this.id}` : '/api/anaplan/check_forecast', {
      workspaceID: this.workspaceID,
      modelID: this.modelID,
      importName: this.importName,
      exportName: this.exportName
    })
      .then(data => {
        if ('hugeData' in data.data) {
          this.hugeData = data.data.hugeData;
        } else {
          this.hugeData = false;
        }
        if (this.$route.path.includes('anaplan')) {
          this.id = data.id;
          this.status = 'check';
        }
        ({ name: this.name, ready: this.ready } = data);
        this.forecastType = data.data.forecastType;
        if (this.forecastType === 'time_series') {
          ({ errors: this.errors, cleaning: this.cleaning } = data.data);
          let key = 0;
          let categories = [];
          if (!this.hugeData) {
            data.data.columns.forEach(column => {
              if (column.type === 'category') {
                let list = [];
                data.data.categories_values.forEach(value => {
                  list.push({ text: value[key], value: value[key] });
                });
                list = this.$lodash.uniqBy(list, 'value');
                categories.push({ name: column.name, list, value: list[0].value });
                key++;
              } else if (['date_hours', 'date_days', 'date_weeks', 'date_months', 'date_quarters', 'date_years'].includes(column.type)) {
                this.date = column.type;
              }
            });
          }
          this.categories = categories;
          this.series.testForecast = data.data.forecasts;
          this.best_configs = data.data.best_config;
          if (!this.hugeData) {
            this.models.value = Object.keys(this.sorted_errors)[0];
            this.models.params = data.data.best_config[0][this.models.value];
          }
          // this.models.value = Object.keys(this.sorted_errors)[0];
          // this.models.params = data.data.best_config[0][this.models.value];
          this.models.cleaning = data.data.cleaning;
        } else if (this.forecastType === 'clustering') {
          this.clusters = data.data.clusters;
        } else if (this.forecastType === 'regression') {
          ({ errors: this.errors, cleaning: this.cleaning } = data.data);
          this.series.testForecast = data.data.forecasts;
          this.best_configs = data.data.best_config;
          this.models.value = Object.keys(this.sorted_errors)[0];
          this.models.cleaning = data.data.cleaning;
          if (this.cleaning.cross_validation.active) {
            this.accuracy_score_list.push({ text: 'CV', value: 'cv' });
          }
        } else if (this.forecastType === 'classification') {
          ({ errors: this.errors, cleaning: this.cleaning } = data.data);
          this.series.testForecast = data.data.forecasts;
          this.best_configs = data.data.best_config;
          this.models.value = Object.keys(this.sorted_errors)[0];
          this.feature_importance.value = this.models.value;
          for (let model in data.data.errors[0]) {
            this.feature_importance.list.push({ text: this.$t(`story.detail.${model}.short`), value: model });
          }
          this.models.cleaning = data.data.cleaning;
          this.accuracy_score_list = [
            { text: this.$t('story.forecast.accuracy_score.name'), value: 'accuracy_score' },
            { text: this.$t('story.forecast.precision_score.name'), value: 'precision_score' },
            { text: this.$t('story.forecast.average_precision_score.name'), value: 'average_precision_score' },
            { text: this.$t('story.forecast.recall_score.name'), value: 'recall_score' },
            { text: this.$t('story.forecast.f1_score.name'), value: 'f1_score' },
            { text: this.$t('story.forecast.roc_auc_score.name'), value: 'roc_auc_score' },
            { text: this.$t('story.forecast.confusion_matrix.name'), value: 'confusion_matrix' }
          ];
          // this.cleaning.accuracy_score = { value: 'correct_class' };
          if (this.cleaning.cross_validation.active) {
            this.accuracy_score_list.push({ text: 'CV ' + this.$t(`story.forecast.${this.cleaning.accuracy_score.value}.name`), value: 'cv' });
            // this.accuracy_score_list.push({ text: 'CV md', value: 'cv_mean_deviation' });
            // this.accuracy_score_list.push({ text: 'CV std', value: 'cv_std' });
          }
        }
        this.columns = data.data.columns;

        if (this.forecastType === 'time_series') {
          this.columns.forEach(column => {
            if (['binary', 'ordinal', 'numerical'].includes(column.type)) {
              this.features.list.push(column.name);
              if (this.features.value === '') {
                this.features.value = column.name;
              }
            }
          });
        }

        if (this.forecastType === 'time_series') {
          let dateType = this.columns.filter(o => o.type.includes('date'))[0]['type'];
          this.steps = {
            date_hours: 24 * 7,
            date_days: 30,
            date_weeks: 52,
            date_months: 12,
            date_quarters: 4,
            date_years: 5
          }[dateType];
          // this.steps = 3;
        }

        if (this.forecastType === 'clustering' || this.forecastType === 'classification') {
          this.axisX = this.$lodash.cloneDeep(this.columnsFeatures[0]);
          this.axisY = this.$lodash.cloneDeep(this.columnsFeatures[1]);
        }

        if (this.forecastType === 'regression') {
          this.axisX = this.$lodash.cloneDeep(this.columnsFeatures[0]);
          this.axisY = { name: this.columnForecast, type: 'numerical' };
        }
        let forecasts = data.data.forecasts[0];
        for (let forecast in forecasts) {
          if (Object.prototype.hasOwnProperty.call(forecasts, forecast)) {
            if (this.forecastType === 'clustering' && this.models.value === '') {
              this.models.value = forecast;
            }
            this.models.list.push({ text: this.$t(`story.detail.${forecast}.short`), value: forecast });
          }
        }
        if (this.hugeData) {
          this.models.value = 'SES';
        }
        this.getHistory();
        this.setSettings();
      })
      .catch(error => {
        this.status = 'error';
        this.serverErrors = error;
      });
    // Демо
    // http://localhost/#/anaplan/import/ed7a0111d7a69c9cfa089c49d8917c78fc8353a0/8a81b09c603d346301614d2ed7985806/6AE36243ECFA474087DDF36EAE5AF682/Import%20Brain2Logic/Export%20Brain2Logic
    // https://brain2logic.com/#/anaplan/import/0c6055f7b8859b96d5f660c236a76692f44ce27e/8a81b09c603d346301614d2ed7985806/6AE36243ECFA474087DDF36EAE5AF682/Import%20Brain2Logic/Export%20Brain2Logic
    // Stada
    // http://localhost/#/anaplan/import/ed7a0111d7a69c9cfa089c49d8917c78fc8353a0/8a81b09c664b16f50166cbe95b3970e5/BD32C45B89BE40C0873CF8497BF65D95/B2L%20Import%20Forecast/B2L%20Export%20History
    // https://brain2logic.com/#/anaplan/import/0c6055f7b8859b96d5f660c236a76692f44ce27e/8a81b09c664b16f50166cbe95b3970e5/BD32C45B89BE40C0873CF8497BF65D95/B2L%20Import%20Forecast/B2L%20Export%20History
    // Promo
    // http://localhost/#/anaplan/import/ed7a0111d7a69c9cfa089c49d8917c78fc8353a0/8a81b09c603d346301614d2ed7985806/76A8D79D11464845AD5E3B2EC2CC8EF5/B2L%20Import/B2L%20History%20Export
    // https://brain2logic.com/#/anaplan/import/0c6055f7b8859b96d5f660c236a76692f44ce27e/8a81b09c603d346301614d2ed7985806/76A8D79D11464845AD5E3B2EC2CC8EF5/B2L%20Import/B2L%20History%20Export
  },
  methods: {
    setSettings() {
      this.$fetch.get('/api/stories/settings').then(data2 => {
        let settings = {};
        for (let model in this.best_configs[this.index]) {
          settings[model] = {};
          for (let parameter in data2.settings[this.forecastType][model]) {
            let array = [];
            data2.settings[this.forecastType][model][parameter].forEach(value => {
              if (!['adf', 'kpss', 'pp', 'ch'].includes(value)) {
                array.push({ text: value, value });
              }
            });
            settings[model][parameter] = {
              list: array,
              value: parameter in this.best_configs[this.index][model] ? this.best_configs[this.index][model][parameter] : data2.settings[this.forecastType][model][parameter][0]
            };
          }
        }
        this.settings = settings;
      });
    },
    getHistory() {
      let categories = [];
      this.categories.forEach(category => {
        categories.push({ name: category.name, value: category.value });
      });

      this.$fetch.post(`/api/chart_forecast/${this.id}`, { categories, forecastType: this.forecastType, hugeData: this.hugeData, axis: { x: this.axisX, y: this.axisY } }).then(data => {
        this.index = data.index;
        this.setSettings();
        this.series.history.dates = [];
        this.series.history.values = [];
        this.series.forecasts = { dates: [], values: [] };
        if (this.forecastType === 'time_series') {
          data.values.forEach(value => {
            this.series.history.dates.push(value[0]);
            this.series.history.values.push(value[1]);
          });

          this.features.list.forEach((value1, index) => {
            this.series.history.features.push([]);
            data.values.forEach(value2 => {
              this.series.history.features[index].push(value2[2 + index]);
            });
          });

          // if (this.name.includes('погод')) {
          //   data.values.forEach(value => {
          //     this.series.temperature.push(value[2]);
          //   });
          // }
          this.prepareChartData(true);
          if (this.columnsFeatures.length === 0) {
            this.forecast(false);
          }
        } else if (this.forecastType === 'clustering') {
          this.uniqueAxis = data.specific_data.uniqueAxis;
          data.values.forEach(value => {
            this.series.history.values.push({ x: value[0], y: value[1] });
          });
          this.series.clusters = data.specific_data.clusters;
          this.prepareChartData(true);
        } else if (this.forecastType === 'regression') {
          this.uniqueAxis = data.specific_data.uniqueAxis;
          data.values.forEach(value => {
            this.series.history.values.push({ x: value[0], y: value[1] });
          });
          this.prepareChartData(true);
        } else if (this.forecastType === 'classification') {
          this.uniqueAxis = data.specific_data.uniqueAxis;
          data.values.forEach(value => {
            this.series.history.values.push({ x: value[0], y: value[1] });
          });
          this.clusters = data.specific_data.clusters_unique;
          this.series.clusters = data.specific_data.clusters;
          this.prepareChartData(true);
        }
      });
    },
    columnFeatureUpdate(type) {
      if (type === 'x') {
        if (this.axisX.name === this.axisY.name) {
          this.$set(this, 'axisY', this.columnsFeatures[0].name !== this.axisX.name ? this.columnsFeatures[0] : this.columnsFeatures[1]);
        }
        this.axisX.type = this.columns.filter(o => o.name === this.axisX.name)[0].type;
      } else if (type === 'y') {
        if (this.axisX.name === this.axisY.name) {
          this.$set(this, 'axisX', this.columnsFeatures[0].name !== this.axisY.name ? this.columnsFeatures[0] : this.columnsFeatures[1]);
        }
        this.axisY.type = this.columns.filter(o => o.name === this.axisY.name)[0].type;
      }
      this.getHistory();
    },
    getProbability() {
      if (this.probability_threshold.active) {
        this.$fetch
          .post(`/api/probability_threshold/${this.id}`, { value: this.probability_threshold.value })
          .then(data => {
            this.errors = [data];
          })
          .catch(error => {
            this.serverErrors = error;
          });
      } else {
        this.$fetch[!this.$route.path.includes('anaplan') ? 'get' : 'post'](!this.$route.path.includes('anaplan') ? `/api/stories/${this.id}` : '/api/anaplan/check_forecast', {
          workspaceID: this.workspaceID,
          modelID: this.modelID,
          importName: this.importName,
          exportName: this.exportName
        })
          .then(data => {
            this.errors = data.data.errors;
          })
          .catch(error => {
            this.status = 'error';
            this.serverErrors = error;
          });
      }
    },
    prepareChartData(type = true) {
      if (this.forecastType === 'time_series') {
        let chartData = {};
        let dates = [];
        let emptyLength = 0;
        let emptyArray = [];
        let forecasts = {};
        if (type) {
          dates = this.series.history.dates.concat(this.series.forecasts.dates);
          emptyLength = this.series.forecasts.dates.length;
          for (let i = 0; i < emptyLength; i++) {
            emptyArray.push(null);
          }

          for (let forecast in this.series.testForecast[this.index]) {
            if (Object.prototype.hasOwnProperty.call(this.series.testForecast[this.index], forecast)) {
              forecasts[forecast] = this.series.testForecast[this.index][forecast];
              let emptyLength2 = this.series.history.values.length - forecasts[forecast].length;
              for (let i = 0; i < emptyLength2; i++) {
                forecasts[forecast].unshift(null);
              }
            }
          }
          chartData = {
            labels: dates,
            datasets: [
              {
                label: this.columns.filter(o => o.type === 'forecast')[0]['name'],
                backgroundColor: '#ff6361',
                borderColor: '#ff6361',
                fill: false,
                data: this.series.history.values.concat(emptyArray),
                borderWidth: ['date_hours', 'date_days'].includes(this.date) ? 2 : 3
              },
              {
                label: this.$t('story.forecast.forecast') + this.$t(`story.detail.${this.models.value}.short`),
                backgroundColor: '#01579B',
                borderColor: '#01579B',
                fill: false,
                data: forecasts[this.models.value].concat(this.series.forecasts.values),
                borderWidth: ['date_hours', 'date_days'].includes(this.date) ? 2 : 3
              }
            ]
          };
        } else {
          dates = this.series.history.dates.concat(this.series.forecasts.dates.slice(this.series.forecasts.dates.length - this.steps, this.series.forecasts.dates.length));
          emptyLength = this.series.forecasts.dates.slice(this.series.forecasts.dates.length - this.steps, this.series.forecasts.dates.length).length;
          for (let i = 0; i < emptyLength; i++) {
            emptyArray.push(null);
          }

          let emptyArray2 = [];
          let emptyLength2 = dates.length - this.series.forecasts.dates.length;
          for (let i = 0; i < emptyLength2; i++) {
            emptyArray2.push(null);
          }
          chartData = {
            labels: dates,
            datasets: [
              {
                label: this.columns.filter(o => o.type === 'forecast')[0]['name'],
                backgroundColor: '#ff6361',
                borderColor: '#ff6361',
                fill: false,
                data: this.series.history.values.concat(emptyArray),
                borderWidth: ['date_hours', 'date_days'].includes(this.date) ? 2 : 3
              },
              {
                label: this.$t('story.forecast.forecast') + this.$t(`story.detail.${this.models.value}.short`),
                backgroundColor: '#01579B',
                borderColor: '#01579B',
                fill: false,
                data: emptyArray2.concat(this.series.forecasts.values),
                borderWidth: ['date_hours', 'date_days'].includes(this.date) ? 2 : 3
              }
            ]
          };
        }

        // if (this.name.includes('погод')) {
        //   chartData.datasets[0]['yAxisID'] = 'A';
        //   chartData.datasets[1]['yAxisID'] = 'A';
        // }

        // if (this.name.includes('погод')) {
        //   chartData.datasets.push({
        //     label: 'Температура',
        //     backgroundColor: '#00CC00',
        //     borderColor: '#00CC00',
        //     yAxisID: 'B',
        //     fill: false,
        //     data: this.series.temperature.concat(this.series.forecasts.temperature),
        //     borderWidth: ['date_hours', 'date_days'].includes(this.date) ? 2 : 3
        //   });
        // }

        if (this.features.list.length > 0) {
          chartData.datasets.push({
            label: this.features.value === 'Температура' ? 'Temperature': this.features.value,
            backgroundColor: '#00CC00',
            borderColor: '#00CC00',
            yAxisID: 'B',
            fill: false,
            data: this.series.history.features[this.features.list.indexOf(this.features.value)],
            borderWidth: ['date_hours', 'date_days'].includes(this.date) ? 2 : 3
          });
          // chartData.datasets[0]['yAxisID'] = 'A';
          // chartData.datasets[1]['yAxisID'] = 'B';
        }

        // if (this.name.includes('погод')) {
        //   chartData.datasets[0]['yAxisID'] = 'A';
        //   chartData.datasets[1]['yAxisID'] = 'A';
        // }
        if (Object.keys(this.chartData).length === 0) {
          this.chartData = chartData;
        } else {
          this.chartData = chartData;
          this.$refs.chart.updateChart(chartData, this.chartLabels, this.uniqueAxis);
        }
      } else if (this.forecastType === 'clustering') {
        this.chartLabels = [this.axisX, this.axisY];
        let datasets = [];
        if (this.models.value !== 'DBSCAN') {
          for (let i = 1; i <= this.clusters; i++) {
            let list = [];
            this.series.history.values.forEach((value, key) => {
              if (this.series.clusters[this.index][this.models.value][key] + 1 === i) {
                list.push(value);
              }
            });
            datasets.push({
              label: this.$t('story.detail.group') + i,
              backgroundColor: this.clustersColors(i - 1),
              borderColor: this.clustersColors(i - 1),
              data: list
            });
          }
        } else {
          let unique = this.series.clusters[this.index][this.models.value].filter((v, i, a) => a.indexOf(v) === i);
          unique.sort();
          unique.forEach(cluster => {
            let list = [];
            this.series.history.values.forEach((value, key) => {
              if (this.series.clusters[this.index][this.models.value][key] === cluster) {
                list.push(value);
              }
            });
            datasets.push({
              label: cluster === -1 ? this.$t('story.detail.noise') : this.$t('story.detail.group') + cluster,
              backgroundColor: this.clustersColors(cluster + 1),
              borderColor: this.clustersColors(cluster + 1),
              data: list
            });
          });
          // for (let i = 1; i <= this.clusters; i++) {
          //   let list = [];
          //   this.series.history.values.forEach((value, key) => {
          //     if (this.series.clusters[this.index][this.models.value][key] + 1 === i) {
          //       list.push(value);
          //     }
          //   });
          //   datasets.push({
          //     label: this.$t('story.detail.group') + i,
          //     backgroundColor: this.clustersColors(i - 1),
          //     borderColor: this.clustersColors(i - 1),
          //     data: list
          //   });
          // }
        }
        // for (let i = 1; i <= this.clusters; i++) {
        //   let list = [];
        //   this.series.history.values.forEach((value, key) => {
        //     if (this.series.clusters[this.index][this.models.value][key] + 1 === i) {
        //       list.push(value);
        //     }
        //   });
        //   datasets.push({
        //     label: this.$t('story.detail.group') + i,
        //     backgroundColor: this.clustersColors(i - 1),
        //     borderColor: this.clustersColors(i - 1),
        //     data: list
        //   });
        // }

        if (Object.keys(this.chartData).length === 0) {
          this.chartData = { datasets };
        } else {
          this.chartData = { datasets };
          this.$refs.chart.updateChart({ datasets }, this.chartLabels, this.uniqueAxis);
        }
      } else if (this.forecastType === 'regression') {
        this.chartLabels = [this.axisX, this.axisY];
        let datasets = [];
        datasets.push({
          label: this.$t('story.forecast.actual'),
          backgroundColor: '#ff6361',
          borderColor: '#ff6361',
          data: this.series.history.values
        });

        let testForecast = [];
        this.series.testForecast[this.index][this.models.value].forEach((value, index) => {
          testForecast.push({ x: this.series.history.values[index].x, y: value });
        });

        datasets.push({
          type: 'line',
          fill: false,
          label: this.$t('story.forecast.forecast') + this.$t(`story.detail.${this.models.value}.short`),
          backgroundColor: '#01579B',
          borderColor: '#01579B',
          data: testForecast
        });

        if (Object.keys(this.chartData).length === 0) {
          this.chartData = { datasets };
        } else {
          this.chartData = { datasets };
          this.$refs.chart.updateChart({ datasets }, this.chartLabels, this.uniqueAxis);
        }
      } else if (this.forecastType === 'classification') {
        this.chartLabels = [this.axisX, this.axisY];
        let datasets = [];
        this.clusters.forEach((i, index) => {
          let list = [];
          this.series.history.values.forEach((value, key) => {
            if (this.series.clusters[this.index][this.models.value][key] === i) {
              list.push(value);
            }
          });
          datasets.push({
            label: i,
            backgroundColor: this.clustersColors(index),
            borderColor: this.clustersColors(index),
            data: list
          });
        });

        if (Object.keys(this.chartData).length === 0) {
          this.chartData = { datasets };
        } else {
          this.chartData = { datasets };
          this.$refs.chart.updateChart({ datasets }, this.chartLabels, this.uniqueAxis);
        }
      }
    },
    importData() {
      this.$v.$touch();
      this.serverErrors = {};
      if ((this.featureExistense && !this.$v.steps.$invalid) || (!this.featureExistense && !this.$v.file.$invalid) || (!this.$v.file.$invalid && ['clustering', 'regression', 'classification'].includes(this.forecastType))) {
        this.load2 = true;
        this.$fetch
          .post('/api/anaplan/forecast', {
            workspaceID: this.workspaceID,
            modelID: this.modelID,
            importName: this.importName,
            exportName: this.exportName,
            steps: this.steps,
            probability: this.probability
          })
          .then(() => {
            this.status = 'check';
            this.$notify.success({
              message: this.$t('notifications.anaplan_import')
            });
            this.load2 = false;
            this.success_load = true;
          })
          .catch(error => {
            this.status = 'error';
            this.load2 = false;
            this.serverErrors = error;
          });
      }
    },
    forecast(type = true) {
      this.$v.$touch();
      this.serverErrors = {};
      if ((this.featureExistense && !this.$v.steps.$invalid) || (!this.featureExistense && !this.$v.file.$invalid) || (!this.$v.file.$invalid && ['clustering', 'regression', 'classification'].includes(this.forecastType))) {
        this.load = type;
        let categories = [];
        this.categories.forEach(category => {
          categories.push({ name: category.name, value: category.value });
        });
        let formData = new FormData();
        if (this.file !== undefined) {
          formData.append('file', this.file);
        } else {
          formData.append('steps', this.steps);
        }
        formData.append('model', this.models.value);
        formData.append('categories', JSON.stringify(categories));
        formData.append('probability', this.probability);
        formData.append('probability_threshold', JSON.stringify(this.probability_threshold));
        this.$fetch
          .post(`/api/forecast/${this.id}`, formData)
          .then(data => {
            this.load = false;
            if (this.forecastType === 'time_series') {
              ({ date: this.series.forecasts.dates, forecast: this.series.forecasts.values } = data);
              this.prepareChartData(true);
            } else if (this.forecastType === 'clustering') {
              // this.numericalForecast = `${data.data + 1}`;
            } else if (this.forecastType === 'regression') {
              // this.numericalForecast = data.data;
              this.prepareChartData(true);
            } else if (this.forecastType === 'classification') {
              // this.numericalForecast = `${data.data}`;
              this.prepareChartData(true);
            }
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
          });
      }
    },
    gameForecast(model, parameters, cleaning) {
      if (model !== null) {
        this.models.value = model;
      }
      if (parameters !== null) {
        let parametersNew = {};
        for (let parameter in parameters) {
          parametersNew[parameter] = parameters[parameter]['value'];
        }
        this.models.params = parametersNew;
      }

      if (cleaning !== null) {
        this.models.cleaning = cleaning;
      }

      this.$v.$touch();
      this.serverErrors = {};
      if ((this.featureExistense && !this.$v.steps.$invalid) || (!this.featureExistense && !this.$v.file.$invalid) || (!this.$v.file.$invalid && ['clustering', 'regression', 'classification'].includes(this.forecastType))) {
        let categories = [];
        this.categories.forEach(category => {
          categories.push({ name: category.name, value: category.value });
        });
        let formData = new FormData();
        if (this.file !== undefined) {
          formData.append('file', this.file);
        } else {
          formData.append('steps', this.steps);
        }
        formData.append('model', this.models.value);
        formData.append('categories', JSON.stringify(categories));
        formData.append('probability', this.probability);
        formData.append('probability_threshold', JSON.stringify(this.probability_threshold));
        formData.append('categories', JSON.stringify(categories));
        formData.append('parameters', JSON.stringify(this.models.params));
        formData.append('cleaning', JSON.stringify(this.models.cleaning));
        this.$fetch
          .post(`/api/game_forecast/${this.id}`, formData)
          .then(data => {
            if (this.forecastType === 'time_series') {
              ({ date: this.series.forecasts.dates, forecast: this.series.forecasts.values } = data);
              this.prepareChartData(false);
            } else if (this.forecastType === 'clustering') {
              // this.numericalForecast = `${data.data + 1}`;
            } else if (this.forecastType === 'regression') {
              // this.numericalForecast = data.data;
              this.prepareChartData(false);
            } else if (this.forecastType === 'classification') {
              // this.numericalForecast = `${data.data}`;
              this.prepareChartData(false);
            }
          })
          .catch(error => {
            this.serverErrors = error;
          });
      }
    },
    update() {
      this.load_update = true;
      this.$fetch
        .put(`/api/stories/${this.id}`)
        .then(() => {
          this.$notify.success({ message: this.$t('notifications.story_retrained') });
          this.load_update = false;
          this.$router.push('/stories');
        })
        .catch(error => {
          this.load_update = false;
          this.serverErrors = error;
        });
    },
    download(type, history_included = false) {
      this.history_included = history_included;
      this.$v.$touch();
      this.serverErrors = {};
      if (
        (this.featureExistense && !this.$v.steps.$invalid) ||
        (!this.featureExistense && !this.$v.file.$invalid) ||
        (!this.$v.file.$invalid && !history_included && ['clustering', 'regression', 'classification'].includes(this.forecastType)) ||
        (history_included && ['clustering', 'regression', 'classification'].includes(this.forecastType))
      ) {
        this[type === 'one' ? 'load_download' : 'load_download_all'] = true;
        let categories = [];
        if (type === 'one') {
          this.categories.forEach(category => {
            categories.push({ name: category.name, value: category.value });
          });
        }
        let formData = new FormData();
        if (this.file !== undefined) {
          formData.append('file', this.file);
        } else {
          formData.append('steps', this.steps);
        }
        formData.append('model', this.models.value);
        formData.append('categories', JSON.stringify(categories));
        formData.append('probability', this.probability);
        formData.append('probability_threshold', JSON.stringify(this.probability_threshold));
        formData.append('history_included', history_included);
        this.$fetch
          .download(type === 'one' ? `/api/forecast/download/${this.id}` : `/api/forecast/download_all/${this.id}`, formData)
          .then(data => {
            const url = URL.createObjectURL(
              new Blob([data], {
                type: 'application/vnd.ms-excel'
              })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'forecast.xlsx');
            document.body.appendChild(link);
            link.click();
            this[type === 'one' ? 'load_download' : 'load_download_all'] = false;
          })
          .catch(() => {
            this[type === 'one' ? 'load_download' : 'load_download_all'] = false;
          });
      }
    },
    deleteStory() {
      this.load = true;
      this.$fetch
        .delete(`/api/stories/${this.id}`)
        .then(() => {
          this.load = false;
          this.$notify.success({ message: this.$t('notifications.story_deleted') });
          this.load = false;
          this.$router.push('/stories');
        })
        .catch(error => {
          this.load = false;
          this.serverErrors = error;
        });
    },
    checkEmptyObject(obj) {
      return obj === undefined ? false : Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    formatNumber(number) {
      if (number < 0.01) {
        return 0;
      } else {
        return this.$numeral(number).format('0,0.');
      }
    },
    formatPercent(number) {
      if (number < 0.01) {
        return '0%';
      } else {
        return this.$numeral(number / 100).format('0,0.%');
      }
    },
    formatPercentDetail(number) {
      if (number < 0.01) {
        return '0%';
      } else {
        return this.$numeral(number / 100).format('0,0.00%');
      }
    },
    generateColors() {
      let letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    clustersColors(index) {
      let array = ['#6dccda', '#cdcc5d', '#a2a2a2', '#ed97ca', '#a8786e', '#ad8bc9', '#ed665d', '#67bf5c', '#ff9e4a', '#729ece'];
      return array[index];
    },
    updateFeatureImportance() {
      if (this.feature_importance.active) {
        this.$fetch
          .post('/api/story_feature_importance', { id: this.id })
          .then(data => {
            this.feature_importance.columns = data.columns;
            this.feature_importance.scores = data.scores;

            if (Object.prototype.hasOwnProperty.call(this.$refs, 'chart')) {
              if (this.$refs.chart !== undefined) {
                this.$refs.chart.updateChart({ columns: this.feature_importance.columns, scores: this.feature_importance.scores[this.feature_importance.value] });
              }
            }
          })
          .catch(error => {
            this.serverErrors = error;
          });
      }
    },
    getWindowHeight() {
      this.windowHeight = document.documentElement.clientHeight;
      this.chart_width = (document.documentElement.clientWidth - 32 * 2) * 0.65;
      // this.chart_width = this.$refs.table_chart.clientWidth;
    }
  }
};
</script>
