<template>
  <v-card class="elevation-12">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t('settings.change_username') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pb-0">
      <v-form>
        <v-row class="px-2 pt-4">
          <v-text-field :label="$t('auth.username')" id="username" v-model="username" :error-messages="usernameMessage" type="text" />
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="green" @click="changeUsername" id="updateUsername" block :loading="load">{{ $t('buttons.change') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';
import usernameMixin from '@/mixins/usernameMixin';

export default {
  name: 'ChangePassword',
  mixins: [baseMixin, usernameMixin],
  data() {
    return {
      load: false
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created() {
    this.username = this.$store.state.user.username;
  },
  methods: {
    changeUsername() {
      this.$v.$touch();
      this.serverErrors = {};
      if (!this.$v.$invalid) {
        this.load = true;
        this.$fetch
          .put(`/api/users/${this.user.id}`, { username:this.username, company_id: this.user.companyId })
          .then(() => {
            this.load = false;
            this.$fetch.get('api/users/me').then(user => {
              this.$store.commit('putUser', user);
              this.$notify.success({ message: this.$t('notifications.username_updated') });
              this.$emit('update');
            });
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
          });
      }
    }
  }
};
</script>
