<template>
  <v-card class="elevation-12">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t('settings.change_company') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pb-0">
      <v-form>
        <v-row class="px-2 pt-4">
          <v-text-field :label="$t('auth.company')" id="company" v-model="company" :error-messages="companyMessage" type="text" />
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="green" id="updateCompany" @click="changeCompany" block :loading="load">{{ $t('buttons.change') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';
import companyMixin from '@/mixins/companyMixin';

export default {
  name: 'ChangeCompany',
  mixins: [baseMixin, companyMixin],
  data() {
    return {
      load: false
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    company_data() {
      return this.$store.state.company;
    }
  },
  created() {
    this.company = this.company_data.name;
  },
  methods: {
    changeCompany() {
      this.$v.$touch();
      this.serverErrors = {};
      if (!this.$v.$invalid) {
        this.load = true;
        this.$fetch
          .put(`/api/companies/${this.user.companyId}`, { name: this.company })
          .then(() => {
            this.load = false;
            this.$fetch.get(`/api/companies/${this.user.companyId}`).then(company => {
              this.$store.commit('putCompany', company);
              this.$notify.success({ message: this.$t('notifications.compnay_name_updated') });
              this.$emit('update');
            });
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
          });
      }
    }
  }
};
</script>
