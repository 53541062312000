<template>
  <v-card class="elevation-12">
    <v-toolbar dense color="primary" dark flat>
      <v-toolbar-title>{{ $t('source.detail.connection_settings') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pb-0">
        <v-text-field class="mt-4" :label="$t('source.detail.url')" outlined dense v-model="new_s3.url" @change="$emit('update', new_s3)" type="text" />
        <v-text-field :label="$t('source.detail.access_key_id')" outlined dense v-model="new_s3.access_key_id" @change="$emit('update', new_s3)" type="text" />
        <v-text-field :label="$t('source.detail.secret_access_key')" outlined dense v-model="new_s3.secret_access_key" @change="$emit('update', new_s3)" type="text" />
        <v-text-field :label="$t('source.detail.bucket_name')" outlined dense v-model="new_s3.bucket_name" @change="$emit('update', new_s3)" type="text" />
        <v-text-field :label="$t('source.detail.file_name')" outlined dense v-model="new_s3.file_name" @change="$emit('update', new_s3)" type="text" />
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'ChangeConnectionSettings',
  props: ['s3'],
  data() {
    return {
      new_s3: {},
    };
  },
  mounted() {
    this.new_s3 = this.s3;
  }
};
</script>
