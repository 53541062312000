<template>
  <v-card class="elevation-12">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t('settings.new_password') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-row class="px-2 pt-4">
          <v-text-field :label="$t('auth.new_password')" id="password" v-model="password" :error-messages="passwordMessage" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" />
        </v-row>
        <v-row class="px-2">
          <v-text-field :label="$t('auth.confirm_password')" id="passwordConfirm" v-model="passwordConfirm" :error-messages="passwordConfirmMessage" :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'" :type="showPasswordConfirm ? 'text' : 'password'" @click:append="showPasswordConfirm = !showPasswordConfirm" />
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="green" id="reset" @click="resetPassword" block :loading="load">{{ $t('auth.renew_password') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';
import passwordMixin from '@/mixins/passwordMixin';
import passwordConfirmMixin from '@/mixins/passwordConfirmMixin';

import { required, maxLength, minLength } from 'vuelidate/lib/validators';

const passwordComplexity = password => /[A-ZА-Я]/.test(password) && /\d/.test(password);

export default {
  name: 'ChangePassword',
  mixins: [baseMixin, passwordMixin, passwordConfirmMixin],
  data() {
    return {
      load: false
    };
  },
  methods: {
    resetPassword() {
      this.$v.$touch();
      this.serverErrors = {};
      if (!this.$v.$invalid) {
        this.load = true;
        this.$fetch
          .post('/api/users/update/password', {
            password1: this.password,
            password2: this.passwordConfirm
          })
          .then(() => {
            this.load = false;
            this.$notify.success({ message: this.$t('notifications.password_updated') });
            this.password = '';
            this.passwordConfirm = '';
            this.$emit('update');
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
            this.serverErrors.password = this.getError('new_password1');
            this.serverErrors.passwordConfirm = this.getError('new_password2');
          });
      }
    }
  }
};
</script>
