<template>
  <v-card class="elevation-12">
    <v-toolbar dense color="primary" dark flat>
      <v-toolbar-title>{{ $t('source.detail.comment') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pb-0">
        <v-textarea class="mt-5 pt-2" counter outlined :placeholder="$t('source.detail.write_comment')" :value="newComment" @change="$emit('update', $event)"></v-textarea>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'ChangeComment',
  props: ['comment'],
  data() {
    return {
      newComment: '',
    };
  },
  mounted() {
    this.newComment = this.comment;
  },
};
</script>
