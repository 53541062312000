<template>
  <v-main>
    <v-container class="fill-height auth-form" fluid>
      <v-row align="center" justify="center">
        <v-col cols="11" sm="6" md="6">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>{{ $t('auth.login') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pb-0">
              <v-form>
                <v-text-field label="E-Mail" id="email" v-model="email" :error-messages="emailMessage" type="text" />
                <v-text-field :label="$t('auth.password')" id="password" v-model="password" :error-messages="passwordMessage" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" />
                <div class="non_field_errors">{{ badCredentials }}</div>
                <v-btn text block color="primary" class="normal-text-transform" @click="$router.push('/reset_password')">{{ $t('auth.forgot_password') }}</v-btn>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn id="login" color="green" @click="login" block :loading="load">
                {{ $t('auth.login') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';

export default {
  name: 'Login',
  mixins: [baseMixin],
  data() {
    return {
      email: '',
      password: '',
      load: false,
      show: false
    };
  },
  computed: {
    badCredentials() {
      return this.getError('non_field_errors') || this.getError('detail');
    },
    emailMessage() {
      if (this.getError('email') !== '') {
        return [this.getError('email')];
      }
      return [];
    },
    passwordMessage() {
      if (this.getError('password') !== '') {
        return [this.getError('password')];
      }
      return [];
    }
  },
  methods: {
    login() {
      this.serverErrors = {};
      this.load = true;
      this.$fetch
        .post('auth/login', {'email': this.email, 'password': this.password})
        .then(data => {
          this.$fetch.setToken(data.accessToken, data.expiredAt);
          this.$fetch.get('api/users/me').then(user => {
            this.$store.commit('putUser', user);
            this.$fetch.get(`/api/companies/${user.companyId}`).then(company => {
              this.$store.commit('putCompany', company);
              this.$router.push('/stories');
            });
          });
        })
        .catch(error => {
          this.load = false;
          this.serverErrors = error;
        });
    }
  }
};
</script>
