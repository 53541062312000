<template>
  <v-card>
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t('docs.account.name') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <h3 class="pb-2">{{ $t('docs.account.api[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.account.api[1]') }}<a :href="`/docs#`">{{ `${host}/docs#` }}</a>.
      <h3 class="pb-2">{{ $t('docs.account.registration[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.account.registration[1]') }}<a :href="`/#/register`">{{ `${host}/#/register` }}</a>.{{ $t('docs.account.registration[2]') }}<a :href="`/#/login`">{{ `${host}/#/login` }}</a>.</p>
      <h3 class="pb-2">{{ $t('docs.account.login[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.account.login[1]') }}<a :href="`/#/login`">{{ `${host}/#/login` }}</a>{{ $t('docs.account.login[2]') }}</p>
      <h3 class="pb-2">{{ $t('docs.account.change_account[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.account.change_account[1]') }}</p>
      <h3 class="pb-2">{{ $t('docs.account.new_user[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.account.new_user[1]') }}</p>
      <h3 class="pb-2">{{ $t('docs.account.auth[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.account.auth[1]') }} {"Authorization": "Token <span class="green--text">dfgdfg1750c382301aed5a85a20b7f</span>"}.</p>
      <h3 class="pb-2">API</h3>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="orange--text">POST </span>
            <span class="primary--text">{{ host }}/login/</span>
            <span>{{ $t('docs.account.api_login') }}</span>
            </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1"><span class="primary--text">email</span> – {{ $t('docs.account.username') }}</p>
            <p class="mb-1"><span class="primary--text">password</span> – {{ $t('docs.account.password') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.json') }}{"key": "<span class="green--text">dfgdfg1750c382301aed5a85a20b7f</span>"}, {{ $t('docs.where') }} <span class="green--text">dfgdfg1750c382301aed5a85a20b7f</span> — {{ $t('docs.account.token') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Accounts',
  data() {
    return {
      host: '',
    };
  },
  mounted() {
    this.host = window.location.host;
  },
};
</script>
