import axios from 'axios';
import { parseISO, format } from 'date-fns';

axios.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error.response.data)
);

class Fetch {
  constructor(store, router, lang) {
    this.store = store;
    this.router = router;
    this.lang = lang === 'ru' ? 'ru' : 'en-US';
    // this.expiredAt = Date.now();
    this.expiredAt = new Date(Date.now());
  }

  async refresh_token() {
    await axios.post('/auth/refresh_token', {'fingerprint': 'fingerprint'})
    .then(data => {
      this.token = `Bearer ${data.accessToken}`;
      // this.expiredAt = parseISO(data.expiredAt);
      this.expiredAt = new Date(parseISO(data.expiredAt, 'dd.MM.yyyy HH:mm') - 1000*60*5);
    })
    .catch(error => {
      this.router.push('/login');
    });
  }

  async get_user() {
    axios.get('api/users/me', {headers: { Authorization: this.token}}).then(data => {
      this.store.commit('putUser', data);
      axios.get(`/api/companies/${data.companyId}`, {headers: { Authorization: this.token}})
      .then(company => {
        this.store.commit('putCompany', company);
      });
    });
  }

  setToken(token, expiredAt) {
    this.token = `Bearer ${token}`;
    // this.expiredAt = parseISO(expiredAt);
    this.expiredAt = new Date(parseISO(expiredAt, 'dd.MM.yyyy HH:mm') - 1000*60*5);
  }

  setLang(lang) {
    this.lang = lang === 'ru' ? 'ru' : 'en-US';
  }

  async auth() {
    // if (this.token == undefined && this.router.history.current.meta.requiresAuth) {
    //   await this.refresh_token();
    //   await this.get_user();
    // }
    if (this.router.history.current.meta.requiresAuth) {
      let now_is = new Date(Date.now());
      if (this.token == undefined || now_is > this.expiredAt) {
        await this.refresh_token();
        await this.get_user();
      }
    }
  }

  async baseHeader() {
    await this.auth();
    return {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': this.lang,
        Authorization: this.token
      }
    };
  }

  async get(url) {
    let headers = await this.baseHeader();
    return axios.get(url, headers);
  }

  async post(url, data) {
    let headers = await this.baseHeader();
    return axios.post(url, data, headers);
  }

  async patch(url, data) {
    let headers = await this.baseHeader();
    return axios.patch(url, data, headers);
  }

  async put(url, data) {
    let headers = await this.baseHeader();
    return axios.put(url, data, headers);
  }

  async delete(url) {
    let headers = await this.baseHeader();
    return axios.delete(url, headers);
  }

  async download(url, data) {
    let headers = await this.baseHeader();
    headers.responseType = 'blob';
    return axios.post(url, data, headers);
  }
}

export default Fetch;
