import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: '',
    company: '',
    lang: '',
  },
  mutations: {
    putUser(state, data) {
      state.user = data;
    },
    putCompany(state, data) {
      state.company = data;
    },
    putLang(state, data) {
      state.lang = data;
    },
  },
  actions: {
  },
  modules: {
  },
});