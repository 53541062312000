<template>
  <v-main>
    <v-container class="fill-height auth-form" fluid>
      <v-row align="center" justify="center">
        <v-col cols="11" sm="6" md="6">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>{{ $t('auth.restore_password') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pb-0">
              <v-form>
                <v-row class="px-2">
                  <v-text-field label="E-Mail" id="email" v-model="email" :error-messages="emailMessage" type="text" />
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="green" id="reset" @click="resetPassword" block :loading="load">{{ $t('auth.reset_password') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';
import emailMixin from '@/mixins/emailMixin';

export default {
  name: 'ForgotPassword',
  mixins: [baseMixin, emailMixin],
  data() {
    return {
      load: false
    };
  },
  methods: {
    resetPassword() {
      this.$v.$touch();
      this.serverErrors = {};
      if (!this.$v.$invalid) {
        this.load = true;
        this.$fetch
          .post('/api/users/restore/password', this.$data)
          .then(() => {
            this.load = false;
            this.$notify.success({ message: this.$t('notifications.reset_password') });
            this.$router.push('/');
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
          });
      }
    }
  }
};
</script>
