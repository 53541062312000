<template>
  <v-card class="elevation-12">
    <v-toolbar dense color="primary" dark flat>
      <v-toolbar-title>{{ $t('source.detail.add_handling') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pb-0">
      <div class="d-flex flex-row align-center">
      <v-col v-for="(option, key) in options" :key="key" class="d-flex flex-column align-center">
        <v-btn icon color="primary" @click="$emit('update', option.value)">
          <v-icon size="40">{{ option.icon }}</v-icon>
        </v-btn>
        <p class="text-center pt-3 mb-0 primary--text text-body-1">{{ option.name }}</p>
      </v-col>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'AddSourceHandling',
  data() {
    return {
      options: [
        { name: this.$t('source.detail.handling_add_source'), value: 'add_source', icon: 'mdi-database-plus' },
        { name: this.$t('source.detail.handling_preprocessing'), value: 'preprocessing', icon: 'mdi-cog-counterclockwise' },
        { name: this.$t('source.detail.handling_merge'), value: 'merge', icon: 'mdi-set-merge' },
      ],
    };
  },
};
</script>
