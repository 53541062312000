import Vue from 'vue';
import VueRouter from 'vue-router';
import Intro from '../views/layout/Intro.vue';
import Login from '../views/auth/Login.vue';
import Register from '../views/auth/Register.vue';
import SetPassword from '../views/auth/SetPassword.vue';
import ResetPassword from '../views/auth/ResetPassword.vue';
import ForgotPassword from '../views/auth/ForgotPassword.vue';
import StoryList from '../views/story/StoryList.vue';
import SourceList from '../views/story/SourceList.vue';
import SourceDetail from '../views/story/SourceDetail.vue';
import StoryTrain from '../views/story/StoryTrain.vue';
import StoryForecast from '../views/story/StoryForecast.vue';
import Settings from '../views/auth/Settings.vue';
import Tasks from '../views/tasks/Tasks.vue';
import ConnectionList from '../views/connections/ConnectionList.vue';
import Connection from '../views/connections/Connection.vue';
import ProcessList from '../views/processes/ProcessList.vue';
import Process from '../views/processes/Process.vue';
import Documentation from '../views/docs/Documentation.vue';
import GetStarted from '../views/docs/GetStarted.vue';
import Accounts from '../views/docs/Accounts.vue';
import Sources from '../views/docs/Sources.vue';
import Stories from '../views/docs/Stories.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'Intro', component: Intro, meta: { requiresAuth: false } },
  { path: '/login', name: 'Login', component: Login, meta: { requiresAuth: false } },
  { path: '/register', name: 'Register', component: Register, meta: { requiresAuth: false } },
  { path: '/reset_password', name: 'ForgotPassword', component: ForgotPassword, meta: { requiresAuth: false } },
  { path: '/password/reset/:token', name: 'ResetPassword', component: ResetPassword, meta: { requiresAuth: false } },
  { path: '/password/set/:token', name: 'SetPassword', component: SetPassword, meta: { requiresAuth: false } },
  { path: '/settings', name: 'Settings', component: Settings, meta: { requiresAuth: true } },
  { path: '/stories', name: 'StoryList', component: StoryList, meta: { requiresAuth: true } },
  { path: '/story/train/:id', name: 'StoryTrain', component: StoryTrain, meta: { requiresAuth: true } },
  { path: '/story/forecast/:id', name: 'StoryForecast', component: StoryForecast, meta: { requiresAuth: true } },
  { path: '/sources', name: 'SourceList', component: SourceList, meta: { requiresAuth: true } },
  { path: '/source/:id', name: 'SourceDetail', component: SourceDetail, meta: { requiresAuth: true } },
  { path: '/anaplan/export/:token/:workspaceID/:modelID/:exportName', name: 'AnaplanTrain', component: StoryTrain, meta: { requiresAuth: false } },
  { path: '/anaplan/import/:token/:workspaceID/:modelID/:importName/:exportName', name: 'AnaplanForecast', component: StoryForecast, meta: { requiresAuth: false } },
  { path: '/tasks', name: 'Tasks', component: Tasks, meta: { requiresAuth: true } },
  { path: '/connections', name: 'ConnectionList', component: ConnectionList, meta: { requiresAuth: true } },
  { path: '/connections/:id', name: 'Connection', component: Connection, meta: { requiresAuth: true } },
  { path: '/processes', name: 'ProcessList', component: ProcessList, meta: { requiresAuth: true } },
  { path: '/processes/:id', name: 'Process', component: Process, meta: { requiresAuth: true } },
  { path: '/docs', name: 'Documentation', component: Documentation, children: [
    { path: 'get_started', name: 'GetStarted', component: GetStarted },
    { path: 'account', name: 'Accounts', component: Accounts },
    { path: 'sources', name: 'Sources', component: Sources },
    { path: 'stories', name: 'Stories', component: Stories },
  ] },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // let token = localStorage.getItem('b2l-user-token');
    // if (token == null) {
    //   next({
    //     path: '/login',
    //     query: {
    //       redirect: to.fullPath,
    //     },
    //   });
    // } else {
    //   next();
    // }
    next()
  } else {
    next();
  }
});

export default router;
