<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">{{ $t('buttons.settings') }}</v-list-item-title>
        <!-- <v-list-item-subtitle>
          subtext
        </v-list-item-subtitle> -->
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <!-- <v-list dense nav style="height:200%"> -->
    <v-list dense nav>
      <v-expansion-panels>
        <v-expansion-panel v-for="(model, key1) in settingsNew" :key="key1" v-if="!['YTY'].includes(key1)">
          <v-expansion-panel-header @click="$emit('update', key1, model, null)">{{ key1 }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-tooltip top v-for="(parameter, key2) in model" :key="key2">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-select class="mt-3" :items="parameter.list" v-model="parameter.value" @change="$emit('update', key1, model, null)" hide-details dense outlined :label="$t(`story.detail.${key1}.params.${key2}[0]`)"></v-select>
                </span>
              </template>
              <span>{{ $t(`story.detail.${key1}.params.${key2}[1]`) }}</span>
            </v-tooltip>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <div v-if="forecastType == 'time_series'">
          <v-switch hide-details v-model="cleaningNew.percentile.active" @change="$emit('update', null, null, cleaningNew)" :label="$t('story.detail.percentile')"></v-switch>
          <v-slider class="pt-8" hide-details v-if="cleaningNew.percentile.active" v-model="cleaningNew.percentile.value" @change="$emit('update', null, null, cleaningNew)" :label="$t('story.detail.value')" min="1" max="30" thumb-color="green lighten-1" thumb-label="always"></v-slider>
          <v-switch hide-details v-model="cleaningNew.rolling.active" @change="$emit('update', null, null, cleaningNew)" :label="$t('story.detail.rolling')"></v-switch>
          <v-slider class="pt-8" hide-details v-if="cleaningNew.rolling.active" v-model="cleaning.rolling.value" @change="$emit('update', null, null, cleaningNew)" :label="$t('story.detail.value')" min="2" max="15" thumb-color="green lighten-1" thumb-label="always"></v-slider>
        </div>
      </v-expansion-panels>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'SettingsGame',
  props: ['settings', 'cleaning', 'forecastType'],
  data() {
    return {
      settingsNew: {},
      cleaningNew: {}
    };
  },
  mounted() {
    this.settingsNew = this.settings;
    this.cleaningNew = this.cleaning;
  },
  watch: {
    settings: {
      deep: true,
      handler() {
        this.settingsNew = this.settings;
      },
    },
    cleaning: {
      deep: true,
      handler() {
        this.cleaningNew = this.cleaning;
      },
    },
  },
};
</script>
