<template>
  <v-app>
      <Navigation></Navigation>
      <transition name="slide" mode="out-in">
         <router-view></router-view>
      </transition>
      <div id="toast-container"></div>
   </v-app>
</template>

<script>
import Navigation from '@/views/layout/Navigation.vue';

export default {
  name: 'App',
  components: { Navigation },
};
</script>

