import { required, email, maxLength } from 'vuelidate/lib/validators';

const emailMixin = {
  data() {
    return {
      email: ''
    };
  },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(254),
    }
  },
  computed: {
    emailMessage() {
      if (this.$v.email.$error || this.getError('email') !== '') {
        if (!this.$v.email.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.email.email) {
          return [this.$t('validations.email')];
        } else if (!this.$v.email.maxLength) {
          return [this.$t('validations.max_length[0]') + this.$v.email.$params.maxLength.max + this.$t('validations.max_length[1]')];
        } else if (!this.$v.email.$error && this.getError('email') !== '') {
          return [this.getError('email')];
        }
      }
      return [];
    },
  }
};

export default emailMixin;
