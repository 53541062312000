<template>
  <v-app-bar :color="user !== '' || $route.path.includes('docs') || $route.path.includes('anaplan') ? 'primary' : colorNavbar ? 'transparent' : 'primary'" :flat="colorNavbar" fixed dense class="mainNav" id="mainNav">
    <!-- <v-toolbar-title v-if="!$route.path.includes('anaplan')" class="logo" @click="$route.path == '/' ? scrollToSection('mainNav') : $router.push('/')">{{ user.username ? user.company.company : 'Brain2Logic' }}</v-toolbar-title> -->
    <v-toolbar-title v-if="!$route.path.includes('anaplan')" class="logo" @click="$route.path == '/' ? scrollToSection('mainNav') : $router.push('/')">
      <span v-if="user !== ''"> {{ company == '' ? '' : company.name }}</span>
      <img v-else :src="icon" height="35" alt="lorem" />
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- <v-btn color="transparent" class="white--text hidden-sm-and-down" depressed v-if="!user.username && $route.path == '/'" @click="scrollToSection('about')">{{ $t('intro.about') }}</v-btn> -->
    <v-btn color="transparent" class="white--text hidden-sm-and-down" depressed v-if="user == '' && $route.path == '/'" @click="scrollToSection('product')">{{ $t('intro.product') }}</v-btn>
    <v-btn color="transparent" class="white--text hidden-sm-and-down" depressed v-if="user == '' && $route.path == '/'" @click="scrollToSection('cases')">{{ $t('intro.case') }}</v-btn>
    <!-- <v-btn color="transparent" class="white--text hidden-sm-and-down" depressed v-if="!user.username && $route.path == '/'" @click="$router.push('/docs/get_started')">{{ $t('intro.docs') }}</v-btn> -->
    <!-- <v-btn color="transparent" class="white--text hidden-sm-and-down" depressed v-if="!user.username && $route.path == '/'" @click="$router.push('/register')">{{ $t('intro.registration') }}</v-btn> -->
    <v-btn color="transparent" class="white--text hidden-sm-and-down" depressed v-if="user == '' && $route.path == '/'" @click="$router.push('/login')">{{ $t('intro.login') }}</v-btn>
    <!-- <v-menu left bottom close-on-click close-on-content-click open-on-hover style="z-index: 500;" origin="center center" transition="scale-transition" v-if="!['/register', '/login'].includes($route.path) && !$route.path.includes('anaplan')"> -->
    <v-menu left bottom close-on-click close-on-content-click open-on-hover origin="center center" transition="scale-transition" v-if="!['/register', '/login'].includes($route.path) && !$route.path.includes('anaplan')">
      <template v-slot:activator="{ on }">
        <v-btn v-if="user == ''" icon v-on="on" class="hidden-md-and-up">
          <v-icon color="white">mdi-menu</v-icon>
        </v-btn>
        <v-btn v-else color="transparent" v-on="on" class="white--text" depressed
          >{{ user !== '' ? user.username : '' }}
          <v-icon color="white" size="2em">mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <!-- <v-list-item v-if="$route.path == '/'" @click="scrollToSection('about')">
          <v-list-item-title>{{ $t('intro.about') }}</v-list-item-title>
        </v-list-item> -->
        <v-list-item v-if="$route.path == '/'" @click="scrollToSection('product')">
          <v-list-item-title>{{ $t('intro.product') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$route.path == '/'" @click="scrollToSection('cases')">
          <v-list-item-title>{{ $t('intro.case') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$route.path == '/' && user.username" @click="$router.push('/docs/get_started')">
          <v-list-item-title>{{ $t('intro.docs') }}</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item v-if="$route.path == '/' && user !== ''" @click="goToDocs">
          <v-list-item-title>{{ $t('intro.docs') }}</v-list-item-title>
        </v-list-item> -->
        <!-- <v-list-item v-if="!user.username && $route.path == '/'" @click="$router.push('/register')">
          <v-list-item-title>{{ $t('intro.registration') }}</v-list-item-title>
        </v-list-item> -->
        <v-list-item v-if="user == '' && $route.path == '/'" @click="$router.push('/login')">
          <v-list-item-title>{{ $t('intro.login') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user !== ''" @click="$router.push('/sources').catch(err => {})">
          <v-list-item-title>{{ $t('intro.sources') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user !== ''" @click="$router.push('/stories').catch(err => {})">
          <v-list-item-title>{{ $t('intro.stories') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user !== ''" @click="$router.push('/settings').catch(err => {})">
          <v-list-item-title>{{ $t('intro.settings') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user !== ''" @click="$router.push('/tasks').catch(err => {})">
          <v-list-item-title>{{ $t('intro.integration') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user !== ''" @click="logout">
          <v-list-item-title>{{ $t('intro.logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu left bottom close-on-click close-on-content-click open-on-hover origin="center center" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn color="transparent" v-on="on" class="white--text" depressed>
          {{ lang }}
          <v-icon color="white" size="2em">mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item class="v-list-item--link">
          <v-list-item-title @click="setLang('en')">en</v-list-item-title>
        </v-list-item>
        <v-list-item class="v-list-item--link">
          <v-list-item-title @click="setLang('de')">de</v-list-item-title>
        </v-list-item>
        <v-list-item class="v-list-item--link">
          <v-list-item-title @click="setLang('fr')">fr</v-list-item-title>
        </v-list-item>
        <v-list-item class="v-list-item--link">
          <v-list-item-title @click="setLang('es')">es</v-list-item-title>
        </v-list-item>
        <v-list-item class="v-list-item--link">
          <v-list-item-title @click="setLang('ch')">ch</v-list-item-title>
        </v-list-item>
        <v-list-item class="v-list-item--link">
          <v-list-item-title @click="setLang('ko')">ko</v-list-item-title>
        </v-list-item>
        <v-list-item class="v-list-item--link">
          <v-list-item-title @click="setLang('no')">no</v-list-item-title>
        </v-list-item>
        <v-list-item class="v-list-item--link">
          <v-list-item-title @click="setLang('ru')">ru</v-list-item-title>
        </v-list-item> 
        <v-list-item class="v-list-item--link">
          <v-list-item-title @click="setLang('pr')">pr</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import image from '../../img/b2l.png';

export default {
  name: 'Navigation',
  data() {
    return {
      colorNavbar: true,
      lastScrollPosition: 0,
      icon: image
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    company() {
      return this.$store.state.company;
    },
    lang() {
      return this.$store.state.lang;
    }
  },
  mounted() {
    let token = null;
    // if (['AnaplanTrain', 'AnaplanForecast'].includes(this.$route.name)) {
    //   token = this.$route.params.token;
    // } else {
    //   token = localStorage.getItem('b2l-user-token');
    // }
    // if (token !== null) {
    //   this.$fetch
    //     .get('user/')
    //     .then(data => {
    //       this.$store.commit('putUser', data);
    //     })
    //     .catch(() => {
    //       // if (error.response.status === 401) {
    //       localStorage.removeItem('b2l-user-token');
    //       // this.$axios.defaults.headers.common['Authorization'] = '';
    //       this.$fetch.setToken(null);
    //       this.$router.push('/login');
    //       // }
    //     });
    // }
    // this.$fetch.auth();
    window.addEventListener('scroll', this.scroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll);
  },
  methods: {
    logout() {
      this.$fetch.post('auth/logout').then(() => {
        this.$fetch.setToken(null);
        this.$store.commit('putUser', '');
        this.$router.push('/');
      });
    },
    scrollToSection(section) {
      // Smooth scrolling to sections
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: document.getElementById(section).offsetTop
      });
    },
    scroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      this.colorNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
    setLang(lang) {
      this.$store.commit('putLang', lang);
      localStorage.setItem('b2l-lang', lang);
      this.$i18n.locale = lang;
      this.$fetch.setLang(lang);
      this.$vuetify.lang = lang;
    },
    // events() {
    //   this.$ws.webSocket.onmessage = (e) => {
    //     let data = JSON.parse(e.data);
    //     let array = data.message.split('|');
    //     let message;
    //     if (array[0] === 'Story trained') {
    //       message = this.$t('notifications.story_trained[0]') + array[1] + this.$t('notifications.story_trained[1]');
    //       this.$notify.success({ message });
    //     } else if (array[0] === 'Story error') {
    //       message = this.$t('notifications.story_error_base[0]') + array[1] + this.$t('notifications.story_error_base[1]');
    //       this.$notify.error({ message });
    //     } else if (array[0] === 'Story stopped') {
    //       message = this.$t('notifications.story_error_stop[0]') + array[1] + this.$t('notifications.story_error_stop[1]');
    //       this.$notify.error({ message });
    //     } else if (array[0] === 'Forecast prepared') {
    //       message = this.$t('notifications.forecast_prepared[0]') + array[1] + this.$t('notifications.forecast_prepared[1]');
    //       this.$notify.success({ message });
    //     }
    //   };
    // },
  }
};
</script>
