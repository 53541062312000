<template>
  <transition name="fade">
    <v-alert :type="type == 'success' ? 'success' : 'error'" :icon="type == 'success' ? 'mdi-check-circle' : 'mdi-alert'" v-if="show">{{message}}</v-alert>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  props: {
    type: {
      type: String,
    },
    message: {
      type: String,
    },
  },
  mounted() {
    let toastContainer = document.getElementById('toast-container');
    toastContainer.appendChild(this.$el);
    this.showToastr();
  },
  methods: {
    showToastr() {
      this.show = true;
      this.sto = setTimeout(() => this.hideToastr(), 3000);
    },
    hideToastr() {
      clearTimeout(this.sto);
      this.show = false;
    },
  },
};
</script>
