<template>
  <v-card class="elevation-12">
    <v-toolbar dense color="primary" dark flat>
      <v-toolbar-title>{{ $t('connections.detail.csv.create_empty_file') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-text-field class="mt-5 pt-2" :label="$t('connections.detail.csv.file_name')" outlined dense v-model="file_name" :error-messages="nameMessage" type="text" />
      <v-btn color="primary" @click="save" outlined>{{ $t('buttons.save') }}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';

export default {
  name: 'CreateEmptyFile',
  mixins: [baseMixin],
  props: ['id'],
  data() {
    return {
      file_name: ''
    };
  },
  computed: {
    nameMessage() {
      return this.getError('fileName') !== '' ? [this.getError('fileName')] : [];
    }
  },
  methods: {
    save() {
      this.$fetch
        .post(`/api/csv/${this.id}/create/empty/file`, {file_name: this.file_name})
        .then(() => {
          this.$emit('update');
        })
        .catch(error => {
          this.serverErrors = error;
        });
    }
  }
};
</script>
