<template>
  <v-main>
    <v-container fluid id="home">
      <h2>
        <span>{{ $t('source.list.name') + '    ' }}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="mx-2" @click="$router.push(`/source/0/`)" fab dark color="green">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('source.list.add') }}</span>
        </v-tooltip>
      </h2>
      <v-row justify="start" class="my-2">
        <v-col cols="3" v-for="(source, key) in sources" :key="key">
          <v-card @contextmenu.prevent.stop="chooseItem($event, source.id)">
            <v-img :src="source.image ? source.image : 'media/source.jpeg'" class="white--text align-end cursor" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px" @click="$router.push(`/source/${source.id}/`)">
              <v-card-title @click="$router.push(`/source/${source.id}/`)">{{ source.name }}</v-card-title>
            </v-img>
            <v-row justify="end" class="py-0 my-0">
              <v-card-actions class="py-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-information</v-icon>
                  </template>
                  <v-card-text class="pa-1">
                    <p class="text-left mb-0">id: {{ source.id }}</p>
                    <p class="text-left mb-0">{{ $t('source.list.updated') }}: {{ source.updated_at }}</p>
                    <p class="text-left mb-0">{{ $t('source.list.rows') }}: {{ source.data.rows }}</p>
                    <p class="text-left mb-0">{{ $t('source.list.size') }}: {{ source.data.size }}</p>
                    <p class="text-left mb-0" v-if="source.comment != ''">{{ $t('source.list.description') }}: {{ source.comment }}</p>
                  </v-card-text>
                </v-tooltip>
              </v-card-actions>
            </v-row>
          </v-card>
        </v-col>
        <!-- <v-col cols="3" align-self="center">
          <v-btn class="mx-2" fab dark color="green" @click="test">test mail port</v-btn>
        </v-col> -->
      </v-row>
    </v-container>
    <vue-simple-context-menu class="context-padding" elementId="myUniqueId" :options="options" ref="vueSimpleContextMenu" @option-clicked="chooseOption"/>
    <v-dialog v-model="showPhoto">
      <change-photo :id="activeItem" type="source" @update="updatePhoto" @delete="deletePhoto"></change-photo>
    </v-dialog>
  </v-main>
</template>

<script>
import ChangePhoto from '@/views/story/ChangePhoto.vue';

export default {
  name: 'SourceList',
  components: { ChangePhoto },
  data() {
    return {
      sources: [],
      showPhoto: false,
      activeItem: 0,
      options: [
        { name: this.$t('photo.change_cover'), type: 'photo' }
      ]
    };
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      this.$fetch.get('/api/sources').then(data => {
        let adjustedData = [];
        data.forEach(value => {
          adjustedData.push({
            id: value.id,
            name: value.name,
            image: value.image,
            comment: value.comment,
            updated_at: this.$date.format(this.$date.parseISO(value.updated_at), 'dd.MM.yyyy HH:mm'),
            data: {
              size: this.$numeral(value.data.size / 1000 / 1000).format('0,0.0') + this.$t('source.list.mb'),
              rows: this.$numeral(value.data.rows).format('0,0')
            }
          });
        });
        this.sources = adjustedData;
      });
    },
    chooseItem(event, id) {
      this.$refs.vueSimpleContextMenu.showMenu(event, id);
    },
    chooseOption(event) {
      this.activeItem = event.item;
      if (event.option.type === 'photo') {
        this.showPhoto = true;
      }
    },
    updatePhoto() {
      this.showPhoto = false;
      this.update();
    },
    deletePhoto() {
      this.update();
    }
    // test() {
    //   this.$axios.post('source/');
    // },
  }
};
</script>
