<template>
  <v-card class="elevation-12">
    <v-toolbar dense color="primary" dark flat>
      <v-toolbar-title>{{ $t('buttons.settings') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pa-0">
      <v-tabs align-with-title show-arrows v-model="tab" background-color="blue-grey lighten-3" dark centered grow>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-base">{{ $t('story.detail.base_conf') }}</v-tab>
        <v-tab :href="`#tab-${index}`" v-for="(parameters, model, index) in settingsNew[forecastType]" :key="`tab${index}`" v-if="['best', 'all_models'].includes(models[0]) ? true : models.includes(model) ? true : false">{{ $t(`story.detail.${model}.short`) }}</v-tab>
        <v-tab-item value="tab-base">
          <div class="model-settings pt-2 px-2">
            <v-row class="mx-2 pt-2">
              <v-col cols="4" class="px-2 pb-0">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-select :items="cleaningNew.cpu.list" v-model="cleaningNew.cpu.value" hide-details dense outlined :label="$t('story.detail.base_config.cpu.name')"></v-select>
                    </span>
                  </template>
                  <span>{{ $t('story.detail.base_config.cpu.desc') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row class="mx-2 my-0" v-if="forecastType === 'time_series'">
              <v-col cols="4" class="px-2 pb-0">
                <v-switch hide-details v-model="cleaningNew.target_scaling.value" :label="$t('story.detail.target_scaling')"></v-switch>
              </v-col>
              <v-col cols="4" class="px-2 pb-0">
                <v-switch hide-details v-model="cleaningNew.train_accuracy.value" :label="$t('story.detail.train_accuracy')"></v-switch>
              </v-col>
              <v-col cols="4" class="px-2 pb-0">
                <v-switch hide-details v-model="cleaningNew.eliminate_negative_values.value" :label="$t('story.detail.eliminate_negative_values')"></v-switch>
              </v-col>
            </v-row>
            <v-row class="mx-2" v-if="forecastType === 'time_series'">
              <v-col cols="4" class="px-2 pb-0">
                <v-select hide-details :items="cleaningNew.scaler.list" v-model="cleaningNew.scaler.value" outlined dense :label="$t('story.detail.scaler')"></v-select>
              </v-col>
              <v-col cols="4" class="px-2 pb-0">
                <v-select hide-details :items="cleaningNew.test_size.list" v-model="cleaningNew.test_size.value" outlined dense :label="$t('story.detail.test_size')"></v-select>
              </v-col>
              <v-col cols="4" class="px-2 pb-0">
                <v-menu ref="menu" v-if="cleaningNew.test_size.value === -2" v-model="cleaningNew.test_size.menu" :close-on-content-click="false" :return-value.sync="cleaningNew.test_size.date" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="computedDateFormatted" :label="$t('story.detail.test_date')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <!-- <v-date-picker v-model="cleaningNew.test_size.date" :allowed-dates="filterDates" @click:date="$refs.menu.save(cleaningNew.test_size.date)" :locale="`${lang}-${lang}`" :first-day-of-week="1" no-title scrollable></v-date-picker> -->
                  <v-date-picker v-model="cleaningNew.test_size.date" @click:date="$refs.menu.save(cleaningNew.test_size.date)" :locale="`${lang}-${lang}`" :first-day-of-week="1" no-title scrollable></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="mx-2" v-if="forecastType === 'time_series'">
              <v-col cols="4" class="px-2 pb-0">
                <v-select hide-details class="mt-4" :items="cleaningNew.accuracy_score.list" v-model="cleaningNew.accuracy_score.value" outlined dense :label="$t('story.detail.accuracy')"></v-select>
              </v-col>
              <v-col cols="4" class="px-2 pb-0">
                <v-select class="mt-4" hide-details :items="cleaningNew.time_features.list" v-model="cleaningNew.time_features.values" dense outlined multiple :label="$t('story.detail.time_features')">
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item.text }}</span>
                    <span v-if="index === 1" class="grey--text caption">(+{{ cleaningNew.time_features.values.length - 1 }} {{ $t('story.detail.etc') }} )</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-4" v-if="['regression', 'classification'].includes(forecastType)">
              <v-col cols="4" class="px-2 pb-0">
                <v-select hide-details :items="cleaningNew.scaler.list" v-model="cleaningNew.scaler.value" outlined dense :label="$t('story.detail.scaler')"></v-select>
              </v-col>
              <v-col cols="4" class="px-2 pb-0">
                <v-select hide-details :items="cleaningNew.test_size.list" v-model="cleaningNew.test_size.value" outlined dense :label="$t('story.detail.test_size')"></v-select>
              </v-col>
              <v-col cols="4" class="px-2 pb-0">
                <v-select hide-details :items="cleaningNew.accuracy_score.list" v-model="cleaningNew.accuracy_score.value" outlined dense :label="$t('story.detail.accuracy')"></v-select>
              </v-col>
            </v-row>
            <v-row class="mx-2" v-if="['regression', 'classification'].includes(forecastType)">
              <v-col cols="4" class="px-2 pb-0">
                <v-switch hide-details v-model="cleaningNew.cross_validation.active" :label="$t('story.detail.cross_validation')"></v-switch>
              </v-col>
              <v-col cols="4" class="px-2 pb-0">
                <v-select class="pt-3" v-if="cleaningNew.cross_validation.active" hide-details :items="cleaningNew.cross_validation.list" v-model="cleaningNew.cross_validation.folds" outlined dense :label="$t('story.detail.number_of_folds')"></v-select>
              </v-col>
            </v-row>
            <v-row class="mx-2" v-if="forecastType === 'classification'">
              <v-col cols="4" class="px-2 pb-0">
                <v-switch hide-details v-model="cleaningNew.probability_threshold.active" :label="$t('story.detail.probability_threshold')"></v-switch>
              </v-col>
              <v-col cols="4" class="px-2 pb-0">
                <v-slider class="mt-8" v-if="cleaningNew.probability_threshold.active" hide-details v-model="cleaningNew.probability_threshold.value" :label="$t('story.detail.probability_threshold_value')" min="0.01" max="0.95" step="0.01" thumb-color="green lighten-1" thumb-label="always"></v-slider>
              </v-col>
            </v-row>
            <v-row class="mx-2 pt-0" v-if="forecastType === 'classification'">
              <v-col cols="4" class="px-2 pb-0">
                <v-switch hide-details v-model="cleaningNew.hyperparameter_tuning.active" :label="$t('story.detail.hyperparameter_tuning')"></v-switch>
              </v-col>
              <v-col cols="4" class="px-2 pb-0">
                <v-select class="pt-5" v-if="cleaningNew.hyperparameter_tuning.active" hide-details :items="cleaningNew.hyperparameter_tuning.list" v-model="cleaningNew.hyperparameter_tuning.value" outlined dense :label="$t('story.detail.hyperparameter_tuning_value')"></v-select>
              </v-col>
              <v-col cols="4" class="px-2 pb-0">
                <v-select class="pt-5" v-if="cleaningNew.hyperparameter_tuning.active" hide-details :items="cleaningNew.hyperparameter_tuning.iterations_values" v-model="cleaningNew.hyperparameter_tuning.iterations" outlined dense :label="$t('story.detail.hyperparameter_tuning_iterations')"></v-select>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-4" v-if="forecastType === 'clustering'">
              <v-col cols="4" class="px-2 pb-0">
                <v-select hide-details :items="cleaningNew.scaler.list" v-model="cleaningNew.scaler.value" outlined dense :label="$t('story.detail.scaler')"></v-select>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
        <v-tab-item :value="`tab-${index1}`" v-for="(parameters, model, index1) in settingsNew[forecastType]" :key="`tab-item-${index1}`" v-if="['best', 'all_models'].includes(models[0]) ? true : models.includes(model) ? true : false">
          <div class="model-settings pt-2 px-2">
            <p class="model-settings__text mb-1 pl-4"><a class="model-settings__link" style="text-decoration: none;" :href="link[model]" target="_blank">{{ $t(`story.detail.${model}.name`) }}</a></p>
            <!-- <v-row class="mx-2 pt-2" v-if="categories.columns.length == 0"> -->
            <v-row class="mx-2 pt-2">
              <v-col cols="4" class="px-2 pb-0" v-for="(values, parameter, index2) in parameters" :key="`${index1}-${index2}`">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <!-- <v-select :items="values.list" v-model="values.values" @change="selection(model, parameter)" hide-details dense outlined multiple :label="$t(`story.detail.${model}.params.${parameter}[0]`)"></v-select> -->
                      <!-- <v-combobox :items="values.list" v-model="values.values"  @change="selection(model, parameter)" hide-details dense outlined multiple :label="$t(`story.detail.${model}.params.${parameter}[0]`)"></v-combobox> -->
                      <!-- <v-combobox item-text="text" item-value="value" :items="values.list" :return-object="false" v-model="values.values"  @change="selection(model, parameter)" hide-details dense outlined multiple :label="$t(`story.detail.${model}.params.${parameter}[0]`)"></v-combobox> -->
                      <v-combobox :items="values.list" v-model="values.values" @change="selection(model, parameter)" hide-details dense outlined multiple :label="$t(`story.detail.${model}.params.${parameter}[0]`)"></v-combobox>
                    </span>
                  </template>
                  <span>{{ $t(`story.detail.${model}.params.${parameter}[1]`) }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ChangeSettings',
  props: ['settings', 'forecastType', 'cleaning', 'models'],
  data() {
    return {
      tab: null,
      settingsNew: {},
      cleaningNew: {},
      link: {
        SARIMAX: 'https://www.statsmodels.org/dev/generated/statsmodels.tsa.statespace.sarimax.SARIMAX.html',
        HWES: 'https://www.statsmodels.org/dev/generated/statsmodels.tsa.holtwinters.ExponentialSmoothing.html',
        PROPHET: 'https://facebook.github.io/prophet/',
        XGBOOST: 'https://xgboost.readthedocs.io/en/latest/',
        NGBOOST: 'https://github.com/stanfordmlgroup/ngboost',
        CATBOOST: 'https://catboost.ai',
        LIGHTGBM: 'https://lightgbm.readthedocs.io/en/latest/',
        LSTM: 'https://keras.io/api/layers/recurrent_layers/lstm/',
        AR: 'https://www.statsmodels.org/stable/generated/statsmodels.tsa.ar_model.AutoReg.html',
        SES: 'https://www.statsmodels.org/stable/generated/statsmodels.tsa.holtwinters.SimpleExpSmoothing.html',
        LogarR: 'https://docs.scipy.org/doc/scipy/reference/generated/scipy.optimize.curve_fit.html',
        ER: 'https://docs.scipy.org/doc/scipy/reference/generated/scipy.optimize.curve_fit.html',
        // MA: 'https://www.statsmodels.org/stable/generated/statsmodels.tsa.arima.model.ARIMA.html',
        // VAR: 'https://www.statsmodels.org/dev/generated/statsmodels.tsa.vector_ar.var_model.VAR.html',
        // VARMAX: 'https://www.statsmodels.org/stable/generated/statsmodels.tsa.statespace.varmax.VARMAX.html',
        SVR: 'https://scikit-learn.org/stable/modules/generated/sklearn.svm.SVR.html',
        DTR: 'https://scikit-learn.org/stable/modules/generated/sklearn.tree.DecisionTreeRegressor.html',
        RFR: 'https://scikit-learn.org/stable/modules/generated/sklearn.ensemble.RandomForestRegressor.html',
        LR: 'https://scikit-learn.org/stable/modules/generated/sklearn.linear_model.LinearRegression.html',
        PR: 'https://scikit-learn.org/stable/modules/generated/sklearn.preprocessing.PolynomialFeatures.html',
        LogR: 'https://scikit-learn.org/stable/modules/generated/sklearn.linear_model.LogisticRegression.html',
        KNN: 'https://scikit-learn.org/stable/modules/generated/sklearn.neighbors.KNeighborsClassifier.html',
        SVM: 'https://scikit-learn.org/stable/modules/generated/sklearn.svm.SVC.html',
        NB: 'https://scikit-learn.org/stable/modules/generated/sklearn.naive_bayes.GaussianNB.html',
        DTC: 'https://scikit-learn.org/stable/modules/generated/sklearn.tree.DecisionTreeClassifier.html',
        RFC: 'https://scikit-learn.org/stable/modules/generated/sklearn.ensemble.RandomForestClassifier.html',
        XGBOOST_class: 'https://xgboost.readthedocs.io/en/latest/',
        CATBOOST_class: 'https://catboost.ai',
        LIGHTGBM_class: 'https://lightgbm.readthedocs.io/en/latest/',
        NGBOOST_class: 'https://github.com/stanfordmlgroup/ngboost',
        XGBOOST_reg: 'https://xgboost.readthedocs.io/en/latest/',
        CATBOOST_reg: 'https://catboost.ai',
        LIGHTGBM_reg: 'https://lightgbm.readthedocs.io/en/latest/',
        NGBOOST_reg: 'https://github.com/stanfordmlgroup/ngboost',
        KMEANS: 'https://scikit-learn.org/stable/modules/generated/sklearn.cluster.KMeans.html#sklearn.cluster.KMeans',
        AGGLOMERATIVE_CLUSTERING: 'https://scikit-learn.org/stable/modules/generated/sklearn.cluster.AgglomerativeClustering.html#sklearn.cluster.AgglomerativeClustering',
        DBSCAN: 'https://scikit-learn.org/stable/modules/generated/sklearn.cluster.DBSCAN.html#sklearn.cluster.DBSCAN',
        BIRCH: 'https://scikit-learn.org/stable/modules/generated/sklearn.cluster.Birch.html#sklearn.cluster.Birch'
      }
    };
  },
  computed: {
    computedDateFormatted() {
      if (!this.cleaningNew.test_size.date) {
        return null;
      } else {
        const [year, month, day] = this.cleaningNew.test_size.date.split('-');
        return `${day}.${month}.${year}`;
      }
    },
    lang() {
      return this.$store.state.lang;
    },
  },
  mounted() {
    this.settingsNew = this.settings;
    this.cleaningNew = this.cleaning;
  },
  methods: {
    selection(model, parameter) {
      let param = this.settingsNew[this.forecastType][model][parameter];
      let len = param.values.length - 1;
      if (typeof param.values[len] === 'object' && param.values[len] !== null) {
        if (['default', 'adf', 'kpss', 'pp', 'ch'].includes(param.values[len].value)) {
          param.values = [param.values[len]];
        } else {
          param.values = this.$lodash.remove(param.values, n => !['default', 'adf', 'kpss', 'pp', 'ch'].includes(n.value));
        }
      } else {
        param.values = this.$lodash.remove(param.values, n => !['default', 'adf', 'kpss', 'pp', 'ch'].includes(n.value));
      }
    },
    filterDates(value) {
      return this.cleaningNew.test_size.unique_dates.indexOf(value) !== -1;
    },
  },
  watch: {
    settings: {
      deep: true,
      handler() {
        this.settingsNew = this.settings;
        this.cleaningNew = this.cleaning;
      },
    },
  },
};
</script>
