<template>
  <v-main>
    <v-container class="fill-height auth-form" fluid>
      <v-row align="center" justify="center">
        <v-col cols="11" sm="8" md="6">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>{{ $t('auth.registration') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pb-0">
              <v-form>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field :label="$t('auth.username')" id="username" v-model="username" :error-messages="usernameMessage" type="text" />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field label="E-Mail" v-model="email" id="email" :error-messages="emailMessage" type="text" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <v-text-field :label="$t('auth.company')" v-model="company" id="company" :error-messages="companyMessage" type="text" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field :label="$t('auth.password')" v-model="password" id="password" :error-messages="passwordMessage" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field :label="$t('auth.confirm_password')" v-model="passwordConfirm" id="passwordConfirm" :error-messages="passwordConfirmMessage" :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'" :type="showPasswordConfirm ? 'text' : 'password'" @click:append="showPasswordConfirm = !showPasswordConfirm" />
                  </v-col>
                </v-row>
                <v-checkbox :label="$t('auth.agree')" v-model="agree" id="agree" :error-messages="agreeMessage" class="mt-0"></v-checkbox>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="green" id="register" @click="register" block :loading="load">{{ $t('auth.register') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';
import emailMixin from '@/mixins/emailMixin';
import companyMixin from '@/mixins/companyMixin';
import usernameMixin from '@/mixins/usernameMixin';
import passwordMixin from '@/mixins/passwordMixin';
import passwordConfirmMixin from '@/mixins/passwordConfirmMixin';

const equalTrue = value => value === true;

export default {
  name: 'Register',
  mixins: [baseMixin, emailMixin, companyMixin, usernameMixin, passwordMixin, passwordConfirmMixin],
  data() {
    return {
      agree: false,
      load: false
    };
  },
  validations: {
    agree: {
      equalTrue
    }
  },
  computed: {
    agreeMessage() {
      if (this.$v.agree.$error) {
        if (!this.$v.agree.equalTrue) {
          return [this.$t('validations.equal_true')];
        }
      }
      return [];
    }
  },
  methods: {
    register() {
      this.$v.$touch();
      this.serverErrors = {};
      if (!this.$v.$invalid) {
        this.load = true;
        this.$fetch
          .post('/api/register', {
            email: this.email,
            username: this.username,
            company: this.company,
            password1: this.password,
            password2: this.passwordConfirm
          })
          .then(() => {
            this.$notify.success({ message: this.$t('notifications.success_registration') });
            this.$router.push('/login');
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
            this.serverErrors.password = this.getError('password1');
            this.serverErrors.passwordConfirm = this.getError('password2');
          });
      }
    }
  }
};
</script>
