<template>
  <v-main>
    <v-container fluid id="home">
      <h2>{{ $t('settings.settings') }}</h2>
      <v-row class="pt-2">
        <v-col cols="4" class="pl-0">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>{{ $t('settings.profile') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row class="pb-3 pt-3" @mouseover="showUsername = true" @mouseleave="showUsername = false">
                <p class="mb-0 pl-2 profile-item">{{ user.username }}</p>
                <v-icon color="primary" v-if="showUsername" class="cursor" @click="dialogUsername = true">mdi-lead-pencil</v-icon>
              </v-row>
              <v-row class="pb-3">
                <p class="mb-0 pl-2 profile-item">{{ user.email }}</p>
              </v-row>
              <v-row class="pb-3" @mouseover="showCompany = true" @mouseleave="showCompany = false">
                <p class="mb-0 pl-2 profile-item">{{ company.name }}</p>
                <v-icon color="primary" v-if="showCompany" class="cursor" @click="dialogCompany = true">mdi-lead-pencil</v-icon>
              </v-row>
              <v-spacer />
              <v-btn color="green" class="mt-2" block @click="dialogPassword = true">{{ $t('settings.change_password') }}</v-btn>
              <!-- <v-btn color="green" block>Сменить фото</v-btn> -->
            </v-card-text>
          </v-card>
          <v-card class="elevation-12 mt-4">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>{{ $t('settings.anaplan') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row class="pb-3">
                <v-text-field :label="$t('settings.login')" v-model="anaplan_login" :error-messages="anaplanLoginMessage" type="text" />
                <v-text-field :label="$t('auth.password')" v-model="anaplan_password" :error-messages="anaplanPasswordMessage" :append-icon="anaplan_show ? 'mdi-eye' : 'mdi-eye-off'" :type="anaplan_show ? 'text' : 'password'" @click:append="anaplan_show = !anaplan_show" />
              </v-row>
              <v-spacer />
              <v-btn color="green" @click="updateAnaplan" block>{{ $t('buttons.update') }}</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8" class="pr-0">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>{{ $t('settings.users') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left table-header">{{ $t('settings.user') }}</th>
                      <th class="text-left table-header">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user, key) in users" :key="key">
                      <td class="text-left">{{ user.username }}</td>
                      <td class="text-left">{{ user.email }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="mx-2" @click="dialogAddUser = true" small fab dark color="green">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('settings.add_user') }}</span>
              </v-tooltip>
            </v-card-text>
          </v-card>
          <v-card class="elevation-12 mt-4">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>
                <v-tooltip right max-width="400">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ $t('settings.workers') }} ({{ workerLen }})</span>
                  </template>
                  <span>{{ $t('settings.worker_desc') }}</span>
                </v-tooltip>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <transition-group name="slide" tag="div" class="row pb-3">
                <v-col v-for="(worker, key) in workers" :key="key" align="center" cols="6">
                  <div class="purple darken-3 worker">
                    <p class="white--text worker__text mb-0">{{ $t('settings.worker_spec') }}</p>
                    <v-icon size="25" class="worker__icon" dark>mdi-robot</v-icon>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" size="25" @click="deleteWoker" class="worker__icon2" dark>mdi-close</v-icon>
                      </template>
                      <span>{{ $t('settings.delete_worker') }}</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </transition-group>
              <v-spacer />
              <div class="non_field_errors">{{ zeroWorkers }}</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="mx-2" @click="addWorker" small fab dark color="green">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('settings.add_worker') }}</span>
              </v-tooltip>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pt-2">
        <v-col cols="12" class="px-0">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title>{{ $t('tasks.name') }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left table-header">#</th>
                      <th class="text-left table-header">{{ $t('tasks.story_name') }}</th>
                      <th class="text-left table-header">{{ $t('tasks.type') }}</th>
                      <th class="text-left table-header">{{ $t('tasks.cpu') }}</th>
                      <th class="text-left table-header">{{ $t('tasks.remainning_time') }}</th>
                      <th class="text-left table-header"></th>
                      <th class="text-left table-header"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(task, key) in tasks" :key="`task-${key}`">
                      <td>{{ key + 1 }}</td>
                      <td>{{ task.story_name }}</td>
                      <td>{{ $t(`tasks.type_list[${task.type}]`) }}</td>
                      <td>{{ task.cpu }}</td>
                      <td>{{ task.trainning_time }}</td>
                      <td>
                        <v-progress-circular v-if="task.active" :size="30" :width="7" indeterminate color="green"></v-progress-circular>
                      </td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" size="35" color="red" @click="stopTask(task.id, task.story_id)">mdi-stop-circle-outline</v-icon>
                          </template>
                          <span>{{ $t('tasks.stop_task') }}</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogPassword" width="500">
      <change-password @update="dialogPassword = false"></change-password>
    </v-dialog>
    <v-dialog v-model="dialogUsername" width="500">
      <change-username @update="dialogUsername = false"></change-username>
    </v-dialog>
    <v-dialog v-model="dialogCompany" width="500">
      <change-company @update="dialogCompany = false"></change-company>
    </v-dialog>
    <v-dialog v-model="dialogAddUser" width="500">
      <add-user
        @update="
          dialogAddUser = false;
          getUsers();
        "
      ></add-user>
    </v-dialog>
  </v-main>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';
import { required } from 'vuelidate/lib/validators';
import ChangePassword from '@/views/auth/ChangePassword.vue';
import ChangeUsername from '@/views/auth/ChangeUsername.vue';
import ChangeCompany from '@/views/auth/ChangeCompany.vue';
import AddUser from '@/views/auth/AddUser.vue';

export default {
  name: 'Settings',
  mixins: [baseMixin],
  components: { ChangePassword, ChangeUsername, ChangeCompany, AddUser },
  data() {
    return {
      dialogPassword: false,
      dialogUsername: false,
      dialogCompany: false,
      dialogAddUser: false,
      showUsername: false,
      showCompany: false,
      users: [],
      anaplan_login: '',
      anaplan_password: '',
      anaplan_show: false,
      workers: [],
      serverErrors: {},
      tasks: []
    };
  },
  validations: {
    anaplan_login: {
      required
    },
    anaplan_password: {
      required
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    company() {
      return this.$store.state.company;
    },
    anaplanLoginMessage() {
      if (this.$v.anaplan_login.$error || this.getError('anaplan_login') !== '') {
        if (!this.$v.anaplan_login.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.anaplan_login.$error && this.getError('anaplan_login') !== '') {
          return [this.getError('anaplan_login')];
        }
      }
      return [];
    },
    anaplanPasswordMessage() {
      if (this.$v.anaplan_password.$error || this.getError('anaplan_password') !== '') {
        if (!this.$v.anaplan_password.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.anaplan_password.$error && this.getError('anaplan_password') !== '') {
          return [this.getError('anaplan_password')];
        }
      }
      return [];
    },
    workerLen() {
      return this.workers.length;
    },
    zeroWorkers() {
      return this.getError('zero_workers');
    }
  },
  created() {
    this.getUsers();
    // this.getWorkers();
    // this.$ws.webSocket.onmessage = (e) => {
    //   let data = JSON.parse(e.data);
    //   let array = data.message.split('|');
    //   if (['Story trained', 'story_time_elapsed', 'Forecast prepared', 'task_started'].includes(array[0])) {
    //     this.getWorkers();
    //   }
    // };
  },
  methods: {
    getUsers() {
      this.$fetch.get('/api/users').then(data => {
        this.users = data['results'];
      });
    },
    updateAnaplan() {
      this.$v.$touch();
      this.serverErrors = {};
      if (!this.$v.$invalid) {
        this.$fetch
          .put(`/api/companies/${this.user.companyId}`, { data: {login: this.anaplan_login, password: this.anaplan_password }})
          .then(() => {
            this.$notify.success({ message: this.$t('notifications.compnay_anaplan_updated') });
          })
          .catch(error => {
            this.serverErrors = error;
          });
      }
    },
    addWorker() {
      this.serverErrors = {};
      this.$fetch
        .put('company/worker/')
        .then(() => {
          this.getWorkers();
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    deleteWoker() {
      this.serverErrors = {};
      this.$fetch
        .delete('company/worker/')
        .then(() => {
          this.getWorkers();
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    getWorkers() {
      this.$fetch.get('tasks/')
        .then((data) => {
          this.workers = [];
          for (let i = 1; i <= data.workers; i++) {
            this.workers.push(i);
          }
          let tasks = [];
          data.tasks.forEach((task) => {
            let adjustedTask = task;
            let size = this.$numeral(adjustedTask.trainning_time * 1).format('0,0');
            if (task.active) {
              adjustedTask.trainning_time = size + this.$t('story.list.minutes');
            } else {
              adjustedTask.trainning_time = this.$t('tasks.queue') + '(' + size + this.$t('story.list.minutes') + ')';
            }
            tasks.push(adjustedTask);
          });
          this.tasks = tasks;
        });
    },
    stopTask(id, storyId) {
      this.$fetch.post('task/stop/', { id, storyId })
        .then(() => {
          this.getWorkers();
        });
    },
  }
};
</script>
