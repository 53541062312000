<template>
  <v-main>
    <v-container fluid id="home">
      <v-row justify="start" class="mb-2 header-fixed-height pt-3">
        <v-btn icon color="primary" @click="$router.push('/connections')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <h2>{{ name }}</h2>
      </v-row>
      <v-row class="d-flex align-center">
        <v-btn color="primary" class="ml-2" v-if="id == 0" @click="save" outlined>{{ $t('buttons.save') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="id != 0" @click="update" outlined>{{ $t('buttons.update') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="connectionTypes.value in [1, 3]" @click="testConnection" outlined>{{ $t('buttons.test_connection') }}</v-btn>
        <v-btn color="warning" class="ml-2" v-if="id != 0" @click="deleteConnection" outlined>{{ $t('buttons.delete') }}</v-btn>
      </v-row>
      <v-row justify="start" class="mt-8">
        <v-col cols="6" class="py-0 px-2">
          <v-select :items="connectionTypes.list" v-model="connectionTypes.value" :error-messages="connectionTypeMessage" outlined dense :label="$t('connections.detail.connection_type')"></v-select>
        </v-col>
        <v-col cols="6" class="py-0 px-2">
          <v-text-field :label="$t('connections.detail.name')" outlined dense v-model="name" :error-messages="nameMessage" type="text" />
        </v-col>
      </v-row>
      <v-divider v-if="connectionTypes.value != ''"></v-divider>
      <v-row justify="start" class="pt-7" v-if="connectionTypes.value == 1">
        <v-col cols="6" class="py-0 px-2">
          <v-text-field :label="$t('connections.detail.anaplan.login')" outlined dense v-model="anaplan.login" :error-messages="loginMessage" type="text" />
          <v-text-field :label="$t('connections.detail.anaplan.password')" outlined dense v-model="anaplan.password" :error-messages="passwordMessage" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" />
          <v-select :items="anaplanWorkspaces" v-model="anaplan.workspaceId" :error-messages="workspaceIdMessage" @change="changeAnaplanWorkspace" outlined dense label="Workspace"></v-select>
          <v-select :items="anaplanModels" v-model="anaplan.modelId" :error-messages="modelIdMessage" outlined dense label="Model"></v-select>
          <div class="non_field_errors">{{ badResponse }}</div>
        </v-col>
      </v-row>
      <v-row justify="start" class="pt-7" v-else-if="connectionTypes.value >= 3 && connectionTypes.value <= 15">
        <v-col cols="6" class="py-0 px-2">
          <v-text-field :label="$t('connections.detail.postgres.host')" outlined dense v-model="postgres.host" :error-messages="hostMessage" type="text" />
          <v-text-field :label="$t('connections.detail.postgres.port')" outlined dense v-model="postgres.port" :error-messages="portMessage" type="text" />
          <v-text-field :label="$t('connections.detail.postgres.database')" outlined dense v-model="postgres.database" :error-messages="databaseMessage" type="text" />
          <v-text-field :label="$t('connections.detail.postgres.user')" outlined dense v-model="postgres.user" :error-messages="userMessage" type="text" />
          <v-text-field :label="$t('connections.detail.postgres.password')" outlined dense v-model="postgres.password" :error-messages="passwordMessage" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" />
        </v-col>
      </v-row>
      <v-row justify="start" class="pt-7" v-else-if="connectionTypes.value == 16">
        <v-col cols="6" class="py-0 px-2">
          <v-text-field :label="$t('connections.detail.optimacros.host')" outlined dense v-model="optimacros.host" :error-messages="hostMessage" type="text" />
          <v-text-field :label="$t('connections.detail.optimacros.token')" outlined dense v-model="optimacros.token" :error-messages="tokenMessage" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'" @click:append="show2 = !show2"/>
          <v-text-field :label="$t('connections.detail.optimacros.user')" outlined dense v-model="optimacros.user" :error-messages="userMessage" type="text" />
          <v-text-field :label="$t('connections.detail.optimacros.password')" outlined dense v-model="optimacros.password" :error-messages="passwordMessage" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" />
        </v-col>
      </v-row>
      <v-row class="pt-7" v-else-if="connectionTypes.value == 2 && id != 0">
        <v-row class="d-flex align-center pt-2">
          <v-btn color="primary" class="ml-2" @click="onUploadButtonClick" :loading="load" outlined>{{ $t('buttons.upload_new') }}</v-btn>
          <v-btn color="primary" class="ml-2" @click="showCreateEmptyFile = true" outlined>{{ $t('buttons.create_new_empty_file') }}</v-btn>
          <input v-show="false" ref="upload_csv" type="file" @change="uploadCSV" >
          <div class="non_field_errors pl-3">{{ CSVFileMessage }}</div>
        </v-row>
        <v-col cols="12" class="px-0 pt-6">
          <v-simple-table fixed-header :height="`${windowHeight - 300}px`">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <p class="table-header grey--text">#</p>
                  </th>
                  <th class="text-left">
                    <p class="table-header">{{ $t('connections.detail.csv.file_name') }}</p>
                  </th>
                  <th class="text-left">
                    <p class="table-header">{{ $t('connections.detail.csv.size') }}</p>
                  </th>
                  <th class="text-left">
                    <p class="table-header">{{ $t('connections.detail.csv.rows') }}</p>
                  </th>
                  <th class="text-left">
                    <p class="table-header">{{ $t('connections.detail.csv.updated_at') }}</p>
                  </th>
                  <th class="text-left">
                    <p class="table-header"></p>
                  </th>
                  <th class="text-left">
                    <p class="table-header"></p>
                  </th>
                  <th class="text-left">
                    <p class="table-header"></p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, key1) in csv_files" class="cursor" :key="key1">
                  <td class="grey--text left-align">{{ key1 + 1 }}</td>
                  <td class="left-align">{{ row.name }}</td>
                  <td class="left-align">{{ row.size }}</td>
                  <td class="left-align">{{ row.rows }}</td>
                  <td class="left-align">{{ row.updatedAt }}</td>
                  <td>
                    <v-btn color="primary" @click="onUpdateButtonClick(row.id)" outlined>{{ $t('buttons.update') }}</v-btn>
                    <input v-show="false" :ref="`upload_csv_${row.id}`" type="file" @change="updateCSV($event, row.id)" >
                  </td>
                  <td><v-btn color="primary" @click="download(row.id, row.name)" outlined>{{ $t('buttons.download') }}</v-btn></td>
                  <td><v-btn color="warning" @click="deleteFile(row.id)" outlined>{{ $t('buttons.delete') }}</v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-dialog v-model="showCreateEmptyFile" max-width="500">
        <create-empty-file :id="id" @update="closeDialog"></create-empty-file>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';
import CreateEmptyFile from '@/views/connections/CreateEmptyFile.vue';

export default {
  name: 'Connection',
  mixins: [baseMixin],
  components: { CreateEmptyFile },
  data() {
    return {
      id: this.$route.params.id * 1,
      load: false,
      name: this.$t('connections.detail.new'),
      show: false,
      show2: false,
      showCreateEmptyFile: false,
      connectionTypes: {
        list: [
          { text: this.$t('connections.detail.choose'), value: '' },
          { text: 'Anaplan', value: 1 },
          { text: 'CSV', value: 2 },
          { text: 'PostgreSQL', value: 3 },
          { text: 'Oracle', value: 4 },
          { text: 'MySQL', value: 5 },
          { text: 'MS SQL', value: 6 },
          { text: 'SAP', value: 7 },
          { text: '1C Предприятие', value: 8 },
          { text: 'Tableau', value: 9 },
          { text: 'Salesforce', value: 10 },
          { text: 'REST API', value: 11 },
          { text: 'FTP', value: 12 },
          { text: 'Excel', value: 13 },
          { text: 'Amazon S3', value: 14 },
          { text: 'Google Cloud Storage', value: 15 },
          { text: 'Optimacros', value: 16 }
        ],
        value: '',
      },
      anaplan: {
        login: '',
        password: '',
        workspaceId: '',
        modelId: ''
      },
      csv: {},
      postgres: {
        host: '',
        port: '',
        database: '',
        user: '',
        password: ''
      },
      optimacros: {
        host: '',
        token: '',
        user: '',
        password: ''
      },
      anaplanWorkspaces: [],
      anaplanModels: [],
      csv_files: [],
      csv_file: undefined,
      csv_file_update: undefined,
      file_name: ''
    };
  },
  computed: {
    badResponse() {
      return this.getError('detail') !== '' ? [this.getError('detail')] : '';
    },
    nameMessage() {
      return this.getError('name') !== '' ? [this.getError('name')] : [];
    },
    connectionTypeMessage() {
      return this.getError('connectionType') !== '' ? [this.getError('connectionType')] : [];
    },
    loginMessage() {
      return this.getError('login') !== '' ? [this.getError('login')] : [];
    },
    passwordMessage() {
      return this.getError('password') !== '' ? [this.getError('password')] : [];
    },
    workspaceIdMessage() {
      return this.getError('workspaceId') !== '' ? [this.getError('workspaceId')] : [];
    },
    modelIdMessage() {
      return this.getError('modelId') !== '' ? [this.getError('modelId')] : [];
    },
    hostMessage() {
      return this.getError('host') !== '' ? [this.getError('host')] : [];
    },
    portMessage() {
      return this.getError('port') !== '' ? [this.getError('port')] : [];
    },
    databaseMessage() {
      return this.getError('database') !== '' ? [this.getError('database')] : [];
    },
    tokenMessage() {
      return this.getError('token') !== '' ? [this.getError('token')] : [];
    },
    userMessage() {
      return this.getError('user') !== '' ? [this.getError('user')] : [];
    },
    CSVFileMessage() {
      return this.getError('csvFile');
    }
  },
  created() {
    window.addEventListener('resize', this.$lodash.debounce(this.getWindowHeight, 500));
    this.getWindowHeight();
    if (this.id !== 0) {
      this.$fetch.get(`api/connections/${this.id}`).then(data => {
        this.name = data.name;
        this.connectionTypes.value = data.connectionType;
        if (data.connectionType == 1) {
          this.anaplan = data.params;
          this.getAnaplanWorkspaces();
          this.changeAnaplanWorkspace();
        } else if (data.connectionType == 2) {
          this.getCSVFiles();
        } else if (data.connectionType == 3) {
          this.postgres = data.params;
        } else if (data.connectionType == 16) {
          this.optimacros = data.params;
        }
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$lodash.debounce(this.getWindowHeight, 500));
  },
  methods: {
    getWindowHeight() {
      this.windowHeight = document.documentElement.clientHeight;
    },
    save() {
      this.serverErrors = {};
      let data = {name: this.name, connection_type: this.connectionTypes.value, params: {}};
      if (data['connection_type'] == 1) {
        data['params'] = this.anaplan;
      } else if (data['connection_type'] == 16) {
        data['params'] = this.optimacros;
      } else if (data['connection_type'] >= 3) {
        data['params'] = this.postgres;
      }
      this.$fetch
        .post('api/connections', data)
        .then(() => {
          this.$router.push('/connections');
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    update() {
      this.serverErrors = {};
      let data = {name: this.name, connection_type: this.connectionTypes.value, params: {}};
      if (data['connection_type'] == 1) {
        data['params'] = this.anaplan;
      } else if (data['connection_type'] == 16) {
        data['params'] = this.optimacros;
      } else if (data['connection_type'] >= 3) {
        data['params'] = this.postgres;
      }
      this.$fetch
        .put(`api/connections/${this.id}`, data)
        .then(() => {
          this.$router.push('/connections');
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    testConnection() {
      this.serverErrors = {};
      if (this.connectionTypes.value == 1) {
        this.testAnaplan();
      } else if (this.connectionTypes.value == 3) {
        let params = this.postgres;
      }
    },
    async testAnaplan() {
      let params = {login: this.anaplan.login, password: this.anaplan.password};
      await this.$fetch.post('/api/test/connection', params)
        .then(() => {
          this.$notify.success({
            message: this.$t('notifications.test_connection_success')
          });
        })
        .catch(error => {
          this.serverErrors = error;
          this.$notify.error({
            message: this.$t('notifications.test_connection_failure')
          });
        });
      await this.getAnaplanWorkspaces();
    },
    async getAnaplanWorkspaces() {
      let params = {login: this.anaplan.login, password: this.anaplan.password};
      await this.$fetch.post('/api/anaplan/workspaces', params)
        .then(data => {
          this.anaplanWorkspaces = [{text: this.$t('connections.detail.choose'), value: ''}];
          data.forEach(value => {
            this.anaplanWorkspaces.push({text: value.name, value: value.id});
          })
        })
        .catch(error => {
          this.serverErrors = error;
        });  
    },
    async changeAnaplanWorkspace() {
      if (this.anaplan.workspaceId != '') {
        let params = {login: this.anaplan.login, password: this.anaplan.password};
        await this.$fetch.post(`/api/anaplan/workspaces/${this.anaplan.workspaceId}/models`, params)
          .then(data => {
            this.anaplanModels = [{text: this.$t('connections.detail.choose'), value: ''}];
            data.forEach(value => {
              this.anaplanModels.push({text: value.name, value: value.id});
            })
          })
          .catch(error => {
            this.serverErrors = error;
          });
      }
    },
    onUploadButtonClick() {
      this.$refs.upload_csv.click();
    },
    uploadCSV(e) {
      this.csv_file = e.target.files[0]
      this.serverErrors = {};
      if (this.csv_file != undefined) {
        this.load = true;
        let formData = new FormData();
        formData.append('csv_file', this.csv_file);
        this.$fetch
          .post(`/api/csv/${this.id}/upload`, formData)
          .then(() => {
            this.getCSVFiles();
            this.load = false;
          })
          .catch(error => {
            this.serverErrors = error;
            this.load = false;
          });
      }
    },
    onUpdateButtonClick(file_id) {
      this.$refs[`upload_csv_${file_id}`][0].click();
    },
    updateCSV(e, file_id) {
      this.csv_file_update = e.target.files[0]
      this.serverErrors = {};
      if (this.csv_file_update != undefined) {
        let formData = new FormData();
        formData.append('csv_file', this.csv_file_update);
        this.$fetch
          .put(`/api/csv/${this.id}/file/${file_id}`, formData)
          .then(() => {
            this.getCSVFiles();
          })
          .catch(error => {
            this.serverErrors = error;
          });
      }
    },
    getCSVFiles() {
      this.serverErrors = {};
      this.$fetch
        .get(`/api/csv/${this.id}`)
        .then(data => {
          let files = [];
          data.forEach(value => {
            files.push({
              id: value.id,
              name: value.name,
              size: this.$numeral(value.size / 1024 / 1024).format('0,0.0') + this.$t('connections.detail.csv.mb'),
              rows: this.$numeral(value.rows).format('0,0'),
              updatedAt: this.$date.format(this.$date.parseISO(value.updatedAt), 'dd.MM.yyyy HH:mm'),
            });
          });
          this.csv_files = files;
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    deleteFile(file_id) {
      this.serverErrors = {};
      this.$fetch
        .delete(`/api/csv/${this.id}/file/${file_id}`)
        .then(() => {
          this.getCSVFiles();
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    download(file_id, file_name) {
      this.serverErrors = {};
      this.$fetch
        .download(`/api/csv/${this.id}/download/${file_id}`)
        .then(data => {
          const url = URL.createObjectURL(
            new Blob([data], {
              type: 'text/csv'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file_name);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    closeDialog() {
      this.showCreateEmptyFile = false;
      this.getCSVFiles();
    },
    deleteConnection() {
      this.serverErrors = {};
      this.$fetch
        .delete(`api/connections/${this.id}/`)
        .then(() => {
          this.$router.push('/connections');
        })
        .catch(error => {
          this.serverErrors = error;
        });
    }
  }
};
</script>