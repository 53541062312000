<template>
  <v-main>
    <v-container fluid id="home">
      <v-row justify="start" v-if="!$route.path.includes('anaplan')">
        <v-btn icon color="primary" @click="id == 0 ? $router.push('/stories') : (ready === 2 || ready === 3) ? $router.push('/stories') : $router.push(`/story/forecast/${id}`)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <h2>{{ name }}</h2>
      </v-row>
      <v-row v-if="success_load && $route.path.includes('anaplan')" class="mt-2">
        <h2 class="pr-2 success--text">{{ $t('integration.export_success') }}</h2>
      </v-row>
      <v-row v-if="!success_load && $route.path.includes('anaplan')" class="mt-2">
        <v-col cols="6" class="pa-0">
          <v-row justify="start" align="center">
            <img height="30" class="pr-4" src="../../img/anaplan_logo.svg" />
            <h2 class="pr-2">{{ $t('integration.export') }}</h2>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-information</v-icon>
              </template>
              <v-card-text>
                <p class="text-left mb-0">Anaplan Workspace ID: {{ workspaceID }}</p>
                <p class="text-left mb-0">Anaplan Model ID: {{ modelID }}</p>
                <p class="text-left mb-0">Anaplan Export Name: {{ exportName }}</p>
                <p class="text-left mb-0">{{ $t('integration.token') }} Brain2Logic: {{ token }}</p>
              </v-card-text>
            </v-tooltip>
          </v-row>
        </v-col>
        <v-col cols="6" class="pa-0">
          <v-row justify="end" align="center">
            <v-icon v-if="status == 'success'" size="40" color="green">mdi-check-circle</v-icon>
            <v-icon v-else-if="status == 'error'" size="40" color="red">mdi-alert</v-icon>
            <v-progress-circular v-else-if="status == 'progress'" size="40" :width="7" indeterminate color="warning"></v-progress-circular>
            <span :class="{ progress: 'warning--text', success: 'green--text', error: 'red--text' }[status]">{{ $t(`integration.${status}`) + (status == 'error' ? getStatusError : '') }}</span>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="start" class="mt-6">
        <v-btn color="primary" class="ml-2" v-if="!$route.path.includes('anaplan')" outlined :loading="load" @click="train">{{ id == 0 ? $t('buttons.train') : $t('buttons.retrain') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="!$route.path.includes('anaplan')" outlined @click="showSettings = true">{{ $t('buttons.settings') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="!success_load && $route.path.includes('anaplan')" outlined :loading="load" @click="train">{{ id ? $t('buttons.retrain') : $t('buttons.train') }}</v-btn>
        <v-btn color="primary" v-if="!success_load && $route.path.includes('anaplan')" class="ml-2" :loading="load_update" outlined @click="updateSource">{{ $t('buttons.update') }}</v-btn>
        <v-btn color="primary" v-if="!success_load && $route.path.includes('anaplan')" class="ml-2" outlined @click="showSettings = true">{{ $t('buttons.settings') }}</v-btn>
        <div v-if="!success_load">
          <v-btn color="primary" class="ml-2" @click="showComment = true" fab small outlined><v-icon>mdi-information-variant</v-icon></v-btn>
          <div v-if="commentMessage.length > 0" style="font-size: 12px; color: #ff5252 !important; width: 150px; line-height: 12px;">{{ commentMessage[0] }}</div>
        </div>
        <div v-if="ready === 2 || ready === 3">
          <v-tooltip bottom >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="40" color="red">mdi-alert</v-icon>
            </template>
            <v-card-text>
              <p class="text-left mb-0" v-if="ready === 2">{{ train_errors }}</p>
              <p class="text-left mb-0" v-if="ready === 3">{{ $t('story.list.error_stop') }}</p>
            </v-card-text>
          </v-tooltip>
        </div>
      </v-row>
      <v-row class="">
        <v-col cols="3" class="px-2 pb-0" v-if="!$route.path.includes('anaplan')">
          <v-text-field :label="$t('story.detail.name')" outlined dense v-model="name" :error-messages="nameMessage" type="text" />
        </v-col>
        <v-col cols="3" class="px-2 pb-0" v-if="!$route.path.includes('anaplan')">
          <v-select :items="sources.list" v-model="sources.value" :placeholder="$t('story.detail.choose')" :error-messages="sourceMessage" @change="getTable" outlined dense :label="$t('story.detail.source')"></v-select>
        </v-col>
        <v-col cols="3" class="px-2 pb-0" v-if="!success_load">
          <v-select :items="forecastTypeList" v-model="forecastType" @change="changeForecastType" outlined dense :label="$t('story.detail.forecastType')"></v-select>
        </v-col>
        <v-col cols="3" class="px-2 pb-0" v-if="!success_load">
          <v-select :items="modelsList" v-model="models" @change="modelSelection" dense attach outlined multiple :label="$t('story.detail.models')">
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0">{{ item.text }}</span>
              <span v-if="index === 1" class="grey--text caption">(+{{ models.length - 1 }} {{ $t('story.detail.etc') }} )</span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="!success_load">
        <v-col cols="9" class="pt-0 px-0">
          <v-simple-table v-if="!chartActive && !feature_importance.active" fixed-header :height="`${windowHeight - (64 + 16) - 35.2 - 40 - 78}px`">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" v-for="(column, key) in columns" :key="key">
                    <p class="story-fixed-col story-fixed-size">{{ column.name }}</p>
                    <v-select :items="table.typeLists[key]" v-model="column.type" @change="updateChart" class="story-fixed-col" outlined dense :label="$t('story.detail.type')"></v-select>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, key1) in table.values" :key="key1">
                  <td class="text-left" v-for="(value, key2) in row" :key="key2">{{ value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <line-chart v-if="chartActive && !feature_importance.active && forecastType == 'time_series'" ref="chart" :chart-data="datacollection" :size="{ width: chart_width, height: windowHeight - (64 + 16) - 35.2 - 40 - 78}" :dates-type="datesType"></line-chart>
          <bar-chart v-if="feature_importance.active && ['regression', 'classification'].includes(forecastType)" ref="chart" :labels="feature_importance.columns" :scores="feature_importance.scores" :size="{ width: chart_width, height: windowHeight - (64 + 16) - 35.2 - 40 - 78}"></bar-chart>
          <!-- <scatter-chart v-if="chartActive && !feature_importance.active && !elbow_method && ['clustering', 'regression', 'classification'].includes(forecastType)" ref="chart" :chart-data="datacollection" :chart-labels="chartLabels" :unique-axis="uniqueAxis" :forecast-type="forecastType" :size="{ width: chart_width, height: windowHeight - (64 + 16) - 35.2 - 40 - 78}"></scatter-chart>
          <scatter-chart v-else-if="elbow_method && forecastType == 'clustering'" ref="chart" :chart-data="elbow_datacollection" :chart-labels="elbow_chartLabels" :unique-axis="[]" :forecast-type="forecastType" :size="{ width: chart_width, height: windowHeight - (64 + 16) - 35.2 - 40 - 78}"></scatter-chart> -->
          <scatter-chart v-if="chartActive && !feature_importance.active && ['clustering', 'regression', 'classification'].includes(forecastType)" ref="chart" :chart-data="datacollection" :chart-labels="chartLabels" :unique-axis="uniqueAxis" :forecast-type="forecastType" :size="{ width: chart_width, height: windowHeight - (64 + 16) - 35.2 - 40 - 78}"></scatter-chart>
          <!-- <scatter-chart v-else-if="elbow_method && forecastType == 'clustering'" ref="chart" :chart-data="elbow_datacollection" :chart-labels="elbow_chartLabels" :unique-axis="[]" :forecast-type="forecastType" :size="{ width: chart_width, height: windowHeight - (64 + 16) - 35.2 - 40 - 78}"></scatter-chart> -->
          <!-- <violin-chart v-if="chartActive && (forecastType == 'clustering' || forecastType == 'regression' || forecastType == 'classification')" ref="chart" :chart-data="datacollection" :chart-labels="chartLabels" :size="{ width: chart_width, height: !$route.path.includes('anaplan') ? windowHeight - (64 + 16) - 35.2 - 40 - 78 - 20 : windowHeight - (64 + 16) - 35.2 - 208 }"></violin-chart> -->
        </v-col>
        <v-col cols="3" class="pt-0" v-if="sources.value !== ''" :style="`overflow-y: auto;height: ${windowHeight - (64 + 16) - 35.2 - 40 - 78}`">
          <p class="text-left mb-0 font-weight-bold">{{ $t('story.detail.desc') }}</p>
          <p class="text-left mb-0">
            {{ $t('story.detail.rows') }}: <span class="font-weight-bold primary--text">{{ sources.rows }}</span>
          </p>
          <p class="text-left mb-0" v-if="!hugeData">
            {{ $t('story.detail.trainning_time') }}: <span class="font-weight-bold primary--text">{{ trainningTime }} {{ $t('story.detail.time') }}</span>
          </p>
          <p class="text-left mb-0" v-if="!hugeData && categoriesValues.values.length > 0">
            {{ $t('story.detail.forecast_quantity') }}: <span class="font-weight-bold primary--text">{{ $numeral(categoriesValues.values.length).format('0,0') }}</span>
          </p>
          <v-switch v-model="chartActive" hide-details :label="$t('story.detail.chart')"></v-switch>
          <v-text-field class="mt-4" v-if="forecastType === 'time_series' && hugeData" :label="$t('story.forecast.steps') + ' (месяц)'" outlined dense v-model="steps" type="text" />
          <div v-if="forecastType == 'time_series' && !hugeData">
            <v-switch hide-details v-model="cleaning.percentile.active" @click="updateChart" :label="$t('story.detail.percentile')"></v-switch>
            <v-slider class="pt-8" hide-details v-if="cleaning.percentile.active" v-model="cleaning.percentile.value" @change="debounceUpdate" :label="$t('story.detail.value')" min="1" max="30" thumb-color="green lighten-1" thumb-label="always"></v-slider>
            <v-switch hide-details v-model="cleaning.rolling.active" @click="updateChart" :label="$t('story.detail.rolling')"></v-switch>
            <v-slider class="pt-8" hide-details v-if="cleaning.rolling.active" v-model="cleaning.rolling.value" @change="debounceUpdate" :label="$t('story.detail.value')" min="2" max="15" thumb-color="green lighten-1" thumb-label="always"></v-slider>
            <v-switch hide-details v-model="cleaning.diff.active" @click="updateChart" :label="$t('story.detail.diff')"></v-switch>
            <v-slider class="pt-8" hide-details v-if="cleaning.diff.active" v-model="cleaning.diff.value" @change="debounceUpdate" :label="$t('story.detail.value2')" min="1" max="2" thumb-color="green lighten-1" thumb-label="always"></v-slider>
            <p class="text-left mb-0" v-if="cleaning.diff.active">
              {{ $t('story.detail.adfuller') + 0 }}: <span class="font-weight-bold primary--text">{{ adfuller1 }}</span>
              <br />
              {{ $t('story.detail.adfuller') + cleaning.diff.value }}: <span class="font-weight-bold primary--text">{{ adfuller2 }}</span>
            </p>
            <v-switch hide-details v-model="cleaning.components" @click="updateChart" :label="$t('story.detail.comp')"></v-switch>
          </div>
          <v-text-field class="mt-4" hide-details v-if="forecastType == 'clustering'" :label="$t('story.detail.clusters')" outlined dense :error-messages="clustersMessage" v-model="clusters" type="text" />
          <v-select class="pt-5" v-if="forecastType === 'regression' && columnsFeaturesRange.length > 0 && columnsFeatures.length >= 1" hide-details :items="columnsFeaturesRange" v-model="axisX.name" @change="columnFeatureUpdate('x')" outlined dense :label="$t('story.detail.axisX')"></v-select>
          <v-switch class="mt-2 mb-3" hide-details v-if="forecastType == 'clustering'" v-model="elbow_method" @click="updateElbow" :label="$t('story.detail.elbow_method')"></v-switch>
          <v-select v-if="forecastType === 'clustering' && columnsFeaturesRange.length > 0 && columnsFeatures.length > 1" hide-details :items="columnsFeaturesRange" v-model="axisX.name" @change="columnFeatureUpdate('x')" outlined dense :label="$t('story.detail.axisX')"></v-select>
          <v-select class="pt-5" v-if="forecastType === 'classification' && columnsFeaturesRange.length > 0 && columnsFeatures.length > 1" hide-details :items="columnsFeaturesRange" v-model="axisX.name" @change="columnFeatureUpdate('x')" outlined dense :label="$t('story.detail.axisX')"></v-select>
          <v-select class="pt-3" v-if="['clustering', 'classification'].includes(forecastType) && columnsFeaturesRange.length > 0 && columnsFeatures.length > 1" hide-details :items="columnsFeaturesRange" v-model="axisY.name" @change="columnFeatureUpdate('y')" outlined dense :label="$t('story.detail.axisY')"></v-select>
          <div v-if="['regression', 'classification'].includes(forecastType)">
            <v-switch hide-details class="mt-0" v-model="feature_importance.active" @click="updateFeatureImportance" :label="$t('story.detail.feature_importance')"></v-switch>
            <v-select class="pt-3" v-if="feature_importance.active" hide-details @change="updateFeatureImportance" :items="feature_importance.list" v-model="feature_importance.value" outlined dense :label="$t('story.detail.feature_algorithm')"></v-select>
          </div>
          <p class="text-left pt-3" v-if="categories.length > 0">{{ $t('story.detail.analytics') }}</p>
          <v-select v-for="(category, key) in categories" :key="key" :items="searchCategories[key]" v-model="category.values"
            @change="categorySelection(key); updateChart();" :placeholder="$t('story.detail.choose')" attach outlined multiple dense :label="category.name">
            <template v-slot:prepend-item v-if="category.list.length > 20">
              <v-list-item>
                <v-text-field :label="$t('story.detail.search')" @blur="blur(key)" @keyup.native="onKeyUp(key)" :hide-details="true" v-model="searchInput" outlined dense type="text" class="pb-1" />
              </v-list-item>
              <v-divider></v-divider>
            </template>
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0">{{ item.text }}</span>
              <span v-if="index === 1" class="grey--text caption">(+{{ category.values.length - 1 }} {{ $t('story.detail.etc') }} )</span>
            </template>
          </v-select>
          <p class="text-left mt-3" v-if="featuresMaps.length > 0">{{ $t('story.detail.features_maps') }}</p>
          <v-btn v-for="(feature, key) in featuresMaps" :key="`feature${key}`" class="mb-2" block color="primary" @click="chooseFeature(feature)">{{ feature.name }}</v-btn>
          <p class="text-left" v-if="featuresNominal.length > 0">{{ $t('story.detail.features_maps_nominal') }}</p>
          <v-select v-for="(feature, key) in featuresNominal" :key="`nominal${key}`" :items="feature.values" outlined dense :label="feature.name"></v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="showSettings">
      <!-- <change-settings :settings="settings" :categories="categoriesValues" :forecast-type="forecastType" @update="setSettings"></change-settings> -->
      <!-- <change-settings :settings="settings" :forecast-type="forecastType" @update="setSettings"></change-settings> -->
      <change-settings :settings="settings" :cleaning="cleaning" :forecast-type="forecastType" :models="models"></change-settings>
    </v-dialog>
    <v-dialog v-model="showFeatures">
      <change-features :active-feature="active_feature" @update="setFeatures"></change-features>
    </v-dialog>
    <v-dialog v-model="showComment">
      <change-comment :comment="comment" @update="setComment"></change-comment>
    </v-dialog>
  </v-main>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';
// import trainMixin from '@/mixins/trainMixin';
import { required, maxLength, integer } from 'vuelidate/lib/validators';
import LineChart from '@/components/LineChart.vue';
import ScatterChart from '@/components/ScatterChart.vue';
import BarChart from '@/components/BarChart.vue';
// import ViolinChart from '@/components/ViolinChart.vue';

import ChangeSettings from '@/views/story/ChangeSettings.vue';
import ChangeFeatures from '@/views/story/ChangeFeatures.vue';
import ChangeComment from '@/views/story/ChangeComment.vue';
import Fuse from 'fuse.js';

const nameFormat = name => /^[A-zА-я0-9 _-]+$/.test(name);

export default {
  name: 'StoryTrain',
  mixins: [baseMixin],
  // components: { LineChart, ScatterChart, ViolinChart, ChangeSettings, ChangeFeatures, ChangeAnalytics },
  components: { LineChart, ScatterChart, BarChart, ChangeSettings, ChangeFeatures, ChangeComment },
  data() {
    return {
      status: 'progress',
      id: 0,
      ready: 1,
      train_errors: '',
      token: '',
      workspaceID: '',
      modelID: '',
      exportName: '',
      name: this.$t('story.detail.new'),
      sources: {
        list: [],
        value: '',
        rows: ''
      },
      categories: [],
      categoriesValues: {
        columns: [],
        values: []
      },
      features: [],
      active_feature: {},
      success_load: false,
      datacollection: null,
      chartLabels: [],
      chartActive: false,
      load: false,
      load_update: false,
      datesType: '',
      cleaning: {
        percentile: {
          active: false,
          value: 10,
        },
        rolling: {
          active: false,
          value: 3,
        },
        diff: {
          active: false,
          value: 1,
          adfuller1: 0,
          adfuller2: 0,
        },
        components: false,
        dimensionality_reduction: {
          active: false,
          types: [
            { text: this.$t('story.detail.dr_types[0]'), value: 'PCA' },
            { text: this.$t('story.detail.dr_types[1]'), value: 'KernelPCA' },
            { text: this.$t('story.detail.dr_types[2]'), value: 'LDA' }
          ],
          value: 'PCA',
          dimensions: 2,
          sourceDimensions: 0,
          explained_variance: [],
        },
        test_size: {
          list: [],
          value: 0.25,
          menu: false,
          date: new Date().toISOString().substr(0, 10),
          unique_dates: []
        },
        accuracy_score: {
          list: [],
          value: 'rmse'
        },
        target_scaling: {
          value: false
        },
        train_accuracy: {
          value: false
        },
        eliminate_negative_values: {
          value: false
        },
        scaler: {
          list: ['MinMax Scaler', 'Standard Scaler', 'Robust Scaler'],
          value: 'Standard Scaler'
        },
        time_features: {
          list: [
            { text: this.$t('story.detail.feature_month'), value: 'month' },
            { text: this.$t('story.detail.feature_year'), value: 'year' },
            { text: this.$t('story.detail.feature_day_of_week'), value: 'day_of_week' },
            { text: this.$t('story.detail.feature_week'), value: 'week' },
            { text: this.$t('story.detail.feature_last_month_lag'), value: 'last_month_lag' },
            { text: this.$t('story.detail.feature_3_last_month_lag'), value: '3_last_month_lag' },
            { text: this.$t('story.detail.feature_12_last_month_lag'), value: '12_last_month_lag' },
            { text: this.$t('story.detail.feature_last_week_lag'), value: 'last_week_lag' },
            { text: this.$t('story.detail.feature_2_last_week_lag'), value: '2_last_week_lag' },
            { text: this.$t('story.detail.feature_4_last_week_lag'), value: '4_last_week_lag' },
            { text: this.$t('story.detail.feature_prior_1_year_lag'), value: 'prior_1_year_lag' },
            { text: this.$t('story.detail.feature_prior_2_year_lag'), value: 'prior_2_year_lag' }
          ],
          values: []
        },
        cross_validation: {
          active: false,
          folds: 3,
          list: [3, 4, 5, 6, 7]
        },
        probability_threshold: {
          active: false,
          value: 0.7,
        },
        hyperparameter_tuning: {
          active: false,
          list: [
            { text: 'Hyperopt', value: 'Hyperopt' },
            { text: 'Optuna', value: 'Optuna' },
            { text: 'Ax', value: 'Ax' },
            { text: 'Dragonfly', value: 'Dragonfly' },
            { text: 'Bayesian Optimization', value: 'Bayesian Optimization' },
            { text: 'BOHB', value: 'BOHB' },
            { text: 'Nevergrad', value: 'Nevergrad' },
            { text: 'ZOOpt', value: 'ZOOpt' },
            { text: 'Random Search', value: 'Random Search' }
          ],
          value: 'Hyperopt',
          iterations_values: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
          iterations: 15
        },
        cpu: {
          value: 1,
          list: []
        }
      },
      feature_importance: {
        active: false,
        list: [],
        value: 'LogR',
        scores: [],
        columns: []
      },
      windowHeight: 0,
      chart_width: 0,
      forecastType: 'time_series',
      forecastTypeList: [
        { text: this.$t('story.detail.time_series'), value: 'time_series' },
        { text: this.$t('story.detail.regression'), value: 'regression' },
        { text: this.$t('story.detail.clustering'), value: 'clustering' },
        { text: this.$t('story.detail.classification'), value: 'classification' }
      ],
      table: {
        columns: [],
        values: [],
        typeLists: [],
        typeValues: []
      },
      columns: [],
      models: [],
      modelsList: [],
      showSettings: false,
      showFeatures: false,
      // settingsBase: {},
      settings: {},
      searchInput: '',
      searchCategories: [],
      clusters: 3,
      axisX: {
        name: '',
        type: '',
      },
      axisY: {
        name: '',
        type: '',
      },
      uniqueAxis: [],
      showComment: false,
      comment: '',
      elbow_method: false,
      // sum_of_squared_distances: [],
      elbow_chartLabels: [
        { name: this.$t('story.detail.elbow_x'), type: 'numerical' },
        { name: this.$t('story.detail.elbow_y'), type: 'numerical' }
      ],
      elbow_datacollection: null,
      hugeData: false,
      steps: 1,
    };
  },
  validations: {
    clusters: {
      required,
      integer
    },
    name: {
      required,
      maxLength: maxLength(150),
      nameFormat
    },
    sources: {
      value: {
        required
      }
    },
    cleaning: {
      dimensionality_reduction: {
        dimensions: {
          required,
          integer
        }
      }
    },
    comment: {
      maxLength: maxLength(500)
    },
  },
  computed: {
    // computedDateFormatted() {
    //   if (!this.cleaning.test_size.date) {
    //     return null;
    //   } else {
    //     const [year, month, day] = this.cleaning.test_size.date.split('-');
    //     return `${day}.${month}.${year}`;
    //   }
    // },
    // lang() {
    //   return this.$store.state.lang;
    // },
    clustersMessage() {
      if (this.$v.clusters.$error || this.getError('clusters') !== '') {
        if (!this.$v.clusters.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.clusters.integer) {
          return [this.$t('validations.number')];
        } else if (!this.$v.clusters.$error && this.getError('clusters') !== '') {
          return [this.getError('clusters')];
        }
      }
      return [];
    },
    commentMessage() {
      if (this.$v.comment.$error || this.getError('comment') !== '') {
        if (!this.$v.comment.maxLength) {
          return [this.$t('validations.max_length[0]') + this.$v.comment.$params.maxLength.max + this.$t('validations.max_length[1]')];
        } else if (!this.$v.comment.$error && this.getError('comment') !== '') {
          return [this.getError('comment')];
        }
      }
      return [];
    },
    featuresMaps() {
      return this.features.filter(o => ['binary', 'ordinal'].includes(o.type));
    },
    featuresNominal() {
      return this.features.filter(o => o.type === 'nominal');
    },
    columnsFeatures() {
      return this.columns.filter(o => ['numerical', 'binary', 'ordinal', 'nominal'].includes(o.type));
    },
    columnsFeaturesRange() {
      return this.columns
        .filter(o => ['numerical', 'binary', 'ordinal', 'nominal'].includes(o.type))
        .map(o => {
          return { text: o.name, value: o.name };
        });
    },
    columnForecast() {
      return this.columns.filter(o => o.type === 'forecast').length ? this.columns.filter(o => o.type === 'forecast')[0]['name'] : '';
    },
    trainningTimeNumber() {
      let categories = 1;
      if (this.categoriesValues.values.length > 0) {
        categories = this.categoriesValues.values.length;
      }
      let time = 0;
      if (this.models.includes('best')) {
        time = 3;
      } else {
        this.models.forEach(value => {
          time += {
            SARIMAX: 2,
            HWES: 0.5,
            PROPHET: 0.2,
            XGBOOST: 0.1,
            CATBOOST: 0.1,
            LIGHTGBM: 0.1,
            LSTM: 0.2,
            YTY: 0.1,
            AR: 0.1
            // MA: 0.1
          }[value];
        });
      }
      return categories * time;
    },
    trainningTime() {
      let categories = 1;
      if (this.categoriesValues.values.length > 0) {
        categories = this.categoriesValues.values.length;
      }
      let time = 0;
      if (this.models.includes('best')) {
        time = 3;
      } else {
        this.models.forEach(value => {
          time += {
            SARIMAX: 2,
            HWES: 0.5,
            PROPHET: 0.2,
            XGBOOST: 0.1,
            CATBOOST: 0.1,
            LIGHTGBM: 0.1,
            LSTM: 0.2,
            YTY: 0.1,
            AR: 0.1
            // MA: 0.1
          }[value];
        });
      }
      return categories * time < 1 ? this.$numeral(categories * time).format('0,0.0') : this.$numeral(categories * time).format('0,0');
    },
    nameMessage() {
      if (this.$v.name.$error || this.getError('name') !== '') {
        if (!this.$v.name.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.name.nameFormat) {
          return [this.$t('validations.username_format')];
        } else if (!this.$v.name.maxLength) {
          return [this.$t('validations.max_length[0]') + this.$v.name.$params.maxLength.max + this.$t('validations.max_length[1]')];
        } else if (!this.$v.name.$error && this.getError('name') !== '') {
          return [this.getError('name')];
        }
      }
      return [];
    },
    sourceMessage() {
      if (this.$v.sources.value.$error || this.getError('source') !== '') {
        if (!this.$v.sources.value.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.sources.value.$error && this.getError('source') !== '') {
          return [this.getError('source')];
        }
      }
      return [];
    },
    dimensionsMessage() {
      if (this.$v.cleaning.dimensionality_reduction.dimensions.$error || this.getError('dimensions') !== '') {
        if (!this.$v.cleaning.dimensionality_reduction.dimensions.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.cleaning.dimensionality_reduction.dimensions.integer) {
          return [this.$t('validations.number')];
        } else if (!this.$v.cleaning.dimensionality_reduction.dimensions.$error && this.getError('dimensions') !== '') {
          return [this.getError('dimensions')];
        }
      }
      return [];
    },
    getStatusError() {
      let error;
      if (Object.prototype.hasOwnProperty.call(this.serverErrors, 'detail')) {
        error = 'Brain2Logic - ' + this.serverErrors.detail;
      } else if (Object.prototype.hasOwnProperty.call(this.serverErrors, 'anaplan')) {
        error = this.serverErrors.anaplan;
      } else if (Object.prototype.hasOwnProperty.call(this.serverErrors, 'name')) {
        error = 'Brain2Logic - ' + this.$t('validations.anaplan_name');
      }
      return error;
    }
  },
  mounted() {
    // window.addEventListener('resize', this.$lodash.debounce(this.getWindowHeight, 500));
    window.addEventListener('resize', this.getWindowHeight);
    this.getWindowHeight();
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.$lodash.debounce(this.getWindowHeight, 500));
    window.removeEventListener('resize', this.getWindowHeight);
  },
  created() {
    if (!this.$route.path.includes('anaplan')) {
      this.id = this.$route.params.id * 1;
    } else {
      this.id = false;
      this.token = this.$route.params.token;
      this.workspaceID = this.$route.params.workspaceID;
      this.modelID = this.$route.params.modelID;
      this.exportName = this.$route.params.exportName;
      this.name = this.$route.params.exportName;
      this.$fetch.setToken(this.token);
    }

    this.$fetch.get('/api/stories/settings').then(dataSettings => {
      let settings = {};
      for (let forecastType in dataSettings.settings) {
        settings[forecastType] = {};
        for (let model in dataSettings.settings[forecastType]) {
          settings[forecastType][model] = {};
          for (let parameter in dataSettings.settings[forecastType][model]) {
            let array = [{ text: this.$t('story.detail.default') + '(' + this.$t(`story.detail.${model}.default.${parameter}`) + ')', value: 'default' }];
            dataSettings.settings[forecastType][model][parameter].forEach(value => {
              array.push({ text: value, value });
            });
            settings[forecastType][model][parameter] = {
              list: array,
              values: [{ text: this.$t('story.detail.default') + '(' + this.$t(`story.detail.${model}.default.${parameter}`) + ')', value: 'default' }]
            };
          }
        }
      }
      // this.settingsBase = settings;
      this.settings = settings;

      this.cleaning.cpu.list = [];
      for (let i = 1; i <= dataSettings.workers; i++) {
        if (i === 1) {
          this.cleaning.cpu.list.push({ text: this.$t('story.detail.default') + '(1)', value: 1 });
        } else {
          this.cleaning.cpu.list.push({ text: i, value: i });
        }
      }

      if (!this.$route.path.includes('anaplan')) {
        this.$fetch.get('/api/sources').then(dataSources => {
          let items = [];
          dataSources.forEach(value => {
            items.push({ text: value.name, value: value.id });
          });
          this.sources.list = items;
          if (this.id !== 0) {
            this.$fetch.get(`/api/stories/${this.id}`).then(data => {
              this.getStory(data);
              this.getTable(false);
            });
          } else {
            this.updateColumnTypes(true);
          }
        });
      } else {
        this.$fetch
          .post('/api/anaplan/preview', {
            workspaceID: this.workspaceID,
            modelID: this.modelID,
            exportName: this.exportName
          })
          .then(data => {
            this.status = 'success';
            this.sources.value = data.source_id * 1;
            if (data.story === false) {
              this.id = 0;
              this.getTable(true);
            } else {
              this.id = data.story.id;
              this.getStory(data.story);
              this.getTable(false);
            }
          })
          .catch(error => {
            this.status = 'error';
            this.serverErrors = error;
          });
        // Демо
        // http://localhost/#/anaplan/export/fd9dbd157246f925b790c60a792cca17a5e1583d/8a81b09c603d346301614d2ed7985806/6AE36243ECFA474087DDF36EAE5AF682/Export%20Brain2Logic
        // https://brain2logic.com/#/anaplan/export/0c6055f7b8859b96d5f660c236a76692f44ce27e/8a81b09c603d346301614d2ed7985806/6AE36243ECFA474087DDF36EAE5AF682/Export%20Brain2Logic
        // Stada
        // http://localhost/#/anaplan/export/ed7a0111d7a69c9cfa089c49d8917c78fc8353a0/8a81b09c664b16f50166cbe95b3970e5/BD32C45B89BE40C0873CF8497BF65D95/B2L%20Export%20History
        // https://brain2logic.com/#/anaplan/export/0c6055f7b8859b96d5f660c236a76692f44ce27e/8a81b09c664b16f50166cbe95b3970e5/BD32C45B89BE40C0873CF8497BF65D95/B2L%20Export%20History
        // Promo
        // http://localhost/#/anaplan/export/ed7a0111d7a69c9cfa089c49d8917c78fc8353a0/8a81b09c603d346301614d2ed7985806/76A8D79D11464845AD5E3B2EC2CC8EF5/B2L%20History%20Export
        // https://brain2logic.com/#/anaplan/export/0c6055f7b8859b96d5f660c236a76692f44ce27e/8a81b09c603d346301614d2ed7985806/76A8D79D11464845AD5E3B2EC2CC8EF5/B2L%20History%20Export
      }
    });
  },
  methods: {
    getTable(trigger = true) {
      this.$fetch.get(`/api/sources/${this.sources.value}`).then(data => {
        this.table.columns = data.columns;
        this.table.values = data.values;
        this.updateColumnTypes(trigger);
        this.sources.rows = this.$numeral(data.data.rows).format('0,0');
        if ('huge_data' in data.data) {
          this.hugeData = data.data.huge_data;
        } else {
          this.hugeData = false;
        }
        this.updateChart();
      });
    },
    // filterDates(value) {
    //   return this.cleaning.test_size.unique_dates.indexOf(value) !== -1;
    // },
    getStory(data) {
      ({ name: this.name, comment: this.comment, source_id: this.sources.value } = data);
      this.columns = data.data.columns;
      if (data.ready === 2) {
        this.train_errors = data.data.train_errors;
      }
      this.ready = data.ready;
      data.data.categories.forEach(category => {
        this.categories.push({ list: [], values: category['values'], name: category['name'] });
        this.categoriesValues.columns.push(category['name']);
      });
      this.forecastType = data.data.forecastType;
      this.models = data.data.models;
      if (this.forecastType === 'time_series' || this.forecastType === 'regression' || this.forecastType === 'classification') {
        data.data.categories_values.forEach(value => {
          this.categoriesValues.values.push(value);
        });
        for (let model in data.data.settings) {
          for (let param in data.data.settings[model]) {
            if (param in this.settings[this.forecastType][model]) {
              this.settings[this.forecastType][model][param].values = [];
              data.data.settings[model][param].forEach(value1 => {
                let check = false;
                this.settings[this.forecastType][model][param].list.forEach(value2 => {
                  if (value1 === value2.value) {
                    check = true;
                    if (value1 === 'default') {
                      this.settings[this.forecastType][model][param].values.push({ text: this.$t('story.detail.default') + '(' + this.$t(`story.detail.${model}.default.${param}`) + ')', value: 'default' });
                    } else {
                      this.settings[this.forecastType][model][param].values.push({ text: value1, value: value1 });
                    }
                  }
                });
                if (!check) {
                  this.settings[this.forecastType][model][param].values.push(value1);
                }
              });
            }
          }
        }

        if (this.forecastType === 'time_series') {
          this.cleaning.percentile = data.data.cleaning.percentile;
          this.cleaning.rolling = data.data.cleaning.rolling;
          this.cleaning.test_size.value = data.data.cleaning.test_size.value;
          this.cleaning.test_size.date = data.data.cleaning.test_size.date;
          this.cleaning.scaler.value = data.data.cleaning.scaler.value;
          this.cleaning.target_scaling.value = data.data.cleaning.target_scaling.value;
          this.cleaning.train_accuracy.value = data.data.cleaning.train_accuracy.value;
          this.cleaning.accuracy_score.value = data.data.cleaning.accuracy_score.value;
          this.cleaning.eliminate_negative_values.value = data.data.cleaning.eliminate_negative_values.value;
          this.cleaning.time_features.values = data.data.cleaning.time_features.values;
          this.cleaning.cpu.value = data.data.cleaning.cpu.value;
        } else if (this.forecastType === 'regression' || this.forecastType === 'classification') {
          this.cleaning.test_size.value = data.data.cleaning.test_size.value;
          this.cleaning.cross_validation.active = data.data.cleaning.cross_validation.active;
          this.cleaning.cross_validation.folds = data.data.cleaning.cross_validation.folds;
          this.cleaning.scaler.value = data.data.cleaning.scaler.value;
          this.cleaning.accuracy_score.value = data.data.cleaning.accuracy_score.value;
          this.cleaning.cpu.value = data.data.cleaning.cpu.value;
          if (this.forecastType === 'classification') {
            this.cleaning.probability_threshold.active = data.data.cleaning.probability_threshold.active;
            this.cleaning.probability_threshold.value = data.data.cleaning.probability_threshold.value;
            this.cleaning.hyperparameter_tuning.active = data.data.cleaning.hyperparameter_tuning.active;
            this.cleaning.hyperparameter_tuning.value = data.data.cleaning.hyperparameter_tuning.value;
            this.cleaning.hyperparameter_tuning.iterations = data.data.cleaning.hyperparameter_tuning.iterations;
          }
        } else if (this.forecastType === 'clustering') {
          this.cleaning.scaler.value = data.data.cleaning.scaler.value;
          this.cleaning.cpu.value = data.data.cleaning.cpu.value;
        }
        this.features = data.data.features;
      } else {
        this.clusters = data.data.clusters;
        this.features = data.data.features;
      }
    },
    changeForecastType() {
      this.updateColumnTypes(true);
      if (!this.$route.path.includes('anaplan')) {
        this.updateChart();
      }
    },
    updateSource() {
      this.load_update = true;
      this.$fetch
        .patch('/api/anaplan/preview', {
          workspaceID: this.workspaceID,
          modelID: this.modelID,
          exportName: this.exportName
        })
        .then(() => {
          this.getTable(false);
          this.load_update = false;
        })
        .catch(error => {
          this.status = 'error';
          this.serverErrors = error;
          this.load_update = false;
        });
    },
    updateChart() {
      let categories = this.columns.filter(o => o.type === 'category');
      categories.forEach(category => {
        let exist = false;
        this.categories.forEach(value => {
          if (value.name === category.name) {
            exist = true;
            category.values = value.values;
          }
        });
        if (!exist) {
          category.values = ['all'];
        }
      });

      if (this.forecastType === 'clustering' && this.columnsFeatures.length > 1 && (this.axisX.name === '' || this.axisY.name === '')) {
        this.axisX = this.$lodash.cloneDeep(this.columnsFeatures[0]);
        this.axisY = this.$lodash.cloneDeep(this.columnsFeatures[1]);
      }

      if (this.forecastType === 'regression' && this.columnForecast) {
        this.axisY = { name: this.columnForecast, type: 'numerical' };
      }

      if (this.forecastType === 'regression' && this.columnForecast && this.columnsFeatures.length >= 1 && this.axisX.name === '') {
        this.axisX = this.$lodash.cloneDeep(this.columnsFeatures[0]);
      }

      if (this.forecastType === 'classification' && this.columnsFeatures.length > 1 && (this.axisX.name === '' || this.axisY.name === '')) {
        this.axisX = this.$lodash.cloneDeep(this.columnsFeatures[0]);
        this.axisY = this.$lodash.cloneDeep(this.columnsFeatures[1]);
      }

      if (
        (this.columns.filter(o => o.type.includes('date')).length > 0 && this.columnForecast && this.forecastType === 'time_series')
        || (this.forecastType === 'clustering' && this.columnsFeatures.length > 1)
        || (this.forecastType === 'regression' && this.columnForecast && this.columnsFeatures.length >= 1)
        || (this.forecastType === 'classification' && this.columnForecast && this.columnsFeatures.length > 1)
      ) {
        this.$fetch
          .post(`/api/chart_train/${this.sources.value}`, { columns: this.columns, categories, hugeData: this.hugeData, forecastType: this.forecastType, axis: { x: this.axisX, y: this.axisY },
            cleaning: this.cleaning, features: this.features })
          .then(data => {
            if (this.forecastType === 'time_series') {
              this.categoriesValues = data.categoriesValues;
              this.cleaning.test_size.unique_dates = data.specific_data.unique_dates;
              this.datesType = this.columns.filter(o => o.type.includes('date'))[0]['type'];
              let dates = [];
              let values = [];
              data.values.forEach(value => {
                dates.push(value[0]);
                values.push(value[1]);
              });
              this.$set(this, 'datacollection', {
                labels: dates,
                datasets: [
                  {
                    label: this.columns.filter(o => o.type === 'forecast')[0]['name'],
                    backgroundColor: '#ff6361',
                    borderColor: '#ff6361',
                    fill: false,
                    data: values,
                    borderWidth: ['date_hours', 'date_days'].includes(this.datesType) ? 2 : 3
                  }
                ]
              });
              if (data.specific_data.percentile_values.length > 0) {
                this.datacollection.datasets.push({
                  label: this.$t('story.detail.percentile'),
                  backgroundColor: '#01579B',
                  borderColor: '#01579B',
                  fill: false,
                  data: data.specific_data.percentile_values,
                  borderWidth: ['date_hours', 'date_days'].includes(this.datesType) ? 2 : 3
                });
              }

              if (data.specific_data.rolling_values.length > 0) {
                this.datacollection.datasets.push({
                  label: this.$t('story.detail.rolling'),
                  backgroundColor: '#00CC00',
                  borderColor: '#00CC00',
                  fill: false,
                  data: data.specific_data.rolling_values,
                  borderWidth: ['date_hours', 'date_days'].includes(this.datesType) ? 2 : 3
                });
              }

              if (data.specific_data.diff_values.length > 0) {
                this.datacollection.datasets.push({
                  label: this.$t('story.detail.diff2') + this.cleaning.diff.value,
                  backgroundColor: '#05ed00',
                  borderColor: '#05ed00',
                  fill: false,
                  data: data.specific_data.diff_values,
                  borderWidth: ['date_hours', 'date_days'].includes(this.datesType) ? 2 : 3
                });
                this.adfuller1 = data.specific_data.adfuller_values1;
                this.adfuller2 = data.specific_data.adfuller_values2;
              }

              if (data.specific_data.comp_values.length > 0) {
                this.datacollection.datasets.push({
                  label: this.$t('story.detail.trend'),
                  backgroundColor: '#6dccda',
                  borderColor: '#6dccda',
                  fill: false,
                  data: data.specific_data.comp_values[0].trend,
                  borderWidth: ['date_hours', 'date_days'].includes(this.datesType) ? 2 : 3
                });
                this.datacollection.datasets.push({
                  label: this.$t('story.detail.seasonal'),
                  backgroundColor: '#cdcc5d',
                  borderColor: '#cdcc5d',
                  fill: false,
                  data: data.specific_data.comp_values[0].seasonal,
                  borderWidth: ['date_hours', 'date_days'].includes(this.datesType) ? 2 : 3
                });
                this.datacollection.datasets.push({
                  label: this.$t('story.detail.residual'),
                  backgroundColor: '#a2a2a2',
                  borderColor: '#a2a2a2',
                  fill: false,
                  data: data.specific_data.comp_values[0].resid,
                  borderWidth: ['date_hours', 'date_days'].includes(this.datesType) ? 2 : 3
                });
              }
            }

            if (['regression', 'classification', 'clustering'].includes(this.forecastType)) {
              this.cleaning.dimensionality_reduction.sourceDimensions = data.specific_data.dimensions_length;
              this.cleaning.dimensionality_reduction.explained_variance = data.specific_data.explained_variance;
            }

            if (this.forecastType === 'regression') {
              let values = [];
              this.uniqueAxis = data.specific_data.uniqueAxis;
              this.chartLabels = [this.axisX, this.axisY];
              data.values.forEach(value => {
                values.push({ x: value[0], y: value[1] });
              });
              this.$set(this, 'datacollection', {
                datasets: [
                  {
                    label: this.columns.filter(o => o.type === 'forecast')[0]['name'],
                    backgroundColor: '#ff6361',
                    borderColor: '#ff6361',
                    data: values
                  }
                ]
              });
            }

            if (this.forecastType === 'classification') {
              this.uniqueAxis = data.specific_data.uniqueAxis;
              this.chartLabels = [this.axisX, this.axisY];
              let forecastValues = [];
              data.values.forEach(value => {
                forecastValues.push(value[2]);
              });
              forecastValues = this.$lodash.uniqBy(forecastValues);
              let datasets = [];
              forecastValues.forEach((forecastValue, key) => {
                let values = [];
                data.values.forEach(value => {
                  if (value[2] === forecastValue) {
                    values.push({ x: value[0], y: value[1] });
                  }
                });
                datasets.push({
                  label: forecastValue,
                  backgroundColor: this.clustersColors(key),
                  borderColor: this.clustersColors(key),
                  data: values
                });
              });
              this.$set(this, 'datacollection', { datasets });
            }

            if (this.forecastType === 'clustering') {
              let values = [];
              let elbowValues = [];
              this.uniqueAxis = data.specific_data.uniqueAxis;
              // this.sum_of_squared_distances = data.specific_data.sum_of_squared_distances;
              this.chartLabels = [this.axisX, this.axisY];
              data.values.forEach(value => {
                values.push({ x: value[0], y: value[1] });
              });
              this.$set(this, 'datacollection', {
                datasets: [
                  {
                    label: '',
                    backgroundColor: '#ff6361',
                    borderColor: '#ff6361',
                    // fill: false,
                    data: values
                  }
                ]
              });
              data.specific_data.sum_of_squared_distances.forEach((value, index) => {
                elbowValues.push({ x: index + 1, y: value });
              });
              this.$set(this, 'elbow_datacollection', {
                datasets: [
                  {
                    label: '',
                    backgroundColor: '#ff6361',
                    borderColor: '#ff6361',
                    // fill: false,
                    data: elbowValues
                  }
                ]
              });
            }

            this.categories.forEach(c1 => {
              data.categories.forEach(c2 => {
                if (c1['name'] === c2['name'] && c1['list'].length === 0) {
                  let array = [];
                  array.push({ text: this.$t('story.detail.all_values'), value: 'all' });
                  c2.values.forEach(value => array.push({ text: value, value }));
                  c1['list'] = array;
                }
              });
            });

            let newCategories = this.difference(data.categories, this.categories, 'name');
            newCategories.forEach(c => {
              let array = [];
              array.push({ text: this.$t('story.detail.all_values'), value: 'all' });
              c.values.forEach(value => array.push({ text: value, value }));
              this.categories.push({ list: array, values: ['all'], name: c.name });
            });

            let removeCategories = this.difference(this.categories, data.categories, 'name');
            removeCategories.forEach(c => {
              this.categories.forEach((n, index) => {
                if (n.name === c.name) {
                  this.categories.splice(index, 1);
                }
              });
            });

            let searchCategories = [];
            this.categories.forEach(category => {
              searchCategories.push(category.list);
            });
            this.$set(this, 'searchCategories', searchCategories);

            let newFeatures = this.difference(data.features, this.features, ['name', 'type']);
            newFeatures.forEach(feature => {
              this.features.push(feature);
            });

            let removeFeatures = this.difference(this.features, data.features, ['name', 'type']);
            removeFeatures.forEach(f => {
              this.features.forEach((n, index) => {
                if (n.name === f.name && n.type === f.type) {
                  this.features.splice(index, 1);
                }
              });
            });

            if (Object.prototype.hasOwnProperty.call(this.$refs, 'chart')) {
              if (this.$refs.chart !== undefined) {
                this.$refs.chart.updateChart(this.datacollection, this.chartLabels, this.uniqueAxis);
              }
            }
          });
      } else {
        this.$set(this, 'datacollection', null);
        if (Object.prototype.hasOwnProperty.call(this.$refs, 'chart')) {
          if (this.$refs.chart !== undefined) {
            this.$refs.chart.updateChart(this.datacollection, this.chartLabels, this.uniqueAxis);
          }
        }
      }
    },
    updateElbow() {
      if (Object.prototype.hasOwnProperty.call(this.$refs, 'chart')) {
        if (this.$refs.chart !== undefined) {
          if (this.elbow_method) {
            this.$refs.chart.updateChart(this.$lodash.cloneDeep(this.elbow_datacollection), this.elbow_chartLabels, []);
          } else {
            this.$refs.chart.updateChart(this.datacollection, this.chartLabels, this.uniqueAxis);
          }
        }
      }
    },
    train() {
      this.$v.$touch();
      this.serverErrors = {};
      let check = false;
      if (!this.$route.path.includes('anaplan')) {
        check = !this.$v.$invalid;
      } else {
        check = !this.$v.clusters.$invalid && !this.$v.name.$invalid && !this.$v.comment.$invalid;
      }

      if (check) {
        this.load = true;
        let categories = [];
        this.categories.forEach(category => {
          categories.push({ values: category['values'], name: category['name'] });
        });
        let settings = {};
        for (let model in this.settings[this.forecastType]) {
          settings[model] = {};
          for (let param in this.settings[this.forecastType][model]) {
            settings[model][param] = [];
            this.settings[this.forecastType][model][param].values.forEach(value => {
              if (typeof value === 'object' && value !== null) {
                settings[model][param].push(value.value);
              } else if (!isNaN(value)) {
                settings[model][param].push(value * 1);
              } else {
                settings[model][param].push(value);
              }
            });
          }
        }

        this.$fetch[this.id === 0 ? 'post' : 'patch'](this.id === 0 ? `/api/stories` : `/api/stories/${this.id}`, {
          columns: this.columns,
          id: this.sources.value,
          name: this.name,
          comment: this.comment,
          forecastType: this.forecastType,
          models: this.models,
          settings,
          cleaning: this.cleaning,
          features: this.features,
          clusters: this.clusters,
          categories,
          anaplan: this.$route.path.includes('anaplan'),
          workspaceID: this.workspaceID,
          modelID: this.modelID
        })
          .then(() => {
            if (!this.$route.path.includes('anaplan')) {
              this.$notify.success({
                message: this.id === 0 ? this.$t('notifications.story_started_train') : this.$t('notifications.story_retrained')
              });
              this.load = false;
              this.$router.push('/stories');
            } else {
              this.$notify.success({
                message: this.$t('notifications.anaplan_export')
              });
              this.load = false;
              this.success_load = true;
            }
          })
          .catch(error => {
            if (!this.$route.path.includes('anaplan')) {
              this.load = false;
              this.serverErrors = error;
            } else {
              this.load = false;
              this.status = 'error';
              this.serverErrors = error;
            }
          });
      }
    },
    updateFeatureImportance() {
      if (this.feature_importance.active) {
        let categories = [];
        this.categories.forEach(category => {
          categories.push({ values: category['values'], name: category['name'] });
        });

        this.$fetch
          .post('/api/feature_importance', { id: this.sources.value, forecastType: this.forecastType,
            model: this.feature_importance.value, columns: this.columns, features: this.features, categories })
          .then(data => {
            this.feature_importance.columns = data.columns;
            this.feature_importance.scores = data.scores;

            if (Object.prototype.hasOwnProperty.call(this.$refs, 'chart')) {
              if (this.$refs.chart !== undefined) {
                this.$refs.chart.updateChart(this.feature_importance);
              }
            }
          })
          .catch(error => {
            this.serverErrors = error;
          });
      }
    },
    columnFeatureUpdate(type) {
      if (type === 'x') {
        if (this.axisX.name === this.axisY.name) {
          this.$set(this, 'axisY', this.columnsFeatures[0].name !== this.axisX.name ? this.columnsFeatures[0] : this.columnsFeatures[1]);
        }
        this.axisX.type = this.columns.filter(o => o.name === this.axisX.name)[0].type;
      } else if (type === 'y') {
        if (this.axisX.name === this.axisY.name) {
          this.$set(this, 'axisX', this.columnsFeatures[0].name !== this.axisY.name ? this.columnsFeatures[0] : this.columnsFeatures[1]);
        }
        this.axisY.type = this.columns.filter(o => o.name === this.axisY.name)[0].type;
      }
      this.updateChart();
    },
    debounceUpdate() {
      this.updateChart();
      // this.$lodash.debounce(this.updateChart, 500);
    },
    clustersColors(index) {
      let array = ['#6dccda', '#cdcc5d', '#a2a2a2', '#ed97ca', '#a8786e', '#ad8bc9', '#ed665d', '#67bf5c', '#ff9e4a', '#729ece'];
      return array[index];
    },
    getWindowHeight() {
      this.windowHeight = document.documentElement.clientHeight;
      this.chart_width = (document.documentElement.clientWidth - 32 * 2) * 0.75;
      // this.chart_width = this.$refs.table_chart.clientWidth;
    },
    modelSelection() {
      if (this.models.length === 0) {
        if (this.forecastType === 'time_series') {
          this.models = ['best'];
        } else if (this.forecastType === 'clustering') {
          this.models = ['all_models'];
        }
      } else {
        let last = this.models[this.models.length - 1];
        if (last === 'best') {
          this.models = ['best'];
        } else if (last === 'all_models') {
          this.models = ['all_models'];
        } else {
          let index = this.models.indexOf('best');
          if (index !== -1) {
            this.models.splice(index, 1);
          }
          index = this.models.indexOf('all_models');
          if (index !== -1) {
            this.models.splice(index, 1);
          }
        }
      }
    },
    arrayList(array) {
      let result = [];
      array.forEach(value => {
        result.push({ text: this.$t(`story.detail.${value}`), value });
      });
      return result;
    },
    updateColumnTypes(trigger) {
      let columns = [];
      let typeLists = [];
      let forecastCheck = false;
      if (this.forecastType === 'time_series') {
        this.table.columns.forEach(value => {
          if (value.type === 'date') {
            if (value.period === 'hours') {
              columns.push({ name: value.name, type: 'date_hours' });
              typeLists.push(this.arrayList(['empty', 'date_hours', 'date_days', 'date_weeks', 'date_months', 'date_quarters', 'date_years']));
            } else if (value.period === 'days') {
              columns.push({ name: value.name, type: 'date_days' });
              typeLists.push(this.arrayList(['empty', 'date_days', 'date_weeks', 'date_months', 'date_quarters', 'date_years']));
            } else if (value.period === 'weeks') {
              columns.push({ name: value.name, type: 'date_weeks' });
              typeLists.push(this.arrayList(['empty', 'date_weeks', 'date_months', 'date_quarters', 'date_years']));
            } else if (value.period === 'months') {
              columns.push({ name: value.name, type: 'date_months' });
              typeLists.push(this.arrayList(['empty', 'date_months', 'date_quarters', 'date_years']));
            } else if (value.period === 'quarters') {
              columns.push({ name: value.name, type: 'date_quarters' });
              typeLists.push(this.arrayList(['empty', 'date_quarters', 'date_years']));
            } else if (value.period === 'years') {
              columns.push({ name: value.name, type: 'date_years' });
              typeLists.push(this.arrayList(['empty', 'date_years']));
            }
          } else if (value.type === 'number') {
            columns.push({ name: value.name, type: forecastCheck ? 'empty' : 'forecast' });
            if (!forecastCheck) {
              forecastCheck = true;
            }
            typeLists.push(this.arrayList(['empty', 'forecast', 'numerical', 'binary', 'ordinal', 'nominal', 'category']));
          } else {
            columns.push({ name: value.name, type: 'empty' });
            typeLists.push(this.arrayList(['empty', 'forecast', 'numerical', 'binary', 'ordinal', 'nominal', 'category']));
          }
        });
        this.modelsList = [
          { text: this.$t('story.detail.best'), value: 'best' },
          { text: 'SARIMAX', value: 'SARIMAX' },
          { text: 'HWES', value: 'HWES' },
          { text: 'PROPHET', value: 'PROPHET' },
          { text: 'XGBOOST', value: 'XGBOOST' },
          { text: 'NGBOOST', value: 'NGBOOST' },
          { text: 'CATBOOST', value: 'CATBOOST' },
          { text: 'LIGHTGBM', value: 'LIGHTGBM' },
          { text: 'LSTM', value: 'LSTM' },
          { text: 'YTY', value: 'YTY' },
          { text: 'AR', value: 'AR' },
          { text: 'LR', value: 'LR' },
          { text: 'SES', value: 'SES' },
          { text: 'PR', value: 'PR' },
          { text: 'LogarR', value: 'LogarR' },
          { text: 'ER', value: 'ER' },
          // { text: 'MA', value: 'MA' },
          // { text: 'VAR', value: 'VAR' },
          // { text: 'VARMAX', value: 'VARMAX' }
        ];
        if (trigger) {
          this.models = ['best'];
        }
      } else if (this.forecastType === 'regression') {
        this.table.columns.forEach(value => {
          if (value.type === 'number') {
            columns.push({ name: value.name, type: forecastCheck ? 'empty' : 'forecast' });
            if (!forecastCheck) {
              forecastCheck = true;
            }
            typeLists.push(this.arrayList(['empty', 'forecast', 'numerical', 'binary', 'ordinal']));
          } else {
            columns.push({ name: value.name, type: 'empty' });
            typeLists.push(this.arrayList(['empty', 'forecast', 'numerical', 'binary', 'ordinal', 'nominal', 'category']));
          }
        });
        this.modelsList = [
          { text: this.$t('story.detail.best'), value: 'best' },
          { text: 'Support Vector Regression', value: 'SVR' },
          { text: 'Decision Tree Regression', value: 'DTR' },
          { text: 'Random Forest Regression', value: 'RFR' },
          { text: 'Linear Regression', value: 'LR' },
          { text: 'Polynomial Regression', value: 'PR' },
          { text: 'XGBOOST', value: 'XGBOOST_reg' },
          { text: 'NGBOOST', value: 'NGBOOST_reg' },
          { text: 'CATBOOST', value: 'CATBOOST_reg' },
          { text: 'LIGHTGBM', value: 'LIGHTGBM_reg' },
        ];
        if (trigger) {
          this.models = ['best'];
        }
        this.feature_importance.list = [
          { text: 'Linear Regression', value: 'LR' },
          { text: 'Decision Tree Regression', value: 'DTR' },
          { text: 'Random Forest Regression', value: 'RFR' },
          { text: 'XGBOOST', value: 'XGBOOST_reg' }
        ];
        this.feature_importance.value = 'LR';
      } else if (this.forecastType === 'classification') {
        this.table.columns.forEach(value => {
          if (value.type === 'number') {
            columns.push({ name: value.name, type: forecastCheck ? 'numerical' : 'forecast' });
            if (!forecastCheck) {
              forecastCheck = true;
            }
            typeLists.push(this.arrayList(['empty', 'forecast', 'numerical', 'binary', 'ordinal']));
          } else {
            columns.push({ name: value.name, type: 'numerical' });
            typeLists.push(this.arrayList(['empty', 'forecast', 'numerical', 'binary', 'ordinal', 'nominal', 'category']));
          }
        });
        this.modelsList = [
          { text: this.$t('story.detail.best'), value: 'best' },
          { text: 'Logistic Regression', value: 'LogR' },
          { text: 'K-Nearest Neighbors', value: 'KNN' },
          { text: 'Support Vector Machine', value: 'SVM' },
          { text: 'Naive Bayes', value: 'NB' },
          { text: 'Decision Tree Classifier', value: 'DTC' },
          { text: 'Random Forest Classifier', value: 'RFC' },
          { text: 'XGBOOST', value: 'XGBOOST_class' },
          { text: 'CATBOOST', value: 'CATBOOST_class' },
          { text: 'LIGHTGBM', value: 'LIGHTGBM_class' },
          { text: 'NGBOOST', value: 'NGBOOST_class' },
        ];
        if (trigger) {
          this.models = ['best'];
        }
        this.feature_importance.list = [
          { text: 'Logistic Regression', value: 'LogR' },
          { text: 'Decision Tree Classifier', value: 'DTC' },
          { text: 'Random Forest Classifier', value: 'RFC' },
          { text: 'XGBOOST', value: 'XGBOOST_class' }
        ];
        this.feature_importance.value = 'LogR';
      } else if (this.forecastType === 'clustering') {
        this.table.columns.forEach(value => {
          if (value.type === 'date') {
            columns.push({ name: value.name, type: 'empty' });
            typeLists.push(this.arrayList(['empty']));
          } else if (value.type === 'number') {
            columns.push({ name: value.name, type: 'numerical' });
            typeLists.push(this.arrayList(['empty', 'numerical', 'binary']));
          } else {
            columns.push({ name: value.name, type: 'empty' });
            typeLists.push(this.arrayList(['empty', 'numerical', 'binary', 'ordinal', 'nominal', 'category']));
          }
        });
        this.modelsList = [
          { text: this.$t('story.detail.all_models'), value: 'all_models' },
          { text: 'KMEANS', value: 'KMEANS' },
          { text: 'Agglomerative Clustering', value: 'AGGLOMERATIVE_CLUSTERING' },
          { text: 'DBSCAN', value: 'DBSCAN' },
          { text: 'BIRCH', value: 'BIRCH' },
        ];
        if (trigger) {
          this.models = ['all_models'];
        }
      }

      if (this.forecastType === 'time_series') {
        this.cleaning.test_size.list = [
          { text: 'AUTO', value: -1 },
          { text: this.$t('story.detail.choose_date'), value: -2 },
          // { text: '0%', value: 0 },
          { text: '5%', value: 0.05 },
          { text: '10%', value: 0.1 },
          { text: '15%', value: 0.15 },
          { text: '20%', value: 0.2 },
          { text: '25%', value: 0.25 },
        ];
        if (trigger) {
          this.cleaning.test_size.value = -1;
        }
      } else if (this.forecastType === 'regression' || this.forecastType === 'classification') {
        this.cleaning.test_size.list = [
          { text: '0%', value: 0 },
          { text: '5%', value: 0.05 },
          { text: '10%', value: 0.1 },
          { text: '15%', value: 0.15 },
          { text: '20%', value: 0.2 },
          { text: '25%', value: 0.25 },
        ];
        if (trigger) {
          this.cleaning.test_size.value = 0.1;
        }
      }

      if (this.forecastType === 'time_series' || this.forecastType === 'regression') {
        this.cleaning.accuracy_score.list = [
          { text: this.$t('story.forecast.rmse.name'), value: 'rmse' },
          { text: this.$t('story.forecast.mse.name'), value: 'mse' },
          { text: this.$t('story.forecast.mape.name'), value: 'mape' },
          { text: this.$t('story.forecast.mae.name'), value: 'mae' },
        ];
        if (trigger) {
          this.cleaning.accuracy_score.value = 'rmse';
        }
      } else if (this.forecastType === 'classification') {
        this.cleaning.accuracy_score.list = [
          { text: this.$t('story.forecast.accuracy_score.name'), value: 'accuracy_score' },
          { text: this.$t('story.forecast.precision_score.name'), value: 'precision_score' },
          { text: this.$t('story.forecast.average_precision_score.name'), value: 'average_precision_score' },
          { text: this.$t('story.forecast.recall_score.name'), value: 'recall_score' },
          { text: this.$t('story.forecast.f1_score.name'), value: 'f1_score' },
          { text: this.$t('story.forecast.roc_auc_score.name'), value: 'roc_auc_score' },
        ];
        if (trigger) {
          this.cleaning.accuracy_score.value = 'accuracy_score';
        }
      }

      if (trigger) {
        this.columns = columns;
      }
      this.table.typeLists = typeLists;
    },
    setFeatures(values) {
      this.features.forEach((feature, key) => {
        if (feature.name === this.active_feature.name) {
          this.features[key].values = values;
        }
      });
    },
    chooseFeature(feature) {
      this.active_feature = feature;
      this.showFeatures = true;
    },
    categorySelection(key) {
      if (this.categories[key].values.length === 0) {
        this.categories[key].values = ['all'];
      } else {
        let last = this.categories[key].values[this.categories[key].values.length - 1];
        if (last === 'all') {
          this.categories[key].values = ['all'];
        } else {
          let index = this.categories[key].values.indexOf('all');
          if (index !== -1) {
            this.categories[key].values.splice(index, 1);
          }
        }
      }
    },
    onKeyUp(key) {
      let searchResult;
      let options = {
        keys: ['value'],
        minMatchCharLength: 1,
        threshold: 0.1
      };
      let fuse = new Fuse(this.categories[key].list, options);
      searchResult = fuse.search(this.searchInput);
      if (this.searchInput === '') {
        this.$set(this.searchCategories, key, this.categories[key].list);
      } else {
        let result = [];
        searchResult.forEach(value => {
          result.push(value.item);
        });
        this.$set(this.searchCategories, key, result);
      }
    },
    blur(key) {
      this.searchInput = '';
      this.$set(this.searchCategories, key, this.categories[key].list);
    },
    formatPercent(number) {
      if (number < 0.01) {
        return '0%';
      } else {
        return this.$numeral(number / 100).format('0,0.%');
      }
    },
    setComment(value) {
      this.comment = value;
    }
  }
};
</script>
