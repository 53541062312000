<template>
  <v-card class="elevation-12">
    <v-toolbar dense color="primary" dark flat>
      <v-toolbar-title>{{ column + $t('source.detail.column_desc') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pb-0 d-flex flex-row">
      <v-col cols="4" class="px-0">
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="grey--text">{{ $t('source.detail.column_type') }}</td>
                <td class="font-weight-bold primary--text">{{ new_desc.dtype != '' ? $t(`source.detail.column_types.${new_desc.dtype.includes('datetime') ? 'datetime' : new_desc.dtype }`) : '' }}</td>
              </tr>
              <tr v-if="new_desc.count != ''">
                <td class="grey--text">{{ $t('source.detail.column_count') }}</td>
                <td class="font-weight-bold primary--text">{{ formatNumber(new_desc.count) }}</td>
              </tr>
              <tr v-if="new_desc.unique != ''">
                <td class="grey--text">{{ $t('source.detail.column_unique') }}</td>
                <td class="font-weight-bold primary--text">{{ formatNumber(new_desc.unique) }}</td>
              </tr>
              <tr v-if="'first' in new_desc ? (new_desc.first != '' ? true : false) : false">
                <td class="grey--text">{{ $t('source.detail.date_first') }}</td>
                <td class="font-weight-bold primary--text">{{ formatDate(new_desc.first) }}</td>
              </tr>
              <tr v-if="'last' in new_desc ? (new_desc.last != '' ? true : false) : false">
                <td class="grey--text">{{ $t('source.detail.date_last') }}</td>
                <td class="font-weight-bold primary--text">{{ formatDate(new_desc.last) }}</td>
              </tr>
              <tr v-if="new_desc.min != ''">
                <td class="grey--text">{{ $t('source.detail.number_min') }}</td>
                <td class="font-weight-bold primary--text">{{ formatNumber(new_desc.min) }}</td>
              </tr>
              <tr v-if="new_desc.max != ''">
                <td class="grey--text">{{ $t('source.detail.number_max') }}</td>
                <td class="font-weight-bold primary--text">{{ formatNumber(new_desc.max) }}</td>
              </tr>
              <tr v-if="new_desc.mean != ''">
                <td class="grey--text">{{ $t('source.detail.number_mean') }}</td>
                <td class="font-weight-bold primary--text">{{ formatNumber(new_desc.mean) }}</td>
              </tr>
              <tr v-if="new_desc.std != ''">
                <td class="grey--text">{{ $t('source.detail.number_std') }}</td>
                <td class="font-weight-bold primary--text">{{ formatNumber(new_desc.std) }}</td>
              </tr>
              <tr v-if="new_desc['25%'] != ''">
                <td class="grey--text">{{ $t('source.detail.number_25') }}</td>
                <td class="font-weight-bold primary--text">{{ formatNumber(new_desc['25%']) }}</td>
              </tr>
              <tr v-if="new_desc['50%'] != ''">
                <td class="grey--text">{{ $t('source.detail.number_50') }}</td>
                <td class="font-weight-bold primary--text">{{ formatNumber(new_desc['50%']) }}</td>
              </tr>
              <tr v-if="new_desc['75%'] != ''">
                <td class="grey--text">{{ $t('source.detail.number_75') }}</td>
                <td class="font-weight-bold primary--text">{{ formatNumber(new_desc['75%']) }}</td>
              </tr>
              <tr>
                <td class="grey--text">{{ $t('source.detail.has_nan') }}</td>
                <td :class="`font-weight-bold ${new_desc.count_nan > 0 ? 'warning--text' : 'success--text'}`">{{ new_desc.count_nan > 0 ? formatNumber(new_desc.count_nan) : $t('source.detail.has') }}</td>
              </tr>
              <tr v-if="new_desc.dtype == 'object'">
                <td class="grey--text">{{ $t('source.detail.has_empty') }}</td>
                <td :class="`font-weight-bold ${new_desc.count_empty > 0 ? 'warning--text' : 'success--text'}`">{{ new_desc.count_empty > 0 ? formatNumber(new_desc.count_empty) : $t('source.detail.has') }}</td>
              </tr>
              <tr v-if="new_desc.dtype.includes('int') || new_desc.dtype.includes('float')">
                <td class="grey--text">{{ $t('source.detail.has_zeros') }}</td>
                <td :class="`font-weight-bold ${new_desc.count_zero > 0 ? 'warning--text' : 'success--text'}`">{{ new_desc.count_zero > 0 ? formatNumber(new_desc.count_zero) : $t('source.detail.has') }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="8" class="px-0">
        <!-- <line-chart v-if="new_desc.dtype == 'object' || new_desc.dtype.includes('datetime')" ref="chart" :chart-data="datacollection" :size="{ width: chart_width, height: windowHeight - (64 + 16) - 35.2 - 40 - 78}" :dates-type="datesType"></line-chart> -->
        <distribution-chart ref="chart" :labels="new_desc.dist.category" :scores="new_desc.dist.values" :dtype="new_desc.dtype" :size="{ width: chart_width, height: windowHeight - (64 + 16) - 35.2 - 40 - 78}"></distribution-chart>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
// import LineChart from '@/components/LineChart.vue';
import DistributionChart from '@/components/DistributionChart.vue';

export default {
  name: 'ChangeColumnInfo',
  props: ['desc', 'column'],
  // components: { LineChart, BarChart },
  components: { DistributionChart },
  data() {
    return {
      new_desc: {
        dist: {
          category: [],
          values: []
        },
        dtype: '',
        count: '',
        unique: '',
        first: '',
        last: '',
        min: '',
        max: '',
        mean: '',
        std: '',
        '25%': '',
        '50%': '',
        '75%': '',
        count_nan: 0,
        count_empty: 0,
        count_zero: 0
      },
      windowHeight: 0,
      chart_width: 0
    };
  },
  mounted() {
    this.new_desc = this.desc[this.column];
    window.addEventListener('resize', this.getWindowHeight);
    this.getWindowHeight();
    this.$refs.chart.updateChart(this.new_desc);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowHeight);
  },
  methods: {
    getWindowHeight() {
      this.windowHeight = document.documentElement.clientHeight;
      this.chart_width = (document.documentElement.clientWidth - 32 * 2) * 0.65;
    },
    formatNumber(number) {
      if (number < 0.01) {
        return 0;
      } else {
        return this.$numeral(number).format('0,0.');
      }
    },
    formatDate(date) {
      return this.$date.format(this.$date.parseISO(date), 'dd.MM.yyyy');
    }
  },
  watch: {
    desc: {
      deep: true,
      handler() {
        this.new_desc = this.desc[this.column];
        // this.$refs.chart.updateChart(this.new_desc);
      },
    },
    column() {
      this.new_desc = this.desc[this.column];
      this.$refs.chart.updateChart(this.new_desc);
    },
  },
};
</script>
