<template>
  <v-main>
    <v-container fluid id="home">
      <v-row justify="start" class="align-center">
        <v-btn icon color="primary" @click="$router.push('/tasks')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <h2>
          <span>{{ $t('connections.list.name') + '    ' }}</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="mx-2" @click="$router.push(`/connections/0/`)" fab dark color="green">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('connections.list.add') }}</span>
          </v-tooltip>
        </h2>
      </v-row>
      <v-row justify="start" class="my-2">
        <v-col cols="3" v-for="(connection, key) in connections" :key="key">
          <v-card>
            <v-img :src="connection.image" class="white--text align-end cursor" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px" @click="$router.push(`/connections/${connection.id}/`)">
              <v-card-title @click="$router.push(`/connections/${connection.id}/`)">{{ connection.name }}</v-card-title>
            </v-img>
            <v-row justify="end" class="py-0 my-0">
              <v-card-actions class="py-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-information</v-icon>
                  </template>
                  <v-card-text class="pa-1">
                    <p class="text-left mb-0">{{ $t('connections.list.type') }}: {{ connection.type }}</p>
                    <p class="text-left mb-0">{{ $t('connections.list.created') }}: {{ connection.created_at }}</p>
                  </v-card-text>
                </v-tooltip>
              </v-card-actions>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import anaplan_image from '../../img/integrations/anaplan.png';
import optimacros_image from '../../img/integrations/optimacros.jpeg';
import csv_image from '../../img/integrations/csv.png';
import postgres_image from '../../img/integrations/postgres.jpeg';
import oracle_image from '../../img/integrations/oracle.png';
import mysql_image from '../../img/integrations/mysql.png';
import mssql_image from '../../img/integrations/mssql.jpg';
import sap_image from '../../img/integrations/sap.png';
import one_c_image from '../../img/integrations/1с.jpg';
import tableau_image from '../../img/integrations/tableau.webp';
import salesforce_image from '../../img/integrations/salesforce.webp';
import rest_image from '../../img/integrations/rest.jpg';
import ftp_image from '../../img/integrations/ftp.png';
import excel_image from '../../img/integrations/excel.png';
import amazon_s3_image from '../../img/integrations/amazon_s3.png';
import google_s3_image from '../../img/integrations/google_cs.webp';

export default {
  name: 'ConnectionList',
  data() {
    return {
      connections: [],
      activeItem: 0,
      options: [
        { name: this.$t('photo.change_cover'), type: 'photo' }
      ],
      definitions: {
        1: {type: 'Anaplan', image: anaplan_image},
        2: {type: 'CSV', image: csv_image},
        3: {type: 'PostgreSQL', image: postgres_image},
        4: {type: 'Oracle', image: oracle_image},
        5: {type: 'MySQL', image: mysql_image},
        6: {type: 'MS SQL', image: mssql_image},
        7: {type: 'SAP', image: sap_image},
        8: {type: '1C Предприятие', image: one_c_image},
        9: {type: 'Tableau', image: tableau_image},
        10: {type: 'Salesforce', image: salesforce_image},
        11: {type: 'REST API', image: rest_image},
        12: {type: 'FTP', image: ftp_image},
        13: {type: 'Excel', image: excel_image},
        14: {type: 'Amazon S3', image: amazon_s3_image},
        15: {type: 'Google Cloud Storage', image: google_s3_image},
        16: {type: 'Optimacros', image: optimacros_image}
      }
    };
  },
  mounted() {
    this.update();
  },
  methods: {
    async update() {
      await this.$fetch
        .get('api/connections')
        .then(data => {
          let connections = [];
          data.forEach(value => {
            connections.push({
              id: value.id,
              name: value.name,
              image: this.definitions[value.connectionType].image,
              type: this.definitions[value.connectionType].type,
              created_at: this.$date.format(this.$date.parseISO(value.createdAt), 'dd.MM.yyyy HH:mm'),
            });
          });
          this.connections = connections;
        })
        .catch(error => {
          this.serverErrors = error;
        });
    }
  }
};
</script>