import { required, sameAs } from 'vuelidate/lib/validators';

const passwordConfirmMixin = {
  data() {
    return {
      passwordConfirm: '',
      showPasswordConfirm: false
    };
  },
  validations: {
    passwordConfirm: {
      required,
      sameAs: sameAs('password')
    },
  },
  computed: {
    passwordConfirmMessage() {
      if (this.$v.passwordConfirm.$error || this.getError('password2') !== '') {
        if (!this.$v.passwordConfirm.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.passwordConfirm.sameAs) {
          return [this.$t('validations.same_as')];
        } else if (!this.$v.passwordConfirm.$error && this.getError('password2') !== '') {
          return [this.getError('password2')];
        }
      }
      return [];
    },
  }
};

export default passwordConfirmMixin;
