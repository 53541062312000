export default {
  intro: {
    about: 'О нас',
    product: 'О продукте',
    case: 'Кейсы',
    docs: 'Документация',
    registration: 'Регистрация',
    login: 'Вход',
    logout: 'Выход',
    sources: 'Источники',
    stories: 'Истории',
    settings: 'Настройки',
    integration: 'Интеграция',
    connections: 'Подключения',
    processes: 'Процессы',
    tasks: 'Запросы',
    contact: {
      name: 'Заинтересовал продукт? Напишите нам',
      contact: 'Имя',
      subject: 'Тема',
      message: 'Сообщение',
      send: 'Отправить'
    },
    main_header: 'ДЕМОКРАТИЗАЦИЯ ПРЕДИКТИВНОЙ АНАЛИТИКИ',
    main_subheader: 'Машинное обучение для всех',
    products: {
      name: 'Как это работает?',
      list: [
        {
          name: 'Загружаете ваши данные',
          logo: 'cloud-upload'
        },
        {
          name: 'Обучаете историю с автоподбором лучшей модели',
          logo: 'cloud-search'
        },
        {
          name: 'Получаете прогноз',
          logo: 'cloud-download'
        },
        {
          name: 'Переобучаете историю на новых данных и факторах',
          logo: 'cloud-refresh'
        },
      ],
      another_list: [
        {
          name: 'Без кода',
          desc: 'Возможность манипулировать данными и работать со сложными алгоритмами без кодирования. Люди с разным уровнем навыков машинного обучения могут принимать решения на основе данных.'
        },
        {
          name: 'Упор на UX/UI дизайн',
          desc: 'Мы обеспечиваем рабочую среду для бизнес-аналитиков, где они могут управлять сложными алгоритмами, настраивать и адаптировать модели с открытым исходным кодом'
        },
        {
          name: 'Автоматический подбор лучшей модели',
          desc: 'Автоматический выбор оптимальной модели из широкого спектра алгоритмов с учетом внешних и внутренних факторов'
        },
        {
          name: 'Облачный сервис',
          desc: 'Лучшие стандарты SaaS для масштабируемости, производительности и безопасности'
        },
        {
          name: 'Обработка данных и аналитика',
          desc: 'Возможность обработки, чистки и слияния данных, детальная аналитика по данным и моделям, игра с параметрами, выявление значимости факторов'
        },
        {
          name: 'Передовые алгоритмы',
          desc: 'Использование передовых алгоритмов поиска гиперпараметров и подбора моделей, использование кросс-валидации, снижения размерности'
        },
        {
          name: 'Работа с BigData',
          desc: 'Потоковая обработка данных и инкрементное обучение позволяет работать с большими данными и сложными моделями'
        },
        {
          name: 'Высокая производительность',
          desc: 'Быстрый подбор моделей по множеству параметров с сжатием данных на нескольких CPU в параллели и возожностью вычислений на GPU'
        },
        {
          name: 'Легкая интеграция',
          desc: 'Современный развитый API обеспечивает легкую интеграцию с любой системой, базой данных и облачной службой'
        },
        {
          name: 'Безопасность',
          desc: 'Шифрование данных, размещение данных в дата-центрах Tier 3, шифрование передаваемых данных, аутентификация по токенам'
        },
      ]
    },
    cases: {
      name: 'Решаемые кейсы',
      list: [
        'Планирование продаж по регионам, каналам и SKU с учётом сезонности, трендов и цикловПрогноз продаж текущей продуктовой линейки',
        'Планирование продаж с учетом влияющих факторов (погода, праздники, продаж др. продуктов, расположения точки и т.д.)',
        'Планирование продаж новой линейки продуктов на основе истории продаж связанных продуктов',
        'Планирование продаж с учётом промо активностей (тип, продолжительность, размер скидки и т.д.)',
        'Выявление параметров оттока клиентов с целью их удержания (например, отправки смс о спец. предложении)',
        'Кластеризация клиентов на группы на основе множества факторов (средний чек, частота заказа и т.д.)',
        'Прогнозирование стоимости продукта / услуги на основе множества влияющих факторов и эластичности спроса',
        'Таргетированная реклама, выявление клиентов с максимальным потенциалом принятия предложения',
        'Оценка кредитного риска клиента с учетом влияющих факторов',
        'Выявление случаев мошенничества (аномальных операций)',
        'Оптимизация ассортимента черех кластеризацию на основе множества параметров',
        'Выявление лидов с наиболольшим потенциалом'
      ]
    },
    business_cases: {
      name: 'Истории успеха',
      challenges: 'ЗАДАЧИ',
      benefits: 'ПРЕИМУЩЕСТВА',
      list: [
        {
          title: 'Ведущая медицинская компания',
          area: 'Решение: Прогнозирование продаж',
          case: 'Прогнозирование выручки по 2000+ SKU и 80 регионам с автоматическим выбором оптимальной модели для каждой единицы прогнозирования и возможностью включения дополнительных факторов для повышения точности',
          challenges: ['Большое количество уникальных SKU, которые необходимо прогнозировать отдельно', 'Ограниченная доступность специалистов по data science', 'Проблемы с точностью прогнозирования'],
          benefits: ['Возможность расчета прогнозов по каждому SKU и региону', 'Поддержка менеджеров в прогнозировании', 'Возможность добавления новых факторов и переменных в процесс прогнозирования', 'Интеграция с моделями Anaplan']
        },
        {
          title: 'Ведущая интернет-компания',
          area: 'Решение: Прогнозирование продаж B2B клиентов',
          case: 'Прогнозирование продаж по 2 миллионам клиентов B2B с учетом внутренних и внешних атрибутов',
          challenges: ['Отсутствие указания статистики для менеджеров по продажам при прогнозировании', 'Ошибки в суждениях', 'Нет специалистов по data science для решения конкретных задач прогнозирования'],
          benefits: ['Возможность рассчитывать прогнозы по миллионам существующих клиентов', 'Возможность обеспечить основу для прогнозирования новых клиентов', 'Вызов для суждений при прогнозировании', 'Возможность добавлять новые факторы и переменные в процесс прогнозирования']
        },
        {
          title: 'Ведущая телеком компания',
          area: 'Решение: Система рекомендаций',
          case: 'Сервис рекомендаций контента для каждого пользователя на основе истории потребления и подбора схожих комбинаций множества характеристик контента',
          challenges: ['Медленный процесс выбора рекомендаций с использованием 50 параметров и 400 характеристик контента', 'Многочасовая работа по управлению алгоритмами машинного обучения', 'Низкая доступность специалистов по data science'],
          benefits: ['Подтверждена возможность настройки алгоритмов машинного обучения без программирования аналитиками', 'Повышена точность прогнозирования ROC AUC с 72% до 86%', 'Ускорена настройка моделей машинного обучения в 2 раза']
        }
      ]
    },
    email_placeholder: 'Введите свой email',
    early_access: 'Запросить ранний доступ',
    demonstration: 'Демонстрация'
  },
  auth: {
    registration: 'Регистрация',
    username: 'Имя пользователя',
    company: 'Компания',
    password: 'Пароль',
    old_password: 'Старый пароль',
    new_password: 'Новый пароль',
    confirm_password: 'Подтвердите пароль',
    agree: 'Я согласен на обработку персональных данных и условиями использования системы',
    register: 'Зарегистрироваться',
    login: 'Вход',
    forgot_password: 'Забыли свой пароль?',
    restore_password: 'Восстановление пароля',
    set_password: 'Установка пароля',
    reset_password: 'Сбросить пароль',
    renew_password: 'Обновить пароль'
  },
  settings: {
    settings: 'Настройки',
    profile: 'Профиль пользователя',
    change_password: 'Изменить пароль',
    users: 'Пользователи компании',
    user: 'Имя пользователя',
    add_user: 'Добавить пользователя',
    change_username: 'Изменение имени пользователя',
    add_new_user: 'Добавление пользователя',
    change_company: 'Изменение имени компании',
    new_password: 'Новый пароль',
    anaplan: 'Интеграция с Anaplan',
    workers: 'Воркеры',
    worker_desc: 'Воркер отвечает за фоновые тяжелые задачи (обучение историй, подготовка прогнозов, обработку данных и т.д.). Каждый воркер решает одну задачу, либо несколько воркеров могут решать совместно единую задачу',
    worker_spec: '1 CPU / 2 GB RAM',
    add_worker: 'Добавить воркера',
    delete_worker: 'Удалить воркера',
    login: 'Логин'
  },
  tasks: {
    name: 'Запросы',
    story_name: 'Имя истории',
    type: 'Задача',
    type_list: ['Обучение истории', 'Получение прогноза'],
    remainning_time: 'Оставшиеся время',
    stop_task: 'Остановить таск',
    cpu: 'Воркеры',
    queue: 'В очереди '
  },
  photo: {
    name: 'Изображения',
    change_cover: 'Изменить обложку',
    upload_photo: 'Загрузить изображение',
    reset_photo: 'Сбросить изображение'
  },
  source: {
    list: {
      name: 'Список источников',
      updated: 'Обновлен',
      rows: 'Строк',
      size: 'Размер',
      mb: ' Мб',
      add: 'Добавить источник',
      description: 'Описание'
    },
    detail: {
      new: 'Новый источник',
      upload: 'Загрузить',
      name: 'Наименование источника',
      date_hours: 'Даты (часы)',
      date_days: 'Даты (дни)',
      date_weeks: 'Даты (недели)',
      date_months: 'Даты (месяцы)',
      date_quarters: 'Даты (кварталы)',
      date_years: 'Даты (года)',
      number: 'Числа',
      category: 'Категории',
      external_data: 'Внешние данные',
      no: 'Не используются',
      mixed: 'Смешивание и обработка',
      weather: 'Погода Yandex',
      exchange_rate: 'Курсы валют Банка России',
      data_errors: 'Ошибки в данных!',
      empty_value: 'Пустое поле',
      wrong_value: 'Некорректное значение',
      comment: 'Комментарий / описание',
      write_comment: 'Напишите комментарий...',
      connection_settings: 'Параметры подключения',
      s3: 'S3 Объектное хранилище',
      url: 'URL-адрес',
      access_key_id: 'Ключ доступа',
      secret_access_key: 'Секрет доступа',
      bucket_name: 'Имя корзины',
      file_name: 'Имя файла',
      add_handling: 'Добавить источник / обработку',
      handling_add_source: 'Добавление источника',
      handling_preprocessing: 'Обработка данных',
      handling_merge: 'Объединение данных',
      process_name: 'Наименование процесса',
      new_process: 'Новый процесс',
      column_desc: ' - описание колонки данных',
      column_type: 'Тип колонки',
      column_types: { int32: 'Int', int64: 'Int', float32: 'Float', float64: 'Float', object: 'Категория (текст)', datetime: 'Дата' },
      column_count: 'Кол-во значений',
      column_unique: 'Кол-во уникальных значений',
      date_first: 'Начальная дата',
      date_last: 'Конечная дата',
      number_min: 'Минимальное значение',
      number_max: 'Максимальное значение',
      number_mean: 'Среднее значение',
      number_std: 'Стандартное отклонение',
      number_25: 'Нижний персентиль (25%)',
      number_50: 'Медиана',
      number_75: 'Верхний персентиль (75%)',
      has: 'Нет',
      has_nan: 'Имеет n/a значения',
      has_empty: 'Имеет пустые значения',
      has_zeros: 'Имеет нулевые значения',
      category_count: 'Количество',
      value_distribution: 'Распределение значений',
      categories_values: 'Категория',
      numerical_values: 'Диапазон значений',
      rows: 'Количество строк - ',
      columns: 'Количество колонок - ',
      handling_result1: 'Превью',
      handling_result2: 'Обработанный источник',
      show_data: 'Таблица данных',
      add_condition: 'Добавить условие',
      remove_column: 'Удаление столбцов',
      rename_column: 'Переименование столбцов',
      replace_nan: 'Замена NaN значений',
      replace_empty: 'Замена пустых значений',
      replace_zeros: 'Замена нулевых значений',
      change_type: 'Изменение типа колонок',
      create_new_column: 'Создание новых колонок',
      column: 'Колонка данных',
      new_name: 'Новое наименование',
      impute_type: 'Вид замены',
      impute_delete: 'Удалить строки',
      impute_median: 'Значение по медиане',
      impute_average: 'Среднее значение',
      impute_most_freq: 'Наиболее частое значение',
      impute_0: 'Нулевое значение',
      impute_empty: 'Пустое значение',
      impute_value: 'Ваше значение',
      value: 'Значение',
      columnd_dtype: 'Тип колонки',
      merge: 'Тип слияния',
      merge_left_join: 'Слияние таблиц (left join)',
      source1: 'Источник 1',
      source2: 'Источник 2',
      merge_key: 'Ключ сокдинения'
    }
  },
  story: {
    list: {
      name: 'Список историй',
      updated: 'Обновлен',
      time: 'Время обновления',
      add: 'Добавить историю',
      minutes: ' минут',
      error_base: 'Ошибка обучения',
      error_stop: 'Обучение остановлено',
      wait: 'В очереди на обучение'
    },
    detail: {
      new: 'Новая история',
      name: 'Наименование истории',
      source: 'Источник',
      choose: 'Выберите',
      models: 'Используемые модели',
      etc: 'др.',
      forecastType: 'Вид прогноза',
      type: 'Тип',
      desc: 'Параметры',
      rows: 'Строк',
      trainning_time: 'Время обучения',
      forecast_quantity: 'Количество прогнозов',
      time: ' мин',
      chart: 'График',
      percentile: 'Сглаживание пиков',
      value: 'Значение (%)',
      value2: 'Значение',
      rolling: 'Сглаживание разброса данных',
      diff: 'Смещение данных',
      diff2: 'Смещение порядка ',
      adfuller: 'Тест Дики-Фуллера порядка ',
      comp: 'Компоненты временного ряда',
      trend: 'Тренд',
      seasonal: 'Сезонный',
      residual: 'Остаточный',
      time_series: 'Прогнозирование временного ряда',
      regression: 'Регрессия',
      clustering: 'Кластеризация',
      classification: 'Классификация',
      clusters: 'Количесто групп',
      all_models: 'Все модели',
      group: 'Группа ',
      best: 'Лучшая модель',
      empty: 'Поле не используется',
      date_hours: 'Величина часа',
      date_days: 'Величина даты',
      date_weeks: 'Величина недели',
      date_months: 'Величина месяца',
      date_quarters: 'Величина квартала',
      date_years: 'Величина года',
      forecast: 'Прогнозируемая величина',
      numerical: 'Влияющая величина (число)',
      binary: 'Влияющая величина (бинарная, только два значения)',
      ordinal: 'Влияющая величина (четкий порядок значений)',
      nominal: 'Влияющая величина (разные значения)',
      category: 'Аналитический разрез',
      analytics: 'Аналитики',
      features_maps: 'Меппинг влияющих величин',
      features_maps_nominal: 'Доступные значения номинальных величин',
      features_map: 'Меппинг величины - ',
      features_map_binary: 'Перетащите значения полей что относится к True, а что к False',
      features_map_ordinal: 'Перетащите значения полей в порядке возрастания',
      mb: ' Мб',
      all_values: 'Все значения',
      default: 'По умолчанию ',
      SARIMAX: {
        name: 'SARIMAX - cезонная интегрированная модель авторегрессии скользящего среднего с доп. регрессорами',
        short: 'SARIMAX',
        default: {
          p: '0-2',
          d: 'ADF тест',
          q: '0-2',
          P: '0-2',
          D: 'CH тест',
          Q: '0-2',
          m: 'auto',
          enforce_stationarity: 'false',
          enforce_invertibility: 'false'
        },
        params: {
          p: ['p', 'Порядок авторегрессий модели (AR)'],
          d: ['d', 'Порядок изменения тренда модели'],
          q: ['q', 'Порядок скользящей средней модели (MA)'],
          P: ['P', 'Порядок авторегрессий (AR) для сезонного компонента модели'],
          D: ['D', 'Порядок изменения тренда для сезонного компонента модели'],
          Q: ['Q', 'Порядок скользящей средней (MA) для сезонного компонента модели'],
          m: ['Период сезонного цикла', 'Период сезонного цикла'],
          enforce_stationarity: ['Обеспечивать неподвижность ряда', 'Требовать или не требовать, чтобы параметры авторегрессии соответствовали процессу стационарности'],
          enforce_invertibility: ['Принудительная инвертируемость', 'Требование или нет соответствия параметров скользящего среднего для инвертируемого процесса']
        }
      },
      HWES: {
        name: 'HWES - модель экспотенциального сглаживания Хольта-Винтерса',
        short: 'HWES',
        default: {
          trend: 'все варианты',
          damped: 'все варианты',
          seasonal: 'все варианты',
          boxcox: 'все варианты',
          bias: 'все варианты'
        },
        params: {
          trend: ['Тренд', 'Тип компонента тренда'],
          damped: ['Затухание', 'Должен ли компонент тренда быть затухающим или нет'],
          seasonal: ['Сезонность', 'Тип сезонного компонента'],
          boxcox: ['Преобразование Бокса-Кокса', 'Должно ли преобразование Бокса-Кокса быть применено к данным в первую очередь'],
          bias: ['Устранение ошибок', 'Устранить ошибку от прогнозных и подогнанных значений, убедившись в том, что средний остаток равен нулю'],
          seasonal_periods: ['Период сезонного цикла']
        }
      },
      PROPHET: {
        name: 'Prophet (Пророк) - модель прогнозирования временных рядов, созданная Facebook',
        short: 'PROPHET',
        default: {
          order_yearly: '10',
          order_weekly: '3',
          interval_width: '0.8'
        },
        params: {
          order_yearly: ['Годовая сезонность', 'Порядок Фурье для годовой сезонности'],
          order_weekly: ['Недельная сезонность', 'Порядок Фурье для недельной сезонности'],
          interval_width: ['Ширина интервала неопределенности', 'Ширина интервала неопределенности']
        }
      },
      XGBOOST: {
        name: 'XGBOOST - модель экстримального градиентного бустинга',
        short: 'XGBOOST',
        default: {
          estimators: '100',
          learning_rates: '0.01',
          diff: 'ADF тест'
        },
        params: {
          estimators: ['Количество деревьев', 'Количество деревьев градиентного бустинга'],
          learning_rates: ['Скорость обучения', 'Скорость обучения, используемая для снижения градиентного шага'],
          diff: ['Разность ряда', 'Определяет разность ряда для достижения стационарности']
        }
      },
      // NDR: {
      //   name: 'Normally Distributed Regression',
      //   short: 'Normally Distributed Regression',
      // },
      // SA: {
      //   name: 'Smoothed Average with damping',
      //   short: 'Smoothed Average with damping',
      // },
      // SLR: {
      //   name: 'Smoothed Linear Regression with damping',
      //   short: 'Smoothed Linear Regression with damping',
      // },
      NGBOOST: {
        name: 'NGBOOST - модель градиентного бустинга от Stanford University',
        short: 'NGBOOST',
        default: {
          estimators: '500',
          learning_rates: '0.01',
          diff: 'ADF тест'
        },
        params: {
          estimators: ['Количество деревьев', 'Количество деревьев градиентного бустинга'],
          learning_rates: ['Скорость обучения', 'Скорость обучения, используемая для снижения градиентного шага'],
          diff: ['Разность ряда', 'Определяет разность ряда для достижения стационарности']
        }
      },
      CATBOOST: {
        name: 'CATBOOST - модель градиентного бустинга от Yandex',
        short: 'CATBOOST',
        default: {
          iterations: '1000',
          learning_rates: '0.03',
          depths: '6',
          diff: 'ADF тест'
        },
        params: {
          iterations: ['Максимальное количество деревьев', 'Максимальное количество деревьев, которое может быть построено при решении проблем машинного обучения'],
          learning_rates: ['Скорость обучения', 'Скорость обучения, используемая для снижения градиентного шага'],
          depths: ['Максимальная глубина дерева', 'Определяет максимальную глубину дерева при обучении'],
          diff: ['Разность ряда', 'Определяет разность ряда для достижения стационарности']
        }
      },
      LIGHTGBM: {
        name: 'LIGHTGBM - модель градиентного бустинга от Microsoft',
        short: 'LIGHTGBM',
        default: {
          iterations: '100',
          learning_rates: '0.1',
          depths: '-1',
          diff: 'ADF тест'
        },
        params: {
          iterations: ['Максимальное количество деревьев', 'Максимальное количество деревьев, которое может быть построено при решении проблем машинного обучения'],
          learning_rates: ['Скорость обучения', 'Скорость обучения, используемая для снижения градиентного шага'],
          depths: ['Максимальная глубина дерева', 'Определяет максимальную глубину дерева при обучении'],
          diff: ['Разность ряда', 'Определяет разность ряда для достижения стационарности']
        }
      },
      LSTM: {
        name: 'LSTM - глубокая нейронная сеть (Long Short-Term Memory)',
        short: 'LSTM',
        default: {
          epochs: '30',
          batch_size: '32'
        },
        params: {
          epochs: ['Количество итераций', 'Сколько раз мы передаем набор данных в нейронную сеть'],
          batch_size: ['Размер партии', 'Общее количество данных для тренировки, представленных в одной партии']
        }
      },
      AR: {
        name: 'Autoregressive model using Conditional Maximum Likelihood (OLS)',
        short: 'AutoRegressive model',
        default: {
          lags: '1-5',
          trend: 'c',
          seasonal: 'True'
        },
        params: {
          lags: ['Лаги', 'Количество лагов для включения в модель'],
          trend: ['Тренд', 'Тренд для включения в модель'],
          seasonal: ['Сезоннность', 'Включать ли сезонные данные в модель']
        }
      },
      YTY: {
        name: 'Year-To-Year Model (YTY)',
        short: 'Year-To-Year Model',
      },
      SES: {
        name: 'Simple Exponential Smoothing (SES)',
        short: 'SES',
      },
      LogarR: {
        name: 'Logarithmic Regression (LogarR)',
        short: 'LogarR',
      },
      ER: {
        name: 'Exponential Regression (ER)',
        short: 'ER',
      },
      // MA: {
      //   name: 'Moving Average (MA)',
      //   short: 'MA',
      //   default: {
      //     q: '0-7'
      //   },
      //   params: {
      //     q: ['q', 'Порядок скользящей средней'],
      //   }
      // },
      // VAR: {
      //   name: 'Vector Autoregression (VAR)',
      //   short: 'VAR',
      // },
      // VARMAX: {
      //   name: 'Vector Autoregressive Moving Average with eXogenous regressors model (VARMAX)',
      //   short: 'VARMAX',
      //   default: {
      //     p: '1-7',
      //     q: '1-7',
      //     trend: 'все варианты',
      //   },
      //   params: {
      //     p: ['p', 'Порядок авторегрессий модели (AR)'],
      //     q: ['q', 'Порядок скользящей средней модели (MA)'],
      //     // trend: ['Тренд', 'Параметр вида тренда'],
      //   }
      // },
      SVR: {
        name: 'Support Vector Regression (SVR)',
        short: 'Support Vector Regression',
        default: {
          kernel: 'rbf'
        },
        params: {
          kernel: ['Кернел', 'Определяет тип ядра (кернела), который будет использоваться в алгоритме']
        }
      },
      DTR: {
        name: 'Decision Tree Regression (DTR)',
        short: 'Decision Tree Regression',
        default: {
          criterion: 'mse'
        },
        params: {
          criterion: ['Критерий', 'Функция измерения качества разделения данных']
        }
      },
      RFR: {
        name: 'Random Forest Regression (RFR)',
        short: 'Random Forest Regression',
        default: {
          n_estimators: '100'
        },
        params: {
          n_estimators: ['Количество деревьев', 'Количество деревьев в лесу']
        }
      },
      PR: {
        name: 'Polynomial Regression (PR)',
        short: 'Polynomial Regression',
        default: {
          degree: '2'
        },
        params: {
          degree: ['Cтепень полинома', 'Cтепень полинома']
        }
      },
      XGBOOST_reg: {
        name: 'XGBOOST - модель экстримального градиентного бустинга',
        short: 'XGBOOST',
        default: {
          n_estimators: '100',
          learning_rate: '0.01'
        },
        params: {
          n_estimators: ['Количество деревьев', 'Количество деревьев градиентного бустинга'],
          learning_rate: ['Скорость обучения', 'Скорость обучения, используемая для снижения градиентного шага']
        }
      },
      NGBOOST_reg: {
        name: 'NGBOOST - модель градиентного бустинга от Stanford University',
        short: 'NGBOOST',
        default: {
          n_estimators: '500',
          learning_rate: '0.01'
        },
        params: {
          n_estimators: ['Количество деревьев', 'Количество деревьев градиентного бустинга'],
          learning_rate: ['Скорость обучения', 'Скорость обучения, используемая для снижения градиентного шага']
        }
      },
      CATBOOST_reg: {
        name: 'CATBOOST - модель градиентного бустинга от Yandex',
        short: 'CATBOOST',
        default: {
          iterations: '1000',
          learning_rate: '0.03',
          depth: '6'
        },
        params: {
          iterations: ['Максимальное количество деревьев', 'Максимальное количество деревьев, которое может быть построено при решении проблем машинного обучения'],
          learning_rate: ['Скорость обучения', 'Скорость обучения, используемая для снижения градиентного шага'],
          depth: ['Максимальная глубина дерева', 'Определяет максимальную глубину дерева при обучении']
        }
      },
      LIGHTGBM_reg: {
        name: 'LIGHTGBM - модель градиентного бустинга от Microsoft',
        short: 'LIGHTGBM',
        default: {
          num_iterations: '100',
          learning_rate: '0.1',
          max_depth: '-1'
        },
        params: {
          num_iterations: ['Максимальное количество деревьев', 'Максимальное количество деревьев, которое может быть построено при решении проблем машинного обучения'],
          learning_rate: ['Скорость обучения', 'Скорость обучения, используемая для снижения градиентного шага'],
          max_depth: ['Максимальная глубина дерева', 'Определяет максимальную глубину дерева при обучении']
        }
      },
      LR: {
        name: 'Linear Regression (LR)',
        short: 'Linear Regression',
      },
      LogR: {
        name: 'Logistic Regression (LogR)',
        short: 'Logistic Regression',
        default: {
          penalty: 'l2'
        },
        params: {
          penalty: ['Пенальти', 'Используется для уточнения нормы, используемой при пенальти']
        }
      },
      KNN: {
        name: 'K-Nearest Neighbors (KNN)',
        short: 'K-Nearest Neighbors',
        default: {
          n_neighbors: '5'
        },
        params: {
          n_neighbors: ['Количество соседей', 'Количество соседей, которое по умолчанию используется для запросов']
        }
      },
      KMEANS: {
        name: 'K-Nearest Neighbors',
        short: 'K-Nearest Neighbors'
      },
      AGGLOMERATIVE_CLUSTERING: {
        name: 'Agglomerative Clustering',
        short: 'Agglomerative Clustering'
      },
      DBSCAN: {
        name: 'Density-Based Spatial Clustering of Applications with Noise (DBSCAN)',
        short: 'DBSCAN'
      },
      BIRCH: {
        name: 'BIRCH использует алгоритм кластеризации Birch',
        short: 'BIRCH'
      },
      SVM: {
        name: 'Support Vector Machine (SVM)',
        short: 'Support Vector Machine',
        default: {
          kernel: 'rbf'
        },
        params: {
          kernel: ['Кернел', 'Определяет тип ядра (кернела), который будет использоваться в алгоритме']
        }
      },
      NB: {
        name: 'Naive Bayes (NB)',
        short: 'Naive Bayes',
      },
      DTC: {
        name: 'Decision Tree Classifier (DTC)',
        short: 'Decision Tree Classifier',
        default: {
          criterion: 'gini'
        },
        params: {
          criterion: ['Критерий', 'Функция измерения качества разделения данных']
        }
      },
      RFC: {
        name: 'Random Forest Classifier (RFC)',
        short: 'Random Forest Classifier',
        default: {
          n_estimators: '100',
          criterion: 'gini'
        },
        params: {
          n_estimators: ['Количество деревьев', 'Количество деревьев в лесу'],
          criterion: ['Критерий', 'Функция измерения качества разделения данных']
        }
      },
      XGBOOST_class: {
        name: 'XGBOOST - модель экстримального градиентного бустинга',
        short: 'XGBOOST',
        default: {
          n_estimators: '100',
          learning_rate: '0.01',
          max_depth: '3',
          random_state: '0',
          gamma: '0',
          min_child_weight: '1',
          max_delta_step: '0',
          booster: 'gbtree',
          subsample: '1',
          colsample_bytree: '1',
          colsample_bylevel: '1',
          colsample_bynode: '1',
          reg_alpha: '0',
          reg_lambda: '1',
          scale_pos_weight: '1',
          early_stopping_rounds: 'None'
        },
        params: {
          n_estimators: ['Количество деревьев', 'Количество деревьев градиентного бустинга'],
          learning_rate: ['Скорость обучения', 'Скорость обучения, используемая для снижения градиентного шага'],
          max_depth: ['Максимальная глубина дерева', 'Определяет максимальную глубину дерева при обучении'],
          random_state: ['Случайное состояние', 'Определяет посев случайного числа'],
          gamma: ['Гамма', 'Минимальное снижение потерь, необходимое для дальнейшего разбиения на узел листа дерева'],
          min_child_weight: ['Минимальный вес звена', 'Минимальная сумма необходимого веса звена'],
          max_delta_step: ['Максимальная дельта шаг', 'Максимальный дельта шаг при выходе листа'],
          booster: ['Бустер', 'Какой бустер использовать'],
          subsample: ['Подвыборка', 'Соотношение подвыборки учебных экземпляров'],
          colsample_bytree: ['Подвыборка по дереву', 'Соотношение подвыборки колонок при построении каждого дерева'],
          colsample_bylevel: ['Подвыборка по уровню', 'Соотношение подвыборки колонок при построении каждого уровня'],
          colsample_bynode: ['Подвыборка по звену', 'Соотношение подвыборки колонок при построении каждого звена'],
          reg_alpha: ['Альфа', 'Показатель регуляризации L1 по весам'],
          reg_lambda: ['Лямбда', 'Показатель регуляризации L2 по весам'],
          scale_pos_weight: ['Масштабирование веса позитивных значений', 'Контроль баланса положительных и отрицательных весов'],
          early_stopping_rounds: ['Раунд ранней остановки', 'Активирует раннюю остановку']
        }
      },
      CATBOOST_class: {
        name: 'CATBOOST - модель градиентного бустинга от Yandex',
        short: 'CATBOOST',
        default: {
          iterations: 'None',
          learning_rate: 'None',
          depth: 'None',
          random_state: 'None',
          l2_leaf_reg: 'None',
          model_size_reg: 'None',
          rsm: 'None',
          min_data_in_leaf: 'None',
          subsample: 'None',
          class_weights: 'None',
          early_stopping_rounds: 'None'
        },
        params: {
          iterations: ['Максимальное количество деревьев', 'Максимальное количество деревьев, которое может быть построено при решении проблем машинного обучения'],
          learning_rate: ['Скорость обучения', 'Скорость обучения, используемая для снижения градиентного шага'],
          depth: ['Максимальная глубина дерева', 'Определяет максимальную глубину дерева при обучении'],
          random_state: ['Случайное состояние', 'Определяет посев случайного числа'],
          l2_leaf_reg: ['Регуляризация листьев L2', 'Коэффициент регуляризации функции затрат L2'],
          model_size_reg: ['Регуляризация размера модели', 'Коэффициент регуляризации размера модели'],
          rsm: ['Отношение подвыборки', 'Соотношение колонок подвыборки при построении каждого дерева'],
          min_data_in_leaf: ['Минимальные выборки в листе', 'Минимальные обучающие выборки в листе'],
          subsample: ['Подвыборка в партии', 'Подвыборка в партии'],
          class_weights: ['Вес для 1 класса', 'Значение используется в качестве мультипликатора для веса класса 1'],
          early_stopping_rounds: ['Раунд ранней остановки', 'Активирует раннюю остановку']
        }
      },
      LIGHTGBM_class: {
        name: 'LIGHTGBM - модель градиентного бустинга от Microsoft',
        short: 'LIGHTGBM',
        default: {
          n_estimators: '100',
          learning_rate: '0.1',
          max_depth: '-1',
          boosting_type: 'gbdt',
          num_leaves: '31',
          subsample_for_bin: '200000',
          random_state: 'None',
          scale_pos_weight: '1',
          min_split_gain: '0',
          min_child_weight: '0.001',
          min_child_samples: '20',
          subsample: '1',
          subsample_freq: '0',
          colsample_bytree: '1',
          reg_alpha: '0',
          reg_lambda: '0',
          early_stopping_rounds: 'None',
        },
        params: {
          n_estimators: ['Количество деревьев', 'Количество деревьев градиентного бустинга'],
          learning_rate: ['Скорость обучения', 'Скорость обучения, используемая для снижения градиентного шага'],
          max_depth: ['Максимальная глубина дерева', 'Определяет максимальную глубину дерева при обучении'],
          boosting_type: ['Тип бустинга', 'Определяет тип бустинга'],
          num_leaves: ['Максимальное количество листьев', 'Максимальное количество листьев деревьев'],
          subsample_for_bin: ['Подвыборка для корзины', 'Количество подвыборок для построения корзины'],
          random_state: ['Случайное состояние', 'Определяет посев случайного числа'],
          scale_pos_weight: ['Масштабирование веса позитивных значений', 'Контроль баланса положительных и отрицательных весов'],
          min_split_gain: ['Минимальное снижение потерь при разбиении', 'Минимальное снижение потерь, необходимое для дальнейшего разбиения на узел листа дерева'],
          min_child_weight: ['Минимальный вес звена', 'Минимальный вес, необходимый для звена (листа)'],
          min_child_samples: ['Минимальное количество подвыборок звена', 'Минимальное количество подвыборок, необходимых звену (лист)'],
          subsample: ['Подвыборка', 'Соотношение подвыборки учебных экземпляров'],
          subsample_freq: ['Частота подвыборки', 'Частота подвыборки'],
          colsample_bytree: ['Подвыборка по дереву', 'Соотношение подвыборки колонок при построении каждого дерева'],
          reg_alpha: ['Альфа', 'Показатель регуляризации L1 по весам'],
          reg_lambda: ['Лямбда', 'Показатель регуляризации L2 по весам'],
          early_stopping_rounds: ['Раунд ранней остановки', 'Активирует раннюю остановку']
        }
      },
      NGBOOST_class: {
        name: 'NGBOOST - модель градиентного бустинга от Stanford University',
        short: 'NGBOOST',
        default: {
          n_estimators: '500',
          learning_rate: '0.01',
          random_state: 'None',
          minibatch_frac: '1',
          col_sample: '1',
          early_stopping_rounds: 'None',
        },
        params: {
          n_estimators: ['Количество деревьев', 'Количество деревьев градиентного бустинга'],
          learning_rate: ['Скорость обучения', 'Скорость обучения, используемая для снижения градиентного шага'],
          random_state: ['Случайное состояние', 'Определяет посев случайного числа'],
          minibatch_frac: ['Строки подвыборки', 'Процент подвыборок строк для использования в каждой итерации'],
          col_sample: ['Колонки подвыборки', 'Процент подвыборок колонок для использования в каждой итерации'],
          early_stopping_rounds: ['Раунд ранней остановки', 'Активирует раннюю остановку']
        }
      },
      optimal_config: 'Оптимальные параметры модели: ',
      empty_config: 'нет параметров',
      search: 'Поиск',
      trainType: 'Тип обучения',
      embedded: 'Встроенный',
      custom: 'Пользовательский',
      class: 'Класс ',
      axisX: 'Ось X',
      axisY: 'Ось Y',
      dimensionality_reduction: 'Снижение размерности',
      dr_name: 'Алгоритм',
      dr_types: ['Principal Component Analysis (PCA)', 'Kernel PCA', 'Linear Discriminant Analysis (LDA)'],
      dr_dimensions: 'Целевое количество размерностей',
      dr_current_dimensions: 'Текущее количество размерностей',
      target_accuracy_dimensions: 'Точность при целевом количестве размерностей',
      test_size: 'Размер тестового сета',
      feature_importance: 'Значимость факторов',
      feature_imp: 'Значимость фактора',
      feature_algorithm: 'Алгоритм',
      features: 'Факторы',
      cross_validation: 'Кросс валидация',
      number_of_folds: 'Количество групп',
      balanced: 'Балансировка классов',
      probability_threshold: 'Порог вероятности для класса 1',
      probability_threshold_value: 'Вероятность',
      hyperparameter_tuning: 'Оптимизация гиперпараметров',
      hyperparameter_tuning_value: 'Модель оптимизации',
      hyperparameter_tuning_iterations: 'Максимальное кол-во итераций',
      target_scaling: 'Масштабирование прогнозных значений',
      train_accuracy: 'Учет точности прогноза на тренировочном ряде',
      eliminate_negative_values: 'Устранить отрицательные значения',
      scaler: 'Модель масштабирования',
      test_date: 'Дата начала тестового ряда',
      choose_date: 'Указать дату',
      accuracy: 'Метод определения точности прогноза',
      time_features: 'Доп. факторы времени',
      feature_month: 'Месяц',
      feature_year: 'Год',
      feature_day_of_week: 'День недели',
      feature_week: 'Номер недели',
      feature_last_month_lag: 'Лаг прошлого месяца',
      feature_3_last_month_lag: 'Лаг 3-х прошлых месяцев',
      feature_12_last_month_lag: 'Лаг 12-и прошлых месяцев',
      feature_last_week_lag: 'Лаг прошлой недели',
      feature_2_last_week_lag: 'Лаг 2-х прошлых недель',
      feature_4_last_week_lag: 'Лаг 4-х прошлых недель',
      feature_prior_1_year_lag: 'Лаг прошлого года',
      feature_prior_2_year_lag: 'Лаг 2-х прошлых лет',
      elbow_method: 'Определение количества групп',
      elbow_x: 'Количество групп',
      elbow_y: 'Сумма среднеквадратичной ошибки',
      noise: 'Шум данных',
      base_conf: 'Базовые',
      cpu: 'Количество параллельных процессов',
      base_config: {
        cpu: {
          name: 'Количество параллельных процессов',
          desc: 'Определяет количество используемых параллельных процессов для ускорения обучения модели, обработки данных и прогнозирования'
        }
      }
    },
    forecast: {
      status: 'Статус',
      trained: 'Обучена',
      not_trained: 'Необучена',
      steps: 'Период прогноза',
      choose: 'Выберите',
      models: 'Модель прогноза',
      model: 'Модель',
      best: 'Лучшая модель',
      all_values: 'Все значения',
      actual: 'Факт',
      forecast: 'Прогноз ',
      // group_forecast: 'Прогноз группы',
      // regression_forecast: 'Прогноз величины',
      // classification_forecast: 'Прогноз класса',
      // numerical_forecast: 'Прогноз: ',
      step_period: {
        date_hours: ' (часов)',
        date_days: ' (дней)',
        date_weeks: ' (недель)',
        date_months: ' (месяцев)',
        date_quarters: ' (кварталов)',
        date_years: ' (лет)'
      },
      upload: 'Данные для прогноза',
      file_configs: 'Настройки в формате json',
      probability: 'Прогнозировать вероятность каждого класса',
      cv: {
        name: 'Cross Validation Error',
        desc: 'Средняя ошибка прогноза при кросс валидации'
      },
      // cv_mean_deviation: {
      //   name: 'Cross Validation Mean Deviation',
      //   desc: 'Среднее отклонение прогноза при кросс валидации'
      // },
      // cv_std: {
      //   name: 'Cross Validation Standard Deviation',
      //   desc: 'Среднее квадратичное отклонение прогноза при кросс валидации'
      // },
      rmse: {
        name: 'RMSE',
        desc: 'Root Mean Square Error (RMSE) - корень cреднеквадратическоый ошибки'
      },
      mse: {
        name: 'MSE',
        desc: 'Mean Square Error (MSE) - cреднеквадратическая ошибка'
      },
      mape: {
        name: 'MAPE',
        desc: 'Mean Absolute Percentage Error (MAPE) - cредний процент абсолютной ошибки'
      },
      mae: {
        name: 'MAE',
        desc: 'Mean Absolute Error (MAE) - средняя абсолютная ошибка'
      },
      bias: {
        name: 'bias',
        desc: 'Средняя ошибка прогноза'
      },
      accuracy_score: {
        name: 'Accuracy',
        desc: '% предсказанных классов совпадают с фактическим набором классов'
      },
      precision_score: {
        name: 'Precision',
        desc: 'Отношение tp / (tp + fp), где tp - число истинных положительных результатов, а fp - число ложных срабатываний'
      },
      average_precision_score: {
        name: 'Average Precision',
        desc: 'Средняя точность суммирует кривую precision-recall как средневзвешенное значение precision, достигнутое на каждом пороге, с увеличением recall по сравнению с предыдущим пороговым значением'
      },
      recall_score: {
        name: 'Recall',
        desc: 'Отношение tp / (tp + fn), где tp - число истинных положительных результатов и fn - число ложных отрицательных'
      },
      f1_score: {
        name: 'F1 score',
        desc: 'F1 score может быть интерпретирован как средневзвешенное значение Precision и Recall. Формула для оценки F1: F1 = 2 * (precision * recall) / (precision + recall)'
      },
      roc_auc_score: {
        name: 'ROC AUC',
        desc: 'Вычисляет область под рабочей характеристической кривой рисивера (ROC AUC)'
      },
      confusion_matrix: {
        name: 'Confusion Matrix',
        desc: 'Вычислите confusion matrix для оценки точности классификации'
      },
    },
  },
  integration: {
    export: 'Экспорт данных',
    export_success: 'Модель начала обучаться, можете закрывать окно',
    import: 'Импорт данных',
    export_import: 'Экспорт и импорт данных',
    import_success: 'Началось формирование прогноза и его импорт, можете закрывать окно',
    status: 'Статус',
    success: 'Данные готовы для обучения',
    check: 'История готова для прогнозирования',
    progress: 'Просмотр данных',
    error: 'Ошибка - ',
    token: 'Токен '
  },
  buttons: {
    detail: 'Подробнее',
    change: 'Изменить',
    save: 'Сохранить',
    update: 'Обновить',
    download: 'Скачать',
    download_all: 'Скачать все',
    delete: 'Удалить',
    train: 'Обучить',
    retrain: 'Переобучить',
    forecast: 'Прогноз',
    settings: 'Параметры моделей',
    import: 'Импорт прогнозов',
    jupyter: 'Открыть Jupyter Notebook',
    preview: 'Превью',
    duplicate: 'Дублировать',
    test_connection: 'Тест подключения',
    upload_new: 'Загрузить файл',
    create_new_empty_file: 'Создать пустой файл',
    start: 'Запуск',
    restart: 'Перезапустить'
  },
  notifications: {
    story_trained: ['История ', ' обучена!'],
    forecast_prepared: ['Прогноз ', ' подготовлен и отправлен!'],
    success_registration: 'Вам на почту отправлено письмо для подтверждения email',
    reset_password: 'Вам на почту отправлено письмо для сброса пароля',
    renew_password: 'Ваш пароль сброшен',
    set_password: 'Ваш пароль установлен',
    username_updated: 'Имя пользователя изменено',
    new_user: 'Новый пользователь зарегистрирован, на почту отправлено письмо для подтверждения email',
    compnay_name_updated: 'Имя компании изменено',
    compnay_anaplan_updated: 'Логин и пароль Anaplan изменены',
    password_updated: 'Ваш пароль изменен',
    source_uploaded: 'Источник загружен!',
    source_updated: 'Источник обновлен!',
    source_deleted: 'Источник удален!',
    story_started_train: 'История начала обучаться!',
    story_retrained: 'История начала переобучаться!',
    story_deleted: 'История удалена!',
    anaplan_export: 'Импорт данных и обучение истории началось!',
    anaplan_import: 'Подготовка прогноза и импорт данных начался!',
    limit_train_time: 'Время обучения модели не должно превышать 20 минут',
    story_error_base: ['При обучении истории ', ' возникла ошибка!'],
    story_error_stop: ['Обучение истории ', ' остановлено!'],
    message_sent: 'Ваш запрос отправлен',
    test_connection_success: 'Подключение работает!',
    test_connection_failure: 'Ошибка подключения!',
    task_success: ['Задача ', ' выполнена успешно!'],
    task_failed: ['Задача ', ' прошла с ошибкой!']
  },
  validations: {
    required: 'Поле не заполенно',
    max_length: ['Длина имени не должна превышать ', ' символов'],
    min_length_password: ['Длина пароля не должна быть меньше ', ' символов'],
    max_length_password: ['Длина пароля не должна превышать ', ' символов'],
    username_format: 'Поле должно содержать только буквы, цифры, пробелы и символ _',
    anaplan_name: 'Export / Import Name должно содержать только буквы, цифры, пробелы и символа _ и -',
    email: 'Неправильный формат email',
    password_complexity: 'Пароль должен содержать хотя бы одну заглавную букву и одну цифру',
    same_as: 'Пароли не совпадают',
    equal_true: 'Требуется согласие',
    company_format: 'Поле должно содержать только буквы, цифры, пробелы и символа _ и -',
    empty_file: 'Файл не вложен',
    wrong_file: 'Формат данных не допустим. Доступные форматы: xls, xlsx, xlsm, xlsb, odf, csv, json',
    wrong_photo: 'Формат данных не допустим. Доступные форматы: jpeg, png, jfif',
    wrong_format: 'Поле имеет некорректный формат',
    number: 'Поле должно быть числом',
    message_format: 'Поле должно содержать только буквы, цифры, пробелы и символы _.,!?',
    answer: 'Неправильный ответ на вопрос'
  },
  connections: {
    list: {
      name: 'Список подключений',
      add: 'Добавить подключение',
      created: 'Создан',
      type: 'Тип'
    },
    detail: {
      new: 'Новое подключение',
      name: 'Наименование подключения',
      choose: 'Выберите',
      connection_type: 'Тип подключения',
      anaplan: {
        login: 'Логин',
        password: 'Пароль'
      },
      postgres: {
        host: 'Хост базы данных',
        port: 'Порт базы данных',
        database: 'Наименование базы данных',
        user: 'Пользователь',
        password: 'Пароль'
      },
      optimacros: {
        host: 'Хост сервиса',
        service: 'Наименование сервиса API',
        token: 'Токен авторизации',
        user: 'Пользователь FTP',
        password: 'Пароль FTP',
        add_column: 'Добавить колонку'
      },
      csv: {
        file_name: 'Имя файла',
        size: 'Размер',
        rows: 'Кол-во строк',
        updated_at: 'Обновлен',
        mb: ' Мб',
        create_empty_file: 'Создание пустого файла'
      }
    }
  },
  processes: {
    list: {
      name: 'Список процессов',
      add: 'Добавить процесс',
      created: 'Создан'
    },
    detail: {
      new: 'Новый процесс',
      name: 'Наименование процесса',
      choose: 'Выберите',
      source: 'Источник данных',
      target: 'Потребитель данных',
      anaplan: {
        procedures: 'Процедуры'
      },
      csv: {
        files: 'Файлы'
      },
      optimacros: {
        type: 'Тип',
        dimension: 'Измерение',
        measure: 'Куб/Свойство'
      },
      source_columns: 'Колонки источника',
      target_columns: 'Колонки потребителя',
      scheduler: {
        name: 'Частота запуска процесса',
        one_time: 'Разово (не повторяется)',
        each: 'Раз в ...',
        scheduler_start_date: 'Дата начала',
        frequency: 'Частота',
        minute: 'Минута',
        hour: 'Час',
        day: 'День',
        week: 'Неделя',
        month: 'Месяц',
        week_days: 'Дни недели',
        monday: 'Понедельник',
        tuesday: 'Вторник',
        wednesday: 'Среда',
        thursday: 'Четверг',
        friday: 'Пятница',
        saturday: 'Суббота',
        sunday: 'Воскресенье'
      }
    }
  },
  tasks: {
    list: {
      name: 'Список запросов',
      add: 'Запустить запрос',
      process: 'Процесс',
      createdAt: 'Создан',
      updatedAt: 'Обновлен',
      status: 'Статус',
      rows: 'Обработано строк',
      choose: 'Выберите',
      sync: 'Синхронизация',
      finished: 'Завершено',
      error: 'Ошибка'
    }
  },
  docs: {
    docs: 'Документация',
    navs: ['Начало', 'Аккаунт', 'Источники', 'Истории'],
    request: 'Параметры запроса',
    response: 'Ответ',
    json: 'JSON-ответ вида ',
    empty: 'Отсутствуют',
    where: 'где',
    ok: "Ответ в виде 'ок!'",
    excel: 'Excel-файл с данными',
    get_started: 'Первые шаги',
    account: {
      name: 'Аккаунт',
      api: [
        'API',
        'Документация по интеграции с Brain2Logic по API приведена по ссылке ',
      ],
      registration: [
        'Регистрация',
        'Для создания учетной записи необходимо зарегистрироваться по ссылке ',
        ` При регистрации необходимо задать имя и email пользователя, а также наименование компании, к которой
        привязываются пользователи системы. После успешной регистрации на email пользователя придет письмо с
        просьбой подтвердить почту пользователя. После подтверждения почты, система автоматический направит
         в окно входа `
      ],
      login: ['Вход', 'Для входа в систему необходимо перейти по ссылке ', ' и ввести email и пароль.'],
      change_account: ['Изменение данных учетной записи', 'Для изменения данных учетной записи необходимо перейти по ссылке ', '. Здесь можно поменять имя пользователя и компании, изменить пароль.'],
      new_user: ['Добавление новых пользователей', 'Для добавления новых пользователей необходимо перейти по ссылке ', '. Здесь можно добавить новых пользователей.'],
      auth: ['Авторизация API-запросов', 'Во все API-запросы необходимо добавлять заголовок (Headers) с токеном пользователя вида '],
      api_login: 'Получение токена для доступа к API запросам',
      username: 'почта пользователя',
      password: 'пароль пользователя',
      token: 'уникальный токен пользователя'
    },
    sources: {
      name: 'Источники',
      list: [
        'Список источников',
        'По ссылке ',
        ` доступен текущий список источников для историй (модель, обученная на выбранном источнике).
        Под каждым источником есть стрелочка, при нажатии которой, появляется список базовой информации об
        источнике: id номер источника (важен для API), дата и время загрузки, количество строк данных и
        размер источника в мегабайтах.`
      ],
      add: [
        'Добавление источника',
        `Для добавления нового источника необходимо в списке источников нажать на зеленый плюс, после чего
        перейдете в меню загрузки источника. В новом окне необходимо выбрать файл-источник и присвоить имя источнику.
        На текущий момент поддерживаются следующие форматы данных: Excel (.xls, .xlsx, .xlsm, .xlsb), OpenDocument
        Format (.odf), CSV и JSON. Данные в Excel должны быть расположены на первом листе файла, а заголовки таблицы
         расположены в первой строке листа Как только введено имя источника и загружен файл-источник, нажимаем на кнопку
         "Сохранить" и источник сохранится в системе.`
      ],
      update: ['Обновление источника', 'Для обновления источника заходим в соответствующий источник, выбираем новый файл-источник, меняем имя источника (если необходимо) и нажимаем на кнопку "Обновить".'],
      delete: ['Удаление источника', 'Для удаления источника заходим в соответствующий источник и нажимаем на кнопку "Удалить".'],
      download: ['Скачивание источника', 'Для скачивания источника заходим в соответствующий источник и нажимаем на кнопку "Скачать". Источник будет скачан в формате Excel (.xlsx).'],
      api_list: 'Получение списка всех источников',
      api_list_json: 'JSON-ответ в виде массива источников с параметрами:',
      id: 'уникальный номер источника',
      source_name: 'имя источника',
      source_name_update: 'имя источника данных (опционально, если необходимо изменить наименование)',
      ext: 'расширение загруженного файла',
      ready: 'готовность источника',
      rows: 'количество строк данных источника',
      size: 'размер источника в байтах',
      created: 'дата создания источника',
      updated: 'дата изменения источника',
      company: 'уникальный номер компании, к которой относится источник',
      columns: 'список наименований колонок данных',
      values: 'значения первых 100 строк данных',
      api_get: 'Получить информацию об источнике по id',
      api_get_json: 'JSON-ответ по источнику с параметрами:',
      api_add: 'Добавить новый источник',
      add_header: 'Необходимо добавлять заголовок (Headers) вида {"Content-Type": "multipart/form-data"} для загрузки файла-источника',
      file: 'файл с данными и допустимым форматом',
      api_put: 'Обновить информацию об источнике по id',
      api_delete: 'Удалить источник по id',
      api_download: 'Скачать данные источника в Excel'
    },
    stories: {
      name: 'Истории',
      list: [
        'Список историй',
        'По ссылке ',
        ` доступен текущий список историй. Под каждым источником есть стрелочка, при нажатии которой, появляется список базовой информации об истории:
        id номер истории (важен для API), дата и время обновления, последнее время обучения истории в минутах.`
      ],
      add: [
        'Добавление истории',
        `Для добавления новой истории необходимо в списке историй нажать на зеленый плюс,
        после чего перейдете в меню создания истории. В новом окне необходимо выбрать источник для обучения истории, присвоить имя истории
        и выбрать вид прогноза (пока доступен вариант Временный ряд). В поле Используемые модели по умолчанию стоит Лучшая модель (подбирается оптимальная по точности модель),
        но пользователь вправе выбрать несколько или одну конкретную модель обучения. После загрузки таблицы источника, напротив каждой колонки данных необходимо выбрать ее тип.
        Доступны следующие типы:`,
        `Справа дана общая информация об источнике и возможность посмотреть данные на графике.
        Как только все данные заполнены и выбраны, нажимаем на кнопку "Обучить" и модель начнет обучаться в фоновом режиме`
      ],
      empty: ['Поле не используется', 'система пропускает данное поле'],
      date_hours: ['Величина часов', 'колонка с временной шкалой с агрегацией до часов'],
      date_days: ['Величина даты', 'колонка с временной шкалой с агрегацией до дней'],
      date_week: ['Величина недели', 'колонка с временной шкалой с агрегацией до недель'],
      date_months: ['Величина месяца', 'колонка с временной шкалой с агрегацией до месяцев'],
      date_quarters: ['Величина квартала', 'колонка с временной шкалой с агрегацией до кварталов'],
      date_years: ['Величина года', 'колонка с временной шкалой с агрегацией до годов'],
      forecast_value: ['Прогнозируемая величина', 'целевая величина для прогнозирования'],
      feature: ['Влияющая величина', 'влияющая величина на прогноз, например, температура, праздничные дни и т.д.'],
      category: ['Аналитический разрез', 'разрез данных для прогнозирования, например, продукты, рынки и т.д.'],
      get: [
        'Просмотр истории',
        `После окончания обучения истории пользователь получить push-уведомление в окне браузера.
        После заходим в соответствующую историю и смотрим результаты обучения истории. Справа приведена информация об используемых моделях прогнозирования и абсолютной ошибке.
        Можно щелкнуть по каждой модели и посмотреть на графике насколько прогноз модели и факт отличаются. Система по умолчанию выбирает лучшую модель из используемых.`
      ],
      put: ['Обновление истории', 'Для обновления истории заходим в соответствующую историю, нажимаем на кнопку "Переобучить" и модель начнет переобучаться в фоновом режиме с учетом новых данных.'],
      patch: [
        'Изменение истории',
        `Для изменения истории заходим в соответствующую историю, нажимаем на кнопку "Изменить". Меняем источник, имя и типы колонок, если необходимо.
        Нажимаем на кнопку "Переобучить" и модель начнет переобучаться в фоновом режиме с учетом новых данных.`
      ],
      delete: ['Удаление истории', 'Для удаления истории заходим в соответствующую историю и нажимаем на кнопку "Удалить".'],
      forecast: [
        'Получение прогноза',
        `Для получения прогноза истории заходим в соответствующую историю, указываем шаг прогноза (например, 12 -
          прогноз на 12 месяцев вперед от последней даты данных истории) и нажимаем на кнопку "Прогноз". Прогноз будет наложен на график.`
      ],
      download: [
        'Скачивание прогноза',
        `Для скачивания прогноза истории заходим в соответствующую историю, указываем шаг прогноза (например, 12 -
          прогноз на 12 месяцев вперед от последней даты данных истории) и нажимаем на кнопку "Скачать". Прогноз будет скачан в формате Excel (.xlsx).`
      ],
      api_list: 'Получение списка всех историй',
      api_list_json: 'JSON-ответ в виде массива историй с параметрами:',
      id: 'уникальный номер истории',
      story_name: 'имя истории',
      ready: 'готовность истории (если история в процессе обучения, то история не готова)',
      source: 'уникальный номер источника, используемого для обучения модели',
      created: 'дата создания истории',
      updated: 'дата изменения источника',
      time: 'последнее время обучения истории в минутах',
      api_get: 'Получить информацию об истории по id',
      api_get_json: 'JSON-ответ по истории с параметрами:',
      data: 'результаты прогнозов моделей',
      company: 'уникальный номер компании, к которой относится история',
      columns: 'список наименований колонок данных',
      values: 'значения строк данных',
      api_add: 'Добавить новую историю',
      forecast_type: 'time_series (временный ряд)',
      columns_list: `– массив типов колонок в порядке следования колонок в источнике
      (empty (поле не используется), date_hours (величина часов), date_days (величина даты), date_weeks (величина недели), date_months (величина месяца), date_quarters (величина кварталов), date_years (величина лет), forecast (прогнозируемая величина), feature (влияющая величина), category (аналитический разрез).
       Например, ['date_days', 'forecast', 'empty', 'feature', 'feature']`,
      models: `массив типов моделей обучения
      (best (по умолчанию используется лучшая модель), SARIMAX, HWES, PROPHET, XGBOOST, CATBOOST, LIGHTGBM и LSTM.
       Например, ['best'] и ['SARIMAX', 'PROPHET']`,
      story_async_update: 'История начала в фоне обучаться',
      api_put: 'Обновить историю по id',
      api_patch: 'Обновить структуру истории по id',
      api_delete: 'Удалить историю по id',
      api_forecast: 'Получить прогноз по истории',
      steps: 'количество шагов прогноза (например, 12 - прогноз на 12 месяцев вперед от последней даты данных истории)',
      model: `по какой моделе вывести прогноз
      (best (по умолчанию используется лучшая модель), SARIMAX, HWES, PROPHET, XGBOOST, CATBOOST, LIGHTGBM и LSTM.
       Например, 'best' и 'SARIMAX'`,
      forecast_ready: 'Если история находится в процессе обучения (параметр ready равен false), то прогноз не будет получен и будет дан соответствующий ответ',
      api_forecast_json: 'JSON-ответ по прогнозу вида:',
      forecast_dates: 'массив дат, на который составлен прогноз',
      forecast_forecast: 'массив значений прогноза',
      api_download: 'Скачать данные прогноза в Excel'
    }
  }
};
