<template>
  <v-card>
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t('docs.stories.name') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <h3 class="pb-2">{{ $t('docs.stories.list[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.stories.list[1]') }}<a :href="`/#/stories`">{{ `${host}/#/stories` }}</a>{{ $t('docs.stories.list[2]') }}</p>
      <h3 class="pb-2">{{ $t('docs.stories.add[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.stories.add[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.empty[0]') }}</span> – {{ $t('docs.stories.empty[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.date_days[0]') }}</span> – {{ $t('docs.stories.date_days[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.date_week[0]') }}</span> – {{ $t('docs.stories.date_week[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.date_months[0]') }}</span> – {{ $t('docs.stories.date_months[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.forecast_value[0]') }}</span> – {{ $t('docs.stories.forecast_value[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.feature[0]') }}</span> – {{ $t('docs.stories.feature[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.category[0]') }}</span> – {{ $t('docs.stories.category[1]') }}</p>
      <p class="mb-1">{{ $t('docs.stories.add[2]') }}</p>
      <h3 class="pb-2">{{ $t('docs.stories.get[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.stories.get[1]') }}</p>
      <h3 class="pb-2">{{ $t('docs.stories.put[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.stories.put[1]') }}</p>
      <h3 class="pb-2">{{ $t('docs.stories.patch[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.stories.patch[1]') }}</p>
      <h3 class="pb-2">{{ $t('docs.stories.delete[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.stories.delete[1]') }}</p>
      <h3 class="pb-2">{{ $t('docs.stories.forecast[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.stories.forecast[1]') }}</p>
      <h3 class="pb-2">{{ $t('docs.stories.download[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.stories.download[1]') }}</p>
      <h3 class="pb-2">API</h3>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="primary--text pr-4">GET</span>
              <span class="primary--text pr-4">{{ host }}/story/</span>
              <span>{{ $t('docs.stories.api_list') }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1">{{ $t('docs.empty') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.stories.api_list_json') }}</p>
            <p class="mb-1"><span class="primary--text">id</span> – {{ $t('docs.stories.id') }}</p>
            <p class="mb-1"><span class="primary--text">name</span> – {{ $t('docs.stories.story_name') }}</p>
            <p class="mb-1"><span class="primary--text">ready</span> – {{ $t('docs.stories.ready') }}</p>
            <p class="mb-1"><span class="primary--text">source</span> – {{ $t('docs.stories.source') }}</p>
            <p class="mb-1"><span class="primary--text">created_at</span> – {{ $t('docs.stories.created') }}</p>
            <p class="mb-1"><span class="primary--text">updated_at</span> – {{ $t('docs.stories.updated') }}</p>
            <p class="mb-1"><span class="primary--text">trainning_time</span> – {{ $t('docs.stories.time') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="primary--text pr-4">GET</span>
              <span class="primary--text pr-4">{{ host }}/story/{id}/</span>
              <span>{{ $t('docs.stories.api_get') }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1">{{ $t('docs.empty') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.stories.api_get_json') }}</p>
            <p class="mb-1"><span class="primary--text">id</span> – {{ $t('docs.stories.id') }}</p>
            <p class="mb-1"><span class="primary--text">name</span> – {{ $t('docs.stories.story_name') }}</p>
            <p class="mb-1"><span class="primary--text">ready</span> – {{ $t('docs.stories.ready') }}</p>
            <p class="mb-1"><span class="primary--text">data</span> – {{ $t('docs.stories.data') }}</p>
            <p class="mb-1"><span class="primary--text">company</span> – {{ $t('docs.stories.company') }}</p>
            <p class="mb-1"><span class="primary--text">source</span> – {{ $t('docs.stories.source') }}</p>
            <p class="mb-1"><span class="primary--text">created_at</span> – {{ $t('docs.stories.created') }}</p>
            <p class="mb-1"><span class="primary--text">updated_at</span> – {{ $t('docs.stories.updated') }}</p>
            <p class="mb-1"><span class="primary--text">trainning_time</span> – {{ $t('docs.stories.time') }}</p>
            <p class="mb-1"><span class="primary--text">columns</span> – {{ $t('docs.stories.columns') }}</p>
            <p class="mb-1"><span class="primary--text">values</span> – {{ $t('docs.stories.values') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="orange--text pr-4">POST</span>
              <span class="primary--text pr-4">{{ host }}/story/0/</span>
              <span>{{ $t('docs.stories.api_add') }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1"><span class="primary--text">id</span> – {{ $t('docs.stories.source') }}</p>
            <p class="mb-1"><span class="primary--text">name</span> – {{ $t('docs.stories.story_name') }}</p>
            <p class="mb-1"><span class="primary--text">forecastType</span> – {{ $t('docs.stories.forecast_type') }}</p>
            <p class="mb-1"><span class="primary--text">columns</span> – {{ $t('docs.stories.columns_list') }}</p>
            <p class="mb-1"><span class="primary--text">models</span> – {{ $t('docs.stories.models') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.json') }}</p>
            <p class="mb-1"><span class="primary--text">id</span> – {{ $t('docs.stories.id') }}</p>
            <p class="mb-1">{{ $t('docs.stories.story_async_update') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="green--text pr-4">PUT</span>
              <span class="primary--text pr-4">{{ host }}/story/{id}/</span>
              <span>{{ $t('docs.stories.api_put') }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1">{{ $t('docs.empty') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.ok') }}</p>
            <p class="mb-1">{{ $t('docs.stories.api_put') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="deep-purple--text pr-4">PATCH</span>
              <span class="primary--text pr-4">{{ host }}/story/{id}/</span>
              <span>{{ $t('docs.stories.api_patch') }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1"><span class="primary--text">id</span> – {{ $t('docs.stories.source') }}</p>
            <p class="mb-1"><span class="primary--text">name</span> – {{ $t('docs.stories.story_name') }}</p>
             <p class="mb-1"><span class="primary--text">forecastType</span> – {{ $t('docs.stories.forecast_type') }}</p>
            <p class="mb-1"><span class="primary--text">columns</span> – {{ $t('docs.stories.columns_list') }}</p>
            <p class="mb-1"><span class="primary--text">models</span> – {{ $t('docs.stories.models') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.ok') }}</p>
            <p class="mb-1">{{ $t('docs.stories.api_put') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="red--text pr-4">DELETE</span>
              <span class="primary--text pr-4">{{ host }}/story/{id}/</span>
              <span>{{ $t('docs.stories.api_delete') }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1">{{ $t('docs.empty') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.ok') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="orange--text pr-4">POST</span>
              <span class="primary--text pr-4">{{ host }}/forecast/{id}/</span>
              <span>{{ $t('docs.stories.api_forecast') }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1"><span class="primary--text">steps</span> – {{ $t('docs.stories.steps') }}</p>
            <p class="mb-1"><span class="primary--text">model</span> – {{ $t('docs.stories.model') }}</p>
            <p class="mb-1 font-weight-medium">{{ $t('docs.stories.forecast_ready') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.stories..api_forecast_json') }}</p>
            <p class="mb-1"><span class="primary--text">data.date</span> – {{ $t('docs.stories.forecast_dates') }}</p>
            <p class="mb-1"><span class="primary--text">data.forecast</span> – {{ $t('docs.stories.forecast_forecast') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="orange--text pr-4">POST</span>
              <span class="primary--text pr-4">{{ host }}/forecast/download/{id}/</span>
              <span>{{ $t('docs.stories.api_download') }}</span>
            </v-row>
            </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1"><span class="primary--text">steps</span> – {{ $t('docs.stories.steps') }}</p>
            <p class="mb-1"><span class="primary--text">model</span> – {{ $t('docs.stories.model') }}</p>
            <p class="mb-1 font-weight-medium">{{ $t('docs.stories.forecast_ready') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.excel') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Stories',
  data() {
    return {
      host: '',
    };
  },
  mounted() {
    this.host = window.location.host;
  },
};
</script>
