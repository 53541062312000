import { required, maxLength } from 'vuelidate/lib/validators';

const usernameFormat = username => /^[A-zА-я0-9 _]+$/.test(username);

const usernameMixin = {
  data() {
    return {
      username: ''
    };
  },
  validations: {
    username: {
      required,
      maxLength: maxLength(150),
      usernameFormat
    }
  },
  computed: {
    usernameMessage() {
      if (this.$v.username.$error || this.getError('username') !== '') {
        if (!this.$v.username.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.username.maxLength) {
          return [this.$t('validations.max_length[0]') + this.$v.username.$params.maxLength.max + this.$t('validations.max_length[1]')];
        } else if (!this.$v.username.usernameFormat) {
          return [this.$t('validations.username_format')];
        } else if (!this.$v.username.$error && this.getError('username') !== '') {
          return [this.getError('username')];
        }
      }
      return [];
    }
  }
};

export default usernameMixin;
