<template>
  <v-main style="overflow-y: auto;">
    <!-- About -->
    <v-container fluid class="pa-0" id="about">

      <v-row no-gutters class="h-100" justify="center">
        <v-col cols="8" align-self="center">
          <h1 class="header wow fadeIn" data-wow-delay="0.5s">{{ $t('intro.main_header') }}</h1>
          <p class="text my-5 wow fadeIn" data-wow-delay="0.5s">{{ $t('intro.main_subheader') }}</p>
          <!-- <v-row justify="center" style="margin-top: 50px;" class="wow fadeIn" data-wow-delay="1s">
            <input type="hidden" style="color: white;" name="email" id="email">
            <v-text-field label="" dark success style="border-radius: 0px; max-width: 300px" outlined v-model="email" :error-messages="emailMessage" :placeholder="$t('intro.email_placeholder')" type="text" />
            <v-btn color="primary" :loading="load" height="56" dark x-large style="border-radius: 0px;max-width: 300px;text-transform: none;" @click="send">{{ $t('intro.early_access') }}</v-btn>
          </v-row> -->
        </v-col>
      </v-row>
    </v-container>
    <!-- Services Section -->
    <v-container fluid class="pa-0 services" id="product">
      <h2 class="header">{{ $t('intro.products.name') }}</h2>
      <hr class="divider my-4" />
      <v-row no-gutters justify="center" class="pa-4 text-center">
        <v-col cols="12" md="6" lg="3" xl="3" class="pa-4 wow fadeInUp" v-for="key in [0,1,2,3]" :key="key" :data-wow-delay="`${1+key}s`">
          <v-row justify="center" class="pb-4">
            <v-icon color="indigo" size="4em" class="text-center">mdi-{{ $t(`intro.products.list[${key}].logo`) }} </v-icon>
          </v-row>
          <p class="card__header mb-2 text-h6 text-md-h6 text-lg-h5 text-xl-h5">{{ $t(`intro.products.list[${key}].name`) }}</p>
        </v-col>
        <div class="mt-5 mx-4 d-flex flex-row justify-center flex-wrap">
          <div class="ma-0 pa-0 wow fadeIn" v-for="key in [0,1,2,3,4,5,6,7,8,9]" :key="key" :data-wow-delay="`${5+0.5*key}s`">
            <v-tooltip bottom :max-width="250">
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" class="ma-2" :input-value="true" filter color="green" text-color="white">{{ $t(`intro.products.another_list[${key}].name`) }}</v-chip>
              </template>
              <span>{{ $t(`intro.products.another_list[${key}].desc`) }}</span>
            </v-tooltip>
          </div>
        </div>
      </v-row>
    </v-container>
    <!-- Cases Section -->
    <v-container fluid class="pa-0 services" id="cases">
      <div style="position: relative;">
        <h2 class="header text-center">{{ $t('intro.cases.name') }}</h2>
        <hr class="divider_carousel" :style="`top: ${$vuetify.breakpoint.smAndDown ? 115 : 100}px;`" />
      </div>
      <v-carousel cycle :height="$vuetify.breakpoint.smAndDown ? 800 : 500" hide-delimiter-background show-arrows-on-hover hide-delimiters>
          <v-carousel-item v-for="index in [0,1,2,3]" :key="`index-${index}`">
          <v-sheet :color="index%2 == 0 ? 'indigo' : 'blue'" class="pa-0 ma-0">
            <v-row class="fill-height my-0" align="center" justify="center" style="padding-right: 60px; padding-left: 60px; padding-bottom: 60px; padding-top: 120px;">
              <v-col cols="12" md="4" lg="4" xl="4" class="white--text" v-for="key in [0,1,2]" :key="`key-${key}`">
                <p class="text-center text-h6 text-md-h6 text-lg-h5 text-xl-h5" style="word-wrap: break-word">{{ $t(`intro.cases.list[${index*3 + key}]`) }}</p>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-container>

    <v-container fluid class="pa-0 services" id="business_cases">
      <h2 class="header">{{ $t('intro.business_cases.name') }}</h2>
      <hr class="divider my-4" />
      <v-row no-gutters justify="space-around" class="text-center">
        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="pa-4"  v-for="index in [0,1,2]" :key="`index-${index}`">
          <v-img v-if="index == 0" style="width: 100%" src="../../img/case_farma.png"></v-img>
          <v-img v-if="index == 1" style="width: 100%" src="../../img/case_internet_company.png"></v-img>
          <v-img v-if="index == 2" style="width: 100%" src="../../img/case_streaming.png"></v-img>
          <h3 class="pt-3" :style="`font-size: 20px; height: ${$vuetify.breakpoint.smAndDown ? 'auto': '90px'}`">{{ $t(`intro.business_cases.list[${index}].title`) }}</h3>
          <p class="text-left pt-2" :style="`font-size: 18px; height: ${$vuetify.breakpoint.smAndDown ? 'auto': '130px'}`">{{ $t(`intro.business_cases.list[${index}].case`) }}</p>
          <div :style="`height: ${$vuetify.breakpoint.smAndDown ? 'auto': '250px'}`">
            <p class="text-left pt-2 font-weight-bold" style="font-size: 20px;">{{ $t('intro.business_cases.challenges') }}</p>
            <p class="text-left" style="font-size: 18px;" v-for="(challenge, key1) in $t(`intro.business_cases.list[${index}].challenges`)" :key="`challenges-${key1}`">{{ challenge }}</p>
          </div>
          <div>
            <p class="text-left pt-2 font-weight-bold" style="font-size: 20px;">{{ $t('intro.business_cases.benefits') }}</p>
            <p class="text-left" style="font-size: 18px;" v-for="(benefits, key2) in $t(`intro.business_cases.list[${index}].benefits`)" :key="`benefits-${key2}`">{{ benefits }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="pa-0" id="demonstration">
      <h2 class="header">{{ $t('intro.demonstration') }}</h2>
      <hr class="divider my-4" />
      <div class="bg-video" :style="`height: ${$vuetify.breakpoint.mdAndDown ? ($vuetify.breakpoint.smAndDown ? 300 : 400) : 600}px`">
        <video id="myVideo" class="bg-video__content" autoplay muted loop>
          <!-- <source src="../../img/b2l.mp4" type="video/mp4"> -->
          <source src="video/b2l.mp4" type="video/mp4">
        </video>
      </div>
    </v-container>

    <!-- Contact Section -->
    <v-container fluid class="pa-0 services" id="contact">
      <h2 class="header">{{ $t('intro.contact.name') }}</h2>
      <hr class="divider my-4" />
      <v-row class="pt-10" align="center" justify="center">
        <v-card class="elevation-12" align="center" style="width: 60%">
          <v-card-text>
            <v-row>
              <v-col cols="6" class="px-2 pb-0">
                <v-text-field :label="$t('intro.contact.contact')" v-model="name" :error-messages="nameMessage" type="text" />
              </v-col>
              <v-col cols="6" class="px-2 pb-0">
                <v-text-field label="Email" v-model="email" :error-messages="emailMessage" type="text" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="px-2 pb-0">
                <v-text-field :label="$t('intro.contact.subject')" v-model="subject" :error-messages="subjectMessage" type="text" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="px-2 pb-0">
                <v-textarea name="input-7-1" :label="$t('intro.contact.message')" v-model="body" :error-messages="bodyMessage"></v-textarea>
              </v-col>
            </v-row>
            <input type="hidden" style="color: white;" name="email" id="email">
            <v-btn color="green" class="mt-4" :loading="load" block @click="send">{{ $t('intro.contact.send') }}</v-btn>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>

    <!-- Footer -->
    <v-footer class="font-weight-medium mt-0">
      <v-col class="text-center" cols="12">
        <v-row align="center" justify="center">
          <p class="mb-0 pr-4">Copyright &copy; Brain2Logic</p>
        </v-row>
      </v-col>
    </v-footer>
  </v-main>

</template>

<script>
import { WOW } from 'wowjs';
import baseMixin from '@/mixins/baseMixin';
import { required, email, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'Intro',
  mixins: [baseMixin],
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      body: '',
      load: false
    };
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(254),
    },
    email: {
      required,
      email,
      maxLength: maxLength(254),
    },
    subject: {
      required,
      maxLength: maxLength(254),
    },
    body: {
      required
    },
  },
  computed: {
    nameMessage() {
      if (this.$v.name.$error || this.getError('name') !== '') {
        if (!this.$v.name.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.name.maxLength) {
          return [this.$t('validations.max_length[0]') + this.$v.name.$params.maxLength.max + this.$t('validations.max_length[1]')];
        } else if (!this.$v.name.$error && this.getError('name') !== '') {
          return [this.getError('name')];
        }
      }
      return [];
    },
    emailMessage() {
      if (this.$v.email.$error || this.getError('email') !== '') {
        if (!this.$v.email.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.email.email) {
          return [this.$t('validations.email')];
        } else if (!this.$v.email.maxLength) {
          return [this.$t('validations.max_length[0]') + this.$v.email.$params.maxLength.max + this.$t('validations.max_length[1]')];
        } else if (!this.$v.email.$error && this.getError('email') !== '') {
          return [this.getError('email')];
        }
      }
      return [];
    },
    subjectMessage() {
      if (this.$v.subject.$error || this.getError('subject') !== '') {
        if (!this.$v.subject.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.subject.maxLength) {
          return [this.$t('validations.max_length[0]') + this.$v.subject.$params.maxLength.max + this.$t('validations.max_length[1]')];
        } else if (!this.$v.subject.$error && this.getError('subject') !== '') {
          return [this.getError('subject')];
        }
      }
      return [];
    },
    bodyMessage() {
      if (this.$v.body.$error || this.getError('body') !== '') {
        if (!this.$v.body.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.body.$error && this.getError('body') !== '') {
          return [this.getError('body')];
        }
      }
      return [];
    },
  },
  mounted() {
    new WOW().init();
    document.getElementById('myVideo').play();
  },
  methods: {
    scrollToSection(section) {
      // Smooth scrolling to sections
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: document.getElementById(section).offsetTop
      });
    },
    send() {
      this.$v.$touch();
      this.serverErrors = {};
      let hiddenEmail = document.getElementById('email').value;
      if (!this.$v.$invalid && hiddenEmail === '') {
        this.load = true;
        this.$fetch
          .post('/api/contact', {
            name: this.name,
            email: this.email,
            subject: this.subject,
            body: this.body
          })
          .then(() => {
            this.load = false;
            this.$notify.success({ message: this.$t('notifications.message_sent') });
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
          });
      }
    }
  }
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
  border-width: medium !important;
}

/* input, input::focus {
  color: white !important;
} */

.bg-video {
  width: 100%;
  overflow: hidden;
}

.bg-video__content {
  height: 100%;
  width: 100%;
  outline:none;
}

/* .bg-video {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  z-index: 1;
  opacity: .5;
  overflow: hidden;
}

.bg-video__content {
  height: 100%;
  width: 100%;
  outline:none;
} */

video:focus { outline:none; }

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
            transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }
@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
            transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); } }

@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0); } }

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0); } }

@-webkit-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0); } }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0); } }

@-webkit-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@-webkit-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-enter-active, .bounceIn,
.bounce-leave-active, .bounceOut {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.bounce-enter-active, .bounceIn {
  -webkit-animation-name: bounceIn;
          animation-name: bounceIn; }

.bounce-leave-active, .bounceOut {
  -webkit-animation-name: bounceOut;
          animation-name: bounceOut; }

.bounceDown-enter-active, .bounceInDown,
.bounceDown-leave-active, .bounceOutDown {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.bounceDown-enter-active, .bounceInDown {
  -webkit-animation-name: bounceInDown;
          animation-name: bounceInDown; }

.bounceDown-leave-active, .bounceOutDown {
  -webkit-animation-name: bounceOutDown;
          animation-name: bounceOutDown; }

.bounceLeft-enter-active, .bounceInLeft,
.bounceLeft-leave-active, .bounceOutLeft {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.bounceLeft-enter-active, .bounceInLeft {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft; }

.bounceLeft-leave-active, .bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
          animation-name: bounceOutLeft; }

.bounceRight-enter-active, .bounceInRight,
.bounceRight-leave-active, .bounceOutRight {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.bounceRight-enter-active, .bounceInRight {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight; }

.bounceRight-leave-active, .bounceOutRight {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight; }

.bounceUp-enter-active, .bounceInUp,
.bounceUp-leave-active, .bounceOutUp {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.bounceUp-enter-active, .bounceInUp {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp; }

.bounceUp-leave-active, .bounceOutUp {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); } }

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0); } }

@keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0); } }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); } }

@keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); } }

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0); } }

@keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0); } }

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); } }

@keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); } }

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

@keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.fade-enter-active, .fadeIn,
.fade-leave-active, .fadeOut {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.fade-enter-active, .fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn; }

.fade-leave-active, .fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut; }

.fadeDown-enter-active, .fadeInDown,
.fadeDown-leave-active, .fadeOutDown {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.fadeDown-enter-active, .fadeInDown {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown; }

.fadeDown-leave-active, .fadeOutDown {
  -webkit-animation-name: fadeOutDown;
          animation-name: fadeOutDown; }

.fadeDownBig-enter-active, .fadeInDownBig,
.fadeDownBig-leave-active, .fadeOutDownBig {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.fadeDownBig-enter-active, .fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
          animation-name: fadeInDownBig; }

.fadeDownBig-leave-active, .fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
          animation-name: fadeOutDownBig; }

.fadeLeft-enter-active, .fadeInLeft,
.fadeLeft-leave-active, .fadeOutLeft {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.fadeLeft-enter-active, .fadeInLeft {
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft; }

.fadeLeft-leave-active, .fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
          animation-name: fadeOutLeft; }

.fadeLeftBig-enter-active, .fadeInLeftBig,
.fadeLeftBig-leave-active, .fadeOutLeftBig {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.fadeLeftBig-enter-active, .fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
          animation-name: fadeInLeftBig; }

.fadeLeftBig-leave-active, .fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
          animation-name: fadeOutLeftBig; }

.fadeRight-enter-active, .fadeInRight,
.fadeRight-leave-active, .fadeOutRight {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.fadeRight-enter-active, .fadeInRight {
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight; }

.fadeRight-leave-active, .fadeOutRight {
  -webkit-animation-name: fadeOutRight;
          animation-name: fadeOutRight; }

.fadeRightBig-enter-active, .fadeInRightBig,
.fadeRightBig-leave-active, .fadeOutRightBig {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.fadeRightBig-enter-active, .fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
          animation-name: fadeInRightBig; }

.fadeRightBig-leave-active, .fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
          animation-name: fadeOutRightBig; }

.fadeUp-enter-active, .fadeInUp,
.fadeUp-leave-active, .fadeOutUp {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.fadeUp-enter-active, .fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp; }

.fadeUp-leave-active, .fadeOutUp {
  -webkit-animation-name: fadeOutUp;
          animation-name: fadeOutUp; }

.fadeUpBig-enter-active, .fadeInUpBig,
.fadeUpBig-leave-active, .fadeOutUpBig {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.fadeUpBig-enter-active, .fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
          animation-name: fadeInUpBig; }

.fadeUpBig-leave-active, .fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
          animation-name: fadeOutUpBig; }

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
            transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: none;
            transform: none;
    opacity: 1; } }

@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
            transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: none;
            transform: none;
    opacity: 1; } }

@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
            transform-origin: center;
    opacity: 1; }
  to {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
            transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
            transform-origin: center;
    opacity: 1; }
  to {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
            transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: none;
            transform: none;
    opacity: 1; } }

@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: none;
            transform: none;
    opacity: 1; } }

@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: none;
            transform: none;
    opacity: 1; } }

@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: none;
            transform: none;
    opacity: 1; } }

@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: none;
            transform: none;
    opacity: 1; } }

@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: none;
            transform: none;
    opacity: 1; } }

@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
            transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: none;
            transform: none;
    opacity: 1; } }

@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
            transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: none;
            transform: none;
    opacity: 1; } }

@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
            transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    opacity: 1; }
  to {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
            transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.rotate-enter-active, .rotateIn,
.rotate-leave-active, .rotateOut {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.rotate-enter-active, .rotateIn {
  -webkit-animation-name: rotateIn;
          animation-name: rotateIn; }

.rotate-leave-active, .rotateOut {
  -webkit-animation-name: rotateOut;
          animation-name: rotateOut; }

.rotateDownLeft-enter-active, .rotateInDownLeft,
.rotateDownLeft-leave-active, .rotateOutDownLeft {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.rotateDownLeft-enter-active, .rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
          animation-name: rotateInDownLeft; }

.rotateDownLeft-leave-active, .rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
          animation-name: rotateOutDownLeft; }

.rotateDownRight-enter-active, .rotateInDownRight,
.rotateDownRight-leave-active, .rotateOutDownRight {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.rotateDownRight-enter-active, .rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
          animation-name: rotateInDownRight; }

.rotateDownRight-leave-active, .rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
          animation-name: rotateOutDownRight; }

.rotateUpLeft-enter-active, .rotateInUpLeft,
.rotateUpLeft-leave-active, .rotateOutUpLeft {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.rotateUpLeft-enter-active, .rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
          animation-name: rotateInUpLeft; }

.rotateUpLeft-leave-active, .rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
          animation-name: rotateOutUpLeft; }

.rotateUpRight-enter-active, .rotateInUpRight,
.rotateUpRight-leave-active, .rotateOutUpRight {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.rotateUpRight-enter-active, .rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
          animation-name: rotateInUpRight; }

.rotateUpRight-leave-active, .rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
          animation-name: rotateOutUpRight; }

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); } }

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); } }

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); } }

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); } }

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); } }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-enter-active, .slideIn,
.slide-leave-active, .slideOut {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.slide-enter-active, .slideIn {
  -webkit-animation-name: slideIn;
          animation-name: slideIn; }

.slide-leave-active, .slideOut {
  -webkit-animation-name: slideOut;
          animation-name: slideOut; }

.slideDown-enter-active, .slideInDown,
.slideDown-leave-active, .slideOutDown {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.slideDown-enter-active, .slideInDown {
  -webkit-animation-name: slideInDown;
          animation-name: slideInDown; }

.slideDown-leave-active, .slideOutDown {
  -webkit-animation-name: slideOutDown;
          animation-name: slideOutDown; }

.slideLeft-enter-active, .slideInLeft,
.slideLeft-leave-active, .slideOutLeft {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.slideLeft-enter-active, .slideInLeft {
  -webkit-animation-name: slideInLeft;
          animation-name: slideInLeft; }

.slideLeft-leave-active, .slideOutLeft {
  -webkit-animation-name: slideOutLeft;
          animation-name: slideOutLeft; }

.slideRight-enter-active, .slideInRight,
.slideRight-leave-active, .slideOutRight {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.slideRight-enter-active, .slideInRight {
  -webkit-animation-name: slideInRight;
          animation-name: slideInRight; }

.slideRight-leave-active, .slideOutRight {
  -webkit-animation-name: slideOutRight;
          animation-name: slideOutRight; }

.slideUp-enter-active, .slideInUp,
.slideUp-leave-active, .slideOutUp {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.slideUp-enter-active, .slideInUp {
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp; }

.slideUp-leave-active, .slideOutUp {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp; }

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@-webkit-keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
            transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
            transform-origin: left center; } }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
            transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
            transform-origin: left center; } }

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
            transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
            transform-origin: right center; } }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
            transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
            transform-origin: right center; } }

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoom-enter-active, .zoomIn,
.zoom-leave-active, .zoomOut {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.zoom-enter-active, .zoomIn {
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn; }

.zoom-leave-active, .zoomOut {
  -webkit-animation-name: zoomOut;
          animation-name: zoomOut; }

.zoomDown-enter-active, .zoomInDown,
.zoomDown-leave-active, .zoomOutDown {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.zoomDown-enter-active, .zoomInDown {
  -webkit-animation-name: zoomInDown;
          animation-name: zoomInDown; }

.zoomDown-leave-active, .zoomOutDown {
  -webkit-animation-name: zoomOutDown;
          animation-name: zoomOutDown; }

.zoomLeft-enter-active, .zoomInLeft,
.zoomLeft-leave-active, .zoomOutLeft {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.zoomLeft-enter-active, .zoomInLeft {
  -webkit-animation-name: zoomInLeft;
          animation-name: zoomInLeft; }

.zoomLeft-leave-active, .zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
          animation-name: zoomOutLeft; }

.zoomRight-enter-active, .zoomInRight,
.zoomRight-leave-active, .zoomOutRight {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.zoomRight-enter-active, .zoomInRight {
  -webkit-animation-name: zoomInRight;
          animation-name: zoomInRight; }

.zoomRight-leave-active, .zoomOutRight {
  -webkit-animation-name: zoomOutRight;
          animation-name: zoomOutRight; }

.zoomUp-enter-active, .zoomInUp,
.zoomUp-leave-active, .zoomOutUp {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.zoomUp-enter-active, .zoomInUp {
  -webkit-animation-name: zoomInUp;
          animation-name: zoomInUp; }

.zoomUp-leave-active, .zoomOutUp {
  -webkit-animation-name: zoomOutUp;
          animation-name: zoomOutUp; }

@-webkit-keyframes flipIn {
  from {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0.4; }
  50% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0.5; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
            transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0.8; }
  to {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1; } }

@keyframes flipIn {
  from {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0.4; }
  50% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0.5; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
            transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0.8; }
  to {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1; } }

@-webkit-keyframes flipOut {
  from {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1; }
  40% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
            transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0.6; }
  50% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0.5; }
  80% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0.2; }
  to {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0; } }

@keyframes flipOut {
  from {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1; }
  40% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
            transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0.6; }
  50% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0.5; }
  80% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0.2; }
  to {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
            transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0; } }

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); } }

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); } }

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); } }

@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); } }

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.flip-enter-active, .flipIn,
.flip-leave-active, .flipOut {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.flip-enter-active, .flipIn {
  -webkit-animation-name: flipIn;
          animation-name: flipIn; }

.flip-leave-active, .flipOut {
  -webkit-animation-name: flipOut;
          animation-name: flipOut; }

.flipX-enter-active, .flipInX,
.flipX-leave-active, .flipOutX {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.flipX-enter-active, .flipInX {
  -webkit-animation-name: flipInX;
          animation-name: flipInX; }

.flipX-leave-active, .flipOutX {
  -webkit-animation-name: flipOutX;
          animation-name: flipOutX; }

.flipY-enter-active, .flipInY,
.flipY-leave-active, .flipOutY {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.flipY-enter-active, .flipInY {
  -webkit-animation-name: flipInY;
          animation-name: flipInY; }

.flipY-leave-active, .flipOutY {
  -webkit-animation-name: flipOutY;
          animation-name: flipOutY; }

@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
            transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
            transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
            transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
            transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
            transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

@keyframes lightSpeedOut {
  from {
    opacity: 1; }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
            transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.lightSpeed-enter-active, .lightSpeedIn,
.lightSpeed-leave-active, .lightSpeedOut {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.lightSpeed-enter-active, .lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
          animation-name: lightSpeedIn; }

.lightSpeed-leave-active, .lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
          animation-name: lightSpeedOut; }

</style>
