import en from './locales/en';
import ru from './locales/ru';

import ch from './locales/en';
import de from './locales/en';
import es from './locales/en';
import fr from './locales/en';
import ko from './locales/en';
import no from './locales/en';
import pr from './locales/en';

export default {
  en,
  ru,
  ch,
  de,
  es,
  fr,
  ko,
  no,
  pr
};
