<template>
  <div class="p-0 m-0">
    <vue-simple-context-menu class="context-padding" elementId="myUniqueIdPhoto" :options="options" ref="vueSimpleContextMenu" @option-clicked="chooseOption"/>
  <v-card class="elevation-12">
    <v-toolbar dense color="primary" dark flat>
      <v-toolbar-title>{{ $t('photo.name') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="6" class="px-2 pb-0">
          <v-file-input color="primary" prepend-icon="" outlined dense v-model="photo" :error-messages="photoMessage" @change="savePhoto" :label="$t('photo.upload_photo')" :show-size="photo !== undefined"></v-file-input>
        </v-col>
        <v-col cols="6" class="px-2 pb-0">
          <v-btn color="primary" style="width: 100%" outlined @click="setPhoto('')">{{ $t('photo.reset_photo')}}</v-btn>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="2" v-for="(image, key) in images" :key="key">
          <v-card @contextmenu.prevent.stop="chooseItem($event, image.name)">
            <v-img
              :src="image.path" class="white--text align-end cursor"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px"
              @click="setPhoto(image.name)"
            >
            </v-img>
            <v-card-actions class="py-0">
              <p class="text-left mb-0"><span class="font-weight-bold primary--text">{{ image.name }}</span></p>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  </div>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';

const notUndefined = value => value !== undefined;
const rightFormat = value => ['JPG', 'jpg', 'png', 'PNG', 'jpeg', 'JPEG', 'jfif'].includes(value !== undefined || value !== null ? value.name.split('.').pop() : '');

export default {
  name: 'ChangePhoto',
  props: ['id', 'type'],
  mixins: [baseMixin],
  data() {
    return {
      images: [],
      photo: undefined,
      options: [
        { name: this.$t('buttons.delete'), type: 'delete' }
      ]
    };
  },
  validations: {
    photo: {
      notUndefined,
      rightFormat
    }
  },
  computed: {
    photoMessage() {
      if (this.$v.photo.$error || this.getError('photo') !== '') {
        if (!this.$v.photo.notUndefined) {
          return [this.$t('validations.empty_file')];
        } else if (!this.$v.photo.rightFormat) {
          return [this.$t('validations.wrong_photo')];
        } else if (!this.$v.photo.$error && this.getError('photo') !== '') {
          return [this.getError('photo')];
        }
      }
      return [];
    }
  },
  mounted() {
    this.getPhoto();
  },
  methods: {
    getPhoto() {
      this.$fetch
        .get('/api/photo')
        .then(data => {
          this.images = data;
        })
        .catch(error => {
          this.serverErrors = error.response.data;
        });
    },
    savePhoto() {
      this.$v.$touch();
      this.serverErrors = {};
      if (!this.$v.$invalid) {
        let formData = new FormData();
        formData.append('photo', this.photo);
        this.$fetch
          .post('/api/photo', formData)
          .then(() => {
            this.getPhoto();
          })
          .catch(error => {
            this.serverErrors = error.response.data;
          });
      }
    },
    chooseItem(event, id) {
      this.$refs.vueSimpleContextMenu.showMenu(event, id);
    },
    chooseOption(event) {
      this.$fetch
        .post('/api/photo/delete', { name: event.item })
        .then(() => {
          this.getPhoto();
          this.$emit('delete');
        })
        .catch(error => {
          this.serverErrors = error.response.data;
        });
    },
    setPhoto(name) {
      this.$fetch
        .post('/api/photo/set', { name, id: this.id, photo_type: this.type })
        .then(() => {
          this.$emit('update');
        })
        .catch(error => {
          this.serverErrors = error.response.data;
        });
    }
  }
};
</script>
