<template>
  <v-main style="overflow-y: auto;">
    <v-container fluid id="docs">
      <h2>{{ $t('docs.docs') }}</h2>
      <v-row class="pt-2">
        <v-col cols="2">
          <v-card>
            <v-list>
              <v-list-item-group v-model="model">
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-content @click="i != model ? $router.push(item) : false">
                    <v-list-item-title v-text="$t(`docs.navs[${i}]`)"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="10">
          <transition name="slide" mode="out-in">
            <router-view></router-view>
          </transition>
        </v-col>
      </v-row>
    </v-container>

    <v-footer absolute class="font-weight-medium">
      <v-col class="text-center" cols="12">
        <v-row align="center" justify="center">
          <p class="mb-0 pr-4">Copyright &copy; Brain2Logic</p>
          <img src="../../img/mavericka.png" height="30" alt="lorem">
        </v-row>
      </v-col>
    </v-footer>
  </v-main>
</template>

<script>
export default {
  name: 'Documentation',
  data() {
    return {
      items: [
        '/docs/get_started',
        '/docs/account',
        '/docs/sources',
        '/docs/stories',
      ],
      model: 0,
    };
  },
  mounted() {
    let path = this.$route.path;
    this.items.forEach((item, i) => {
      if (item === path) {
        this.model = i;
      }
    });
  },
};
</script>
