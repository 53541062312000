<template>
  <v-card class="elevation-12">
    <v-toolbar dense color="primary" dark flat>
      <v-toolbar-title>{{ $t('story.detail.features_map') + activeFeature.name }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <p class="text-left mb-0 mt-2">{{ activeFeature.type == 'binary' ? $t('story.detail.features_map_binary') : $t('story.detail.features_map_ordinal') }}</p>
      <v-row justify="start">
        <v-col cols="1" sm="1" md="1" v-if="activeFeature.type == 'binary'">
          <div class="order-class">True:</div>
          <div class="order-class">False:</div>
        </v-col>
        <v-col cols="1" sm="1" md="1" v-else>
          <div class="order-class" v-for="number in numbers" :key="number" >{{ number }}:</div>
        </v-col>
        <v-col cols="11" sm="6" md="6">
          <draggable class="list-group" tag="ul" v-model="values" v-bind="dragOptions" @start="isDragging = true" @end="isDragging = false;dispatch()">
            <transition-group type="transition" name="flip-list">
              <li class="list-group-item v-btn v-btn--block v-btn--flat v-btn--outlined theme--light v-size--default primary--text" v-for="value in values" :key="value.order">{{value.name}}</li>
            </transition-group>
          </draggable>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable';
// https://jsfiddle.net/UXMike/mh7ku5bx/
// https://sortablejs.github.io/Vue.Draggable/#/handle

export default {
  name: 'ChangeFeatures',
  components: { draggable },
  props: ['activeFeature'],
  data() {
    return {
      values: [],
      isDragging: false,
      delayedDragging: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    numbers() {
      let numbers = [];
      this.activeFeature.values.forEach((value, key) => {
        numbers.push(key);
      });
      return numbers;
    },
  },
  mounted() {
    this.prepare();
  },
  methods: {
    dispatch() {
      let values = [];
      this.values.forEach((value) => {
        values.push(value.name);
      });
      this.$emit('update', values);
    },
    prepare() {
      let values = [];
      this.activeFeature.values.forEach((value, key) => {
        values.push({ name: value, order: key, fixed: false });
      });
      this.values = values;
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
    activeFeature() {
      this.prepare();
    },
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  list-style-type: none;
}

.list-group-item {
  cursor: move;
}

.order-class {
  font-size: 0.875rem;
  height: 36px;
  line-height: 36px;
  border: thin solid transparent;
  text-align: center;
}
</style>
