<template>
  <v-card>
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t('docs.get_started') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <h3 class="pb-2">{{ $t('docs.account.api[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.account.api[1]') }}<a :href="`/docs#`">{{ `${host}/docs#` }}</a>.
      <h3 class="pb-2">{{ $t('docs.account.registration[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.account.registration[1]') }}<a :href="`/#/register`">{{ `${host}/#/register` }}</a>.{{ $t('docs.account.registration[2]') }}<a :href="`/#/login`">{{ `${host}/#/login` }}</a>.</p>
      <h3 class="pb-2">{{ $t('docs.account.login[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.account.login[1]') }}<a :href="`/#/login`">{{ `${host}/#/login` }}</a>{{ $t('docs.account.login[2]') }}</p>
      <h3 class="pb-2">{{ $t('docs.sources.add[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.sources.add[1]') }}</p>
      <h3 class="pb-2">{{ $t('docs.stories.add[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.stories.add[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.empty[0]') }}</span> – {{ $t('docs.stories.empty[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.date_days[0]') }}</span> – {{ $t('docs.stories.date_days[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.date_week[0]') }}</span> – {{ $t('docs.stories.date_week[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.date_months[0]') }}</span> – {{ $t('docs.stories.date_months[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.forecast_value[0]') }}</span> – {{ $t('docs.stories.forecast_value[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.feature[0]') }}</span> – {{ $t('docs.stories.feature[1]') }}</p>
      <p class="mb-1"><span class="primary--text">{{ $t('docs.stories.category[0]') }}</span> – {{ $t('docs.stories.category[1]') }}</p>
      <p class="mb-1">{{ $t('docs.stories.add[2]') }}</p>
      <h3 class="pb-2">{{ $t('docs.stories.get[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.stories.get[1]') }}</p>
      <h3 class="pb-2">{{ $t('docs.stories.forecast[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.stories.forecast[1]') }}</p>
      <h3 class="pb-2">{{ $t('docs.stories.download[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.stories.download[1]') }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'GetStarted',
  data() {
    return {
      host: '',
    };
  },
  mounted() {
    this.host = window.location.host;
  },
};
</script>
