<template>
  <v-main>
    <v-container fluid id="home">
      <h2>
        <span>{{ $t('story.list.name') + '    ' }}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="mx-2" @click="$router.push('/story/train/0')" fab dark color="green">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('story.list.add') }}</span>
        </v-tooltip>
      </h2>
      <v-row justify="start" class="my-2">
        <v-col cols="3" v-for="(story, key) in stories" :key="key">
          <v-card @contextmenu.prevent.stop="chooseItem($event, story.id)">
            <v-progress-linear v-if="story.ready === 0" indeterminate color="green"></v-progress-linear>
            <v-img
              :src="story.image ? story.image : 'media/story.png'" class="white--text align-end cursor"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" :height="story.ready == 0 ? '196px' : '200px'"
              @click="story.ready === 1 ? $router.push(`/story/forecast/${story.id}`) : (story.ready === 0 || story.ready === 2 || story.ready === 3) ? $router.push(`/story/train/${story.id}`) : false"
            >
            <!-- @click="$router.push(`/story/forecast/${story.id}`)" -->
              <!-- @click="story.ready === 1 ? $router.push(`/story/forecast/${story.id}`) : (story.ready === 2 || story.ready === 3) ? $router.push(`/story/train/${story.id}`) : false" -->
              <v-card-title @click="story.ready === 1 ? $router.push(`/story/forecast/${story.id}`) : (story.ready === 2 || story.ready === 3) ? $router.push(`/story/train/${story.id}`) : false">{{ story.name }}</v-card-title>
            </v-img>
            <v-row justify="space-between" class="py-0 my-0">
              <p class="text-left mb-0 pl-2" style="align-self: center;">
                <span v-if="story.ready === 0" class="font-weight-bold primary--text">{{ story.trainning_time }}</span>
                <v-icon v-if="story.ready === 2 || story.ready === 3" size="23" color="red">mdi-alert</v-icon>
                <span v-if="story.ready === 2" class="font-weight-bold pl-1 red--text accent-2">{{ $t('story.list.error_base') }}</span>
                <span v-if="story.ready === 3" class="font-weight-bold pl-1 red--text accent-2">{{ $t('story.list.error_stop') }}</span>
              </p>
              <v-card-actions class="py-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-information</v-icon>
                  </template>
                  <v-card-text class="pa-1">
                    <p class="text-left mb-0">id: {{ story.id }}</p>
                    <p class="text-left mb-0">{{ $t('story.list.updated') }}: {{ story.updated_at }}</p>
                    <p class="text-left mb-0" v-if="story.ready === 1">{{ $t('story.list.time') }}: {{ story.trainning_time }}</p>
                    <p class="text-left mb-0" v-if="story.comment != ''">{{ $t('source.list.description') }}: {{ story.comment }}</p>
                  </v-card-text>
                </v-tooltip>
              </v-card-actions>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <vue-simple-context-menu class="context-padding" elementId="myUniqueId" :options="options" ref="vueSimpleContextMenu" @option-clicked="chooseOption"/>
    <v-dialog v-model="showPhoto">
      <change-photo :id="activeItem" type="story" @update="updatePhoto" @delete="deletePhoto"></change-photo>
    </v-dialog>
  </v-main>
</template>

<script>
import ChangePhoto from '@/views/story/ChangePhoto.vue';

export default {
  name: 'StoryList',
  components: { ChangePhoto },
  data() {
    return {
      stories: [],
      showPhoto: false,
      activeItem: 0,
      options: [
        { name: this.$t('photo.change_cover'), type: 'photo' },
        { name: this.$t('buttons.duplicate'), type: 'duplicate' },
        { name: this.$t('buttons.delete'), type: 'delete' }
      ]
    };
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      let self = this;
      this.$fetch.get('/api/stories')
        .then((data) => {
          let adjustedData = [];
          data.forEach((value, key) => {
            adjustedData.push(value);
            adjustedData[key].updated_at = this.$date.format(this.$date.parseISO(adjustedData[key].updated_at), 'dd.MM.yyyy HH:mm');
            let size = this.$numeral(adjustedData[key].trainning_time * 1).format('0,0');
            adjustedData[key].trainning_time = size + this.$t('story.list.minutes');
          });
          if (data.filter(x => x.ready === 0).length > 0) {
            setTimeout(self.update, 5000);
          }
          this.inform_about_update(adjustedData);
          this.stories = adjustedData;
        });
    },
    inform_about_update(tasks) {
      let syncing_tasks = this.stories.filter(x => x.ready === 0);
      syncing_tasks.forEach(value => {
        let new_task = tasks.filter(x => x.id === value.id);
        if (new_task.length == 1) {
          if (new_task[0].ready == 1) {
            let message = this.$t('notifications.story_trained[0]') + new_task[0].name + this.$t('notifications.story_trained[1]');
            this.$notify.success({ message });
          }
          if (new_task[0].ready == 2) {
            let message = this.$t('notifications.story_error_base[0]') + new_task[0].name + this.$t('notifications.story_error_base[1]');
            this.$notify.error({ message });
          }
          if (new_task[0].ready == 3) {
            let message = this.$t('notifications.story_error_stop[0]') + new_task[0].name + this.$t('notifications.story_error_stop[1]');
            this.$notify.error({ message });
          }
        }
      })
    },
    chooseItem(event, id) {
      this.$refs.vueSimpleContextMenu.showMenu(event, id);
    },
    chooseOption(event) {
      this.activeItem = event.item;
      if (event.option.type === 'photo') {
        this.showPhoto = true;
      } else if (event.option.type === 'delete') {
        this.stories.forEach(story => {
          // if (story.id === this.activeItem && story.ready !== 0) {
          if (story.id === this.activeItem) {
            this.$fetch
              .delete(`/api/stories/${this.activeItem}`)
              .then(() => {
                this.$notify.success({ message: this.$t('notifications.story_deleted') });
                this.update();
              });
          }
        });
      } else if (event.option.type === 'duplicate') {
        this.stories.forEach(story => {
          if (story.id === this.activeItem && story.ready !== 0) {
            this.$fetch
              .get(`/api/stories/duplicate/${this.activeItem}`)
              .then(() => {
                // this.$notify.success({ message: this.$t('notifications.story_deleted') });
                this.update();
              });
          }
        });
      }
    },
    updatePhoto() {
      this.showPhoto = false;
      this.update();
    },
    deletePhoto() {
      this.update();
    }
  },
};
</script>
