<template>
  <canvas id="chart" :width="size.width" :height="size.height"></canvas>
</template>

<script>
import Chart from '@/plugins/chart';
import numeral from '../plugins/numeral';

const plugin = {
  afterDraw: (chartInstance) => {
    let ctx = chartInstance.chart.ctx;

    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'normal', Chart.defaults.global.defaultFontFamily);
    ctx.fillStyle = chartInstance.config.options.defaultFontColor;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';

    chartInstance.data.datasets.forEach(function (dataset) {
      for (let i = 0; i < dataset.data.length; i++) {
        let model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
        ctx.fillText(numeral(dataset.data[i]).format('0,0.0'), model.x, dataset.data[i] > 0 ? model.y - 5 : model.y + 17);
      }
    });
  }
};

export default {
  name: 'BarChart',
  props: ['labels', 'scores', 'size'],
  data() {
    return {
      type: 'bar',
      options: {
        responsive: true,
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'category',
              position: 'bottom',
              scaleLabel: {
                display: true,
                labelString: this.$t('story.detail.features')
              },
            }
          ],
          yAxes: [
            {
              type: 'linear',
              ticks: {
                callback: value => this.$numeral(value).format('0,0.0'),
                max: this.scores.length > 0 ? Math.ceil(Math.max.apply(null, this.scores) * 1.2) : 0
              },
              scaleLabel: {
                display: true,
                labelString: this.$t('story.detail.feature_imp'),
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              let label = `${this.$t('story.detail.features')} - ${tooltipItem.xLabel}`;
              label += `, ${this.$t('story.detail.feature_imp')} - ${this.$numeral(tooltipItem.yLabel).format('0,0.0')}`;
              return label;
            }
          }
        },
        legend: {
          display: false
        },
      },
      myChart: {}
    };
  },
  mounted() {
    const ctx = document.getElementById('chart');
    this.myChart = new Chart(ctx, {
      plugins: [plugin],
      type: this.type,
      data: {
        labels: this.labels,
        datasets: [
          {
            label: 'My First Dataset',
            data: this.scores,
            backgroundColor: '#01579B',
            borderColor: '#01579B',
            fillColor: '#79D1CF',
            strokeColor: '#79D1CF',
            borderWidth: 1
          }
        ]
      },
      options: this.options
    });
  },
  methods: {
    updateChart(data) {
      this.myChart.data.labels = data.columns;
      this.myChart.data.datasets[0].data = data.scores;
      this.myChart.options = this.options;
      this.myChart.options.scales.yAxes[0].ticks.max = data.scores.length > 0 ? Math.ceil(Math.max.apply(null, data.scores) * 1.2) : 0;
      this.myChart.update();
    }
  },
  watch: {
    size() {
      this.myChart.canvas.style.height = this.size.height + 'px';
      this.myChart.canvas.style.width = this.size.width + 'px';
      this.myChart.update();
    }
  }
};
</script>
