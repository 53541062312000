<template>
  <canvas id="chart" :width="size.width" :height="size.height"></canvas>
</template>

<script>
import Chart from '@/plugins/chart';

export default {
  name: 'ScatterChart',
  props: ['chartData', 'chartLabels', 'size', 'forecastType', 'uniqueAxis'],
  data() {
    return {
      type: 'scatter',
      new_chartLabels: {},
      options: {
        responsive: true,
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'linear',
              position: 'bottom',
              ticks: {
                callback: value => {
                  if (this.forecastType === 'clustering' || this.forecastType === 'classification') {
                    return this.new_chartLabels[0].type === 'numerical' ? this.$numeral(value).format('0,0.') : value;
                  } else if (this.forecastType === 'regression') {
                    return ['numerical', 'forecast'].includes(this.new_chartLabels[0].type) ? this.$numeral(value).format('0,0.') : value;
                  }
                  return value;
                }
              },
              scaleLabel: {
                display: true,
                labelString: ''
              },
              labels: []
            }
          ],
          yAxes: [
            {
              type: 'linear',
              ticks: {
                callback: value => {
                  if (this.forecastType === 'clustering' || this.forecastType === 'classification') {
                    return this.new_chartLabels[1].type === 'numerical' ? this.$numeral(value).format('0,0.') : value;
                  } else if (this.forecastType === 'regression') {
                    return ['numerical', 'forecast'].includes(this.new_chartLabels[1].type) ? this.$numeral(value).format('0,0.') : value;
                  }
                  return value;
                }
              },
              scaleLabel: {
                display: true,
                labelString: ''
              },
              labels: []
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += `${this.new_chartLabels[0].name} - `;
              if (this.forecastType === 'clustering' || this.forecastType === 'classification') {
                label += this.new_chartLabels[0].type === 'numerical' ? this.$numeral(tooltipItem.xLabel).format('0,0.') : tooltipItem.xLabel;
              } else if (this.forecastType === 'regression') {
                label += ['numerical', 'forecast'].includes(this.new_chartLabels[0].type) ? this.$numeral(tooltipItem.xLabel).format('0,0.') : tooltipItem.xLabel;
              }

              label += `, ${this.new_chartLabels[1].name} - `;
              if (this.forecastType === 'clustering' || this.forecastType === 'classification') {
                label += this.new_chartLabels[1].type === 'numerical' ? this.$numeral(tooltipItem.yLabel).format('0,0.') : tooltipItem.yLabel;
              } else if (this.forecastType === 'regression') {
                label += ['numerical', 'forecast'].includes(this.new_chartLabels[1].type) ? this.$numeral(tooltipItem.yLabel).format('0,0.') : tooltipItem.yLabel;
              }

              return label;
            }
          }
        },
        legend: {
          display: true
        }
      },
      myChart: {}
    };
  },
  mounted() {
    this.new_chartLabels = this.chartLabels;
    let chartData = this.$lodash.cloneDeep(this.chartData);
    this.setOptions(this.chartData, this.chartLabels, this.uniqueAxis);
    const ctx = document.getElementById('chart');
    this.myChart = new Chart(ctx, {
      type: this.type,
      data: chartData,
      options: this.options
    });
  },
  methods: {
    updateChart(data, chartLabels, uniqueAxis) {
      this.setOptions(data, chartLabels, uniqueAxis);
      this.new_chartLabels = chartLabels;

      data.datasets.forEach((dataset, key) => {
        if (key > (this.myChart.data.datasets.length - 1)) {
          this.myChart.data.datasets.push(dataset);
        } else {
          this.myChart.data.datasets[key].data = data.datasets[key].data;
          this.myChart.data.datasets[key].label = data.datasets[key].label;
          this.myChart.data.datasets[key].backgroundColor = data.datasets[key].backgroundColor;
          this.myChart.data.datasets[key].borderColor = data.datasets[key].borderColor;
          this.myChart.data.datasets[key].borderWidth = data.datasets[key].borderWidth;
        }
      });
      this.myChart.data.datasets.forEach((dataset, key) => {
        if (key > (data.datasets.length - 1)) {
          this.myChart.data.datasets.splice(key);
        }
      });
      this.myChart.data.labels = data.labels;

      // this.myChart.data = data;
      this.myChart.options = this.options;
      this.myChart.update();
    },
    setOptions(data, chartLabels, uniqueAxis) {
      this.options.scales.xAxes[0].scaleLabel.labelString = chartLabels[0].name;
      this.options.scales.yAxes[0].scaleLabel.labelString = chartLabels[1].name;

      if (this.forecastType === 'clustering' || this.forecastType === 'classification') {
        this.options.scales.xAxes[0].type = chartLabels[0].type === 'numerical' ? 'linear' : 'category';
        this.options.scales.yAxes[0].type = chartLabels[1].type === 'numerical' ? 'linear' : 'category';
      } else if (this.forecastType === 'regression') {
        this.options.scales.xAxes[0].type = ['numerical', 'forecast'].includes(chartLabels[0].type) ? 'linear' : 'category';
        this.options.scales.yAxes[0].type = ['numerical', 'forecast'].includes(chartLabels[1].type) ? 'linear' : 'category';
      }

      if (uniqueAxis && ['binary', 'ordinal', 'nominal'].includes(chartLabels[0].type)) {
        this.options.scales.xAxes[0].labels = uniqueAxis;
      } else {
        this.options.scales.xAxes[0].labels = [];
      }

      if (uniqueAxis && ['binary', 'ordinal', 'nominal'].includes(chartLabels[1].type)) {
        this.options.scales.yAxes[0].labels = uniqueAxis;
      } else {
        this.options.scales.yAxes[0].labels = [];
      }

      this.options.legend.display = data.datasets.length > 1;
    }
  },
  watch: {
    size() {
      // console.log('watch size');
      this.myChart.canvas.style.height = this.size.height + 'px';
      this.myChart.canvas.style.width = this.size.width + 'px';
      this.myChart.update();
    }
    // size: this.$lodash.debounce(this.resize, 550)
    // this.$lodash.debounce(this.resize, 550)
  }
};
</script>
