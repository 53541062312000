import { required, maxLength } from 'vuelidate/lib/validators';

const companyFormat = company => /^[A-zА-я0-9 _]+$/.test(company);

const companyMixin = {
  data() {
    return {
      company: ''
    };
  },
  validations: {
    company: {
      required,
      maxLength: maxLength(150),
      companyFormat
    }
  },
  computed: {
    companyMessage() {
      if (this.$v.company.$error || this.getError('company') !== '') {
        if (!this.$v.company.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.company.companyFormat) {
          return [this.$t('validations.company_format')];
        } else if (!this.$v.company.maxLength) {
          return [this.$t('validations.max_length[0]') + this.$v.company.$params.maxLength.max + this.$t('validations.max_length[1]')];
        } else if (!this.$v.company.$error && this.getError('company') !== '') {
          return [this.getError('company')];
        }
      }
      return [];
    }
  }
};

export default companyMixin;
