<template>
  <v-card class="elevation-12">
    <v-toolbar dense color="primary" dark flat>
      <v-toolbar-title>{{ $t('tasks.list.add') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-select class="mt-5 pt-2" :items="processes" v-model="process_value" :error-messages="processMessage" outlined dense :label="$t('tasks.list.process')"></v-select>
      <v-btn color="primary" @click="start" outlined>{{ $t('buttons.start') }}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';

export default {
  name: 'RunTask',
  mixins: [baseMixin],
  data() {
    return {
      processes: [],
      process_value: 0
    };
  },
  computed: {
    processMessage() {
      return this.getError('fileName') !== '' ? [this.getError('fileName')] : [];
    }
  },
  mounted() {
    this.getProccess();
  },
  methods: {
    getProccess() {
      this.$fetch
        .get('api/processes')
        .then(data => {
          let processes = [{text: this.$t('tasks.list.choose'), value: 0}];
          data.forEach(value => {
            processes.push({value: value.id, text: value.name});
          });
          this.processes = processes;
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    start() {
      this.$fetch
        .post(`/api/integration/tasks/${this.process_value}`)
        .then(() => {
          this.$emit('update');
        })
        .catch(error => {
          this.serverErrors = error;
        });
    }
  }
};
</script>
