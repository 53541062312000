<template>
  <v-main>
    <v-container fluid id="home">
      <h2>
        <span>{{ $t('tasks.list.name') + '    ' }}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="mx-2" @click="showRunTask = true" fab dark color="green">
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('tasks.list.add') }}</span>
        </v-tooltip>
      </h2>
      <v-row justify="start" class="mt-4">
        <v-btn color="primary" class="ml-0" outlined @click="$router.push('/connections')">{{ $t('intro.connections') }}</v-btn>
        <v-btn color="primary" class="ml-2" outlined @click="$router.push('/processes')">{{ $t('intro.processes') }}</v-btn>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0 pt-6">
          <v-simple-table fixed-header :height="`${windowHeight - 250}px`">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <p class="table-header grey--text">#</p>
                  </th>
                  <th class="text-left">
                    <p class="table-header">{{ $t('tasks.list.process') }}</p>
                  </th>
                  <th class="text-left">
                    <p class="table-header">{{ $t('tasks.list.createdAt') }}</p>
                  </th>
                  <th class="text-left">
                    <p class="table-header">{{ $t('tasks.list.updatedAt') }}</p>
                  </th>
                  <th class="text-left">
                    <p class="table-header">{{ $t('tasks.list.rows') }}</p>
                  </th>
                  <th class="text-left">
                    <p class="table-header">{{ $t('tasks.list.status') }}</p>
                  </th>
                  <th class="text-left">
                    <p class="table-header"></p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, key) in tasks" class="cursor" :key="key">
                  <td class="grey--text left-align text-left">{{ key + 1 }}</td>
                  <td class="left-align">{{ row.name }}</td>
                  <td class="left-align">{{ row.createdAt }}</td>
                  <td class="left-align">{{ row.updatedAt }}</td>
                  <td class="left-align">{{ $numeral(row.rows).format('0,0') }}</td>
                  <td v-if="row.status != 3" :class="`left-align ${statuses[row.status].color}--text`" >{{ statuses[row.status].name }}</td>
                  <td v-else :class="`left-align ${statuses[row.status].color}--text`" >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{ statuses[row.status].name }}</span>
                      </template>
                      <span>{{ row.errorMsg }}</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-btn v-if="row.status !== 1" color="primary" @click="restart(row.processId)" outlined>{{ $t('buttons.restart') }}</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-dialog v-model="showRunTask" max-width="500">
        <run-task @update="closeDialog"></run-task>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import RunTask from '@/views/tasks/RunTask.vue';

export default {
  name: 'Tasks',
  components: { RunTask },
  data() {
    return {
      tasks: [],
      showRunTask: false,
      statuses: {
        1: { name: this.$t('tasks.list.sync'), color: 'blue'},
        2: { name: this.$t('tasks.list.finished'), color: 'green'},
        3: { name: this.$t('tasks.list.error'), color: 'red'}
      }
    };
  },
  created() {
    window.addEventListener('resize', this.$lodash.debounce(this.getWindowHeight, 500));
    this.getWindowHeight();
    this.getTasks();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$lodash.debounce(this.getWindowHeight, 500));
  },
  methods: {
    getWindowHeight() {
      this.windowHeight = document.documentElement.clientHeight;
    },
    closeDialog() {
      this.showRunTask = false;
      this.getTasks();
    },
    getTasks() {
      let self = this;
      this.$fetch
        .get('api/integration/tasks')
        .then(data => {
          let tasks = [];
          data.forEach(value => {
            tasks.push({
              id: value.id,
              name: value.name,
              status: value.status,
              errorMsg: value.errorMsg,
              processId: value.processId,
              rows: value.params.rows,
              createdAt: this.$date.format(this.$date.parseISO(value.createdAt), 'dd.MM.yyyy HH:mm'),
              updatedAt: this.$date.format(this.$date.parseISO(value.updatedAt), 'dd.MM.yyyy HH:mm'),
            });
          });
          if (data.filter(x => x.status === 1).length > 0) {
            setTimeout(self.getTasks, 5000);
          }
          this.inform_about_update(tasks);
          this.tasks = tasks;
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    restart(process_id) {
      this.$fetch
        .post(`/api/integration/tasks/${process_id}`)
        .then(() => {
          this.getTasks();
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    inform_about_update(tasks) {
      // let syncing_tasks = this.tasks.filter(x => x.status === 1);
      // console.log('syncing_tasks');
      // console.log(syncing_tasks);
      // syncing_tasks.forEach(value => {
      //   let new_task = tasks.filter(x => x.id === value.id);
      //   if (new_task.length == 1) {
      //     if (new_task[0].status == 2) {
      //       this.$notify.success({ message: this.$t('notifications.task_success[0]') + new_task[0].name + this.$t('notifications.task_success[1]') });
      //     }
      //     if (new_task[0].status == 3) {
      //       this.$notify.error({ message: this.$t('notifications.task_failed[0]') + new_task[0].name + this.$t('notifications.task_failed[1]') });
      //     }
      //   }
      // })
    }
  }
};
</script>
