<template>
  <v-main>
    <v-container fluid id="home">
      <v-row justify="start" class="align-center">
        <v-btn icon color="primary" @click="$router.push('/tasks')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <h2>
          <span>{{ $t('processes.list.name') + '    ' }}</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="mx-2" @click="$router.push(`/processes/0/`)" fab dark color="green">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('processes.list.add') }}</span>
          </v-tooltip>
        </h2>
      </v-row>
      <v-row justify="start" class="my-2">
        <v-col cols="3" v-for="(process, key) in processes" :key="key">
          <v-card>
            <v-img :src="process.image" class="white--text align-end cursor" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px" @click="$router.push(`/processes/${process.id}/`)">
              <v-card-title @click="$router.push(`/processes/${process.id}/`)">{{ process.name }}</v-card-title>
            </v-img>
            <v-row justify="end" class="py-0 my-0">
              <v-card-actions class="py-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-information</v-icon>
                  </template>
                  <v-card-text class="pa-1">
                    <p class="text-left mb-0">{{ $t('processes.list.created') }}: {{ process.created_at }}</p>
                  </v-card-text>
                </v-tooltip>
              </v-card-actions>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import image from '../../img/integrations/process.png';

export default {
  name: 'ProcessList',
  data() {
    return {
      processes: [],
      showPhoto: false,
      activeItem: 0
    };
  },
  mounted() {
    this.update();
  },
  methods: {
    async update() {
      await this.$fetch
        .get('api/processes')
        .then(data => {
          let processes = [];
          data.forEach(value => {
            processes.push({
              id: value.id,
              name: value.name,
              image: image,
              created_at: this.$date.format(this.$date.parseISO(value.createdAt), 'dd.MM.yyyy HH:mm'),
            });
          });
          this.processes = processes;
        })
        .catch(error => {
          this.serverErrors = error;
        });
    }
  }
};
</script>
