import Vue from 'vue';
import { parseISO, format, parse } from 'date-fns';
// import 'chartjs-chart-box-and-violin-plot';

import VueSimpleContextMenu from 'vue-simple-context-menu';
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';

import Vuelidate from 'vuelidate';
import App from './App.vue';
import router from './router';
import store from './store';

import i18n from './plugins/i18n';

import vuetify from './plugins/vuetify';
import numeral from './plugins/numeral';
import Notify from './components/Notify';
import Fetch from './plugins/fetch';

import './sass/_app.scss';

Vue.use(Notify);

let lang = localStorage.getItem('b2l-lang');
if (lang == null) {
  lang = window.navigator.language.slice(0, 2) === 'ru' ? 'ru' : 'en';
  localStorage.setItem('b2l-lang', lang);
}
i18n.locale = lang;
store.commit('putLang', lang);
vuetify.userPreset.lang = lang;

const fetch = new Fetch(store, router, lang);

let debounce = require('lodash/debounce');
let uniqBy = require('lodash/uniqBy');
let remove = require('lodash/remove');
let cloneDeep = require('lodash/cloneDeep');
let isEqual = require('lodash/isEqual');

Vue.prototype.$lodash = { debounce, uniqBy, remove, cloneDeep, isEqual };

Vue.prototype.$date = { parseISO, format, parse };

Vue.prototype.$numeral = numeral;

Vue.prototype.$fetch = fetch;

Vue.use(Vuelidate);

Vue.component('vue-simple-context-menu', VueSimpleContextMenu);

Vue.config.productionTip = false;

let strategies = Vue.config.optionMergeStrategies;
strategies.validations = strategies.methods;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
