const baseMixin = {
  data() {
    return {
      serverErrors: {}
    };
  },
  methods: {
    getError(name) {
      if (Object.prototype.hasOwnProperty.call(this.serverErrors, name)) {
        if (Array.isArray(this.serverErrors[name])) {
          return this.serverErrors[name][0];
        } else {
          return this.serverErrors[name];
        }
      } else {
        return '';
      }
    },
    difference(array1, array2, fields) {
      let diff = [];
      let range = typeof fields === 'string' ? [fields] : fields;
      array1.forEach(value1 => {
        let check = false;
        array2.forEach(value2 => {
          let i = 0;
          range.forEach(field => {
            if (value1[field] === value2[field]) {
              i += 1;
            }
          });
          if (i === range.length) {
            check = true;
          }
        });
        if (!check) {
          diff.push(value1);
        }
      });
      return diff;
    }
  }
};

export default baseMixin;
