import { required, minLength, maxLength } from 'vuelidate/lib/validators';

// const passwordComplexity = password => {
//   // probably already handled by the required flag
//   if (password === '') {
//     return true;
//   }
//   // Minimum of 1 Uppercase Letter
//   if (/[A-ZА-Я]/.test(password) === false) {
//     return false;
//   }
//   // Minimum of 1 Number
//   if (/\d/.test(password) === false) {
//     return false;
//   }
//   return true;
// };

const passwordComplexity = (password) => /[A-ZА-Я]/.test(password) && /\d/.test(password);

const passwordMixin = {
  data() {
    return {
      password: '',
      showPassword: false
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(50),
      passwordComplexity
    }
  },
  computed: {
    passwordMessage() {
      if (this.$v.password.$error || this.getError('password') !== '') {
        if (!this.$v.password.required) {
          return [this.$t('validations.required')];
        } else if (!this.$v.password.maxLength) {
          return [this.$t('validations.max_length_password[0]') + this.$v.password.$params.maxLength.max + this.$t('validations.max_length_password[1]')];
        } else if (!this.$v.password.minLength) {
          return [this.$t('validations.min_length_password[0]') + this.$v.password.$params.minLength.min + this.$t('validations.min_length_password[1]')];
        } else if (!this.$v.password.passwordComplexity) {
          return [this.$t('validations.password_complexity')];
        } else if (!this.$v.password.$error && this.getError('password') !== '') {
          return [this.getError('password')];
        }
      }
      return [];
    }
  }
};

export default passwordMixin;
