import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({});


// import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
// import en from 'vuetify/es5/locale/en';
// import ru from 'vuetify/es5/locale/ru';

// Vue.use(Vuetify);

// const opts = {
//   lang: {
//     locales: { en, ru },
//     current: 'en',
//   },
// };

// export default new Vuetify(opts);