<template>
  <v-card class="elevation-12">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t('settings.add_new_user') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-row class="pt-2 pt-4">
          <v-col cols="12" sm="6" md="6" class="py-0">
            <v-text-field :label="$t('auth.username')" id="username" v-model="username" :error-messages="usernameMessage" type="text" />
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-0">
            <v-text-field label="E-Mail" id="email" v-model="email" :error-messages="emailMessage" type="text" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="green" id="register" @click="addUser" block :loading="load">{{ $t('settings.add_user') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';
import emailMixin from '@/mixins/emailMixin';
import usernameMixin from '@/mixins/usernameMixin';

export default {
  name: 'AddUser',
  mixins: [baseMixin, emailMixin, usernameMixin],
  data() {
    return {
      load: false
    };
  },
  methods: {
    addUser() {
      this.$v.$touch();
      this.serverErrors = {};
      if (!this.$v.$invalid) {
        this.load = true;
        this.$fetch
          .post('/api/users/invite', {
            email: this.email,
            username: this.username
          })
          .then(() => {
            this.$notify.success({ message: this.$t('notifications.new_user') });
            this.load = false;
            this.username = '';
            this.email = '';
            this.$emit('update');
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
          });
      }
    }
  }
};
</script>
