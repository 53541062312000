export default {
  intro: {
    about: 'About Us',
    product: 'About product',
    case: 'Cases',
    docs: 'Documentation',
    registration: 'Registration',
    login: 'Login',
    logout: 'Logout',
    sources: 'Sources',
    stories: 'Stories',
    settings: 'Settings',
    integration: 'Integration',
    connections: 'Connections',
    processes: 'Processes',
    tasks: 'Tasks',
    contact: {
      name: 'Interested in a product? Contact us',
      contact: 'Name',
      subject: 'Subject',
      message: 'Message',
      send: 'Send'
    },
    main_header: 'The Democratization of Predictive Analytics',
    main_subheader: 'Machine Learning for Everyone',
    products: {
      name: 'How does it work?',
      list: [
        {
          name: 'Uploading your data',
          logo: 'cloud-upload'
        },
        {
          name: 'Train the story with auto-search for the best model',
          logo: 'cloud-search'
        },
        {
          name: 'Retrieve a prediction',
          logo: 'cloud-download'
        },
        {
          name: 'Retrain the story on new data and features',
          logo: 'cloud-refresh'
        },
      ],
      another_list: [
        {
          name: 'No code',
          desc: 'No programming skills required. Brain2Logic gives opportunity to manipulate data and work with complex algorithms without coding. People with different level of machine learning skills are able to bring data-driven decisions'
        },
        {
          name: 'UX/UI centric approach',
          desc: 'We provide working environment for business analysts where they are able to manage complex algorithms, setup and customize open source models'
        },
        {
          name: 'AI model selection',
          desc: 'Automatic selection of the optimal model from a wide range of algorithms taking into account external and internal factors by each SKU and POS Brain2Logic opens access to multiple open source algorithms and models from SARIMAX and PROPHET to XGBOOST and LSTM'
        },
        {
          name: 'Software as a Service',
          desc: 'Top SaaS standards for scalability, performance and security'
        },
        {
          name: 'Data processing and analytics',
          desc: 'Ability to process, clean and merge data, detailed analytics on data and models, play with parameters, identify the significance of features'
        },
        {
          name: 'Advanced algorithms',
          desc: 'Using advanced algorithms for hyperparameter search and model fitting, use of cross validation, dimensionality reduction'
        },
        {
          name: 'Work with BigData',
          desc: 'Streaming data processing and incremental learning allows to work with big data and complex models'
        },
        {
          name: 'High performance',
          desc: 'Fast multiple parameter model matching with multiple CPU, high compression and GPU computing capabilities'
        },
        {
          name: 'Easy integration',
          desc: 'Advanced API for easy integration with any system, database or cloud service'
        },
        {
          name: 'Security',
          desc: 'Data encryption and data hosting in Tier 3 data centers'
        },
      ]
    },
    cases: {
      name: 'Business cases',
      list: [
        'Sales planning by region, channel and SKU taking into account seasonality, trends and cyclesPredicting sales of the current product line',
        'Sales planning taking into account influencing factors (weather, holidays, other product sales, location, etc.)',
        'Sales planning for new product line based on sales history of related products',
        'Sales planning based on promotional activities (type, duration, size of discount, etc.)',
        'Identifying customer churn parameters in order to retain customers (e.g., texting about a special offer)',
        'Clustering customers into groups based on multiple factors (average check, order frequency, etc.)',
        'Predicting the cost of a product/service based on multiple influencing factors and elasticity of demand',
        'Targeted advertising, identifying customers with maximum offer acceptance potential',
        'Customer credit risk assessment based on influencing factors',
        'Fraud detection (anomalous transactions)',
        'Assortment optimization via clustering based on multiple parameters',
        'Identification of Leads with the Highest Potential'
      ]
    },
    business_cases: {
      name: 'Success Stories',
      challenges: 'CHALLENGES',
      benefits: 'BENEFITS',
      list: [
        {
          title: 'Leading medical company',
          area: 'Solution: Revenue forecasting',
          case: 'Revenue forecasting by 2000+ SKUs and 80 regions with automatic selection of optimal model for each forecasting unit and options to include additional factors to improve accuracy',
          challenges: ['Large number of unique SKUs that need to be forecasted separately', 'Limited availability of data science specialists', 'Forecast accuracy issues'],
          benefits: ['Ability to calculate forecasts by each SKU and region', 'Support for managers in forecasting activities', 'Ability to add new factors and variables into forecasting process', 'Integration with Anaplan models']
        },
        {
          title: 'Leading internet company',
          area: 'Solution: B2B customers sales forecasting',
          case: 'Sales forecasting by 2 million of B2B customers taking into account internal and external attributes',
          challenges: ['No statistics indication for sales managers in forecasting', 'Judgmental estimations errors', 'No data science specialists are available for particular forecasting tasks'],
          benefits: ['Ability to calculate forecasts by millions of existing customers', 'Ability to provide basis for forecasting of new customers', 'Challenge for judgmental forecasting', 'Ability to add new factors and variables into forecasting process']
        },
        {
          title: 'Leading telecom company',
          area: 'Solution: Recommendation forecasting',
          case: 'Content recommendation service for each user based on consumption history and matching similar combinations of multiple content characteristics',
          challenges: ['Slow process of selecting recommendations using 50 parameters and 400 content characteristics', 'Hours of work to manage machine learning algorithms', 'Low data science specialists availability'],
          benefits: ['Confirmed the ability to tune machine learning algorithms without any programming by analysts', 'Increased prediction accuracy of ROC AUC from 72% to 86%', 'Accelerated machine learning models tuning by 2 times']
        }
      ]
    },
    email_placeholder: 'Enter email address',
    early_access: 'Request early access',
    demonstration: 'Demonstration'
  },
  auth: {
    registration: 'Registration',
    username: 'Username',
    company: 'Company',
    password: 'Password',
    old_password: 'Old password',
    new_password: 'New password',
    confirm_password: 'Confirm Password',
    agree: 'I agree to the processing of personal data and the terms of use of the system.',
    register: 'Sign up',
    login: 'Login',
    forgot_password: 'Forgot your password?',
    restore_password: 'Restore password',
    set_password: 'Set password',
    reset_password: 'Reset password',
    renew_password: 'Update password'
  },
  settings: {
    settings: 'Settings',
    profile: 'User Profile',
    change_password: 'Change password',
    users: 'Users',
    user: 'Username',
    add_user: 'Add a user',
    change_username: 'Username update',
    add_new_user: 'Adding a user',
    change_company: 'Company name change',
    new_password: 'New password',
    anaplan: 'Integration with Anaplan',
    workers: 'Workers',
    worker_desc: 'Worker is responsible for the background heavy tasks (story trainning, forecasting, data pre-processing, etc.). Each worker solves one problem, or several workers can solve a single problem together',
    worker_spec: '1 CPU / 2 GB RAM',
    add_worker: 'Add a worker',
    delete_worker: 'Delete a worker',
    login: 'Login'
  },
  tasks: {
    name: 'Tasks',
    story_name: 'Story name',
    type: 'Task',
    type_list: ['Story trainning', 'Forecast preparation'],
    remainning_time: 'Remainning time',
    stop_task: 'Stop task',
    cpu: 'Workers',
    queue: 'In a queue '
  },
  photo: {
    name: 'Images',
    change_cover: 'Change cover',
    upload_photo: 'Upload a photo',
    reset_photo: 'Reset photo'
  },
  source: {
    list: {
      name: 'List of sources',
      updated: 'Updated',
      rows: 'Rows',
      size: 'Size',
      mb: ' Mb',
      add: 'Add a source',
      description: 'Description',
      wait: 'In the queue to train'
    },
    detail: {
      new: 'New source',
      upload: 'Upload',
      name: 'Story name',
      date_hours: 'Dates (hours)',
      date_days: 'Dates (days)',
      date_weeks: 'Dates (weeks)',
      date_months: 'Dates (months)',
      date_quarters: 'Dates (quarters)',
      date_years: 'Dates (years)',
      number: 'Numbers',
      category: 'Category',
      external_data: 'Eternal data',
      no: 'Not used',
      mixed: 'Mixing and processing',
      weather: 'Weather Yandex',
      exchange_rate: 'Exchange rates Bank of Russia',
      data_errors: 'Errors in data!',
      empty_value: 'Empty field',
      wrong_value: 'Wrong format',
      comment: 'Comments / description',
      write_comment: 'Write some comment...',
      connection_settings: 'Connection settings',
      s3: 'S3 Object Storage',
      url: 'URL address',
      access_key_id: 'Access key',
      secret_access_key: 'Access secret',
      bucket_name: 'Bucket name',
      file_name: 'File name',
      add_handling: 'Add a source / preprocessing',
      handling_add_source: 'Add a source',
      handling_preprocessing: 'Preprocess data',
      handling_merge: 'Merge data',
      process_name: 'Process name',
      column_desc: ' - column data description',
      column_type: 'Column type',
      column_types: { int32: 'Int', int64: 'Int', float32: 'Float', float64: 'Float', object: 'Category (text)', datetime: 'Date' },
      column_count: 'Values count',
      column_unique: 'Unique values count',
      date_first: 'Start date',
      date_last: 'End date',
      number_min: 'Min value',
      number_max: 'Max value',
      number_mean: 'Mean value',
      number_std: 'Standard deviation',
      number_25: 'Lower percentile (25%)',
      number_50: 'Median',
      number_75: 'Upper percentile (75%)',
      has: 'No',
      has_nan: 'Has n/a values',
      has_empty: 'Has empty values',
      has_zeros: 'Has zero values',
      category_count: 'Quantity',
      value_distribution: 'Value distribution',
      categories_values: 'Сategory',
      numerical_values: 'Value range',
      rows: 'Rows - ',
      columns: 'Columns - ',
      handling_result1: 'Preview',
      handling_result2: 'Preprocessed source',
      show_data: 'Data table',
      add_condition: 'Add condition',
      remove_column: 'Remove columns',
      rename_column: 'Rename columns',
      replace_nan: 'Replace NaN values',
      replace_empty: 'Replace empty values',
      replace_zeros: 'Replace zeros values',
      change_type: 'Change column types',
      create_new_column: 'Create new columns',
      column: 'Data column',
      new_name: 'New name',
      impute_type: 'Imputing type',
      impute_delete: 'Delete rows',
      impute_median: 'Median value',
      impute_average: 'Average value',
      impute_most_freq: 'Most frequent value',
      impute_0: 'Zero value',
      impute_empty: 'Empty value',
      impute_value: 'Your value',
      value: 'Value',
      columnd_dtype: 'Column type',
      merge: 'Merge type',
      merge_left_join: 'Left join',
      source1: 'Source 1',
      source2: 'Source 2',
      merge_key: 'Merge key'
    }
  },
  story: {
    list: {
      name: 'List of stories',
      updated: 'Updated',
      time: 'Average trainning time',
      add: 'Add a story',
      minutes: ' minutes',
      error_base: 'Trainning error',
      error_stop: 'Trainning stopped'
    },
    detail: {
      new: 'New story',
      name: 'Story name',
      source: 'Source',
      choose: 'Choose',
      models: 'Used models',
      etc: 'etc',
      forecastType: 'Forecast Type',
      type: 'Type',
      desc: 'Parameters',
      rows: 'Rows',
      trainning_time: 'Trainning time',
      forecast_quantity: 'Number of forecasts',
      time: ' min',
      chart: 'Chart',
      percentile: 'Peak smoothing',
      value: 'Value (%)',
      value2: 'Value',
      rolling: 'Data variance smoothing',
      diff: 'Data offset',
      diff2: 'Differencing of order ',
      adfuller: 'Dickey-Fuller test order of ',
      comp: 'Components of time series',
      trend: 'Trend',
      seasonal: 'Seasonal',
      residual: 'Residual',
      time_series: 'Time series forecasting',
      regression: 'Regression',
      clustering: 'Clustering',
      classification: 'Classification',
      clusters: 'Number of groups',
      all_models: 'All models',
      group: 'Group ',
      best: 'Best model',
      empty: 'The field is not used',
      date_hours: 'Hour value',
      date_days: 'Date value',
      date_weeks: 'Week value',
      date_months: 'Month value',
      date_quarters: 'Quarter value',
      date_years: 'Year value',
      forecast: 'Forecast value',
      numerical: 'Feature value (numerical)',
      binary: 'Feature value (binary, only two values)',
      ordinal: 'Feature value (clear order of values)',
      nominal: 'Feature value (discrete values)',
      category: 'Analytical value',
      analytics: 'Analytics',
      features_maps: 'Mapping feature values',
      features_maps_nominal: 'Available values for nominal features',
      features_map: 'Feature mapping - ',
      features_map_binary: 'Drag the field values that relate to True that to False',
      features_map_ordinal: 'Drag the field values in ascending order',
      mb: ' Mb',
      all_values: 'All values',
      default: 'Default ',
      SARIMAX: {
        name: 'SARIMAX - Seasonal AutoRegressive Integrated Moving Average with eXogenous regressors model',
        short: 'SARIMAX',
        default: {
          p: '0-2',
          d: 'ADF тест',
          q: '0-2',
          P: '0-2',
          D: 'CH тест',
          Q: '0-2'
        },
        params: {
          p: ['p', 'Order of autoregressive parameters for the model (AR)'],
          d: ['d', 'Order of differences for the model'],
          q: ['q', 'Order of moving average parameters for the model (MA)'],
          P: ['P', 'Order of autoregressive parameters (AR) for seasonal component of the model'],
          D: ['D', 'Order of differences for seasonal component of the model'],
          Q: ['Q', 'Order of moving average parameters (MA) for seasonal component of the model'],
          m: ['Period of seasonal cycle', 'Period of seasonal cycle'],
          enforce_stationarity: ['Enforce stationarity', 'Whether or not to require the autoregressive parameters to correspond to a stationarity process'],
          enforce_invertibility: ['Enforce invertibility', 'Whether or not to require the moving average parameters to correspond to an invertible process']
        }
      },
      HWES: {
        name: 'HWES - Holt Winter’s Exponential Smoothing model',
        short: 'HWES',
        default: {
          trend: 'all values',
          damped: 'all values',
          seasonal: 'all values',
          boxcox: 'all values',
          bias: 'all values'
        },
        params: {
          trend: ['Trend', 'Type of trend component'],
          damped: ['Damped', 'Should the trend component be damped'],
          seasonal: ['Seasonal', 'Type of seasonal component'],
          boxcox: ['Use Box-Cox', 'Should the Box-Cox transform be applied to the data first'],
          bias: ['Remove bias', 'Remove bias from forecast values and fitted values by enforcing that the average residual is equal to zero'],
          seasonal_periods: ['Period of seasonal cycle']
        }
      },
      PROPHET: {
        name: 'Prophet - model for forecasting time series created by Facebook',
        short: 'PROPHET',
        default: {
          order_yearly: '10',
          order_weekly: '3',
          interval_width: '0.8'
        },
        params: {
          order_yearly: ['Yearly seasonality', 'Fourier order for yearly seasonality'],
          order_weekly: ['Weekly seasonality', 'Fourier order for weekly seasonality'],
          interval_width: ['Uncertainty intervals width', 'Uncertainty intervals width']
        }
      },
      XGBOOST: {
        name: 'XGBOOST - Extreme Gradient Boosting model',
        short: 'XGBOOST',
        default: {
          estimators: '100',
          learning_rates: '0.01',
          diff: 'ADF test'
        },
        params: {
          estimators: ['Trees number', 'Number of gradient boosted trees'],
          learning_rates: ['Learning rate', 'The learning rate used for reducing the gradient step'],
          diff: ['Series differencing', 'Determines the series differencing to achieve stationarity']
        }
      },
      NGBOOST: {
        name: 'NGBOOST - gradient boosting model created by Stanford University',
        short: 'NGBOOST',
        default: {
          estimators: '500',
          learning_rates: '0.01',
          diff: 'ADF test'
        },
        params: {
          estimators: ['Trees number', 'Number of gradient boosted trees'],
          learning_rates: ['Learning rate', 'The learning rate used for reducing the gradient step'],
          diff: ['Series differencing', 'Determines the series differencing to achieve stationarity']
        }
      },
      CATBOOST: {
        name: 'CATBOOST - gradient boosting model created by Yandex',
        short: 'CATBOOST',
        default: {
          iterations: '1000',
          learning_rates: '0.03',
          depths: '6',
          diff: 'ADF test'
        },
        params: {
          iterations: ['Maximum trees number', 'The maximum number of trees that can be built when solving machine learning problems'],
          learning_rates: ['Learning rate', 'The learning rate used for reducing the gradient step'],
          depths: ['Maximum tree depth', 'Determines the maximum depth of the tree in training'],
          diff: ['Series differencing', 'Determines the series differencing to achieve stationarity']
        }
      },
      LIGHTGBM: {
        name: 'LIGHTGBM - gradient boosting model created by Microsoft',
        short: 'LIGHTGBM',
        default: {
          iterations: '100',
          learning_rates: '0.1',
          depths: '-1',
          diff: 'ADF тест'
        },
        params: {
          iterations: ['Maximum trees number', 'The maximum number of trees that can be built when solving machine learning problems'],
          learning_rates: ['Learning rate', 'The learning rate used for reducing the gradient step'],
          depths: ['Maximum tree depth', 'Determines the maximum depth of the tree in training'],
          diff: ['Series differencing', 'Determines the series differencing to achieve stationarity']
        }
      },
      LSTM: {
        name: 'LSTM - deep neural network (Long Short-Term Memory)',
        short: 'LSTM',
        default: {
          epochs: '30',
          batch_size: '32'
        },
        params: {
          epochs: ['Number of epochs', 'How much times we pass a dataset to the neural network'],
          batch_size: ['Batch size', 'Total number of training examples present in a single batch']
        }
      },
      AR: {
        name: 'Autoregressive model using Conditional Maximum Likelihood (OLS)',
        short: 'AutoRegressive model',
        default: {
          lags: '1-5',
          trend: 'c',
          seasonal: 'True'
        },
        params: {
          lags: ['Lags', 'The number of lags to include in the model'],
          trend: ['Trend', 'The trend to include in the model'],
          seasonal: ['Seasonal', 'Whether to include seasonal dummies in the model']
        }
      },
      YTY: {
        name: 'Year-To-Year Model (YTY)',
        short: 'Year-To-Year Model',
      },
      SES: {
        name: 'Simple Exponential Smoothing (SES)',
        short: 'SES',
      },
      LogarR: {
        name: 'Logarithmic Regression (LogarR)',
        short: 'LogarR',
      },
      ER: {
        name: 'Exponential Regression (ER)',
        short: 'ER',
      },
      // MA: {
      //   name: 'Moving Average (MA)',
      //   short: 'MA',
      //   default: {
      //     q: '0-7'
      //   },
      //   params: {
      //     q: ['q', 'Moving average order'],
      //   }
      // },
      // VAR: {
      //   name: 'Vector Autoregression (VAR)',
      //   short: 'VAR',
      // },
      // VARMAX: {
      //   name: 'Vector Autoregressive Moving Average with eXogenous regressors model (VARMAX)',
      //   short: 'VARMAX',
      //   default: {
      //     p: '1-7',
      //     q: '1-7',
      //     trend: 'all values',
      //   },
      //   params: {
      //     p: ['p', 'Order of autoregressive parameters for the model (AR)'],
      //     q: ['q', 'Order of moving average parameters for the model (MA)'],
      //     // trend: ['Trend', 'Trend type parameter'],
      //   }
      // },
      SVR: {
        name: 'Support Vector Regression (SVR)',
        short: 'Support Vector Regression',
        default: {
          kernel: 'rbf'
        },
        params: {
          kernel: ['Kernel', 'Specifies the kernel type to be used in the algorithm']
        }
      },
      DTR: {
        name: 'Decision Tree Regression (DTR)',
        short: 'Decision Tree Regression',
        default: {
          criterion: 'mse'
        },
        params: {
          criterion: ['Criterion', 'The function to measure the quality of a split']
        }
      },
      RFR: {
        name: 'Random Forest Regression (RFR)',
        short: 'Random Forest Regression',
        default: {
          n_estimators: '100'
        },
        params: {
          n_estimators: ['Number of trees', 'The number of trees in the forest']
        }
      },
      PR: {
        name: 'Polynomial Regression (PR)',
        short: 'Polynomial Regression',
        default: {
          degree: '2'
        },
        params: {
          degree: ['Degree', 'The degree of the polynomial features']
        }
      },
      XGBOOST_reg: {
        name: 'XGBOOST - Extreme Gradient Boosting model',
        short: 'XGBOOST',
        default: {
          n_estimators: '100',
          learning_rate: '0.01'
        },
        params: {
          n_estimators: ['Trees number', 'Number of gradient boosted trees'],
          learning_rate: ['Learning rate', 'The learning rate used for reducing the gradient step']
        }
      },
      NGBOOST_reg: {
        name: 'NGBOOST - gradient boosting model created by Stanford University',
        short: 'NGBOOST',
        default: {
          n_estimators: '500',
          learning_rate: '0.01'
        },
        params: {
          n_estimators: ['Trees number', 'Number of gradient boosted trees'],
          learning_rate: ['Learning rate', 'The learning rate used for reducing the gradient step']
        }
      },
      CATBOOST_reg: {
        name: 'CATBOOST - gradient boosting model created by Yandex',
        short: 'CATBOOST',
        default: {
          iterations: '1000',
          learning_rate: '0.03',
          depth: '6'
        },
        params: {
          iterations: ['Maximum trees number', 'The maximum number of trees that can be built when solving machine learning problems'],
          learning_rate: ['Learning rate', 'The learning rate used for reducing the gradient step'],
          depth: ['Maximum tree depth', 'Determines the maximum depth of the tree in training']
        }
      },
      LIGHTGBM_reg: {
        name: 'LIGHTGBM - gradient boosting model created by Microsoft',
        short: 'LIGHTGBM',
        default: {
          num_iterations: '100',
          learning_rate: '0.1',
          max_depth: '-1'
        },
        params: {
          num_iterations: ['Maximum trees number', 'The maximum number of trees that can be built when solving machine learning problems'],
          learning_rate: ['Learning rate', 'The learning rate used for reducing the gradient step'],
          max_depth: ['Maximum tree depth', 'Determines the maximum depth of the tree in training']
        }
      },
      LR: {
        name: 'Linear Regression (LR)',
        short: 'Linear Regression',
      },
      LogR: {
        name: 'Logistic Regression (LogR)',
        short: 'Logistic Regression',
        default: {
          penalty: 'l2'
        },
        params: {
          penalty: ['Penalty', 'Used to specify the norm used in the penalization']
        }
      },
      KNN: {
        name: 'K-Nearest Neighbors (KNN)',
        short: 'K-Nearest Neighbors',
        default: {
          n_neighbors: '5'
        },
        params: {
          n_neighbors: ['Number of neighbors', 'Number of neighbors to use by default for kneighbors queries']
        }
      },
      KMEANS: {
        name: 'K-Nearest Neighbors',
        short: 'K-Nearest Neighbors'
      },
      AGGLOMERATIVE_CLUSTERING: {
        name: 'Agglomerative Clustering',
        short: 'Agglomerative Clustering'
      },
      DBSCAN: {
        name: 'Density-Based Spatial Clustering of Applications with Noise (DBSCAN)',
        short: 'DBSCAN'
      },
      BIRCH: {
        name: 'BIRCH implements the Birch clustering algorithm',
        short: 'BIRCH'
      },
      SVM: {
        name: 'Support Vector Machine (SVM)',
        short: 'Support Vector Machine',
        default: {
          kernel: 'rbf'
        },
        params: {
          kernel: ['Kernel', 'Specifies the kernel type to be used in the algorithm']
        }
      },
      NB: {
        name: 'Naive Bayes (NB)',
        short: 'Naive Bayes',
      },
      DTC: {
        name: 'Decision Tree Classifier (DTC)',
        short: 'Decision Tree Classifier',
        default: {
          criterion: 'gini'
        },
        params: {
          criterion: ['Criterion', 'The function to measure the quality of a split']
        }
      },
      RFC: {
        name: 'Random Forest Classifier (RFC)',
        short: 'Random Forest Classifier',
        default: {
          n_estimators: '100',
          criterion: 'gini'
        },
        params: {
          n_estimators: ['Number of trees', 'The number of trees in the forest'],
          criterion: ['Criterion', 'The function to measure the quality of a split']
        }
      },
      XGBOOST_class: {
        name: 'XGBOOST - Extreme Gradient Boosting model',
        short: 'XGBOOST',
        default: {
          n_estimators: '100',
          learning_rate: '0.01',
          max_depth: '3',
          random_state: '0',
          gamma: '0',
          min_child_weight: '1',
          max_delta_step: '0',
          booster: 'gbtree',
          subsample: '1',
          colsample_bytree: '1',
          colsample_bylevel: '1',
          colsample_bynode: '1',
          reg_alpha: '0',
          reg_lambda: '1',
          scale_pos_weight: '1',
          early_stopping_rounds: 'None'
        },
        params: {
          n_estimators: ['Trees number', 'Number of gradient boosted trees'],
          learning_rate: ['Learning rate', 'The learning rate used for reducing the gradient step'],
          max_depth: ['Maximum tree depth', 'Determines the maximum depth of the tree in training'],
          random_state: ['Random state', 'Random number seed'],
          gamma: ['Gamma', 'Minimum loss reduction required to make a further partition on a leaf node of the tree'],
          min_child_weight: ['Minimum child weight', 'Minimum sum of instance weight (hessian) needed in a child'],
          max_delta_step: ['Max delta step', 'Maximum delta step we allow each leaf output to be'],
          booster: ['Booster', 'Which booster to use'],
          subsample: ['Subsample', 'Subsample ratio of the training instances'],
          colsample_bytree: ['Colsample by tree', 'Colsample ratio of columns when constructing each tree'],
          colsample_bylevel: ['Colsample by level', 'Colsample ratio of columns for each level'],
          colsample_bynode: ['Colsample by node', 'Colsample ratio of columns for each node (split)'],
          reg_alpha: ['Alpha', 'L1 regularization term on weights'],
          reg_lambda: ['Lambda', 'L2 regularization term on weights'],
          scale_pos_weight: ['Scale posisitive weight', 'Control the balance of positive and negative weights'],
          early_stopping_rounds: ['Early stopping rounds', 'Activates early stopping']
        }
      },
      CATBOOST_class: {
        name: 'CATBOOST - gradient boosting model created by Yandex',
        short: 'CATBOOST',
        default: {
          iterations: 'None',
          learning_rate: 'None',
          depth: 'None',
          random_state: 'None',
          l2_leaf_reg: 'None',
          model_size_reg: 'None',
          rsm: 'None',
          min_data_in_leaf: 'None',
          subsample: 'None',
          class_weights: 'None',
          early_stopping_rounds: 'None',
        },
        params: {
          iterations: ['Maximum trees number', 'The maximum number of trees that can be built when solving machine learning problems'],
          learning_rate: ['Learning rate', 'The learning rate used for reducing the gradient step'],
          depth: ['Maximum tree depth', 'Determines the maximum depth of the tree in training'],
          random_state: ['Random state', 'Random number seed'],
          l2_leaf_reg: ['L2 leaf regularization', 'Coefficient at the L2 regularization term of the cost function'],
          model_size_reg: ['Model size regularization', 'Model size regularization coefficient'],
          rsm: ['Subsample ratio', 'Subsample ratio of columns when constructing each tree'],
          min_data_in_leaf: ['Minimum samples in a leaf', 'The minimum training samples count in leaf'],
          subsample: ['Subsample rate', 'Sample rate for bagging'],
          class_weights: ['Weight for class 1', 'The value is used as multiplier for the class 1 weight'],
          early_stopping_rounds: ['Early stopping rounds', 'Activates early stopping']
        }
      },
      LIGHTGBM_class: {
        name: 'LIGHTGBM - gradient boosting model created by Microsoft',
        short: 'LIGHTGBM',
        default: {
          n_estimators: '100',
          learning_rate: '0.1',
          max_depth: '-1',
          boosting_type: 'gbdt',
          num_leaves: '31',
          subsample_for_bin: '200000',
          random_state: 'None',
          scale_pos_weight: '1',
          min_split_gain: '0',
          min_child_weight: '0.001',
          min_child_samples: '20',
          subsample: '1',
          subsample_freq: '0',
          colsample_bytree: '1',
          reg_alpha: '0',
          reg_lambda: '0',
          early_stopping_rounds: 'None',
        },
        params: {
          n_estimators: ['Trees number', 'Number of gradient boosted trees'],
          learning_rate: ['Learning rate', 'The learning rate used for reducing the gradient step'],
          max_depth: ['Maximum tree depth', 'Determines the maximum depth of the tree in training'],
          boosting_type: ['Boosting type', 'Determines boosting type'],
          num_leaves: ['Maximum tree leaves', 'Maximum tree leaves for base learners'],
          subsample_for_bin: ['Subsample for bin', 'Number of samples for constructing bins'],
          random_state: ['Random state', 'Random number seed'],
          scale_pos_weight: ['Scale posisitive weight', 'Control the balance of positive and negative weights'],
          min_split_gain: ['Minimum partition loss reduction', 'Minimum loss reduction required to make a further partition on a leaf node of the tree'],
          min_child_weight: ['Minimum child weight', 'Minimum sum of instance weight (hessian) needed in a child (leaf)'],
          min_child_samples: ['Minimum child samples', 'Minimum number of data needed in a child (leaf)'],
          subsample: ['Subsample', 'Subsample ratio of the training instances'],
          subsample_freq: ['Frequence of subsample', 'Frequence of subsample'],
          colsample_bytree: ['Subsample by tree', 'Subsample ratio of columns when constructing each tree'],
          reg_alpha: ['Alpha', 'L1 regularization term on weights'],
          reg_lambda: ['Lambda', 'L2 regularization term on weights'],
          early_stopping_rounds: ['Early stopping rounds', 'Activates early stopping']
        }
      },
      NGBOOST_class: {
        name: 'NGBOOST - gradient boosting model created by Stanford University',
        short: 'NGBOOST',
        default: {
          n_estimators: '500',
          learning_rate: '0.01',
          random_state: 'None',
          minibatch_frac: '1',
          col_sample: '1',
          early_stopping_rounds: 'None',
        },
        params: {
          n_estimators: ['Trees number', 'Number of gradient boosted trees'],
          learning_rate: ['Learning rate', 'The learning rate used for reducing the gradient step'],
          random_state: ['Random state', 'Random number seed'],
          minibatch_frac: ['Rows subsample', 'The percent subsample of rows to use in each boosting iteration'],
          col_sample: ['Columns subsample', 'The percent subsample of columns to use in each boosting iteration'],
          early_stopping_rounds: ['Early stopping rounds', 'Activates early stopping']
        }
      },
      optimal_config: 'Optimal model parameters: ',
      empty_config: 'no settings',
      search: 'Search',
      trainType: 'Trainning type',
      embedded: 'Embedded',
      custom: 'Custom',
      class: 'Class ',
      axisX: 'Axis X',
      axisY: 'Axis Y',
      dimensionality_reduction: 'Dimensionality Reduction',
      dr_name: 'Algorithm',
      dr_types: ['Principal Component Analysis (PCA)', 'Kernel PCA', 'Linear Discriminant Analysis (LDA)'],
      dr_dimensions: 'Target number of dimensions',
      dr_current_dimensions: 'Current number of dimensions',
      target_accuracy_dimensions: 'Accuracy at the target number of dimensions',
      test_size: 'Test dataset size',
      feature_importance: 'Feature Importance',
      feature_imp: 'Feature Importance',
      feature_algorithm: 'Algorithm',
      features: 'Features',
      cross_validation: 'Cross Validation',
      number_of_folds: 'Number of folds',
      balanced: 'Stratified classes',
      probability_threshold: 'Probability threshold for class 1',
      probability_threshold_value: 'Probability',
      hyperparameter_tuning: 'Hyperparameter tuning',
      hyperparameter_tuning_value: 'Tuning model',
      hyperparameter_tuning_iterations: 'Max iterations',
      target_scaling: 'Forecast value scaling',
      train_accuracy: 'Include accuracy on training data',
      eliminate_negative_values: 'Eliminate negative values',
      scaler: 'Scaling model',
      test_date: 'Test starting date',
      choose_date: 'Choose a date',
      accuracy: 'Forecast accuracy score',
      time_features: 'Time features',
      feature_month: 'Month',
      feature_year: 'Year',
      feature_day_of_week: 'Day of Week',
      feature_week: 'Week',
      feature_last_month_lag: 'Last month lag',
      feature_3_last_month_lag: 'Last 3 month lags',
      feature_12_last_month_lag: 'Last 12 month lags',
      feature_last_week_lag: 'Last week lag',
      feature_2_last_week_lag: 'Last 2 week lags',
      feature_4_last_week_lag: 'Last 4 week lags',
      feature_prior_1_year_lag: 'Prior year lag',
      feature_prior_2_year_lag: 'Prior 2 year lags',
      elbow_method: 'Define number of groups',
      elbow_x: 'Quantity of groups',
      elbow_y: 'Sum of squared error',
      noise: 'Data noise',
      base_conf: 'Base',
      cpu: 'Number of parallel processes',
      base_config: {
        cpu: {
          name: 'Number of parallel processes',
          desc: 'Determines the number of parallel processes used to accelerate model training, data processing and prediction'
        }
      }
    },
    forecast: {
      status: 'Status',
      trained: 'Trained',
      not_trained: 'Not trained',
      steps: 'Forecast period',
      choose: 'Choose',
      models: 'Forecast model',
      model: 'Model',
      best: 'Best model',
      all_values: 'All values',
      actual: 'Actual',
      forecast: 'Forecast ',
      // group_forecast: 'Group forecasting',
      // regression_forecast: 'Value forecasting',
      // classification_forecast: 'Class forecasting',
      // numerical_forecast: 'Forecast: ',
      step_period: {
        date_hours: ' (hours)',
        date_days: ' (days)',
        date_weeks: ' (weeks)',
        date_months: ' (months)',
        date_quarters: ' (quarters)',
        date_years: ' (years)'
      },
      upload: 'Data for forecast',
      file_configs: 'Settings in json format',
      probability: 'Predict the probability of each class',
      cv: {
        name: 'Cross Validation Error',
        desc: 'Average prediction error in cross validation'
      },
      // cv_mean_deviation: {
      //   name: 'Cross Validation Mean Deviation',
      //   desc: 'Mean deviation in cross validation'
      // },
      // cv_std: {
      //   name: 'Cross Validation Standard Deviation',
      //   desc: 'Standard deviation in cross validation'
      // },
      rmse: {
        name: 'RMSE',
        desc: 'Root Mean Square Error'
      },
      mse: {
        name: 'MSE',
        desc: 'Mean Square Error'
      },
      mape: {
        name: 'MAPE',
        desc: 'Mean Absolute Percentage Error'
      },
      mae: {
        name: 'MAE',
        desc: 'Mean Absolute Error'
      },
      bias: {
        name: 'bias',
        desc: 'Mean Forecast Error'
      },
      accuracy_score: {
        name: 'Accuracy',
        desc: '% of predicted labels match the actual set of labels'
      },
      precision_score: {
        name: 'Precision',
        desc: 'The precision is the ratio tp / (tp + fp) where tp is the number of true positives and fp the number of false positives'
      },
      average_precision_score: {
        name: 'Average Precision',
        desc: 'Average precision summarizes a precision-recall curve as the weighted mean of precisions achieved at each threshold, with the increase in recall from the previous threshold used as the weight'
      },
      recall_score: {
        name: 'Recall',
        desc: 'The recall is the ratio tp / (tp + fn) where tp is the number of true positives and fn the number of false negatives'
      },
      f1_score: {
        name: 'F1 score',
        desc: 'The F1 score can be interpreted as a weighted average of the precision and recall. The formula for the F1 score is: F1 = 2 * (precision * recall) / (precision + recall)'
      },
      roc_auc_score: {
        name: 'ROC AUC',
        desc: 'Compute Area Under the Receiver Operating Characteristic Curve (ROC AUC)'
      },
      confusion_matrix: {
        name: 'Confusion Matrix',
        desc: 'Compute confusion matrix to evaluate the accuracy of a classification'
      },
    },
  },
  integration: {
    export: 'Data export',
    export_success: 'Model started learning, you can close the window',
    import: 'Data import',
    export_import: 'Data export & import',
    import_success: 'The generation of a forecast and its import has started, you can close the window',
    status: 'Status',
    success: 'Data is ready for trainning',
    check: 'Story is ready for forecasting',
    progress: 'Data preview',
    error: 'Error - ',
    token: 'Token '
  },
  buttons: {
    detail: 'Learn more',
    change: 'Change',
    save: 'Save',
    update: 'Update',
    download: 'Download',
    download_all: 'Download All',
    delete: 'Delete',
    train: 'Train',
    retrain: 'Re-train',
    forecast: 'Forecast',
    settings: 'Model parameters',
    import: 'Forecast import',
    jupyter: 'Open Jupyter Notebook',
    preview: 'Preview',
    duplicate: 'Duplicate',
    test_connection: 'Test connection',
    upload_new: 'Upload a file',
    create_new_empty_file: 'Create a new empty file',
    start: 'Run',
    restart: 'Restart'
  },
  notifications: {
    story_trained: ['Story ', ' is trained!'],
    forecast_prepared: ['Forecast ', ' have been prepared and sent!'],
    success_registration: 'An email has been sent to you to confirm',
    reset_password: 'An email has been sent to your email to reset your password',
    renew_password: 'Your password has been reset',
    set_password: 'Your password has been set',
    username_updated: 'Username has been updated',
    new_user: 'The new user is registered, a confirmation email has been sent to the mailbox',
    compnay_name_updated: 'Company name has been updated',
    compnay_anaplan_updated: "Anaplan's login and password have been updated",
    password_updated: 'Your password has been updated',
    source_uploaded: 'Source has been uploaded!',
    source_updated: 'Source has been updated!',
    source_deleted: 'Source has been deleted!',
    story_started_train: 'Story has been started to train!',
    story_retrained: 'Story has been started to re-train!',
    story_deleted: 'Story has been deleted!',
    anaplan_export: 'Data import and story trainning have been started!',
    anaplan_import: 'Forecast preparation and data import have been started!',
    limit_train_time: 'Training time of the story should not exceed 20 minutes',
    story_error_base: ['There was an error while trainning story ', '!'],
    story_error_stop: ['Story ', ' trainning stopped!'],
    message_sent: 'Your request has been sent',
    test_connection_success: 'Connection works!',
    test_connection_failure: 'Connection error!',
    task_success: ['Task ', ' is successfully completed!'],
    task_failed: ['Task ', ' is executed with error!']
  },
  validations: {
    required: 'The field is required',
    max_length: ['The length of the name must not exceed ', ' characters'],
    min_length_password: ['Password length should not be less than ', ' characters'],
    max_length_password: ['The length of the password must not exceed ', ' characters'],
    username_format: 'The field should contain only letters, numbers, spaces and the symbol _',
    anaplan_name: 'Export / Import Name should contain only letters, numbers, spaces and the symbols _ and -',
    email: 'Incorrect email format',
    password_complexity: 'The password must contain at least one capital letter and one number',
    same_as: "Passwords don't match",
    equal_true: 'Consent required',
    company_format: 'The field should contain only letters, numbers, spaces and the symbols _ and -',
    empty_file: 'File is not attached',
    wrong_file: 'The data format is not acceptable. Available formats: xls, xlsx, xlsm, xlsb, odf, csv, json',
    wrong_photo: 'The data format is not acceptable. Available formats: jpeg, png, jfif',
    wrong_format: 'The field is not in the correct format',
    number: 'The field should be the number',
    message_format: 'The field should contain only letters, numbers, spaces and the symbols _.,!?',
    answer: 'Wrong answer to the question'
  },
  connections: {
    list: {
      name: 'Connections list',
      add: 'Add a connection',
      created: 'Created',
      type: 'Type'
    },
    detail: {
      new: 'New connection',
      name: 'Connection name',
      choose: 'Choose',
      connection_type: 'Connection type',
      anaplan: {
        login: 'Login',
        password: 'Password'
      },
      postgres: {
        host: 'Host',
        port: 'Port',
        database: 'DB name',
        user: 'DB user',
        password: 'DB password'
      },
      optimacros: {
        host: 'Service host',
        service: 'API service name',
        token: 'Auth token',
        user: 'FTP user',
        password: 'FTP password',
        add_column: 'Add a column'
      },
      csv: {
        file_name: 'Name',
        size: 'Size',
        rows: 'Rows',
        updated_at: 'Updated at',
        mb: ' Mb',
        create_empty_file: 'Create an empty file'
      }
    }
  },
  processes: {
    list: {
      name: 'Processes list',
      add: 'Add a process',
      created: 'Created'
    },
    detail: {
      new: 'New process',
      name: 'Process name',
      choose: 'Choose',
      source: 'Data source',
      target: 'Data target',
      anaplan: {
        procedures: 'Procedures'
      },
      csv: {
        files: 'Files'
      },
      optimacros: {
        type: 'Type',
        dimension: 'Dimension',
        measure: 'Cube/Property'
      },
      source_columns: 'Source columns',
      target_columns: 'Target columns',
      scheduler: {
        name: 'Process startup scheduler',
        one_time: 'Does Not Repeat',
        each: 'Once in ...',
        scheduler_start_date: 'Start date',
        frequency: 'Frequency',
        minute: 'Minute',
        hour: 'Hour',
        day: 'Day',
        week: 'Week',
        month: 'Month',
        week_days: 'Week days',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday'
      }
    }
  },
  tasks: {
    list: {
      name: 'Tasks list',
      add: 'Run a task',
      process: 'Process',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      status: 'Status',
      rows: 'Rows processed',
      choose: 'Choose',
      sync: 'Syncing',
      finished: 'Finished',
      error: 'Error'
    }
  },
  docs: {
    docs: 'Documentation',
    navs: ['Get Started', 'Account', 'Sources', 'Stories'],
    request: 'Request parameters',
    response: 'Response',
    json: 'JSON-response ',
    empty: 'None',
    where: 'where',
    ok: "The answer is 'ок!'",
    excel: 'Excel data file',
    get_started: 'Get Started',
    account: {
      name: 'Account',
      api: [
        'API',
        'Documentation on integration with Brain2Logic via API can be found at ',
      ],
      registration: [
        'Registration',
        'To create an account you need to register by clicking on the following link ',
        ` At registration it is necessary to specify the name and email of the user, and also the name of the company to which
        system users are tied in. After successful registration, the user will receive an email with
        to confirm the user's mail. After confirming the mail, the system will automatically send to login box `
      ],
      login: ['Login', 'To login, you need to click on the link ', ' and enter your email and password.'],
      change_account: ['Changing your account details', 'To change your account details, please click on the following link ', '. Here you can change your user and company name, change your password.'],
      new_user: ['Adding new users', 'To add new users, please click on the following link ', '. Here you can add new users.'],
      auth: ['Authorization of API requests', 'In all API queries you need add a header with the token of the user as '],
      api_login: 'Getting a token to access the API requests',
      username: 'user email',
      password: 'user password',
      token: 'unique user token'
    },
    sources: {
      name: 'Sources',
      list: [
        'List of sources',
        'By clicking here ',
        ` the current list of sources for stories is available (model trained on the selected source).
        Under each source there is an arrow, when you press it, a list of basic information about
        source: id source number (important for the API), download date and time, number of data lines and
        source size in megabytes.`
      ],
      add: [
        'Adding a source',
        `To add a new source, click on the green plus in the list of sources, and then
        go to the source download menu. In the new window, select the source file and assign a name to the source.
        Currently the following data formats are supported: Excel (.xls, .xlsx, .xlsm, .xlsb), OpenDocument.
        Format (.odf), CSV and JSON. The data in Excel should be located on the first sheet of the file, and the table headers
         located in the first line of the sheet Once the source name is entered and the source file is loaded, click on the button
         "Save" and the source is stored in the system.`
      ],
      update: ['Update source', 'To update the source, go to the corresponding source, select a new source file, change the source name (if necessary) and press the "Update" button.'],
      delete: ['Delete source', 'To delete a source, enter the corresponding source and press the "Delete" button.'],
      download: ['Download the source', 'To download the source, go to the corresponding source and press the "Download" button. The source will be downloaded in Excel (.xlsx) format.'],
      api_list: 'Get a list of all sources',
      api_list_json: 'JJSON response as an array of sources with parameters:',
      id: 'unique source number',
      source_name: 'source name',
      source_name_update: 'source name (optional, if name change is required)',
      ext: 'upload file extension',
      ready: 'if source is ready',
      rows: 'source row count',
      size: 'source size in bytes',
      created: 'source date and time created',
      updated: 'source date and time updated',
      company: 'unique company number to which the source belongs',
      columns: 'data column name list',
      values: 'first 100 rows of data values',
      api_get: 'Get the source by id',
      api_get_json: 'JSON-response by source with parameters:',
      api_add: 'Add a new source',
      add_header: 'You need to add a header of the form {"Content-Type": "multipart/form-data"} to load the source file.',
      file: 'data file with a valid format',
      api_put: 'Update source information by id',
      api_delete: 'Delete source by id',
      api_download: 'Download source data to Excel'
    },
    stories: {
      name: 'Stories',
      list: [
        'List of stories',
        'By clicking here ',
        ` the current list of stories is available. Under each source there is an arrow, as soon as you press it, the list of basic information about the story will appear:
        id story number (important for the API), date and time of update, last time of story training in minutes.`
      ],
      add: [
        'Add a story',
        `To add a new story, click on the green plus in the list of stories,
        and then go to the story menu. In the new window, select a source for story training, assign a name to the story.
        and select the type of forecast (as long as the Time series option is available). The Best model is selected by default in the Used models field (the best model in terms of accuracy is selected),
        but the user can choose several or one specific training model. After loading the source table, you must select the type of each data column in front of it.
        The following types are available:`,
        `On the right you can find general information about the source and the possibility to view the data on the graph.
        Once all the data have been filled in and selected, click on the "Train" button and the model will start learning in the background.`
      ],
      empty: ['The field is not used', 'system skips this field'],
      date_hours: ['Hour value', 'timescale field with up to hours aggregation'],
      date_days: ['Date value', 'timescale field with up to days aggregation'],
      date_week: ['Week value', 'timescale field with aggregation up to weeks'],
      date_months: ['Month value', 'timescale field with up to months of aggregation'],
      date_quarter: ['Quarter value', 'timescale field with up to quarters of aggregation'],
      date_years: ['Year value', 'timescale field with up to years of aggregation'],
      forecast_value: ['Forecast value', 'forecast target value'],
      feature: ['Feature value', 'affecting the forecast value, e.g. temperature, holidays, etc.'],
      category: ['Analytical value', 'a section of data for forecasting, e.g. products, markets, etc.'],
      get: [
        'View a story',
        `After completing story training, the user will receive a push notification in the browser window.
        After that we go to the corresponding story and see the results of story training. On the right you can see the information about the used forecast models and the absolute error.
        You can click on each model and see on the chart how different the model forecast and fact are. By default, the system selects the best model from those used.`
      ],
      put: ['Updating story', 'To update the story, enter the corresponding story, press the "Retrain" button and the model will start retraining in the background mode taking into account the new data.'],
      patch: [
        'Change the story',
        `To change the story, enter the corresponding story, press the "Change" button. Change the source, name and column types, if necessary.
        Press the "Retrain" button and the model will start retraining in the background mode, taking into account the new data.`
      ],
      delete: ['Delete the story', 'To delete the story, enter the corresponding story and press the "Delete" button.'],
      forecast: [
        'Getting a forecast',
        `To get a forecast of the story, go to the corresponding story, specify the forecast step (e.g. 12 -).
        forecast 12 months ahead of the last date of the story data) and click on the "Forecast" button. The forecast will be applied to the chart.`
      ],
      download: [
        'Download a forecast',
        `To download the forecast of the story, enter the corresponding story, specify the forecast step (e.g. 12 -).
        forecast 12 months ahead of the last date of the story data) and click on "Download". The forecast will be downloaded in Excel (.xlsx) format.`
      ],
      api_list: 'Get a list of all the stories',
      api_list_json: 'JSON-response as an array of stories with parameters:',
      id: 'unique story number',
      story_name: 'story name',
      ready: 'if story is readyv(if story is in the learning process, story is not ready)',
      source: 'unique source number used to teach the model',
      created: 'story date and time created',
      updated: 'story date and time updated',
      time: 'recent story learning time in minutes',
      api_get: 'Get story information by id',
      api_get_json: 'JSON-response by story with parameters:',
      data: 'model prediction results',
      company: 'unique company number, to which the story refers',
      columns: 'data column name list',
      values: 'data values',
      api_add: 'Add a new story',
      forecast_type: 'time_series (time series)',
      columns_list: `– column type array in the order of the columns in the source
      (empty (field not used), date_hours (hour value), date_days (date value), date_weeks (week value), date_months (month value), date_quarters (quarter value), date_years (year value), forecast (predicted value), feature (affecting value), category (analytical section).
      For example, ['date_days', 'forecast', 'empty', 'feature']`,
      models: `array of learning model types
      (best (default is a best model), SARIMAX, HWES, PROPHET, XGBOOST, CATBOOST, LIGHTGBM and LSTM.
       For example, ['best'] and ['SARIMAX', 'PROPHET']`,
      story_async_update: 'story began in the background of learning',
      api_put: 'Update story by id',
      api_patch: 'Update story structure by id',
      api_delete: 'Delete story by id',
      api_forecast: 'Get a forecast of story',
      steps: 'number of forecast steps (e.g. 12 - forecast 12 months ahead of the last date of story data)',
      model: `on which model to make a prediction
      (best (default is best model), SARIMAX, HWES, PROPHET, XGBOOST, CATBOOST, LIGHTGBM and LSTM.
       For example, 'best' and 'SARIMAX'.`,
      forecast_ready: 'If the story is in the process of learning (the parameter ready is false), the forecast will not be received and the corresponding answer will be given.',
      api_forecast_json: 'SON-response to the view forecast:',
      forecast_dates: 'date array for which the forecast is made',
      forecast_forecast: 'forecast values array',
      api_download: 'Download forecast data to Excel'
    }
  }
};
