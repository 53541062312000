<template>
  <v-card>
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t('docs.sources.name') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <h3 class="pb-2">{{ $t('docs.sources.list[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.sources.list[1]') }}<a :href="`/#/sources`">{{ `${host}/#/sources` }}</a>{{ $t('docs.sources.list[2]') }}</p>
      <h3 class="pb-2">{{ $t('docs.sources.add[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.sources.add[1]') }}</p>
      <h3 class="pb-2">{{ $t('docs.sources.update[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.sources.update[1]') }}</p>
      <h3 class="pb-2">{{ $t('docs.sources.delete[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.sources.delete[1]') }}</p>
      <h3 class="pb-2">{{ $t('docs.sources.download[0]') }}</h3>
      <p class="mb-1">{{ $t('docs.sources.download[1]') }}</p>
      <h3 class="pb-2">API</h3>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="primary--text pr-4">GET</span>
              <span class="primary--text pr-4">{{ host }}/source/</span>
              <span>{{ $t('docs.sources.api_list') }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1">{{ $t('docs.empty') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.sources.api_list_json') }}</p>
            <p class="mb-1"><span class="primary--text">id</span> – {{ $t('docs.sources.id') }}</p>
            <p class="mb-1"><span class="primary--text">name</span> – {{ $t('docs.sources.source_name') }}</p>
            <p class="mb-1"><span class="primary--text">file_extension</span> – {{ $t('docs.sources.ext') }}</p>
            <p class="mb-1"><span class="primary--text">ready</span> – {{ $t('docs.sources.ready') }}</p>
            <p class="mb-1"><span class="primary--text">data.rows</span> – {{ $t('docs.sources.rows') }}</p>
            <p class="mb-1"><span class="primary--text">data.size</span> – {{ $t('docs.sources.size') }}</p>
            <p class="mb-1"><span class="primary--text">created_at</span> – {{ $t('docs.sources.created') }}</p>
            <p class="mb-1"><span class="primary--text">updated_at</span> – {{ $t('docs.sources.updated') }}</p>
            <p class="mb-1"><span class="primary--text">company</span> – {{ $t('docs.sources.company') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="primary--text pr-4">GET</span>
              <span class="primary--text pr-4">{{ host }}/source/{id}/</span>
              <span>{{ $t('docs.sources.api_get') }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1">{{ $t('docs.empty') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.sources.api_get_json') }}</p>
            <p class="mb-1"><span class="primary--text">id</span> – {{ $t('docs.sources.id') }}</p>
            <p class="mb-1"><span class="primary--text">name</span> – {{ $t('docs.sources.source_name') }}</p>
            <p class="mb-1"><span class="primary--text">file_extension</span> – {{ $t('docs.sources.ext') }}</p>
            <p class="mb-1"><span class="primary--text">ready</span> – {{ $t('docs.sources.ready') }}</p>
            <p class="mb-1"><span class="primary--text">data.rows</span> – {{ $t('docs.sources.rows') }}</p>
            <p class="mb-1"><span class="primary--text">data.size</span> – {{ $t('docs.sources.size') }}</p>
            <p class="mb-1"><span class="primary--text">created_at</span> – {{ $t('docs.sources.created') }}</p>
            <p class="mb-1"><span class="primary--text">updated_at</span> – {{ $t('docs.sources.updated') }}</p>
            <p class="mb-1"><span class="primary--text">company</span> – {{ $t('docs.sources.company') }}</p>
            <p class="mb-1"><span class="primary--text">columns</span> – {{ $t('docs.sources.columns') }}</p>
            <p class="mb-1"><span class="primary--text">values</span> – {{ $t('docs.sources.values') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="orange--text pr-4">POST</span>
              <span class="primary--text pr-4">{{ host }}/source/0/</span>
              <span>{{ $t('docs.sources.api_add') }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1 font-weight-medium">{{ $t('docs.sources.add_header') }}</p>
            <p class="mb-1"><span class="primary--text">file</span> – {{ $t('docs.sources.file') }}</p>
            <p class="mb-1"><span class="primary--text">name</span> – {{ $t('docs.sources.source_name') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.json') }}</p>
            <p class="mb-1"><span class="primary--text">id</span> – {{ $t('docs.sources.id') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="green--text pr-4">PUT</span>
              <span class="primary--text pr-4">{{ host }}/source/{id}/</span>
              <span>{{ $t('docs.sources.api_put') }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1 font-weight-medium">{{ $t('docs.sources.add_header') }}</p>
            <p class="mb-1"><span class="primary--text">file</span> – {{ $t('docs.sources.file') }}</p>
            <p class="mb-1"><span class="primary--text">name</span> – {{ $t('docs.sources.source_name_update') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.ok') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="red--text pr-4">DELETE</span>
              <span class="primary--text pr-4">{{ host }}/source/{id}/</span>
              <span>{{ $t('docs.sources.api_delete') }}</span>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1">{{ $t('docs.empty') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.ok') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row>
              <span class="primary--text pr-4">GET</span>
              <span class="primary--text pr-4">{{ host }}/source/download/{id}/</span>
              <span>{{ $t('docs.sources.api_download') }}</span>
            </v-row>
            </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="pb-2">{{ $t('docs.request') }}</h3>
            <p class="mb-1">{{ $t('docs.empty') }}</p>
            <h3 class="pb-2">{{ $t('docs.response') }}</h3>
            <p class="mb-1">{{ $t('docs.excel') }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Sources',
  data() {
    return {
      host: '',
    };
  },
  mounted() {
    this.host = window.location.host;
  },
};
</script>
