<template>
  <v-main>
    <v-container fluid id="home">
      <v-row justify="start" class="mb-2 header-fixed-height pt-3">
        <v-btn icon color="primary" @click="$router.push('/processes')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <h2>{{ name }}</h2>
      </v-row>
      <v-row class="d-flex align-center">
        <v-btn color="primary" class="ml-2" v-if="id == 0" @click="save" outlined>{{ $t('buttons.save') }}</v-btn>
        <v-btn color="primary" class="ml-2" v-if="id != 0" @click="update" outlined>{{ $t('buttons.update') }}</v-btn>
        <v-btn color="warning" class="ml-2" v-if="id != 0" @click="deleteProcess" outlined>{{ $t('buttons.delete') }}</v-btn>
      </v-row>
      <v-row justify="start" class="mt-8">
        <v-col cols="6" class="py-0 px-2">
          <v-text-field :label="$t('processes.detail.name')" outlined dense v-model="name" :error-messages="nameMessage" type="text" />
        </v-col>
        <v-col cols="6" class="py-0 px-2">
          <v-select :items="scheduler.list" v-model="scheduler.value" hide-details outlined dense :label="$t('processes.detail.scheduler.name')"></v-select>
          <v-menu
            v-if="scheduler.value != ''" ref="menu1" v-model="menu1" :close-on-content-click="false"
            transition="scale-transition" offset-y min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-2" v-model="formatted_scheduler_start_date" :label="$t('processes.detail.scheduler.scheduler_start_date')" prepend-icon="mdi-calendar"
                readonly v-bind="attrs" v-on="on" clearable @click:clear="scheduler_start_date = null"
              ></v-text-field>
            </template>
            <v-date-picker v-model="scheduler_start_date" no-title scrollable first-day-of-week="1" :locale="locale" @change="menu1 = false">
            </v-date-picker>
          </v-menu>
          <v-row justify="start" class="mt-1" v-if="scheduler.value != ''">
            <v-col cols="6" class="py-0">
              <v-select :items="scheduler_frequency.list" v-model="scheduler_frequency.value" outlined dense :label="$t('processes.detail.scheduler.each')"></v-select>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-select v-if="scheduler_frequency.value == 'minute'" :items="scheduler_frequency_minute.list" v-model="scheduler_frequency_minute.value" outlined dense :label="$t('processes.detail.scheduler.frequency')"></v-select>
              <v-select v-else-if="scheduler_frequency.value == 'hour'" :items="scheduler_frequency_hour.list" v-model="scheduler_frequency_hour.value" outlined dense :label="$t('processes.detail.scheduler.frequency')"></v-select>
              <v-select v-else-if="scheduler_frequency.value == 'week'" :items="scheduler_frequency_week.list" v-model="scheduler_frequency_week.value" outlined dense :label="$t('processes.detail.scheduler.frequency')"></v-select>
              <v-select v-else-if="scheduler_frequency.value == 'month'" :items="scheduler_frequency_month.list" v-model="scheduler_frequency_month.value" outlined dense :label="$t('processes.detail.scheduler.frequency')"></v-select>
              <v-select v-else-if="scheduler_frequency.value == 'day'" :items="scheduler_frequency_day.list" v-model="scheduler_frequency_day.value" outlined dense :label="$t('processes.detail.scheduler.frequency')"></v-select>
            </v-col>
          </v-row>
          <v-select v-if="scheduler.value != ''" class="mt-2 mb-5 px-3" hide-details :items="scheduler_week_days.list" v-model="scheduler_week_days.values" dense outlined multiple :label="$t('processes.detail.scheduler.week_days')"></v-select>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row justify="start" class="pt-7">
        <v-col cols="6" class="py-0 px-2">
          <div class="blue white--text rounded-lg pa-1 mb-4">{{ $t('processes.detail.source') }}</div>
          <v-select :items="connections" v-model="source" @change="chooseSource('source')" outlined dense :label="$t('processes.detail.source')"></v-select>
          <v-select :items="anaplan.procedures" v-model="anaplan.procedure_value" v-if="sourceConnectionType == 1 && anaplan.procedures.length > 0" @change="getAnaplanColumns('source')" outlined dense :label="$t('processes.detail.anaplan.procedures')"></v-select>
          <v-select :items="csv.files" v-model="csv.file_value" v-if="sourceConnectionType == 2 && csv.files.length > 0" @change="getCSVColumns('source')" outlined dense :label="$t('processes.detail.csv.files')"></v-select>
          <v-text-field :label="$t('connections.detail.optimacros.service')" v-if="sourceConnectionType == 16" outlined dense v-model="optimacros.service" type="text" />
        </v-col>
        <v-col cols="6" class="py-0 px-2">
          <div class="blue white--text rounded-lg pa-1 mb-4">{{ $t('processes.detail.target') }}</div>
          <v-select :items="connections" v-model="target" @change="chooseSource('target')" outlined dense :label="$t('processes.detail.target')"></v-select>
          <v-select :items="anaplan.procedures" v-model="anaplan.procedure_value" v-if="targetConnectionType == 1 && anaplan.procedures.length > 0" @change="getAnaplanColumns('target')" outlined dense :label="$t('processes.detail.anaplan.procedures')"></v-select>
          <v-select :items="csv.files" v-model="csv.file_value" v-if="targetConnectionType == 2 && csv.files.length > 0" @change="getCSVColumns('target')" outlined dense :label="$t('processes.detail.csv.files')"></v-select>
          <v-text-field :label="$t('connections.detail.optimacros.service')" v-if="targetConnectionType == 16" outlined dense v-model="optimacros.service" type="text" />
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="6" class="py-0 px-2">
          <v-simple-table class="pb-3 pt-2" v-if="showColumns && sourceConnectionType !== 16">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <p class="table-header grey--text">#</p>
                  </th>
                  <th class="text-left">
                    <p class="table-header">{{ $t('processes.detail.source_columns') }}</p>
                  </th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(value, key) in targetColumns" :key="key">
                  <td class="grey--text">{{ key + 1 }}</td>
                  <td><v-select hide-details :items="sourceColumns" v-model="source_columns[key]" outlined dense label=" "></v-select></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="pb-3 pt-2" v-else-if="showColumns && sourceConnectionType == 16">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <p class="table-header">{{ $t('processes.detail.source_columns') }}</p>
                  </th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(value, key) in source_columns" :key="key">
                  <td>
                    <v-text-field label="" hide-details outlined dense v-model="value.name" type="text" />
                  </td>
                  <td class="px-0">
                    <v-tooltip bottom v-if="showColumns && sourceConnectionType == 16">
                      <template v-slot:activator="{ on }">
                        <v-btn small v-on="on" class="mx-2" @click="source_columns.splice(key, 1)" fab dark color="red">
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('connections.detail.optimacros.add_column') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-tooltip bottom v-if="showColumns && sourceConnectionType == 16">
            <template v-slot:activator="{ on }">
              <v-btn small v-on="on" class="mx-2" @click="addOptimacrosSourceColumn" fab dark color="green">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('connections.detail.optimacros.add_column') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6" class="py-0 px-2">
          <v-simple-table class="pb-3 pt-2" v-if="(showColumns && targetConnectionType !== 16) && (csv.columns.length > 0 && targetConnectionType == 2)">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <p class="table-header">{{ $t('processes.detail.target_columns') }}</p>
                  </th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(value, key) in targetColumns" :key="key">
                  <td>{{ value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="pb-3 pt-2" v-else-if="showColumns && sourceConnectionType == 16">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <p class="table-header">{{ $t('processes.detail.target_columns') }}</p>
                  </th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(value, key) in targetColumns" :key="key">
                  <td><v-text-field label="" hide-details outlined dense v-model="targetColumns[key]" type="text" /></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="pb-3 pt-2" v-else-if="showColumns && targetConnectionType == 16">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <p class="table-header">{{ $t('processes.detail.target_columns') }}</p>
                  </th>
                  <th class="text-left">
                    <p class="table-header">{{ $t('processes.detail.optimacros.type') }}</p>
                  </th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(value, key) in optimacros.columns" :key="key">
                  <td>
                    <v-text-field label="" hide-details outlined dense v-model="value.name" type="text" />
                  </td>
                  <td>
                    <v-select :items="optimacros.types_list" hide-details v-model="value.type" outlined dense label=""></v-select>
                  </td>
                  <td class="px-0">
                    <v-tooltip bottom v-if="showColumns && targetConnectionType == 16">
                      <template v-slot:activator="{ on }">
                        <v-btn small v-on="on" class="mx-2" @click="optimacros.columns.splice(key, 1)" fab dark color="red">
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('connections.detail.optimacros.add_column') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-tooltip bottom v-if="showColumns && targetConnectionType == 16">
            <template v-slot:activator="{ on }">
              <v-btn small v-on="on" class="mx-2" @click="addOptimacrosTargetColumn" fab dark color="green">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('connections.detail.optimacros.add_column') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';

export default {
  name: 'Process',
  mixins: [baseMixin],
  data() {
    return {
      id: this.$route.params.id * 1,
      load: false,
      name: this.$t('processes.detail.new'),
      connections: [],
      source: 0,
      target: 0,
      anaplan: {
        procedures: [],
        procedure_value: '',
        columns: []
      },
      csv: {
        files: [],
        file_value: '',
        columns: [],
        target_columns: []
      },
      optimacros: {
        service: '',
        columns: [],
        types_list: [
          { text: this.$t('processes.detail.optimacros.dimension'), value: 'dimension' },
          { text: this.$t('processes.detail.optimacros.measure'), value: 'measure' }
        ]
      },
      source_columns: [],
      scheduler: {
        list: [
          { text: this.$t('processes.detail.scheduler.one_time'), value: '' },
          { text: this.$t('processes.detail.scheduler.each'), value: 'each' }
        ],
        value: ''
      },
      // scheduler_start_date: (new Date(Date.now())).toISOString().substr(0, 10),
      scheduler_start_date: this.$date.format(this.$date.parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      scheduler_frequency: {
        list: [
          { text: this.$t('processes.detail.scheduler.minute'), value: 'minute' },
          { text: this.$t('processes.detail.scheduler.hour'), value: 'hour' },
          { text: this.$t('processes.detail.scheduler.day'), value: 'day' },
          { text: this.$t('processes.detail.scheduler.week'), value: 'week' },
          { text: this.$t('processes.detail.scheduler.month'), value: 'month' }
        ],
        value: 'hour'
      },
      scheduler_week_days: {
        list: [
          { text: this.$t('processes.detail.scheduler.monday'), value: 1 },
          { text: this.$t('processes.detail.scheduler.tuesday'), value: 2 },
          { text: this.$t('processes.detail.scheduler.wednesday'), value: 3 },
          { text: this.$t('processes.detail.scheduler.thursday'), value: 4 },
          { text: this.$t('processes.detail.scheduler.friday'), value: 5 },
          { text: this.$t('processes.detail.scheduler.saturday'), value: 6 },
          { text: this.$t('processes.detail.scheduler.sunday'), value: 7 }
        ],
        values: [1, 2, 3, 4, 5, 6, 7]
      },
      scheduler_frequency_minute: {
        list: [
          { text: 1, value: 1 },
          { text: 5, value: 5 },
          { text: 10, value: 10 },
          { text: 15, value: 15 },
          { text: 20, value: 20 },
          { text: 30, value: 30 }
        ],
        value: 10
      },
      scheduler_frequency_hour: {
        list: [
          { text: 1, value: 1 },
          { text: 2, value: 2 },
          { text: 3, value: 3 },
          { text: 4, value: 4 },
          { text: 6, value: 6 },
          { text: 8, value: 8 },
          { text: 12, value: 12 }
        ],
        value: 1
      },
      scheduler_frequency_week: {
        list: [
          { text: 1, value: 1 },
          { text: 2, value: 2 }
        ],
        value: 1
      },
      scheduler_frequency_month: {
        list: [
          { text: 1, value: 1 },
          { text: 2, value: 2 },
          { text: 3, value: 3 },
          { text: 4, value: 4 },
          { text: 6, value: 6 },
          { text: 12, value: 12 }
        ],
        value: 1
      },
      scheduler_frequency_day: {
        list: [
          { text: '00:00', value: '00:00' },
          { text: '01:00', value: '01:00' },
          { text: '02:00', value: '02:00' },
          { text: '03:00', value: '03:00' },
          { text: '04:00', value: '04:00' },
          { text: '05:00', value: '05:00' },
          { text: '06:00', value: '06:00' },
          { text: '07:00', value: '07:00' },
          { text: '08:00', value: '08:00' },
          { text: '09:00', value: '09:00' },
          { text: '10:00', value: '10:00' },
          { text: '11:00', value: '11:00' },
          { text: '12:00', value: '12:00' },
          { text: '13:00', value: '13:00' },
          { text: '14:00', value: '14:00' },
          { text: '15:00', value: '15:00' },
          { text: '16:00', value: '16:00' },
          { text: '17:00', value: '17:00' },
          { text: '18:00', value: '18:00' },
          { text: '19:00', value: '19:00' },
          { text: '20:00', value: '20:00' },
          { text: '21:00', value: '21:00' },
          { text: '22:00', value: '22:00' },
          { text: '23:00', value: '23:00' },
        ],
        value: 1
      },
      menu1: false,
      locale: 'en-US'
    };
  },
  computed: {
    formatted_scheduler_start_date () {
      return this.scheduler_start_date ? this.$date.format(this.$date.parseISO(this.scheduler_start_date), 'dd.MM.yyyy') : ''
    },
    nameMessage() {
      return this.getError('name') !== '' ? [this.getError('name')] : [];
    },
    sourceConnectionType() {
      return this.source == 0 ? null : this.connections.filter(x => x.value === this.source).map(x => x.connectionType)[0];
    },
    targetConnectionType() {
      return this.target == 0 ? null : this.connections.filter(x => x.value === this.target).map(x => x.connectionType)[0];
    },
    showColumns() {
      return this.sourceConnectionType != null && this.targetConnectionType != null;
    },
    sourceColumns() {
      if (this.source == 0) {
        return [];
      } else {
        let values = [{ text: this.$t('processes.detail.choose'), value: '' }];
        if (this.sourceConnectionType == 1) {
          this.anaplan.columns.forEach(value => {
            values.push({ text: value, value});
          });
          return values;
        } else if (this.sourceConnectionType == 2) {
          this.csv.columns.forEach(value => {
            values.push({ text: value, value});
          });
          return values;
        } else if (this.sourceConnectionType == 16) {
          return this.source_columns;
        } else if (this.sourceConnectionType == 3) {
          return [];
        }
      }
    },
    targetColumns() {
      if (this.target == 0) {
        return [];
      } else {
        if (this.targetConnectionType == 1) {
          if (this.anaplan.columns.length == 0 && this.sourceColumns.length > 0) {
            if (this.anaplan.procedure_value == '') {
              return [];
            }
            return this.sourceColumns.filter(x => x.value !== '').map(x => x.value);
          }
          return this.anaplan.columns;
        } else if (this.targetConnectionType == 2) {
          if (this.sourceConnectionType == 16) {
            return this.csv.target_columns;
          }
          if (this.csv.columns.length == 0 && this.sourceColumns.length > 0) {
            if (this.csv.file_value == '') {
              return [];
            }
            return this.sourceColumns.filter(x => x.value !== '').map(x => x.value);
          }
          return this.csv.columns;
        } else if (this.targetConnectionType == 3) {
          return [];
        } else if (this.targetConnectionType == 16) {
          return this.optimacros.columns;
        }
      }
    }
  },
  async created() {
    window.addEventListener('resize', this.$lodash.debounce(this.getWindowHeight, 500));
    this.getWindowHeight();
    let lang = localStorage.getItem('b2l-lang');
    this.locale = (lang == null || lang != 'ru') ? 'en-US' : 'ru-RU';
    await this.getConnections();
    if (this.id !== 0) {
      const data =  await this.$fetch.get(`api/processes/${this.id}`);
      this.name = data.name;
      this.source = data.params.source_id;
      this.target = data.params.target_id;
      this.anaplan.procedure_value = data.params.procedure_value;
      this.optimacros.service = 'optimacros_service' in data.params ? data.params.optimacros_service : '';
      this.csv.file_value = data.params.file_value;
      this.scheduler.value = data.params.scheduler;
      if (this.sourceConnectionType == 1) {
        // Anaplan
        await this.getAnaplanProcedures('source')
        await this.getAnaplanColumns('source')
      } else if (this.sourceConnectionType == 2) {
        // CSV
        await this.getCSVFiles('source')
        await this.getCSVColumns('source')
      } else if (this.sourceConnectionType == 3) {
        // PostgreSQL
      }

      if (this.targetConnectionType == 1) {
        // Anaplan
        await this.getAnaplanProcedures('target')
        await this.getAnaplanColumns('target')
      } else if (this.targetConnectionType == 2) {
        // CSV
        await this.getCSVFiles('target')
        await this.getCSVColumns('target')
        if (this.source_columns.length == 0) {
          let target_columns = [];
          data.params.columns.forEach((value, index) => {
            target_columns.push(value.target);
          })
          this.csv.target_columns = target_columns;
        }
      } else if (this.targetConnectionType == 16) {
        // Optimacros
        data.params.columns.forEach(value => {
          this.optimacros.columns.push(value.target);
        })
      } else if (this.targetConnectionType == 3) {
        // PostgreSQL
      }

      let columns = []
      data.params.columns.forEach((value, index) => {
        columns.push(value.source);
      })
      this.source_columns = columns;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$lodash.debounce(this.getWindowHeight, 500));
  },
  methods: {
    getWindowHeight() {
      this.windowHeight = document.documentElement.clientHeight;
    },
    async getConnections() {
      await this.$fetch
        .get('api/connections')
        .then(data => {
          let connections = [{ text: this.$t('processes.detail.choose'), value: 0 }];
          data.forEach(value => {
            connections.push({ text: value.name, value: value.id, connectionType: value.connectionType});
          });
          this.connections = connections;
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    chooseSource(source_type) {
      let id = source_type == 'source' ? this.source : this.target;
      let connectionType = this.connections.filter(x => x.value === id).map(x => x.connectionType)[0];
      if (connectionType == 1) {
        // Anaplan
        this.getAnaplanProcedures(source_type);
      } else if (connectionType == 2) {
        // CSV
        this.getCSVFiles(source_type);
      } else if (connectionType == 3) {
        // PostgreSQL
      }
    },
    async getAnaplanProcedures(source_type) {
      let id = source_type == 'source' ? this.source : this.target;
      let procedure_type = source_type == 'source' ? 'exports' : 'imports'
      await this.$fetch
        .get(`/api/anaplan/${id}/procedures/${procedure_type}`)
        .then(data => {
          let procedures = [{ text: this.$t('processes.detail.choose'), value: '' }];
          data.forEach(value => {
            procedures.push({ text: value.name, value: value.id});
          });
          this.anaplan.procedures = procedures;
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    async getAnaplanColumns(source_type) {
      let id = source_type == 'source' ? this.source : this.target;
      let procedure_type = source_type == 'source' ? 'exports' : 'imports'
      await this.$fetch
        .get(`/api/anaplan/${id}/procedures/${procedure_type}/${this.anaplan.procedure_value}`)
        .then(data => {
          this.anaplan.columns = data;
          if (source_type == 'source') {
            this.source_columns = new Array(data.length).fill('');
          }
          this.autoMappingColumns();
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    async getCSVFiles(source_type) {
      let id = source_type == 'source' ? this.source : this.target;
      await this.$fetch
        .get(`/api/csv/${id}`)
        .then(data => {
          let files = [{ text: this.$t('processes.detail.choose'), value: '' }];
          data.forEach(value => {
            files.push({ text: value.name, value: value.id});
          });
          this.csv.files = files;
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    async getCSVColumns(source_type) {
      let id = source_type == 'source' ? this.source : this.target;
      await this.$fetch
        .get(`/api/csv/${id}/file/${this.csv.file_value}`)
        .then(data => {
          this.csv.columns = data;
          if (source_type == 'source') {
            this.source_columns = new Array(data.length).fill('');
          }
          this.autoMappingColumns();
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    autoMappingColumns() {
      if (this.source_columns.length > 0) {
        let sourceColumns = this.sourceColumns.filter(x => x.value !== '').map(x => x.value);
        this.targetColumns.forEach((value, index) => {
          let sourceColumn = sourceColumns.filter(x => x === value)
          if (sourceColumn.length == 1) {
            this.source_columns[index] = sourceColumn[0];
          }
        })
      }
    },
    addOptimacrosSourceColumn() {
      this.source_columns.push({name: '', type: ''});
      this.csv.target_columns.push('');
    },
    addOptimacrosTargetColumn() {
      this.optimacros.columns.push({name: '', type: ''});
    },
    save() {
      this.serverErrors = {};
      let columns = this.source_columns.map((x, i) => { return {source: x, target: this.targetColumns[i]}});
      columns = columns.filter(x => x.source !== '')
      let data = {
        name: this.name,
        source_id: this.source,
        target_id: this.target,
        procedure_value: this.anaplan.procedure_value,
        optimacros_service: this.optimacros.service,
        file_value: this.csv.file_value,
        scheduler: this.scheduler.value,
        columns
      };
      this.$fetch
        .post('/api/processes', data)
        .then(() => {
          this.$router.push('/processes');
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    update() {
      this.serverErrors = {};
      let columns = this.source_columns.map((x, i) => { return {source: x, target: this.targetColumns[i]}});
      columns = columns.filter(x => x.source !== '')
      let data = {
        name: this.name,
        source_id: this.source,
        target_id: this.target,
        procedure_value: this.anaplan.procedure_value,
        optimacros_service: this.optimacros.service,
        file_value: this.csv.file_value,
        scheduler: this.scheduler.value,
        columns
      };
      this.$fetch
        .put(`/api/processes/${this.id}`, data)
        .then(() => {
          this.$router.push('/processes');
        })
        .catch(error => {
          this.serverErrors = error;
        });
    },
    deleteProcess() {
      this.serverErrors = {};
      this.$fetch
        .delete(`api/processes/${this.id}/`)
        .then(() => {
          this.$router.push('/processes');
        })
        .catch(error => {
          this.serverErrors = error;
        });
    }
  }
};
</script>