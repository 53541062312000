<template>
  <v-main>
    <v-container class="fill-height auth-form" fluid>
      <v-row align="center" justify="center">
        <v-col cols="11" sm="6" md="6">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>{{ $t('auth.set_password') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pb-0">
              <v-form>
                <v-row class="px-2">
                  <v-text-field :label="$t('auth.password')" id="password" v-model="password" :error-messages="passwordMessage" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" />
                </v-row>
                <v-row class="px-2">
                  <v-text-field :label="$t('auth.confirm_password')" id="passwordConfirm" v-model="passwordConfirm" :error-messages="passwordConfirmMessage" :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'" :type="showPasswordConfirm ? 'text' : 'password'" @click:append="showPasswordConfirm = !showPasswordConfirm" />
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="green" id="reset" @click="setPassword" block :loading="load">{{ $t('auth.renew_password') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import baseMixin from '@/mixins/baseMixin';
import passwordMixin from '@/mixins/passwordMixin';
import passwordConfirmMixin from '@/mixins/passwordConfirmMixin';

export default {
  name: 'SetPassword',
  mixins: [baseMixin, passwordMixin, passwordConfirmMixin],
  data() {
    return {
      token: this.$route.params.token,
      load: false
    };
  },
  methods: {
    setPassword() {
      this.$v.$touch();
      this.serverErrors = {};
      if (!this.$v.$invalid) {
        this.load = true;
        this.$fetch
          .post('/api/users/invite/password', {
            password1: this.password,
            password2: this.passwordConfirm,
            token: this.token
          })
          .then(() => {
            this.$notify.success({ message: this.$t('notifications.set_password') });
            this.$router.push('/login');
          })
          .catch(error => {
            this.load = false;
            this.serverErrors = error;
            this.serverErrors.password = this.getError('password1');
            this.serverErrors.passwordConfirm = this.getError('password2');
          });
      }
    }
  }
};
</script>
