<template>
  <canvas id="chart" :width="size.width" :height="size.height"></canvas>
</template>

<script>
import Chart from '@/plugins/chart';

export default {
  name: 'DistributionChart',
  props: ['labels', 'scores', 'dtype', 'size'],
  data() {
    return {
      type: 'bar',
      options: {
        responsive: true,
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'category',
              position: 'bottom',
              scaleLabel: {
                display: true,
                labelString: ''
              },
            }
          ],
          yAxes: [
            {
              type: 'linear',
              scaleLabel: {
                display: true,
                labelString: '',
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: () => ''
          }
        },
        legend: {
          display: false
        },
      },
      myChart: {}
    };
  },
  mounted() {
    const ctx = document.getElementById('chart');
    if (this.dtype === 'object' || this.dtype.includes('datetime')) {
      this.options.scales.xAxes[0].scaleLabel.display = false;
      this.options.scales.xAxes[0].type = 'category';
      this.options.scales.xAxes[0].scaleLabel.labelString = this.$t('source.detail.categories_values');
      this.options.scales.yAxes[0].scaleLabel.labelString = this.$t('source.detail.category_count');
      this.options.scales.yAxes[0].ticks = {
        callback: value => this.$numeral(value).format('0,0'),
        min: 0
      };
      this.options.tooltips.callbacks.label = (tooltipItem) => {
        let label = `${this.$t('source.detail.categories_values')} - ${tooltipItem.xLabel}`;
        label += `, ${this.$t('source.detail.category_count')} - ${this.$numeral(tooltipItem.yLabel).format('0,0.0')}`;
        return label;
      };
    } else if (this.dtype.includes('int') || this.dtype.includes('float')) {
      this.options.scales.xAxes[0].scaleLabel.display = true;
      // this.options.scales.xAxes[0].type = 'linear';
      this.options.scales.xAxes[0].ticks = {
        callback: value => this.$numeral(value).format('0,0'),
        // min: 0
      };
      this.options.scales.xAxes[0].scaleLabel.labelString = this.$t('source.detail.numerical_values');
      this.options.scales.yAxes[0].scaleLabel.labelString = this.$t('source.detail.value_distribution');
      this.options.scales.yAxes[0].ticks = {
        callback: value => this.$numeral(value).format('0,0.00'),
        // min: 0
      };
      this.options.tooltips.callbacks.label = (tooltipItem) => {
        let label = `${this.$t('source.detail.numerical_values')} - ${this.$numeral(tooltipItem.xLabel).format('0,0')}`;
        label += `, ${this.$t('source.detail.value_distribution')} - ${this.$numeral(tooltipItem.yLabel).format('0,0.00')}`;
        return label;
      };
    }
    // if (this.dtype === 'object' || this..dtype.includes('datetime')) {
    //     return [];
    //   } else if (this.new_desc.dtype.includes('int') || this.new_desc.dtype.includes('float')) {

    //   }

    this.myChart = new Chart(ctx, {
      type: this.type,
      data: {
        labels: this.labels,
        datasets: [
          {
            label: 'My First Dataset',
            data: this.scores,
            backgroundColor: '#01579B',
            borderColor: '#01579B',
            fillColor: '#79D1CF',
            strokeColor: '#79D1CF',
            borderWidth: 1
          }
        ]
      },
      options: this.options
    });
  },
  methods: {
    updateChart(data) {
      this.myChart.data.labels = data.dist.category;
      this.myChart.data.datasets[0].data = data.dist.values;
      this.myChart.options = this.options;
      if (data.dtype === 'object' || data.dtype.includes('datetime')) {
        this.myChart.options.scales.xAxes[0].scaleLabel.display = false;
        this.myChart.options.scales.xAxes[0].type = 'category';
        this.myChart.options.scales.xAxes[0].scaleLabel.labelString = this.$t('source.detail.categories_values');
        this.myChart.options.scales.yAxes[0].scaleLabel.labelString = this.$t('source.detail.category_count');
        this.myChart.options.scales.yAxes[0].ticks = {
          callback: value => this.$numeral(value).format('0,0'),
          min: 0
        };
        this.myChart.options.tooltips.callbacks.label = (tooltipItem) => {
          let label = `${this.$t('source.detail.categories_values')} - ${tooltipItem.xLabel}`;
          label += `, ${this.$t('source.detail.category_count')} - ${this.$numeral(tooltipItem.yLabel).format('0,0')}`;
          return label;
        };
      } else if (data.dtype.includes('int') || data.dtype.includes('float')) {
        this.myChart.options.scales.xAxes[0].scaleLabel.display = true;
        // this.myChart.options.scales.xAxes[0].type = 'linear';
        this.myChart.options.scales.xAxes[0].ticks = {
          callback: value => this.$numeral(value).format('0,00'),
          // min: 0
        };
        this.myChart.options.scales.xAxes[0].scaleLabel.labelString = this.$t('source.detail.numerical_values');
        this.myChart.options.scales.yAxes[0].scaleLabel.labelString = this.$t('source.detail.value_distribution');
        // this.myChart.options.scales.yAxes[0].ticks = { callback: value => this.$numeral(value).format('0,0') };
        this.myChart.options.scales.yAxes[0].ticks = {
          callback: value => this.$numeral(value).format('0,0.0'),
          // min: 0
        };
        this.myChart.options.tooltips.callbacks.label = (tooltipItem) => {
          let label = `${this.$t('source.detail.numerical_values')} - ${this.$numeral(tooltipItem.xLabel).format('0,0')}`;
          label += `, ${this.$t('source.detail.value_distribution')} - ${this.$numeral(tooltipItem.yLabel).format('0,0.00')}`;
          return label;
        };
      }
      // this.myChart.canvas.style.height = this.size.height + 'px';
      // this.myChart.canvas.style.width = this.size.width + 'px';
      this.myChart.update();
    }
  },
  watch: {
    size() {
      this.myChart.canvas.style.height = this.size.height + 'px';
      this.myChart.canvas.style.width = this.size.width + 'px';
      this.myChart.update();
    },
    labels() {
      this.myChart.canvas.style.height = this.size.height + 'px';
      this.myChart.canvas.style.width = this.size.width + 'px';
      this.myChart.update();
    },
  }
};
</script>
