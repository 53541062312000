<template>
  <div class="p-0 m-0" style="position: relative">
    <canvas id="overlay" :width="size.width" :height="size.height" style="position: absolute; pointer-events: none; left: 0; top: 0"></canvas>
    <canvas id="chart" :width="size.width" :height="size.height"></canvas>
  </div>
</template>

<script>
import Chart from '@/plugins/chart';

export default {
  name: 'LineChart',
  props: ['chartData', 'datesType', 'size', 'features'],
  data() {
    return {
      type: 'line',
      options: {
        responsive: true,
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              // distribution: 'linear',
              time: {
                parser: 'dd.MM.yyyy',
                tooltipFormat: 'dd.MM.yyyy',
                unit: 'month',
                displayFormats: {
                  millisecond: 'MMM yy',
                  second: 'MMM yy',
                  minute: 'MMM yy',
                  hour: 'MMM yy',
                  day: 'dd.MM.yyyy',
                  week: 'MMM yy',
                  month: 'MMM yy',
                  quarter: 'MMM yy',
                  year: 'MMM yy'
                }
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                callback: value => this.$numeral(value).format('0,0.')
              }
            }
          ],
          // yAxes: [
          //   {
          //     id: 'A',
          //     ticks: {
          //       callback: value => this.$numeral(value).format('0,0.')
          //     },
          //     type: 'linear',
          //     position: 'left'
          //   },
          //   {
          //     id: 'B',
          //     ticks: {
          //       callback: value => this.$numeral(value).format('0,0.')
          //     },
          //     type: 'linear',
          //     position: 'right'
          //   }
          // ]
        },
        elements: {
          point: {
            radius: 0
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += this.$numeral(tooltipItem.yLabel).format('0,0.');
              return label;
            }
          }
        }
      },
      myChart: {},
      ctx: {},
      startIndex: 0,
      selectionRect: {
        w: 0,
        startX: 0,
        startY: 0
      },
      overlay: {},
      selectionContext: {},
      drag: false,
      innerChartData: {}
    };
  },
  mounted() {
    this.options.elements.point.radius = this.datesType === 'date_days' ? 0 : 3;
    if (this.features) {
      this.options.scales.yAxes = [
        {
          id: 'A',
          ticks: {
            callback: value => this.$numeral(value).format('0,0.')
          },
          type: 'linear',
          position: 'left'
        },
        {
          id: 'B',
          ticks: {
            callback: value => this.$numeral(value).format('0,0.')
          },
          type: 'linear',
          position: 'right'
        }
      ];
    }
    this.ctx = document.getElementById('chart');
    this.ctx.style.height = this.size.height + 'px';
    this.ctx.style.width = this.size.width + 'px';

    this.innerChartData = this.$lodash.cloneDeep(this.chartData);

    this.myChart = new Chart(this.ctx, {
      type: this.type,
      data: this.innerChartData,
      options: this.options
    });

    this.overlay = document.getElementById('overlay');
    this.selectionContext = this.overlay.getContext('2d');

    this.overlay.style.height = this.size.height + 'px';
    this.overlay.style.width = this.size.width + 'px';

    this.ctx.addEventListener('pointerdown', evt => this.pointerdown(evt));
    this.ctx.addEventListener('pointermove', evt => this.pointermove(evt));
    this.ctx.addEventListener('pointerup', evt => this.pointerup(evt));
    this.ctx.addEventListener('contextmenu', evt => this.rightClick(evt));
  },
  methods: {
    updateChart(data) {
      this.options.elements.point.radius = this.datesType === 'date_days' ? 0 : 3;
      this.innerChartData = this.$lodash.cloneDeep(data);
      this.innerChartData.datasets.forEach((dataset, key) => {
        if (key > (this.myChart.data.datasets.length - 1)) {
          this.myChart.data.datasets.push(dataset);
        } else {
          this.myChart.data.datasets[key].data = this.innerChartData.datasets[key].data;
          this.myChart.data.datasets[key].label = this.innerChartData.datasets[key].label;
          this.myChart.data.datasets[key].backgroundColor = this.innerChartData.datasets[key].backgroundColor;
          this.myChart.data.datasets[key].borderColor = this.innerChartData.datasets[key].borderColor;
          this.myChart.data.datasets[key].borderWidth = this.innerChartData.datasets[key].borderWidth;
        }
      });
      this.myChart.data.datasets.forEach((dataset, key) => {
        if (key > (this.innerChartData.datasets.length - 1)) {
          this.myChart.data.datasets.splice(key);
        }
      });
      this.myChart.data.labels = this.innerChartData.labels;
      // this.myChart.data = this.innerChartData;
      this.myChart.update();
    },
    pointerdown(evt) {
      const points = this.myChart.getElementsAtEventForMode(evt, 'index', {
        intersect: false
      });
      this.startIndex = points[0]._index;
      const rect = this.ctx.getBoundingClientRect();
      this.selectionRect.startX = evt.clientX - rect.left;
      this.selectionRect.startY = this.myChart.chartArea.top;
      this.drag = true;
    },
    pointermove(evt) {
      const rect = this.ctx.getBoundingClientRect();
      if (this.drag) {
        this.selectionRect.w = evt.clientX - rect.left - this.selectionRect.startX;
        this.selectionContext.globalAlpha = 0.5;
        this.selectionContext.clearRect(0, 0, this.ctx.width, this.ctx.height);
        this.selectionContext.fillRect(this.selectionRect.startX, this.selectionRect.startY, this.selectionRect.w, this.myChart.chartArea.bottom - this.myChart.chartArea.top);
      } else {
        this.selectionContext.clearRect(0, 0, this.ctx.width, this.ctx.height);
        let x = evt.clientX - rect.left;
        if (x > this.myChart.chartArea.left) {
          this.selectionContext.fillRect(x, this.myChart.chartArea.top, 1, this.myChart.chartArea.bottom - this.myChart.chartArea.top);
        }
      }
    },
    pointerup(evt) {
      const points = this.myChart.getElementsAtEventForMode(evt, 'index', {
        intersect: false
      });
      this.drag = false;
      if (this.startIndex !== points[0]._index) {
        let start = this.startIndex < points[0]._index ? this.startIndex : points[0]._index;
        let end = points[0]._index > this.startIndex ? points[0]._index : this.startIndex;
        this.innerChartData.labels = this.innerChartData.labels.slice(start, end);
        this.innerChartData.datasets.forEach((dataset, index) => {
          this.innerChartData.datasets[index].data = this.innerChartData.datasets[index].data.slice(start, end);
        });
        this.myChart.data.datasets = this.innerChartData.datasets;
        this.myChart.data.labels = this.innerChartData.labels;
        this.myChart.update();
      }
    },
    rightClick(evt) {
      evt.preventDefault();
      let isRightClick = false;
      if ('which' in evt) {
        // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
        isRightClick = evt.which === 3;
      } else if ('button' in evt) {
        // IE, Opera
        isRightClick = evt.button === 2;
      }
      if (isRightClick && this.chartData.labels.length !== this.innerChartData.labels.length) {
        this.innerChartData = this.$lodash.cloneDeep(this.chartData);
        this.myChart.data = this.innerChartData;
        this.myChart.update();
      }
    }
  },
  beforeDestroy() {
    this.ctx.removeEventListener('pointerdown', evt => this.pointerdown(evt));
    this.ctx.removeEventListener('pointermove', evt => this.pointermove(evt));
    this.ctx.removeEventListener('pointerup', evt => this.pointerup(evt));
    this.ctx.removeEventListener('contextmenu', evt => this.rightClick(evt));
  },
  watch: {
    size() {
      this.myChart.canvas.style.height = this.size.height + 'px';
      this.myChart.canvas.style.width = this.size.width + 'px';
      this.overlay.style.height = this.size.height + 'px';
      this.overlay.style.width = this.size.width + 'px';
      this.myChart.update();
    }
  }
};
</script>
